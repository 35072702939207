<template xmlns:svg="http://www.w3.org/1999/xhtml">
  <svg
    viewBox="0 0 399.7 209.8"
    xml:space="preserve"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <svg:style>
      {{ style }}
    </svg:style>

    <g>
      <g>
        <path
          clip-rule="evenodd"
          d="M266.37,105.93v9.91h2.1h0.56h1.83h0.56h1.83h0.56h1.83h0.56
        h1.82h0.56h1.83h0.56h1.83h0.56h1.83h0.56h1.83h0.56h1.82h0.56h1.82h0.56h1.82h0.56h1.83h0.56h1.83h0.56h1.83h0.56h1.82h0.56h1.82
        h0.56h1.83h0.56h1.83h0.56h2.11v-9.91H266.37z M313.53,115.34h-1.55v-0.91h-0.56v0.91h-1.83v-0.91h-0.56v0.91h-1.83v-0.91h-0.56
        v0.91h-1.82v-2.33h-0.56v2.33h-1.82v-0.91h-0.56v0.91h-1.83v-0.91h-0.56v0.91h-1.83v-0.91h-0.56v0.91h-1.83v-2.33h-0.56v2.33
        h-1.82v-0.91h-0.56v0.91h-1.82v-0.91h-0.56v0.91h-1.82v-0.91h-0.56v0.91h-1.83v-2.33h-0.56v2.33h-1.83v-0.91h-0.56v0.91h-1.83
        v-0.91h-0.56v0.91h-1.83v-0.91h-0.56v0.91h-1.82v-2.33h-0.56v2.33h-1.83v-0.91h-0.56v0.91h-1.83v-0.91h-0.56v0.91h-1.83v-0.91
        h-0.56v0.91h-1.55v-8.91h46.6V115.34z"
          fill="#FFFFFF"
          fill-rule="evenodd"
        />
        <path
          clip-rule="evenodd"
          d="M270.54,111.14c0.04,0.03,0.06,0.08,0.06,0.14
        c0.01,0.21-0.18,0.26-0.33,0.26c-0.18,0-0.35-0.08-0.34-0.28c0-0.07,0.03-0.11,0.07-0.14v-2.76c-0.04-0.03-0.04-0.09-0.04-0.13
        c0-0.2,0.16-0.27,0.3-0.27c0.15,0,0.32,0.07,0.32,0.22c0,0.08-0.03,0.13-0.04,0.15v0.84h0.01c0.05-0.08,0.14-0.19,0.29-0.32
        l0.7-0.66c0.07-0.16,0.15-0.23,0.3-0.23c0.07,0,0.3,0.06,0.3,0.24c-0.01,0.09-0.11,0.24-0.24,0.26l-0.96,0.89l1.36,1.75
        c0.11,0.02,0.19,0.11,0.19,0.17c0,0.12-0.17,0.28-0.36,0.28c-0.22,0-0.29-0.1-0.29-0.18l-1.02-1.36c-0.13-0.17-0.22-0.3-0.27-0.42
        h-0.01V111.14z"
          fill="#FFFFFF"
          fill-rule="evenodd"
        />
        <path
          clip-rule="evenodd"
          d="M275.25,109.52c0,1.21-0.74,2.11-1.64,2.11
        c-0.78,0-1.03-0.83-1.03-1.44c0-1.1,0.75-2.37,1.59-2.37C274.73,107.81,275.25,108.28,275.25,109.52 M273.14,110.12
        c0,0.5,0.15,1.09,0.59,1.09c0.47,0,0.98-0.72,0.98-1.67c0-0.86-0.22-1.3-0.6-1.3C273.68,108.23,273.14,109.23,273.14,110.12z"
          fill="#FFFFFF"
          fill-rule="evenodd"
        />
        <path
          clip-rule="evenodd"
          d="M276.36,111.14c0.02,0.06,0.04,0.13,0.04,0.18
        c0,0.14-0.13,0.23-0.28,0.23c-0.27,0-0.36-0.08-0.36-0.21c0-0.04,0.02-0.16,0.06-0.17v-1.25c-0.06-0.04-0.1-0.12-0.1-0.17
        c0-0.12,0-0.22,0.1-0.25v-1.13c-0.03-0.06-0.05-0.12-0.05-0.16c0-0.14,0.17-0.23,0.37-0.23c0.08,0,0.25,0.1,0.25,0.22
        c0,0.07,0,0.13-0.05,0.17v1.05l1.29-0.07v-0.97c-0.05-0.05-0.05-0.14-0.05-0.19c0-0.14,0.17-0.22,0.31-0.22
        c0.12,0,0.31,0.11,0.31,0.2c0,0.06-0.01,0.11-0.04,0.16v2.83c0.02,0.04,0.04,0.11,0.04,0.17c0,0.12-0.18,0.21-0.32,0.21
        c-0.18,0-0.32-0.1-0.32-0.25c0-0.06,0.04-0.15,0.07-0.18v-1.31l-1.29,0.08V111.14z"
          fill="#FFFFFF"
          fill-rule="evenodd"
        />
        <path
          clip-rule="evenodd"
          d="M280.01,111.16c0.02,0.03,0.06,0.08,0.06,0.14
        c0,0.14-0.1,0.25-0.37,0.25c-0.18,0-0.26-0.08-0.26-0.22c0-0.08,0.01-0.14,0.04-0.19v-2.6l-0.5,0.11
        c-0.05,0.03-0.11,0.06-0.19,0.06c-0.16,0-0.26-0.09-0.26-0.23c0-0.15,0.11-0.26,0.23-0.26c0.1,0,0.13,0.02,0.16,0.03l0.59-0.13
        c0.07-0.07,0.3-0.11,0.41-0.07l0.64-0.12c0.07-0.03,0.12-0.04,0.18-0.04c0.13,0,0.24,0.07,0.24,0.25c0,0.13-0.1,0.25-0.24,0.25
        c-0.08,0-0.13-0.02-0.18-0.04l-0.55,0.11V111.16z"
          fill="#FFFFFF"
          fill-rule="evenodd"
        />
        <path
          clip-rule="evenodd"
          d="M281.26,110.86v-2.51c-0.11-0.18,0.04-0.35,0.25-0.35
        c0.24,0,0.26,0.2,0.26,0.3v2.61c0.05,0.13,0.18,0.26,0.38,0.26c0.64,0,0.93-0.42,0.93-0.57v-2.28c-0.04-0.03-0.06-0.07-0.06-0.12
        c0-0.14,0.15-0.24,0.34-0.24c0.12,0,0.25,0.07,0.25,0.21c0,0.04,0,0.08-0.03,0.11v2.39c0,0.1-0.1,0.37-0.26,0.52
        c-0.34,0.28-0.72,0.44-1.17,0.44C281.45,111.62,281.27,111.18,281.26,110.86"
          fill="#FFFFFF"
          fill-rule="evenodd"
        />
        <path
          clip-rule="evenodd"
          d="M284.35,110.86v-2.51c-0.1-0.18,0.04-0.35,0.25-0.35
        c0.24,0,0.26,0.2,0.26,0.3v2.61c0.05,0.13,0.18,0.26,0.38,0.26c0.64,0,0.93-0.42,0.93-0.57v-2.28c-0.04-0.03-0.06-0.07-0.06-0.12
        c0-0.14,0.15-0.24,0.34-0.24c0.12,0,0.25,0.07,0.25,0.21c0,0.04,0,0.08-0.03,0.11v2.39c0,0.1-0.1,0.37-0.26,0.52
        c-0.34,0.28-0.72,0.44-1.17,0.44C284.54,111.62,284.36,111.18,284.35,110.86"
          fill="#FFFFFF"
          fill-rule="evenodd"
        />
        <path
          clip-rule="evenodd"
          d="M288,111.14l0.77-0.11c0.05-0.04,0.16-0.07,0.2-0.07
        c0.12,0,0.27,0.05,0.27,0.24c0,0.19-0.1,0.28-0.24,0.28c-0.04,0-0.12-0.01-0.17-0.03l-0.88,0.12c-0.06,0.01-0.17,0.03-0.25,0.03
        c-0.18,0-0.31-0.07-0.31-0.23c0-0.1,0.04-0.16,0.09-0.19v-2.77c-0.02-0.04-0.05-0.1-0.05-0.14c0-0.22,0.16-0.29,0.33-0.29
        c0.14,0,0.3,0.05,0.3,0.29c0,0.05-0.04,0.11-0.06,0.15V111.14z"
          fill="#FFFFFF"
          fill-rule="evenodd"
        />
        <path
          clip-rule="evenodd"
          d="M290.26,111.14l0.77-0.11c0.05-0.04,0.16-0.07,0.21-0.07
        c0.11,0,0.26,0.05,0.26,0.24c0,0.19-0.11,0.28-0.24,0.28c-0.05,0-0.11-0.01-0.17-0.03l-0.88,0.12c-0.06,0.01-0.17,0.03-0.25,0.03
        c-0.18,0-0.32-0.07-0.32-0.23c0-0.1,0.04-0.16,0.09-0.19v-2.77c-0.02-0.04-0.04-0.1-0.04-0.14c0-0.22,0.15-0.29,0.33-0.29
        c0.15,0,0.3,0.05,0.3,0.29c0,0.05-0.04,0.11-0.06,0.15V111.14z"
          fill="#FFFFFF"
          fill-rule="evenodd"
        />
        <path
          clip-rule="evenodd"
          d="M292.51,111.11c0.04,0.05,0.08,0.11,0.08,0.18
        c0,0.12-0.13,0.26-0.34,0.26c-0.22,0-0.36-0.1-0.36-0.26c0-0.07,0.04-0.14,0.09-0.2v-2.64c-0.05-0.03-0.07-0.1-0.07-0.16
        c0-0.18,0.2-0.31,0.38-0.31c0.24,0,0.31,0.17,0.31,0.3c0,0.07-0.02,0.14-0.09,0.21V111.11z"
          fill="#FFFFFF"
          fill-rule="evenodd"
        />
        <path
          clip-rule="evenodd"
          d="M295.43,108.5c0,0.12-0.04,0.23-0.24,0.23
        c-0.23,0-0.32-0.09-0.31-0.2c-0.05-0.09-0.19-0.16-0.36-0.16c-0.43,0-0.89,0.45-0.89,0.86c0,0.21,0.08,0.29,0.22,0.29
        c0.13,0,0.31-0.04,0.57-0.11c0.25-0.08,0.43-0.12,0.67-0.12c0.41,0,0.71,0.24,0.71,0.72c0,0.31-0.07,0.57-0.22,0.78
        c-0.35,0.5-1.08,0.82-1.63,0.82c-0.64,0-0.86-0.53-0.86-0.7c0-0.15,0.12-0.3,0.3-0.3c0.19,0,0.21,0.22,0.27,0.35
        c0.06,0.15,0.17,0.24,0.34,0.24c0.37,0,0.88-0.26,1.11-0.6c0.11-0.15,0.13-0.36,0.13-0.57c0-0.25-0.11-0.32-0.28-0.32
        c-0.19,0-0.34,0.05-0.6,0.12c-0.25,0.08-0.44,0.11-0.66,0.11c-0.35,0-0.64-0.17-0.64-0.65c0-0.6,0.69-1.33,1.57-1.33
        C295.05,107.96,295.43,108.29,295.43,108.5"
          fill="#FFFFFF"
          fill-rule="evenodd"
        />
        <path
          clip-rule="evenodd"
          d="M296.87,111.08l1.18-0.08c0.05-0.05,0.11-0.08,0.18-0.08
        c0.2,0,0.33,0.13,0.33,0.25c0,0.22-0.18,0.26-0.48,0.26l-1.21,0.08c-0.04,0.05-0.15,0.09-0.33,0.09c-0.15,0-0.28-0.09-0.28-0.26
        c0-0.09,0.04-0.18,0.08-0.23v-1.06c-0.07-0.07-0.11-0.16-0.11-0.24c0-0.1,0.06-0.2,0.11-0.24v-0.91
        c-0.08-0.04-0.13-0.11-0.13-0.19c0-0.1,0.04-0.16,0.09-0.19c-0.02-0.03-0.04-0.07-0.04-0.1c0-0.11,0.15-0.19,0.33-0.19
        c0.17,0,0.27,0.07,0.28,0.18l0.97-0.09c0.05-0.03,0.14-0.08,0.21-0.08c0.23,0,0.34,0.11,0.34,0.24c0,0.18-0.12,0.3-0.33,0.3
        c-0.08,0-0.12-0.01-0.19-0.03l-0.99,0.1v0.93l0.75-0.1c0.06-0.04,0.13-0.08,0.2-0.08c0.2,0,0.32,0.14,0.32,0.26
        c0,0.19-0.13,0.27-0.35,0.27c-0.05,0-0.08-0.02-0.12-0.04l-0.81,0.11V111.08z"
          fill="#FFFFFF"
          fill-rule="evenodd"
        />
        <path
          clip-rule="evenodd"
          d="M301.23,108.5c0,0.12-0.05,0.23-0.24,0.23
        c-0.23,0-0.32-0.09-0.32-0.2c-0.05-0.09-0.18-0.16-0.36-0.16c-0.44,0-0.89,0.45-0.89,0.86c0,0.21,0.08,0.29,0.22,0.29
        c0.13,0,0.3-0.04,0.57-0.11c0.25-0.08,0.43-0.12,0.67-0.12c0.41,0,0.71,0.24,0.71,0.72c0,0.31-0.07,0.57-0.22,0.78
        c-0.35,0.5-1.08,0.82-1.63,0.82c-0.64,0-0.86-0.53-0.86-0.7c0-0.15,0.12-0.3,0.3-0.3c0.19,0,0.21,0.22,0.26,0.35
        c0.06,0.15,0.18,0.24,0.34,0.24c0.38,0,0.88-0.26,1.11-0.6c0.11-0.15,0.14-0.36,0.14-0.57c0-0.25-0.11-0.32-0.28-0.32
        c-0.19,0-0.34,0.05-0.6,0.12c-0.25,0.08-0.45,0.11-0.66,0.11c-0.35,0-0.64-0.17-0.64-0.65c0-0.6,0.69-1.33,1.56-1.33
        C300.85,107.96,301.23,108.29,301.23,108.5"
          fill="#FFFFFF"
          fill-rule="evenodd"
        />
        <path
          clip-rule="evenodd"
          d="M303.21,111.16c0.02,0.03,0.06,0.08,0.06,0.14
        c0,0.14-0.1,0.25-0.37,0.25c-0.18,0-0.26-0.08-0.26-0.22c0-0.08,0.01-0.14,0.04-0.19v-2.6l-0.49,0.11
        c-0.05,0.03-0.11,0.06-0.19,0.06c-0.16,0-0.26-0.09-0.26-0.23c0-0.15,0.12-0.26,0.23-0.26c0.1,0,0.13,0.02,0.16,0.03l0.59-0.13
        c0.07-0.07,0.3-0.11,0.41-0.07l0.64-0.12c0.07-0.03,0.11-0.04,0.19-0.04c0.12,0,0.23,0.07,0.23,0.25c0,0.13-0.1,0.25-0.23,0.25
        c-0.08,0-0.13-0.02-0.18-0.04l-0.56,0.11V111.16z"
          fill="#FFFFFF"
          fill-rule="evenodd"
        />
        <path
          clip-rule="evenodd"
          d="M305.01,111.11c0.04,0.05,0.08,0.11,0.08,0.18
        c0,0.12-0.14,0.26-0.34,0.26c-0.22,0-0.36-0.1-0.36-0.26c0-0.07,0.04-0.14,0.08-0.2v-2.64c-0.05-0.03-0.06-0.1-0.06-0.16
        c0-0.18,0.2-0.31,0.38-0.31c0.24,0,0.31,0.17,0.31,0.3c0,0.07-0.02,0.14-0.09,0.21V111.11z"
          fill="#FFFFFF"
          fill-rule="evenodd"
        />
        <path
          clip-rule="evenodd"
          d="M305.67,111.26c0-0.15,0.12-0.35,0.35-0.35
        c0.16,0,0.27,0.09,0.27,0.31c0,0.17-0.1,0.35-0.35,0.35C305.81,111.56,305.67,111.47,305.67,111.26"
          fill="#FFFFFF"
          fill-rule="evenodd"
        />
        <path
          clip-rule="evenodd"
          d="M307.53,111.11c0.03,0.04,0.08,0.12,0.08,0.21
        c0,0.16-0.18,0.25-0.39,0.25c-0.22-0.01-0.32-0.07-0.32-0.24c0-0.12,0.04-0.2,0.1-0.26v-1.05c-0.07-0.06-0.11-0.16-0.11-0.24
        c0-0.08,0.05-0.21,0.11-0.26v-0.83c-0.05-0.01-0.12-0.14-0.12-0.23c0-0.1,0.03-0.17,0.12-0.18c-0.02-0.19,0.18-0.28,0.36-0.28
        c0.12,0,0.23,0.07,0.18,0.18l0.95-0.15c0.11-0.01,0.15-0.06,0.24-0.06c0.16,0,0.32,0.08,0.31,0.23c0,0.23-0.08,0.32-0.31,0.32
        c-0.08,0-0.14-0.02-0.22-0.05l-0.96,0.16v0.88l0.87-0.14c0.04-0.04,0.12-0.08,0.26-0.08c0.11,0,0.22,0.17,0.22,0.26
        c0,0.25-0.09,0.3-0.27,0.3c-0.07,0-0.14,0-0.22-0.04l-0.85,0.14V111.11z"
          fill="#FFFFFF"
          fill-rule="evenodd"
        />
        <path
          clip-rule="evenodd"
          d="M310.01,111.11c0.04,0.05,0.08,0.11,0.08,0.18
        c0,0.12-0.13,0.26-0.34,0.26c-0.22,0-0.36-0.1-0.36-0.26c0-0.07,0.04-0.14,0.09-0.2v-2.64c-0.06-0.03-0.07-0.1-0.07-0.16
        c0-0.18,0.2-0.31,0.38-0.31c0.24,0,0.31,0.17,0.31,0.3c0,0.07-0.02,0.14-0.09,0.21V111.11z"
          fill="#FFFFFF"
          fill-rule="evenodd"
        />
      </g>
      <g>
        <path
          clip-rule="evenodd"
          d="M325.44,79.7l-0.33-0.11c-0.21-0.06-0.43-0.11-0.65-0.14
        c-0.16-0.02-0.3-0.01-0.43,0.11c1.3,1.1,1.54,3.92,1.53,5.27c0,1.36-0.44,4.24-1.64,5.32l-0.13,0.11
        c-0.12,0.11-0.02,0.14,0.07,0.14c0.25,0.01,0.54-0.05,0.89-0.12l0.23-0.06c0.09-0.03,0.19-0.06,0.28-0.09
        c1.23-0.51,2.18-2.44,2.18-5.3C327.44,82.01,326.59,80.2,325.44,79.7 M323.99,88.52c0.48-1.09,0.8-2.48,0.82-3.7
        c0.02-1.75-0.26-3.26-0.74-4.29c-0.32-0.68-0.72-1.18-1.19-1.36c-0.11-0.05-0.4-0.11-0.66-0.13c-0.35-0.03-0.65-0.06-0.9,0.13
        c1.36,1.13,1.69,4.24,1.69,5.61c0,0.56-0.1,1.48-0.2,1.98c-0.09,0.41-0.26,1.07-0.41,1.03c-0.02-0.01-0.04-0.03-0.04-0.15
        l0.05-0.8c0.02-0.46,0.01-0.99-0.03-1.75c-0.03-0.56-0.1-1.07-0.19-1.59c-0.11-0.73-0.28-1.41-0.48-2.01
        c-0.41-1.21-0.97-2.12-1.6-2.43c-0.14-0.06-0.23-0.1-0.39-0.12c-0.16-0.01-0.26-0.01-0.41-0.01c-0.4-0.01-0.87,0.02-1.11,0.16v0.1
        c1.38,0.83,2.2,3.85,2.41,6.35l0.07,0.98c0.01,0.38,0.02,0.75,0,1.2c-0.05,0.81-0.09,1.07-0.19,1.57
        c-0.04,0.22-0.09,0.42-0.15,0.64l-0.13,0.38l-0.08,0.23c-0.04,0.1,0.05,0.14,0.09,0.15c0.34,0.07,0.9,0.02,1.21-0.01
        c0.3-0.03,0.57-0.09,0.86-0.21c0.48-0.19,0.74-0.4,0.97-0.67c0.19-0.23,0.36-0.5,0.53-0.81
        C323.86,88.83,323.99,88.52,323.99,88.52z M319.91,89.18c-0.21,0.02-0.41,0.04-0.61,0.05c-0.18,0-0.31,0-0.52-0.02
        c-0.18-0.02-0.21-0.07-0.1-0.33l0.11-0.32c0.32-1.05,0.51-2.23,0.51-3.72c0-2.83-0.79-5.28-1.97-5.64
        c-0.1-0.03-0.18-0.05-0.31-0.05l-0.35,0.01c-0.39,0.04-0.69,0.1-1,0.34c0.02,0.03,0.07,0.06,0.16,0.11
        c1.12,0.79,1.56,3.61,1.54,5.24c-0.02,1.18-0.19,3.28-0.92,4.54c-0.14,0.25-0.32,0.49-0.55,0.68l-0.16,0.13
        c-0.1,0.08-0.05,0.11-0.01,0.15l0.17,0.07c0.05,0.02,0.12,0.04,0.21,0.06c0.71,0.14,1.34,0.23,2.69,0.26
        c0.35,0,0.63-0.07,0.77-0.21c0.19-0.21,0.29-0.39,0.43-1.01c0-0.01,0.03-0.17,0.03-0.27C320.01,89.2,320.01,89.17,319.91,89.18z
         M314.64,87.55c-0.32,0-0.55-1.08-0.55-2.56c0-1.47,0.23-2.55,0.55-2.55c0.32,0,0.55,1.08,0.55,2.56
        C315.19,86.47,314.96,87.55,314.64,87.55z M314.71,79.7c-1.09,0-1.92,2.36-1.92,5.16c0,2.81,0.83,5.16,1.92,5.16
        c1.09,0.01,1.92-2.35,1.92-5.16C316.62,82.07,315.8,79.71,314.71,79.7z"
          fill="#FFFFFF"
          fill-rule="evenodd"
        />
        <path
          clip-rule="evenodd"
          d="M313.21,92.39c0.11-0.02,0.25-0.03,0.4-0.03
        c0.23,0,0.4,0.03,0.53,0.15c0.12,0.11,0.17,0.27,0.17,0.43c0,0.21-0.07,0.36-0.17,0.47c-0.12,0.13-0.32,0.18-0.48,0.18h-0.08v0.74
        h-0.38V92.39z M313.59,93.28l0.06,0c0.19,0,0.28-0.13,0.28-0.32c0-0.17-0.07-0.31-0.25-0.31l-0.09,0.02V93.28z M315.7,93.31
        c0,0.74-0.26,1.04-0.63,1.04c-0.44,0-0.62-0.48-0.62-1.01c0-0.54,0.21-1,0.65-1C315.56,92.34,315.7,92.86,315.7,93.31z
         M314.84,93.34c0,0.44,0.08,0.67,0.24,0.67c0.16,0,0.22-0.29,0.22-0.69c0-0.35-0.05-0.66-0.22-0.66
        C314.92,92.67,314.84,92.92,314.84,93.34z M315.9,93.93c0.08,0.04,0.21,0.08,0.32,0.08c0.18,0,0.27-0.09,0.27-0.22
        c0-0.14-0.09-0.21-0.25-0.32c-0.27-0.16-0.37-0.37-0.37-0.55c0-0.31,0.21-0.57,0.62-0.57c0.13,0,0.25,0.03,0.31,0.07l-0.06,0.33
        c-0.05-0.04-0.14-0.07-0.25-0.07c-0.16,0-0.24,0.1-0.24,0.2c0,0.11,0.06,0.18,0.27,0.31c0.26,0.16,0.36,0.36,0.36,0.56
        c0,0.36-0.27,0.59-0.65,0.59c-0.16,0-0.31-0.04-0.37-0.07L315.9,93.93z M317.09,92.36h0.38v1.96h-0.38V92.36z M318.02,92.71h-0.35
        v-0.35h1.08v0.35h-0.35v1.61h-0.38V92.71z M318.95,92.36h0.39v1.96h-0.39V92.36z M319.68,92.36h0.38v1.96h-0.38V92.36z
         M320.68,94.32l-0.44-1.96h0.42l0.14,0.84c0.04,0.21,0.08,0.45,0.11,0.68l0.1-0.69l0.13-0.83h0.42l-0.45,1.96H320.68z
         M321.72,92.36h0.38v1.96h-0.38V92.36z M322.4,93.93c0.08,0.04,0.21,0.08,0.32,0.08c0.18,0,0.27-0.09,0.27-0.22
        c0-0.14-0.09-0.21-0.26-0.32c-0.27-0.16-0.37-0.37-0.37-0.55c0-0.31,0.21-0.57,0.62-0.57c0.13,0,0.25,0.03,0.31,0.07l-0.06,0.33
        c-0.05-0.04-0.14-0.07-0.25-0.07c-0.16,0-0.24,0.1-0.24,0.2c0,0.11,0.05,0.18,0.27,0.31c0.26,0.16,0.36,0.36,0.36,0.56
        c0,0.36-0.27,0.59-0.65,0.59c-0.16,0-0.31-0.04-0.38-0.07L322.4,93.93z M324.42,93.46h-0.45v0.54h0.5v0.33h-0.89v-1.96h0.85v0.33
        h-0.47v0.45h0.45V93.46z M324.7,93.93c0.07,0.04,0.21,0.08,0.32,0.08c0.18,0,0.27-0.09,0.27-0.22c0-0.14-0.09-0.21-0.26-0.32
        c-0.27-0.16-0.37-0.37-0.37-0.55c0-0.31,0.21-0.57,0.62-0.57c0.13,0,0.25,0.03,0.31,0.07l-0.06,0.33
        c-0.06-0.04-0.14-0.07-0.25-0.07c-0.17,0-0.25,0.1-0.25,0.2c0,0.11,0.06,0.18,0.28,0.31c0.26,0.16,0.35,0.36,0.35,0.56
        c0,0.36-0.26,0.59-0.65,0.59c-0.16,0-0.31-0.04-0.38-0.07L324.7,93.93z M326.1,92.71h-0.35v-0.35h1.08v0.35h-0.35v1.61h-0.38
        V92.71z M327.03,92.36h0.39v1.96h-0.39V92.36z M313.17,96.84c0.08,0.04,0.21,0.08,0.32,0.08c0.18,0,0.27-0.09,0.27-0.22
        c0-0.15-0.09-0.21-0.25-0.32c-0.27-0.16-0.37-0.37-0.37-0.55c0-0.31,0.21-0.58,0.62-0.58c0.12,0,0.25,0.04,0.31,0.07l-0.06,0.33
        c-0.06-0.03-0.14-0.06-0.25-0.06c-0.17,0-0.24,0.1-0.24,0.21c0,0.11,0.06,0.17,0.27,0.3c0.26,0.16,0.36,0.36,0.36,0.56
        c0,0.36-0.27,0.6-0.65,0.6c-0.16,0-0.31-0.04-0.38-0.08L313.17,96.84z M314.73,95.27v1.28c0,0.28,0.09,0.37,0.19,0.37
        c0.11,0,0.19-0.08,0.19-0.37v-1.28h0.38v1.21c0,0.5-0.19,0.78-0.57,0.78c-0.41,0-0.57-0.29-0.57-0.78v-1.21H314.73z M316.98,96.22
        c0,0.74-0.26,1.04-0.63,1.04c-0.45,0-0.62-0.48-0.62-1.01c0-0.54,0.21-1,0.65-1C316.84,95.25,316.98,95.77,316.98,96.22z
         M316.12,96.26c0,0.44,0.08,0.67,0.23,0.67c0.16,0,0.22-0.29,0.22-0.69c0-0.35-0.05-0.66-0.22-0.66
        C316.2,95.58,316.12,95.83,316.12,96.26z M318.42,96.55c-0.01-0.19-0.03-0.44-0.02-0.65l-0.13,0.6l-0.17,0.71h-0.27l-0.16-0.69
        c-0.04-0.16-0.09-0.41-0.12-0.61l-0.04,0.66l-0.03,0.68h-0.33l0.14-1.96h0.4l0.16,0.71c0.06,0.25,0.11,0.47,0.14,0.69
        c0.03-0.22,0.08-0.44,0.13-0.69l0.15-0.71h0.4l0.12,1.96h-0.34L318.42,96.55z M319.39,96.78l-0.09,0.45h-0.37l0.43-1.96h0.46
        l0.38,1.96h-0.37l-0.08-0.45H319.39z M319.7,96.49l-0.07-0.41l-0.06-0.44l-0.08,0.44l-0.07,0.41H319.7z M320.41,95.27h0.38v1.64
        h0.5v0.32h-0.88V95.27z M321.85,96.78l-0.09,0.45h-0.37l0.43-1.96h0.46l0.38,1.96h-0.37l-0.08-0.45H321.85z M322.17,96.49
        l-0.06-0.41l-0.06-0.44l-0.07,0.44l-0.08,0.41H322.17z M322.87,95.27h0.38v1.96h-0.38V95.27z M323.6,97.24v-1.96h0.35l0.3,0.77
        l0.21,0.57c-0.01-0.21-0.03-0.55-0.03-0.91v-0.44h0.33v1.96h-0.35l-0.3-0.75c-0.06-0.16-0.16-0.41-0.2-0.59
        c0,0.19,0.02,0.5,0.02,0.89v0.44H323.6z M325.93,96.37h-0.45v0.53h0.5v0.34h-0.88v-1.96h0.85v0.33h-0.47v0.45h0.45V96.37z
         M326.24,97.24v-1.96h0.35l0.31,0.77l0.21,0.57c0-0.21-0.03-0.55-0.03-0.91v-0.44h0.34v1.96h-0.35l-0.3-0.75
        c-0.06-0.16-0.16-0.41-0.2-0.59c0,0.19,0.02,0.5,0.02,0.89v0.44H326.24z"
          fill="#FFFFFF"
          fill-rule="evenodd"
        />
        <path
          clip-rule="evenodd"
          d="M346.42,83.94c-0.28,0.21-0.53,0.33-0.56,0.28
        c-0.03-0.05,0.16-0.25,0.43-0.45c0.28-0.2,0.53-0.32,0.56-0.27C346.88,83.53,346.69,83.74,346.42,83.94 M346.9,83.56l0-0.03
        l-0.01-0.06l-0.01-0.06l-0.01-0.02l-0.01-0.01l-0.01-0.01l-0.01,0h-0.01l-0.02,0l-0.01,0h-0.01l-0.01,0l-0.02,0.01l-0.06,0.03
        l-0.09,0.05l-0.04,0.03l-0.04,0.02l-0.03,0.03l-0.05,0.04l-0.04,0.02l-0.05,0.04l-0.11,0.08l-0.16,0.12l-0.04,0.03l-0.05,0.04
        l-0.05,0.04l-0.03,0.03l-0.03,0.03l-0.04,0.04l-0.03,0.04l-0.02,0.03l-0.01,0.02l-0.01,0.02l0,0.02l0,0.02l0,0.02l0,0.02
        l0.01,0.01l0.01,0.01l0.03,0.01l0.03,0.01l0.03,0.01l0.69,0.7l0.76-0.66L346.9,83.56z"
          fill="#FFFFFF"
          fill-rule="evenodd"
        />
        <path
          clip-rule="evenodd"
          d="M347.26,86.43l0,0.02l-0.01,0.04l0,0.04l-0.01,0.05l-0.01,0.05
        l-0.01,0.05l-0.01,0.04l-0.01,0.05l-0.01,0.06l-0.01,0.06l-0.03,0.11l-0.04,0.1l-0.01,0.06l-0.02,0.06l-0.02,0.06l-0.03,0.07
        l-0.02,0.04l-0.01,0.04l-0.02,0.04l-0.02,0.05l-0.02,0.04l-0.02,0.05l-0.02,0.04l-0.02,0.04l-0.03,0.05l-0.03,0.05l-0.03,0.05
        l-0.02,0.03l-0.02,0.04l-0.03,0.04l-0.03,0.04l-0.03,0.03l-0.02,0.03l-0.03,0.03l-0.03,0.03l-0.23-0.2l-0.19-0.17l-0.18-0.15
        l-3.65,3.15l0.01,0l0.07,0.02l0.08,0.03l0.15,0.05l0.02,0.01l0.02,0.03l0.01,0.04l0.01,0.04l0.01,0.04l0.01,0.04l0.01,0.03l0,0.04
        l0.01,0.04l0.01,0.04l0,0.04l0,0.03l0,0.06l0,0.05v0.05v0.03v0.03v0.03l0,0.03l0,0.04l0,0.02l-0.01,0.03l0,0.02l-0.01,0.03l0,0.03
        l-0.01,0.04l-0.01,0.03l-0.01,0.03l-0.01,0.04l-0.01,0.03l-0.01,0.03l-0.01,0.03l-0.01,0.03l-0.02,0.03l-0.02,0.03l-0.02,0.03
        l-0.02,0.04l-0.03,0.05l-0.11,0.18l-0.13,0.22l-0.04,0.06l-0.02,0.04l-0.02,0.04l-0.02,0.05l-0.02,0.04l-0.01,0.05l-0.02,0.05
        l-0.01,0.05l-0.01,0.05l-0.01,0.05l-0.01,0.06l-0.01,0.07l0,0.06l0,0.07l0,0.05v0.07l0,0.06l0,0.06l0,0.07l0.01,0.07l0.01,0.06
        l0.02,0.08l0.01,0.06l0.02,0.07l0.02,0.07l0.02,0.06l0.03,0.07l0.04,0.1l0.05,0.11l0.05,0.1l0.06,0.12l0.05,0.11l0.06,0.12
        l0.06,0.12l0.06,0.12l0.05,0.11c0.22,0.04,0.45,0.07,0.69,0.1c0.23,0.02,0.45,0.03,0.68,0.03c0.88,0,1.73-0.16,2.52-0.45
        l-0.04-0.09l-0.12-0.25l-0.06-0.13l-0.05-0.1l-0.02-0.05l-0.01-0.03l-0.01-0.02l0-0.02l0-0.01l0.1-0.09l0.09-0.08l0.1-0.08
        l0.1-0.08l0.1-0.08l0.05-0.04l0.05-0.04l0.05-0.05l0.05-0.04l0.05-0.05l0.05-0.05l0.04-0.05l0.03-0.03l0.02-0.02l0.02-0.03
        l0.02-0.03l0.03-0.03l0.02-0.03l0.03-0.04l0.03-0.04l0.03-0.05l0.03-0.05l0.02-0.05l0.04-0.05l0.03-0.05l0.02-0.04l0.02-0.04
        l0.02-0.05l0.02-0.04l0.02-0.05l0.02-0.05l0.02-0.05l0.01-0.04l0.02-0.05l0.01-0.04l0.01-0.04l0.01-0.04l0.01-0.04l0.01-0.04
        l0-0.03l0.01-0.04l0.01-0.05l0-0.05l0-0.05l0-0.06v-0.05v-0.05v-0.04v-0.05l0-0.06l0-0.06l-0.01-0.06l-0.01-0.07l-0.01-0.06
        l-0.01-0.07l-0.02-0.06l-0.02-0.06l-0.02-0.07l-0.02-0.05l-0.02-0.06l-0.02-0.04l-0.02-0.04l-0.02-0.04l-0.02-0.03l-0.02-0.04
        l-0.03-0.06l-0.04-0.06l-0.07-0.11l-0.07-0.11l-0.07-0.11l-0.07-0.1l-0.15-0.22l-0.16-0.22l-0.08-0.11l-0.07-0.09l-0.05-0.06
        l-0.05-0.06l-0.05-0.07l-0.05-0.05l-0.02-0.06l-0.02-0.06l0.13,0.05l0.13,0.04l0.18,0.05l0.17,0.05l0.03,0l0.03,0h0.03h0.02h0.02
        l0.02,0l0.02,0l0.02-0.01l0.01,0l0.02,0l0.01-0.01l0.01-0.01l0.02-0.01l0.01-0.01l0.01-0.01l0.01-0.02l0.01-0.01l0.01-0.02
        l0.01-0.03l0.02-0.03l0.01-0.02l0.01-0.03l0.01-0.03l0.01-0.03l0.01-0.05l0.02-0.06l0.03-0.1l0.02-0.08l0.02-0.08l0.02-0.08
        l0.02-0.09l0.02-0.1l0.06-0.2l0.03-0.11l0.08-0.33l0.05-0.28l0.03-0.16l0.01-0.12l0.02-0.12l0.01-0.12l0.01-0.09l0.01-0.09
        l0.01-0.11l0-0.07l0-0.07l0-0.08l0-0.09l0.01-0.08l0.01-0.09l0-0.06l0.01-0.08l0.02-0.08l0.01-0.06l0.01-0.06l0.01-0.06l0-0.05
        l0-0.07l0-0.07l0-0.11L347.26,86.43z"
          fill="#FFFFFF"
          fill-rule="evenodd"
        />
        <path
          clip-rule="evenodd"
          d="M341.62,89.26l-0.07-0.03l-0.06-0.02l-0.06-0.03l-0.06-0.03
        l-0.06-0.02l-0.05-0.02l-0.06-0.02l-0.07-0.02l-0.01-0.02l0-0.01l0-0.01v-0.01l0-0.01l0.01-0.01l0.03-0.01l0.04-0.03l0.04-0.03
        l0.03-0.03l0.03-0.02l0.03-0.02l0.02-0.01l0.02-0.02l0.02-0.01l0.02-0.02l0.01-0.01l0.03-0.02l0.04-0.03l0.02-0.02l0.03-0.02
        l0.04-0.03l0.03-0.02l0.03-0.02l0.02-0.02l0.02-0.02l0.03-0.02l0.02-0.02l0.02-0.02l0.02-0.02l0.01-0.01l0.02-0.03l0.02-0.02
        l0.01-0.02l0.02-0.03l0.02-0.03l0.04-0.05l0.01-0.02l0.01-0.01l0.01-0.01l0.01-0.01H342h0.01l0.01,0h0.01l0.02,0.01l0.04,0.04
        l0.04,0.04l0.04,0.04l0.03,0.04l0.03,0.04l0.03,0.05l0.03,0.04l0.02,0.05l0.03,0.06l2.55-2.2l0,0l-0.06-0.05l-0.05-0.04
        l-0.05-0.04l-0.05-0.03l-0.04-0.03l-0.04-0.03l-0.04-0.02l-0.03-0.01l-0.04-0.02l-0.04-0.01l-0.02,0l-0.03,0l-0.03,0l-0.03,0
        l-0.06-0.02l-0.05-0.02l-0.04-0.02l-0.04-0.02l-0.03-0.01l-0.01-0.01l-0.01-0.01l-0.01-0.01l-0.01-0.01L344.07,86l-0.01-0.02
        l0-0.02l-0.01-0.03l-0.01-0.06l-0.01-0.07l-0.02-0.14l0-0.06l0-0.07l-0.01-0.07v-0.07v-0.07v-0.06l0.01-0.04l0.01-0.04l0.01-0.02
        l0.01-0.03l0.03-0.04l0.02-0.03l0.02-0.02l0.04-0.02L344.2,85l0.04-0.01l0.04-0.01l0.04-0.02l0.05-0.01l0.05-0.02l0.12,0h0.12
        l0.05,0h0.03l0.03,0l0.06,0l0.04,0l0.02,0l0.02,0l0.02-0.01l0.02-0.01l0.01,0l0.02-0.02l0.01-0.02l0.01-0.01L345,84.8l0-0.01
        l0.01-0.02l0.01-0.02v-0.02v-0.15l0-0.14l0.01,0l0.02,0l0.01,0l0.01-0.01l0.01-0.01l0.01-0.01l0.01-0.01l0-0.01l0-0.01l0-0.01
        l0-0.01v-0.02l0-0.02l-0.01-0.01l-0.01-0.01l-0.02-0.01l-0.02-0.01l-0.02-0.01l-0.03-0.01l0.03-0.02l0.03-0.02l0.01-0.01
        l0.02-0.02l0.01-0.01l0-0.01l0-0.01v-0.01v-0.01v-0.01l0-0.01v0l0-0.01l-0.01,0h-0.01h-0.01h-0.01h-0.02l0.01-0.04l0.04-0.01
        l0.04-0.01l0.04-0.01l0.03-0.01l0.03-0.01l0.02-0.01l0.02-0.01l0.01-0.01l0.01-0.01l0.01-0.01l0.01-0.01l0.01-0.02l0-0.01l0-0.01
        l0-0.02l0-0.02v-0.02v-0.02l-0.01-0.02l-0.01-0.02l-0.18-0.23l-0.02-0.03l-0.02-0.03l-0.02-0.03l-0.01-0.03l-0.03-0.06l-0.01-0.03
        l-0.01-0.03l-0.01-0.07l0-0.04v-0.03v-0.03v-0.04v-0.04l0-0.04l0-0.03v-0.02l0.25-0.06c-0.05-0.41-0.3-0.79-0.66-1.01
        c-0.17-0.1-0.35-0.17-0.54-0.21l-0.01,0l-0.05-0.02l-0.05-0.01l-0.03-0.01l-0.07-0.02l-0.06-0.01l-0.06-0.01l-0.07-0.01l-0.1,0
        l-0.09-0.01l-0.1,0h-0.09h-0.07l-0.08,0l-0.07,0.01l-0.06,0.01l-0.07,0.01l-0.07,0.02l-0.07,0.01l-0.05,0.01l-0.07,0.02
        l-0.05,0.02l-0.04,0.02l-0.04,0.02l-0.03,0.01l-0.04,0.02l-0.04,0.02l-0.03,0.02l-0.03,0.02l-0.04,0.03l-0.04,0.04l-0.05,0.05
        l-0.03,0.04l-0.03,0.04l-0.02,0.03l-0.04,0.05l-0.03,0.05l-0.03,0.05l-0.03,0.05l-0.02,0.06l-0.03,0.06l-0.01,0.02
        c0.01,0.87-0.28,2.38-0.89,2.98c0.28,0.09,0.58,0.11,0.87,0.07c0.1,0.36-0.02,0.17-0.22,0.49c-0.15,0.23-0.13,0.43-0.33,0.61
        l-0.35,0.48l-0.36,0.5l-0.09,0.13l-0.07,0.09l-0.07,0.1l-0.08,0.11l-0.07,0.1l-0.06,0.08l-0.07,0.11l-0.32,0.49l-0.07,0.11
        l-0.02,0.04l-0.02,0.05l-0.04,0.07l-0.01,0.03L339.72,89l-0.02,0.05l-0.02,0.05l-0.02,0.05l-0.01,0.04l0,0.03l0,0.04l0,0.04v0.03
        v0.03l0,0.03l0,0.04l0.01,0.05l0,0.02l0.01,0.03l0.01,0.02l0.01,0.02l0.01,0.02l0.02,0.03l0.05,0.05l0.04,0.04l0.04,0.04
        l0.04,0.04l0.04,0.03l0.04,0.04l0.04,0.03l0.05,0.03l0.2,0.11l0.21,0.12l0.08,0.04l0.03,0.01L341.62,89.26L341.62,89.26z"
          fill="#FFFFFF"
          fill-rule="evenodd"
        />
        <path
          clip-rule="evenodd"
          d="M351.46,88.7c-0.38,4.16-4.06,7.22-8.23,6.85
        c-1.75-0.16-3.3-0.91-4.49-2.03l11.4-9.84C351.12,85.1,351.63,86.85,351.46,88.7 M336.39,87.32c0.38-4.16,4.06-7.23,8.22-6.84
        c1.91,0.17,3.59,1.04,4.81,2.33l-11.45,9.88C336.82,91.22,336.21,89.32,336.39,87.32z M344.77,78.8
        c-5.09-0.47-9.59,3.28-10.06,8.37c-0.46,5.09,3.28,9.59,8.37,10.06c5.09,0.47,9.59-3.28,10.06-8.37
        C353.6,83.76,349.86,79.26,344.77,78.8z"
          fill="#FFFFFF"
          fill-rule="evenodd"
        />
        <path
          clip-rule="evenodd"
          d="M392.42,88.7c-0.38,4.16-4.06,7.22-8.22,6.85
        c-4.16-0.39-7.23-4.07-6.85-8.23c0.38-4.16,4.06-7.23,8.22-6.84C389.73,80.85,392.8,84.53,392.42,88.7 M385.72,78.8
        c-5.09-0.47-9.59,3.28-10.06,8.37c-0.46,5.09,3.28,9.59,8.37,10.06c5.09,0.47,9.59-3.28,10.06-8.37
        C394.56,83.76,390.81,79.26,385.72,78.8z"
          fill="#FFFFFF"
          fill-rule="evenodd"
        />
        <polygon
          clip-rule="evenodd"
          fill="#FFFFFF"
          fill-rule="evenodd"
          points="381.05,86.45 379.87,86.71 379.44,84.92 381.6,84.33
        383.44,84.33 383.44,91.64 381.05,91.64     "
        />
        <path
          clip-rule="evenodd"
          d="M387.92,86.59v-0.02c0-0.36-0.3-0.68-0.76-0.68
        c-0.46,0-0.76,0.32-0.76,0.68v0.02c0,0.4,0.31,0.71,0.76,0.71C387.61,87.3,387.92,87,387.92,86.59 M388,89.37v-0.02
        c0-0.39-0.33-0.71-0.84-0.71c-0.51,0-0.84,0.32-0.84,0.71v0.02c0,0.36,0.28,0.72,0.84,0.72C387.72,90.09,388,89.72,388,89.37z
         M384,89.64v-0.02c0-0.88,0.52-1.42,1.3-1.74c-0.55-0.28-1.06-0.78-1.06-1.58v-0.02c0-1.19,1.19-2.03,2.91-2.03
        c1.72,0,2.91,0.84,2.91,2.03v0.02c0,0.8-0.51,1.3-1.06,1.58c0.74,0.3,1.31,0.82,1.31,1.74v0.02c0,1.26-1.29,2.13-3.16,2.13
        C385.29,91.77,384,90.89,384,89.64z"
          fill="#FFFFFF"
          fill-rule="evenodd"
        />
        <path
          clip-rule="evenodd"
          d="M369.25,87.8c0,0.32-0.26,0.59-0.59,0.59
        c-0.32,0-0.58-0.27-0.58-0.59c0-0.32,0.26-0.59,0.58-0.59C368.99,87.21,369.25,87.47,369.25,87.8 M368.49,86.46
        c0,0.01,0,0.03-0.01,0.04c-0.01,0.01-0.02,0.02-0.03,0.02h-0.8L364.77,89l5.38,0.01c0.07,0,0.12-0.05,0.13-0.12
        c0.01-0.09,0.23-2.22-1.02-2.46c-0.08-0.34-0.2-0.69-0.33-1.03l-0.63,0.54L368.49,86.46z"
          fill="#FFFFFF"
          fill-rule="evenodd"
        />
        <path
          clip-rule="evenodd"
          d="M360.12,88.39c-0.32,0-0.59-0.27-0.59-0.59
        c0-0.32,0.26-0.59,0.59-0.59c0.33,0,0.59,0.27,0.59,0.59C360.71,88.12,360.45,88.39,360.12,88.39 M360.49,86.52
        c-0.01,0-0.03,0-0.03-0.02c-0.01-0.01-0.01-0.02-0.01-0.04c0.03-0.11,0.75-2.58,1.27-2.76c0.51-0.17,2.58-0.19,2.67-0.19h0.52
        c0.97,0,2.13,0.04,2.35,0.2c0.1,0.07,0.26,0.37,0.43,0.74l0.52-0.45c-0.37-0.6-0.73-1.02-0.89-1.02c-0.17,0-1.53-0.13-2.91-0.13
        c-1.39,0-2.79,0.13-2.94,0.13c-0.3,0-1.58,1.87-1.95,3.47c-1.24,0.24-1.02,2.36-1.01,2.46c0,0.07,0.06,0.12,0.13,0.12l3.76-0.01
        l2.88-2.49H360.49z"
          fill="#FFFFFF"
          fill-rule="evenodd"
        />
        <path
          clip-rule="evenodd"
          d="M358.76,89.31c-0.07,0-0.13,0.06-0.13,0.13v0.85
        c0,0.07,0.06,0.13,0.13,0.13h0.53v0.84c0,0.13,0.04,0.25,0.11,0.35l2.66-2.3H358.76z"
          fill="#FFFFFF"
          fill-rule="evenodd"
        />
        <path
          clip-rule="evenodd"
          d="M368.19,90.42v0.84c0,0.35,0.28,0.63,0.63,0.63h0.42
        c0.35,0,0.63-0.28,0.63-0.63v-0.84h0.19c0.07,0,0.13-0.06,0.13-0.13v-0.85c0-0.07-0.06-0.13-0.13-0.13h-5.65l-1.29,1.11H368.19z"
          fill="#FFFFFF"
          fill-rule="evenodd"
        />
        <path
          clip-rule="evenodd"
          d="M371.94,88.7c-0.38,4.16-4.06,7.22-8.22,6.85
        c-1.75-0.16-3.31-0.91-4.49-2.03l11.4-9.84C371.6,85.1,372.11,86.85,371.94,88.7 M356.87,87.32c0.38-4.16,4.06-7.23,8.22-6.84
        c1.91,0.17,3.59,1.04,4.81,2.33l-11.45,9.88C357.3,91.22,356.68,89.32,356.87,87.32z M365.24,78.8
        c-5.09-0.47-9.58,3.28-10.06,8.37c-0.46,5.09,3.29,9.59,8.37,10.06c5.09,0.47,9.59-3.28,10.06-8.37
        C374.08,83.76,370.33,79.26,365.24,78.8z"
          fill="#FFFFFF"
          fill-rule="evenodd"
        />
        <path
          clip-rule="evenodd"
          d="M270.13,90.64c0-0.21-0.16-0.27-0.34-0.27h-0.35v0.9h0.2v-0.36
        h0.1l0.19,0.36h0.24l-0.23-0.38C270.06,90.86,270.13,90.77,270.13,90.64 M269.76,90.74h-0.12v-0.21h0.13
        c0.07,0,0.16,0.01,0.16,0.11C269.92,90.74,269.83,90.74,269.76,90.74z"
          fill="#FFFFFF"
          fill-rule="evenodd"
        />
        <path
          clip-rule="evenodd"
          d="M269.77,89.95c-0.49,0-0.88,0.39-0.88,0.88
        c0,0.49,0.4,0.88,0.88,0.88c0.49,0,0.89-0.39,0.89-0.88C270.66,90.34,270.26,89.95,269.77,89.95 M269.77,91.54
        c-0.39,0-0.71-0.32-0.71-0.71c0-0.39,0.32-0.71,0.71-0.71c0.39,0,0.71,0.32,0.71,0.71C270.48,91.22,270.16,91.54,269.77,91.54z"
          fill="#FFFFFF"
          fill-rule="evenodd"
        />
        <path
          clip-rule="evenodd"
          d="M280.9,85.08v-5.31h-8.28v-0.59l-1.11-0.43v13.72
        c-2.03,0.19-3.56,1.2-3.56,2.39c0,1.32,1.84,2.4,4.11,2.4c2.27,0,4.11-1.07,4.11-2.4c0-1.19-1.54-2.2-3.56-2.39v-7.4H280.9z
         M276.22,79.95h4.5v1.8h-4.5V79.95z M276.22,83.1h4.5v1.8h-4.5V83.1z M272.61,79.95h2.25v1.8h-2.25V79.95z M272.61,83.1h2.25v1.8
        h-2.25V83.1z M275.01,94.86c0,0.83-1.32,1.5-2.96,1.5c-1.63,0-2.96-0.67-2.96-1.5c0-0.82,1.33-1.5,2.96-1.5
        C273.69,93.37,275.01,94.04,275.01,94.86z"
          fill="#FFFFFF"
          fill-rule="evenodd"
        />
        <path
          clip-rule="evenodd"
          d="M305.15,87.99c0.01-1.3-0.31-2.53-0.89-3.68
        c-0.49-0.95-1.14-1.76-1.94-2.45c-0.87-0.75-1.86-1.29-2.96-1.61c-0.66-0.19-1.34-0.32-2.02-0.33c-2.78-0.05-5.03,1.03-6.73,3.21
        c-1.03,1.32-1.59,2.85-1.65,4.53c-0.09,2.62,0.87,4.81,2.83,6.52c1.41,1.23,3.08,1.9,4.96,1.95c2.59,0.07,4.76-0.88,6.46-2.84
        C304.51,91.77,305.14,89.98,305.15,87.99 M296.55,78.76h0.95l0.05,0.02l0.37,0.02c0.15,0.01,0.3,0.01,0.44,0.04
        c0.28,0.05,0.55,0.1,0.82,0.18c0.29,0.07,0.59,0.17,0.87,0.25c0.37,0.11,0.7,0.27,1.05,0.43c0.41,0.2,0.8,0.42,1.17,0.68
        c0.35,0.24,0.69,0.5,1,0.8c0.22,0.21,0.45,0.41,0.65,0.64c0.3,0.36,0.58,0.73,0.85,1.11c0.17,0.24,0.32,0.51,0.46,0.78
        c0.13,0.23,0.24,0.47,0.34,0.71c0.09,0.22,0.17,0.44,0.25,0.67c0.05,0.14,0.08,0.28,0.12,0.42c0.05,0.16,0.1,0.32,0.13,0.48
        l0.07,0.36c0.03,0.18,0.07,0.36,0.09,0.54c0.03,0.24,0.06,0.49,0.06,0.73c0.01,0.39,0.01,0.78-0.02,1.16
        c-0.02,0.3-0.08,0.61-0.13,0.9c-0.07,0.33-0.13,0.66-0.23,0.99c-0.12,0.39-0.27,0.78-0.44,1.15c-0.17,0.37-0.38,0.74-0.59,1.1
        c-0.13,0.22-0.28,0.43-0.44,0.63c-0.23,0.29-0.46,0.58-0.71,0.85c-0.21,0.23-0.45,0.44-0.69,0.64c-0.22,0.19-0.45,0.37-0.69,0.54
        c-0.23,0.16-0.47,0.31-0.71,0.45c-0.29,0.16-0.6,0.32-0.9,0.46c-0.28,0.12-0.57,0.23-0.87,0.32c-0.32,0.1-0.64,0.19-0.96,0.27
        c-0.23,0.05-0.45,0.08-0.68,0.1c-0.34,0.04-0.67,0.06-1,0.08c-0.25,0.01-0.5,0-0.76-0.02c-0.26-0.01-0.51-0.02-0.76-0.06
        c-0.31-0.05-0.61-0.11-0.92-0.19c-0.2-0.05-0.4-0.11-0.61-0.18c-0.29-0.1-0.59-0.19-0.87-0.32c-0.35-0.16-0.69-0.34-1.02-0.53
        c-0.25-0.15-0.5-0.31-0.73-0.48c-0.27-0.2-0.53-0.43-0.78-0.64c-0.16-0.14-0.31-0.29-0.45-0.44c-0.16-0.17-0.31-0.34-0.45-0.52
        c-0.14-0.17-0.28-0.34-0.4-0.52c-0.16-0.24-0.32-0.5-0.48-0.76c-0.1-0.16-0.18-0.33-0.27-0.5c-0.07-0.16-0.15-0.31-0.22-0.47
        c-0.1-0.22-0.18-0.44-0.26-0.66c-0.05-0.15-0.09-0.3-0.13-0.45l-0.12-0.49l-0.07-0.37c-0.03-0.18-0.06-0.37-0.08-0.55
        c-0.02-0.2-0.03-0.4-0.05-0.6l-0.02-0.02v-0.97l0.04-0.4c0.02-0.17,0.03-0.34,0.06-0.51c0.04-0.22,0.07-0.43,0.12-0.65
        c0.06-0.23,0.12-0.45,0.2-0.68c0.11-0.33,0.22-0.66,0.36-0.98c0.16-0.35,0.34-0.69,0.54-1.03c0.14-0.25,0.3-0.5,0.48-0.72
        c0.21-0.27,0.43-0.54,0.66-0.79c0.14-0.16,0.28-0.3,0.44-0.44c0.16-0.16,0.33-0.31,0.52-0.45c0.23-0.19,0.47-0.37,0.72-0.54
        c0.48-0.32,0.98-0.61,1.52-0.81c0.35-0.13,0.7-0.27,1.05-0.39c0.19-0.06,0.38-0.1,0.58-0.15l0.34-0.07
        c0.18-0.03,0.37-0.06,0.55-0.09c0.19-0.02,0.39-0.03,0.59-0.04L296.55,78.76z"
          fill="#FFFFFF"
          fill-rule="evenodd"
        />
        <path
          clip-rule="evenodd"
          d="M298.17,94.7c1.32-0.22,2.47-0.8,3.43-1.72
        c1.38-1.31,2.07-2.93,2.09-4.84c-1.45-0.08-2.77,0.27-3.95,1.11c-1.16,0.84-1.92,1.96-2.37,3.31l-0.01-0.03
        c0.02-1.36-0.05-2.72,0.04-4.08c0.02-0.22,0.08-0.43,0.18-0.62c0.15-0.28,0.37-0.47,0.6-0.66c0.19-0.15,0.4-0.3,0.58-0.47
        c0.21-0.2,0.36-0.44,0.46-0.71c0.05-0.13,0.11-0.27,0.14-0.41c0.03-0.16,0.05-0.33,0.06-0.5h-0.89c-0.14,0-0.14,0-0.14-0.13v-1.61
        c0-0.07,0-0.15-0.03-0.21c-0.07-0.16-0.23-0.21-0.37-0.18c-0.16,0.03-0.26,0.16-0.25,0.32c0,0.56,0,1.13,0,1.69
        c0,0.09-0.02,0.12-0.12,0.12h-1.18h-0.15v-0.1v-1.72c0-0.14-0.1-0.28-0.24-0.31c-0.14-0.04-0.29,0.02-0.35,0.15
        c-0.03,0.06-0.05,0.14-0.05,0.22v1.67c0,0.08-0.02,0.1-0.1,0.1h-0.5h-0.43c0.01,0.13,0.01,0.25,0.04,0.36
        c0.05,0.19,0.1,0.38,0.17,0.55c0.12,0.31,0.31,0.58,0.55,0.8c0.22,0.19,0.46,0.36,0.69,0.55c0.19,0.16,0.34,0.34,0.44,0.56
        c0.14,0.29,0.19,0.61,0.19,0.92c0.01,1.21,0,2.43,0,3.64v0.1l-0.02,0.01c-0.43-1.35-1.21-2.47-2.35-3.31
        c-1.16-0.85-2.48-1.19-3.93-1.12c-0.01,1.13,0.26,2.18,0.78,3.15c0.67,1.25,1.65,2.2,2.93,2.84
        C295.4,94.76,296.76,94.95,298.17,94.7"
          fill="#FFFFFF"
          fill-rule="evenodd"
        />
      </g>
      <path
        clip-rule="evenodd"
        d="M266.57,190.91v-0.77h0.66c0.51,0,0.9-0.43,0.9-0.91
      c0-0.49-0.4-0.91-0.9-0.91h-1.32v2.58H266.57z M266.57,188.97h0.66c0.15,0,0.26,0.12,0.26,0.27c0,0.15-0.12,0.27-0.26,0.27h-0.66
      V188.97z"
        fill="#FFFFFF"
        fill-rule="evenodd"
      />
      <path
        clip-rule="evenodd"
        d="M268.67,190.48h0.85l0.16,0.43h0.71l-0.97-2.58h-0.67l-0.93,2.58
      h0.7L268.67,190.48z M269.28,189.84h-0.39l0.19-0.56L269.28,189.84z"
        fill="#FFFFFF"
        fill-rule="evenodd"
      />
      <path
        clip-rule="evenodd"
        d="M271.09,190.91v-0.86h0.34l0.49,0.86h0.81l-0.55-0.94
      c0.27-0.1,0.5-0.42,0.5-0.77c0-0.52-0.4-0.87-0.86-0.87h-1.38v2.58H271.09z M271.09,188.96h0.67c0.15,0,0.27,0.08,0.27,0.24
      c0,0.15-0.13,0.24-0.27,0.24h-0.67V188.96z"
        fill="#FFFFFF"
        fill-rule="evenodd"
      />
      <path
        clip-rule="evenodd"
        d="M273.51,190.48h0.85l0.16,0.43h0.71l-0.97-2.58h-0.67l-0.93,2.58
      h0.71L273.51,190.48z M274.12,189.84h-0.39l0.19-0.56L274.12,189.84z"
        fill="#FFFFFF"
        fill-rule="evenodd"
      />
      <path
        clip-rule="evenodd"
        d="M276.08,190.97c0.6,0,1.03-0.43,1.03-1.01
      c0-0.11-0.01-0.24-0.02-0.3l-1.34-0.52c0.02-0.16,0.17-0.29,0.37-0.29c0.19,0,0.33,0.13,0.37,0.32l0.61-0.13
      c-0.09-0.43-0.47-0.79-1-0.79c-0.59,0-1.01,0.47-1.01,1.04l0.01,0.23l1.36,0.52c-0.03,0.19-0.16,0.32-0.36,0.32
      c-0.19,0-0.34-0.12-0.4-0.31l-0.61,0.13C275.19,190.59,275.54,190.97,276.08,190.97"
        fill="#FFFFFF"
        fill-rule="evenodd"
      />
      <polygon
        clip-rule="evenodd"
        fill="#FFFFFF"
        fill-rule="evenodd"
        points="278.32,190.91 278.32,188.96 278.95,188.96
      278.95,188.33 277.03,188.33 277.03,188.96 277.66,188.96 277.66,190.91   "
      />
      <path
        clip-rule="evenodd"
        d="M279.42,190.48h0.85l0.16,0.43h0.71l-0.97-2.58h-0.67l-0.93,2.58
      h0.71L279.42,190.48z M280.03,189.84h-0.39l0.19-0.56L280.03,189.84z"
        fill="#FFFFFF"
        fill-rule="evenodd"
      />
      <polygon
        clip-rule="evenodd"
        fill="#FFFFFF"
        fill-rule="evenodd"
        points="283.99,190.91 283.99,190.28 282.59,190.28
      282.59,189.94 283.67,189.94 283.67,189.31 282.59,189.31 282.59,188.96 283.99,188.96 283.99,188.33 281.94,188.33 281.94,190.91
        "
      />
      <path
        clip-rule="evenodd"
        d="M284.82,190.91v-1.21l1.42,1.26v-2.63h-0.64v1.21
      c-0.13-0.11-1.32-1.17-1.42-1.26v2.63H284.82z"
        fill="#FFFFFF"
        fill-rule="evenodd"
      />
      <path
        clip-rule="evenodd"
        d="M287.17,190.91v-1.21l1.41,1.26v-2.63h-0.64v1.21
      c-0.13-0.11-1.31-1.17-1.42-1.26v2.63H287.17z"
        fill="#FFFFFF"
        fill-rule="evenodd"
      />
      <polygon
        clip-rule="evenodd"
        fill="#FFFFFF"
        fill-rule="evenodd"
        points="290.93,190.91 290.93,190.28 289.53,190.28
      289.53,189.94 290.61,189.94 290.61,189.31 289.53,189.31 289.53,188.96 290.93,188.96 290.93,188.33 288.88,188.33 288.88,190.91
        "
      />
      <polygon
        clip-rule="evenodd"
        fill="#FFFFFF"
        fill-rule="evenodd"
        points="291.75,190.91 291.75,189.7 293.17,190.96 293.17,188.33
      292.53,188.33 292.53,189.54 291.11,188.28 291.11,190.91   "
      />
      <polygon
        clip-rule="evenodd"
        fill="#FFFFFF"
        fill-rule="evenodd"
        points="294.58,190.97 295.56,188.33 295.05,188.33
      294.08,190.97   "
      />
      <path
        clip-rule="evenodd"
        d="M298.58,188.97c0-0.36-0.31-0.65-0.66-0.65h-1.48v2.58h1.47
      c0.46,0,0.79-0.35,0.79-0.77c0-0.36-0.19-0.57-0.4-0.63C298.46,189.42,298.58,189.22,298.58,188.97 M297.07,189.89h0.73
      c0.11,0,0.2,0.08,0.2,0.2c0,0.1-0.07,0.2-0.2,0.2h-0.73V189.89z M297.07,188.95h0.69c0.11,0,0.17,0.07,0.17,0.16
      c0,0.09-0.06,0.16-0.17,0.16h-0.69V188.95z"
        fill="#FFFFFF"
        fill-rule="evenodd"
      />
      <path
        clip-rule="evenodd"
        d="M299.51,190.48h0.85l0.16,0.43h0.71l-0.97-2.57h-0.67l-0.93,2.57
      h0.7L299.51,190.48z M298.79,188.34c0,0.16,0.13,0.29,0.29,0.29c0.16,0,0.28-0.13,0.28-0.29c0-0.16-0.12-0.29-0.28-0.29
      C298.92,188.05,298.79,188.18,298.79,188.34z M300.48,188.34c0,0.16,0.12,0.29,0.28,0.29c0.16,0,0.29-0.13,0.29-0.29
      c0-0.16-0.13-0.29-0.29-0.29C300.6,188.05,300.48,188.18,300.48,188.34z M300.12,189.85h-0.39l0.19-0.55L300.12,189.85z"
        fill="#FFFFFF"
        fill-rule="evenodd"
      />
      <path
        clip-rule="evenodd"
        d="M302.14,190.97c0.6,0,1.03-0.43,1.03-1.01
      c0-0.11-0.01-0.24-0.02-0.3l-1.34-0.52c0.02-0.16,0.17-0.29,0.37-0.29c0.19,0,0.33,0.13,0.37,0.32l0.61-0.13
      c-0.09-0.43-0.47-0.79-1-0.79c-0.6,0-1.02,0.47-1.02,1.04l0.01,0.23l1.36,0.52c-0.03,0.19-0.16,0.32-0.35,0.32
      c-0.19,0-0.34-0.12-0.4-0.31l-0.61,0.13C301.26,190.59,301.61,190.97,302.14,190.97"
        fill="#FFFFFF"
        fill-rule="evenodd"
      />
      <polygon
        clip-rule="evenodd"
        fill="#FFFFFF"
        fill-rule="evenodd"
        points="304.39,190.91 304.39,188.96 305.02,188.96
      305.02,188.33 303.1,188.33 303.1,188.96 303.73,188.96 303.73,190.91   "
      />
      <polygon
        clip-rule="evenodd"
        fill="#FFFFFF"
        fill-rule="evenodd"
        points="306.47,190.91 306.47,189.96 307.54,189.96
      307.54,189.32 306.47,189.32 306.47,188.96 307.86,188.96 307.86,188.33 305.81,188.33 305.81,190.91   "
      />
      <path
        clip-rule="evenodd"
        d="M309.12,190.97c0.74,0,1.34-0.6,1.34-1.35
      c0-0.76-0.6-1.36-1.35-1.36c-0.74,0-1.34,0.6-1.34,1.36C307.77,190.37,308.37,190.97,309.12,190.97 M307.84,188.16
      c0,0.16,0.12,0.28,0.29,0.28c0.16,0,0.28-0.12,0.28-0.28c0-0.16-0.13-0.29-0.29-0.29C307.97,187.87,307.84,188,307.84,188.16z
       M309.84,188.16c0,0.16,0.13,0.28,0.29,0.28c0.16,0,0.28-0.12,0.28-0.28c0-0.16-0.13-0.29-0.29-0.29
      C309.97,187.87,309.84,188,309.84,188.16z M309.11,188.9c0.39,0,0.69,0.32,0.69,0.72c0,0.4-0.3,0.71-0.69,0.71
      c-0.39,0-0.69-0.31-0.69-0.71C308.43,189.22,308.73,188.9,309.11,188.9z"
        fill="#FFFFFF"
        fill-rule="evenodd"
      />
      <path
        clip-rule="evenodd"
        d="M311.27,190.91v-0.86h0.34l0.49,0.86h0.82l-0.55-0.94
      c0.27-0.1,0.5-0.42,0.5-0.77c0-0.52-0.4-0.87-0.87-0.87h-1.38v2.58H311.27z M311.27,188.96h0.67c0.15,0,0.27,0.08,0.27,0.24
      c0,0.15-0.13,0.24-0.27,0.24h-0.67V188.96z"
        fill="#FFFFFF"
        fill-rule="evenodd"
      />
      <polygon
        clip-rule="evenodd"
        fill="#FFFFFF"
        fill-rule="evenodd"
        points="315.12,190.91 315.12,190.28 313.72,190.28
      313.72,189.94 314.8,189.94 314.8,189.31 313.72,189.31 313.72,188.96 315.12,188.96 315.12,188.33 313.07,188.33 313.07,190.91
      "
      />
      <path
        clip-rule="evenodd"
        d="M315.81,190.27h-0.61v0.64h0.61V190.27z M315.81,189.11h-0.61
      v0.64h0.61V189.11z"
        fill="#FFFFFF"
        fill-rule="evenodd"
      />
      <g>
        <path
          clip-rule="evenodd"
          d="M290.04,169.3c0.31,0.41,0.58,0.83,0.81,1.26
        c0.23,0.43,0.42,0.86,0.58,1.3c0.16,0.44,0.27,0.9,0.35,1.35c0.08,0.46,0.11,0.91,0.11,1.36c-0.01,0.46-0.05,0.92-0.14,1.39
        c-0.09,0.46-0.21,0.91-0.38,1.37c-0.17,0.46-0.39,0.91-0.65,1.35c-0.26,0.45-0.56,0.88-0.9,1.31c-0.27,0.33-0.56,0.65-0.87,0.96
        c-0.31,0.31-0.64,0.6-0.99,0.88c-0.35,0.29-0.73,0.56-1.12,0.81c-0.39,0.25-0.8,0.49-1.23,0.72c-0.43,0.22-0.88,0.43-1.34,0.63
        c-0.46,0.19-0.93,0.37-1.42,0.53c-0.49,0.16-1,0.3-1.52,0.43c-0.52,0.12-1.05,0.23-1.59,0.32l-0.43,0.06l-0.43,0.06l-0.43,0.04
        l-0.43,0.04l-0.43,0.03l-0.44,0.02l-0.43,0.01l-0.43,0.01c-0.4,0-0.8-0.01-1.19-0.03c-0.4-0.02-0.79-0.06-1.18-0.1
        c-0.39-0.04-0.78-0.09-1.16-0.16c-0.38-0.06-0.76-0.14-1.14-0.22c-0.38-0.09-0.75-0.18-1.12-0.29c-0.37-0.1-0.73-0.22-1.08-0.35
        c-0.36-0.13-0.7-0.26-1.04-0.4c-0.34-0.15-0.68-0.3-1.01-0.46l-0.09-0.04l-0.08-0.04l-0.06,0.07l-0.06,0.07l-0.93,0.99l-0.92,0.99
        l-1.45-2.92l-1.44-2.93l4.17-0.01l4.17-0.01l-0.88,0.95l-0.88,0.95l-0.06,0.06l-0.06,0.07l0.08,0.04l0.09,0.04
        c0.27,0.14,0.55,0.26,0.83,0.37c0.28,0.12,0.57,0.23,0.86,0.33l0.89,0.28c0.3,0.08,0.6,0.16,0.91,0.23
        c0.31,0.07,0.62,0.13,0.93,0.18c0.32,0.06,0.63,0.09,0.96,0.13c0.32,0.03,0.64,0.06,0.96,0.08c0.32,0.02,0.65,0.02,0.98,0.02h0.36
        l0.36-0.01l0.36-0.02l0.36-0.03l0.36-0.03l0.36-0.04l0.36-0.04l0.36-0.06c0.44-0.07,0.88-0.16,1.3-0.26
        c0.43-0.1,0.84-0.21,1.25-0.35c0.41-0.13,0.8-0.28,1.18-0.43c0.38-0.16,0.75-0.33,1.1-0.52c0.36-0.18,0.7-0.37,1.02-0.58
        c0.32-0.21,0.64-0.43,0.93-0.66c0.29-0.23,0.57-0.47,0.83-0.72c0.26-0.25,0.5-0.51,0.73-0.78c0.16-0.2,0.31-0.4,0.46-0.6
        c0.14-0.2,0.27-0.41,0.39-0.61c0.12-0.21,0.23-0.42,0.32-0.63c0.09-0.21,0.18-0.43,0.25-0.64c0.08-0.21,0.14-0.43,0.19-0.65
        c0.06-0.22,0.09-0.43,0.12-0.65c0.03-0.22,0.05-0.44,0.06-0.65c0.01-0.22,0-0.44-0.01-0.66c-0.02-0.2-0.04-0.41-0.07-0.6
        c-0.03-0.2-0.07-0.4-0.13-0.6c-0.05-0.2-0.11-0.39-0.18-0.58l-0.23-0.58l-0.28-0.55l-0.33-0.54l-0.38-0.53l-0.43-0.5l1.41-0.01
        H290.04z"
          fill="#FFFFFF"
          fill-rule="evenodd"
        />
        <path
          clip-rule="evenodd"
          d="M263.38,178.63l1.53-0.01l-0.26-0.36l-0.24-0.37l-0.22-0.38
        l-0.19-0.38l-0.16-0.38l-0.15-0.4l-0.12-0.4l-0.09-0.4c-0.06-0.31-0.1-0.61-0.13-0.91c-0.02-0.3-0.02-0.6,0.01-0.91
        c0.02-0.3,0.06-0.59,0.13-0.88c0.06-0.29,0.14-0.58,0.25-0.87c0.1-0.28,0.22-0.56,0.36-0.84c0.14-0.27,0.3-0.55,0.47-0.81
        c0.18-0.26,0.37-0.52,0.58-0.78c0.21-0.25,0.44-0.49,0.69-0.74c0.24-0.24,0.5-0.47,0.78-0.68c0.27-0.22,0.56-0.42,0.86-0.62
        c0.3-0.2,0.6-0.38,0.92-0.55c0.32-0.17,0.64-0.34,0.98-0.48c0.34-0.15,0.68-0.29,1.04-0.42c0.36-0.13,0.73-0.25,1.09-0.35
        c0.37-0.1,0.75-0.19,1.14-0.28c0.39-0.08,0.79-0.15,1.18-0.2l0.31-0.04l0.31-0.03l0.31-0.03l0.31-0.02l0.31-0.02l0.31-0.01
        l0.31-0.01l0.31-0.01l0.88,0.02l0.87,0.07c0.29,0.03,0.58,0.07,0.87,0.11c0.29,0.04,0.57,0.09,0.86,0.14l0.84,0.19
        c0.28,0.07,0.56,0.15,0.82,0.24c0.28,0.08,0.54,0.18,0.81,0.28l0.79,0.31l0.01,0.01l0.01,0.01l0.01,0.01l0.01,0l0.01,0l0.01,0.01
        l0.01,0.01l0.01,0.01l0.03,0.01l0.03,0.02l0.08,0.03l0.08,0.03l-0.04,0.06l-0.05,0.05l-0.8,0.86l-0.8,0.86l3.88-0.01l3.88-0.01
        l-1.69-2.35l-1.68-2.35l-0.65,0.7l-0.65,0.7l-0.04,0.05l-0.05,0.06l-0.08-0.04l-0.08-0.03l-0.03-0.01l-0.03-0.02l-0.01-0.01
        l-0.01-0.01h-0.01l-0.01-0.01l-0.01,0l-0.01,0h-0.01l-0.01-0.01c-0.31-0.13-0.63-0.26-0.95-0.37c-0.32-0.12-0.64-0.23-0.97-0.32
        c-0.32-0.1-0.65-0.19-0.98-0.27c-0.33-0.08-0.67-0.16-1-0.23l-1.02-0.18l-1.03-0.13l-1.04-0.07c-0.35-0.02-0.7-0.03-1.05-0.03
        l-0.37,0.01l-0.37,0.01l-0.37,0.01l-0.37,0.03l-0.37,0.02l-0.37,0.04l-0.37,0.04l-0.37,0.04c-0.47,0.07-0.94,0.15-1.4,0.24
        c-0.46,0.09-0.91,0.2-1.36,0.32c-0.45,0.12-0.88,0.26-1.3,0.41c-0.42,0.15-0.84,0.31-1.24,0.49c-0.4,0.18-0.8,0.37-1.18,0.57
        c-0.39,0.2-0.75,0.42-1.1,0.65c-0.36,0.23-0.7,0.48-1.03,0.74c-0.33,0.25-0.64,0.53-0.94,0.81c-0.3,0.29-0.58,0.58-0.84,0.88
        c-0.26,0.3-0.49,0.61-0.71,0.92c-0.21,0.31-0.41,0.64-0.58,0.97c-0.18,0.32-0.32,0.66-0.45,1c-0.13,0.34-0.23,0.69-0.31,1.04
        c-0.08,0.35-0.14,0.7-0.18,1.06c-0.03,0.36-0.04,0.72-0.02,1.09c0.02,0.36,0.06,0.73,0.13,1.1l0.08,0.35l0.08,0.34l0.1,0.34
        l0.12,0.34l0.13,0.34l0.15,0.34l0.16,0.33l0.18,0.32H263.38z"
          fill="#FFFFFF"
          fill-rule="evenodd"
        />
        <path
          clip-rule="evenodd"
          d="M270.43,167.46c0.08-0.02,0.18-0.04,0.29-0.04l0.35-0.02
        c0.12,0,0.25,0.01,0.36,0.04c0.12,0.03,0.22,0.08,0.3,0.14c0.08,0.06,0.14,0.13,0.18,0.21c0.04,0.08,0.07,0.18,0.07,0.29
        c0,0.15-0.04,0.28-0.1,0.4c-0.06,0.12-0.14,0.22-0.24,0.29c-0.1,0.08-0.23,0.14-0.37,0.18c-0.14,0.04-0.29,0.06-0.44,0.06
        l-0.13-0.01l-0.12-0.01l-0.09,0.48l-0.09,0.47h-0.23h-0.22l0.24-1.24L270.43,167.46z M270.65,168.62l0.11,0.02l0.13,0.01
        c0.19,0,0.35-0.06,0.46-0.15c0.11-0.09,0.17-0.23,0.17-0.38c0-0.13-0.05-0.22-0.13-0.28c-0.08-0.06-0.2-0.08-0.33-0.08l-0.15,0.01
        l-0.1,0.02l-0.08,0.43L270.65,168.62z"
          fill="#FFFFFF"
          fill-rule="evenodd"
        />
        <path
          clip-rule="evenodd"
          d="M273.21,169.93l0.02-0.19l0.02-0.23h-0.01h-0.01
        c-0.09,0.17-0.2,0.28-0.32,0.35c-0.12,0.07-0.24,0.1-0.36,0.1c-0.16,0-0.31-0.06-0.4-0.17c-0.1-0.11-0.15-0.26-0.15-0.44
        c0-0.31,0.12-0.63,0.33-0.87c0.22-0.24,0.55-0.41,0.99-0.41c0.1,0,0.21,0.01,0.31,0.03c0.1,0.01,0.19,0.03,0.26,0.06l-0.1,0.47
        l-0.1,0.47l-0.06,0.43c-0.01,0.15-0.02,0.29-0.02,0.4h-0.2H273.21z M273.4,168.43l-0.08-0.02l-0.11-0.01
        c-0.21,0-0.39,0.12-0.52,0.28c-0.13,0.16-0.21,0.38-0.21,0.58c0,0.1,0.02,0.19,0.06,0.25c0.04,0.06,0.11,0.1,0.21,0.1
        c0.1,0,0.22-0.07,0.32-0.19c0.1-0.12,0.19-0.3,0.23-0.53l0.05-0.24L273.4,168.43z"
          fill="#FFFFFF"
          fill-rule="evenodd"
        />
        <path
          clip-rule="evenodd"
          d="M274.11,169.93l0.12-0.63l0.13-0.63l0.06-0.3l0.04-0.26h0.2h0.2
        l-0.02,0.16l-0.02,0.17h0.01c0.09-0.13,0.19-0.22,0.3-0.28c0.11-0.06,0.24-0.09,0.37-0.09c0.13-0.01,0.25,0.03,0.34,0.11
        c0.09,0.08,0.14,0.21,0.14,0.38l-0.01,0.15l-0.02,0.15l-0.1,0.53l-0.1,0.53h-0.23h-0.22l0.1-0.51l0.1-0.52l0.02-0.1l0.01-0.11
        c0-0.07-0.02-0.14-0.05-0.19c-0.04-0.04-0.1-0.07-0.19-0.07c-0.1,0-0.22,0.06-0.32,0.17c-0.1,0.11-0.19,0.28-0.23,0.49l-0.08,0.42
        l-0.08,0.42h-0.23H274.11z"
          fill="#FFFFFF"
          fill-rule="evenodd"
        />
        <path
          clip-rule="evenodd"
          d="M277.24,167.65l-0.05,0.24l-0.04,0.23h0.21h0.21l-0.03,0.17
        l-0.04,0.17h-0.21h-0.21l-0.08,0.36l-0.08,0.37l-0.02,0.13l0,0.12c0,0.06,0.01,0.09,0.04,0.12c0.02,0.03,0.07,0.04,0.13,0.04h0.08
        l0.07-0.01l-0.02,0.17l-0.02,0.17c-0.03,0.02-0.08,0.02-0.13,0.03l-0.16,0.01c-0.15,0-0.27-0.04-0.34-0.11
        c-0.07-0.07-0.11-0.16-0.11-0.26l0.01-0.15l0.03-0.18l0.08-0.41l0.08-0.4h-0.13h-0.13l0.03-0.17l0.03-0.17h0.13h0.13l0.03-0.17
        l0.03-0.17l0.24-0.07L277.24,167.65z"
          fill="#FFFFFF"
          fill-rule="evenodd"
        />
        <path
          clip-rule="evenodd"
          d="M278.57,167.65l-0.05,0.24l-0.04,0.23h0.21h0.21l-0.03,0.17
        l-0.03,0.17h-0.21h-0.21l-0.08,0.36l-0.07,0.37l-0.02,0.13l-0.01,0.12c0,0.06,0.01,0.09,0.04,0.12c0.03,0.03,0.07,0.04,0.13,0.04
        h0.08l0.07-0.01l-0.02,0.17l-0.02,0.18l-0.13,0.02l-0.17,0.01c-0.15,0-0.26-0.04-0.34-0.11c-0.07-0.07-0.1-0.16-0.1-0.26
        l0.01-0.15l0.03-0.18l0.08-0.41l0.08-0.4h-0.13h-0.13l0.03-0.17l0.03-0.17h0.13h0.13l0.03-0.17l0.03-0.17l0.24-0.07L278.57,167.65
        z"
          fill="#FFFFFF"
          fill-rule="evenodd"
        />
        <path
          clip-rule="evenodd"
          d="M278.93,169.93l0.18-0.91l0.18-0.91h0.23h0.23l-0.18,0.91
        l-0.18,0.91h-0.23H278.93z M279.59,167.85c-0.07,0-0.13-0.02-0.17-0.06c-0.04-0.04-0.06-0.1-0.06-0.17c0-0.08,0.03-0.14,0.08-0.19
        c0.05-0.05,0.12-0.08,0.19-0.08c0.07,0,0.13,0.03,0.17,0.07c0.04,0.04,0.07,0.1,0.07,0.17c0,0.08-0.03,0.14-0.08,0.19
        C279.74,167.83,279.67,167.85,279.59,167.85L279.59,167.85z"
          fill="#FFFFFF"
          fill-rule="evenodd"
        />
        <path
          clip-rule="evenodd"
          d="M274.22,178.58c0.08-0.02,0.18-0.03,0.29-0.04l0.34-0.02
        c0.12,0,0.25,0.01,0.36,0.04c0.12,0.03,0.22,0.08,0.3,0.14c0.08,0.05,0.14,0.12,0.18,0.2c0.04,0.08,0.07,0.18,0.07,0.29
        c0,0.15-0.04,0.29-0.1,0.4c-0.06,0.12-0.14,0.21-0.24,0.28c-0.1,0.08-0.23,0.14-0.37,0.18c-0.14,0.04-0.29,0.06-0.45,0.06
        l-0.13-0.01l-0.11-0.01l-0.09,0.48l-0.09,0.47h-0.22h-0.23l0.24-1.23L274.22,178.58z M274.43,179.74l0.1,0.02l0.13,0.01
        c0.19,0,0.35-0.06,0.46-0.15c0.11-0.09,0.18-0.23,0.18-0.38c0-0.13-0.05-0.22-0.13-0.28c-0.08-0.06-0.19-0.08-0.33-0.08
        l-0.14,0.01l-0.1,0.02l-0.08,0.42L274.43,179.74z"
          fill="#FFFFFF"
          fill-rule="evenodd"
        />
        <path
          clip-rule="evenodd"
          d="M277.01,181.05l0.01-0.19l0.02-0.22h-0.01h-0.01
        c-0.09,0.17-0.2,0.27-0.32,0.35c-0.12,0.07-0.24,0.1-0.35,0.1c-0.17,0-0.31-0.06-0.41-0.17c-0.1-0.11-0.15-0.26-0.15-0.44
        c0-0.31,0.11-0.63,0.34-0.87c0.22-0.24,0.55-0.41,0.99-0.41c0.1,0,0.21,0,0.31,0.02l0.26,0.06l-0.1,0.48l-0.09,0.47l-0.07,0.43
        l-0.02,0.39h-0.2H277.01z M277.19,179.55l-0.08-0.01l-0.1-0.01c-0.22,0-0.4,0.11-0.53,0.28c-0.13,0.16-0.21,0.38-0.21,0.59
        c0,0.09,0.02,0.18,0.06,0.24c0.04,0.07,0.11,0.1,0.21,0.1c0.1,0,0.22-0.07,0.32-0.19c0.1-0.12,0.19-0.3,0.23-0.53l0.05-0.24
        L277.19,179.55z"
          fill="#FFFFFF"
          fill-rule="evenodd"
        />
        <path
          clip-rule="evenodd"
          d="M277.92,181.05l0.12-0.63l0.13-0.63l0.06-0.3l0.04-0.26h0.2h0.2
        l-0.02,0.16l-0.02,0.17h0.01c0.09-0.13,0.19-0.22,0.3-0.28c0.12-0.06,0.24-0.09,0.37-0.09c0.13,0,0.25,0.04,0.34,0.12
        c0.09,0.08,0.14,0.2,0.14,0.38l-0.01,0.14l-0.02,0.16l-0.1,0.53l-0.1,0.53h-0.23h-0.23l0.1-0.51l0.1-0.51l0.02-0.11l0.01-0.11
        c0-0.07-0.02-0.14-0.05-0.19c-0.04-0.04-0.1-0.07-0.19-0.07c-0.1,0-0.22,0.06-0.32,0.17c-0.1,0.12-0.19,0.28-0.23,0.49l-0.08,0.42
        l-0.08,0.42h-0.23H277.92z"
          fill="#FFFFFF"
          fill-rule="evenodd"
        />
        <path
          clip-rule="evenodd"
          d="M281.06,178.77l-0.04,0.24l-0.05,0.23h0.21h0.22l-0.04,0.17
        l-0.03,0.16h-0.21h-0.22l-0.08,0.37l-0.07,0.37l-0.02,0.13l-0.01,0.13c0,0.05,0.02,0.09,0.04,0.12c0.03,0.03,0.07,0.05,0.13,0.05
        l0.08-0.01l0.08-0.01l-0.02,0.18l-0.02,0.17l-0.13,0.03l-0.16,0.01c-0.16,0-0.27-0.04-0.35-0.11c-0.07-0.07-0.1-0.16-0.1-0.26
        l0.01-0.15l0.03-0.18l0.08-0.4l0.08-0.41h-0.13h-0.13l0.03-0.16l0.03-0.17h0.13h0.13l0.03-0.16l0.03-0.17l0.24-0.07L281.06,178.77
        z"
          fill="#FFFFFF"
          fill-rule="evenodd"
        />
        <path
          clip-rule="evenodd"
          d="M270.1,173.57c0,0.86-0.21,1.81-0.63,2.55
        c-0.42,0.74-1.07,1.26-1.93,1.26c-0.57,0-0.97-0.22-1.23-0.58c-0.26-0.36-0.37-0.85-0.37-1.4c0-0.87,0.22-1.8,0.65-2.51
        c0.43-0.71,1.07-1.2,1.92-1.2c0.59,0,0.99,0.23,1.24,0.57C270.01,172.61,270.1,173.08,270.1,173.57 M267.14,175.44
        c0,0.2,0.02,0.45,0.09,0.64c0.08,0.19,0.22,0.34,0.46,0.34c0.42,0,0.72-0.53,0.92-1.14c0.2-0.62,0.29-1.33,0.29-1.69
        c0-0.18-0.01-0.42-0.08-0.6c-0.07-0.19-0.2-0.33-0.43-0.33c-0.41,0-0.72,0.49-0.94,1.09
        C267.25,174.34,267.14,175.03,267.14,175.44z"
          fill="#FFFFFF"
          fill-rule="evenodd"
        />
        <path
          clip-rule="evenodd"
          d="M269.7,178.29c0.19-0.37,0.37-0.79,0.54-1.2
        c0.17-0.41,0.32-0.82,0.43-1.21l0.66-0.04l0.66-0.04c-0.19,0.43-0.42,0.87-0.66,1.29c-0.24,0.41-0.49,0.8-0.75,1.13l-0.44,0.04
        L269.7,178.29z"
          fill="#FFFFFF"
          fill-rule="evenodd"
        />
        <path
          clip-rule="evenodd"
          d="M285.82,177.1c-0.14,0.08-0.33,0.14-0.56,0.2
        c-0.23,0.05-0.5,0.08-0.78,0.08c-0.67,0-1.2-0.2-1.58-0.57c-0.38-0.36-0.59-0.88-0.62-1.51h-0.31h-0.31l0.06-0.3l0.07-0.31h0.27
        h0.26l0.03-0.2l0.03-0.19h-0.26h-0.27l0.07-0.3l0.06-0.3h0.3h0.29c0.21-0.58,0.6-1.08,1.1-1.44c0.5-0.36,1.11-0.58,1.75-0.58
        c0.29,0,0.54,0.03,0.75,0.08c0.21,0.05,0.37,0.11,0.5,0.18l-0.18,0.47l-0.19,0.46c-0.08-0.04-0.2-0.08-0.36-0.11
        c-0.16-0.03-0.35-0.06-0.57-0.06c-0.34,0-0.66,0.12-0.92,0.3c-0.26,0.18-0.47,0.43-0.59,0.7h0.99h0.98l-0.07,0.3l-0.07,0.3h-1.03
        h-1.03l-0.04,0.19l-0.03,0.2h1.03h1.03l-0.07,0.31l-0.07,0.3h-0.96h-0.96c0.04,0.32,0.16,0.58,0.35,0.77
        c0.19,0.18,0.46,0.29,0.8,0.29c0.19,0,0.4-0.03,0.57-0.07c0.18-0.03,0.33-0.08,0.43-0.12l0.06,0.46L285.82,177.1z"
          fill="#FFFFFF"
          fill-rule="evenodd"
        />
        <polygon
          clip-rule="evenodd"
          fill="#FFFFFF"
          fill-rule="evenodd"
          points="274.73,172.86 274.71,172.86 273.61,173.35
        273.57,172.38 275.13,171.7 276.14,171.7 275.09,177.3 273.89,177.3     "
        />
        <path
          clip-rule="evenodd"
          d="M281.56,173.52c0,1.75-0.85,3.87-2.61,3.87
        c-1.17,0-1.62-0.9-1.62-2.02c0-1.76,0.9-3.78,2.62-3.78C281.16,171.6,281.56,172.52,281.56,173.52 M278.54,175.42
        c0,0.41,0.07,0.99,0.56,0.99c0.86,0,1.23-2.13,1.23-2.88c0-0.37-0.05-0.95-0.52-0.95C278.98,172.58,278.54,174.6,278.54,175.42z"
          fill="#FFFFFF"
          fill-rule="evenodd"
        />
      </g>
      <g>
        <path
          d="M267.93,58.05c0.04,0,0.08,0.01,0.11,0.04s0.05,0.05,0.06,0.09l1.2,3.6h-0.49l-0.97-3.06
        c-0.01-0.05-0.03-0.1-0.05-0.15c-0.02-0.05-0.03-0.1-0.05-0.15h-0.15c-0.01,0.05-0.03,0.1-0.04,0.15
        c-0.01,0.05-0.03,0.1-0.05,0.15l-0.97,3.06h-0.49l1.2-3.6c0.01-0.04,0.03-0.07,0.06-0.09s0.07-0.04,0.11-0.04H267.93z
         M268.62,60.2v0.41h-1.89V60.2H268.62z"
          fill="#FFFFFF"
        />
        <path
          d="M270.03,58.03c0.09,0,0.14,0.05,0.14,0.14v0.31c0,0.09-0.05,0.14-0.14,0.14h-0.22
        c-0.09,0-0.14-0.05-0.14-0.14v-0.31c0-0.09,0.05-0.14,0.14-0.14H270.03z M270.15,59.14v2.63h-0.45v-2.63H270.15z"
          fill="#FFFFFF"
        />
        <path
          d="M271.29,59.14l0.04,0.5l0.03,0.08v2.05h-0.46v-2.63H271.29z M272.5,59.08c0.47,0,0.71,0.24,0.71,0.72v1.97
        h-0.46v-1.84c0-0.18-0.03-0.3-0.09-0.37c-0.06-0.07-0.15-0.1-0.29-0.1c-0.16,0-0.32,0.04-0.49,0.12s-0.35,0.19-0.58,0.32
        l-0.02-0.33c0.21-0.16,0.41-0.28,0.61-0.37C272.1,59.12,272.3,59.08,272.5,59.08z"
          fill="#FFFFFF"
        />
        <path
          d="M275.01,59.08c0.4,0,0.68,0.07,0.86,0.22c0.18,0.14,0.27,0.36,0.27,0.64c0,0.23-0.05,0.42-0.15,0.55
        c-0.1,0.13-0.26,0.2-0.48,0.2h-1.53v-0.36h1.42c0.12,0,0.2-0.04,0.23-0.12s0.05-0.17,0.05-0.27c0-0.17-0.05-0.29-0.15-0.36
        c-0.1-0.07-0.26-0.11-0.5-0.11c-0.19,0-0.34,0.03-0.45,0.08c-0.11,0.06-0.19,0.15-0.23,0.29c-0.04,0.14-0.07,0.33-0.07,0.58
        c0,0.28,0.03,0.5,0.08,0.64s0.15,0.25,0.27,0.3c0.12,0.05,0.28,0.08,0.49,0.08c0.14,0,0.29-0.01,0.46-0.02
        c0.17-0.01,0.32-0.03,0.45-0.05l0.05,0.31c-0.08,0.03-0.18,0.06-0.31,0.08c-0.12,0.02-0.24,0.04-0.37,0.05s-0.24,0.02-0.34,0.02
        c-0.3,0-0.55-0.05-0.73-0.14s-0.32-0.24-0.4-0.44c-0.08-0.2-0.13-0.47-0.13-0.81c0-0.34,0.04-0.62,0.13-0.82
        c0.08-0.2,0.22-0.34,0.39-0.43C274.5,59.12,274.73,59.08,275.01,59.08z"
          fill="#FFFFFF"
        />
        <path
          d="M277.6,59.08c0.12,0,0.24,0,0.37,0.01c0.13,0.01,0.26,0.02,0.39,0.03s0.25,0.03,0.35,0.04l-0.04,0.33
        c-0.17-0.01-0.33-0.01-0.5-0.02c-0.17,0-0.33-0.01-0.5-0.01c-0.16,0-0.29,0-0.38,0.01s-0.16,0.04-0.2,0.09
        c-0.04,0.05-0.06,0.13-0.06,0.24c0,0.14,0.03,0.23,0.1,0.27c0.06,0.04,0.16,0.08,0.29,0.11l0.77,0.17
        c0.21,0.05,0.36,0.13,0.46,0.23c0.1,0.1,0.15,0.27,0.15,0.49c0,0.22-0.04,0.38-0.12,0.49c-0.08,0.11-0.2,0.18-0.37,0.22
        c-0.17,0.04-0.38,0.05-0.63,0.05c-0.09,0-0.23,0-0.42-0.01c-0.19-0.01-0.39-0.03-0.61-0.07l0.03-0.33c0.09,0,0.18,0.01,0.28,0.01
        c0.1,0.01,0.2,0.01,0.3,0.01s0.21,0,0.33,0c0.2,0,0.35-0.01,0.46-0.03c0.11-0.02,0.19-0.05,0.24-0.11
        c0.05-0.05,0.07-0.13,0.07-0.23c0-0.13-0.04-0.22-0.11-0.26c-0.08-0.04-0.18-0.08-0.3-0.11l-0.76-0.17
        c-0.14-0.04-0.26-0.08-0.35-0.14c-0.09-0.06-0.15-0.14-0.19-0.23c-0.04-0.1-0.06-0.22-0.06-0.36c0-0.21,0.04-0.36,0.11-0.46
        c0.07-0.1,0.18-0.17,0.33-0.21S277.36,59.08,277.6,59.08z"
          fill="#FFFFFF"
        />
        <path
          d="M280.47,59.08c0.3,0,0.54,0.04,0.73,0.13c0.18,0.09,0.31,0.24,0.39,0.44s0.12,0.47,0.12,0.81
        c0,0.33-0.04,0.6-0.12,0.81s-0.21,0.35-0.39,0.44s-0.42,0.13-0.73,0.13c-0.3,0-0.54-0.04-0.72-0.13s-0.31-0.24-0.39-0.44
        c-0.08-0.2-0.12-0.47-0.12-0.81c0-0.33,0.04-0.6,0.12-0.81c0.08-0.2,0.21-0.35,0.39-0.44C279.93,59.12,280.17,59.08,280.47,59.08z
         M280.47,59.47c-0.19,0-0.34,0.03-0.45,0.09c-0.11,0.06-0.19,0.16-0.24,0.31c-0.05,0.14-0.07,0.34-0.07,0.6
        c0,0.25,0.02,0.46,0.07,0.6c0.05,0.14,0.12,0.25,0.24,0.31c0.11,0.06,0.26,0.09,0.45,0.09c0.19,0,0.34-0.03,0.45-0.09
        c0.11-0.06,0.19-0.16,0.24-0.31c0.05-0.14,0.07-0.34,0.07-0.6c0-0.26-0.02-0.46-0.07-0.6c-0.05-0.14-0.13-0.25-0.24-0.31
        C280.81,59.5,280.66,59.47,280.47,59.47z"
          fill="#FFFFFF"
        />
        <path
          d="M283.19,59.08c0.12,0,0.24,0,0.37,0.01c0.13,0.01,0.26,0.02,0.39,0.03s0.25,0.03,0.35,0.04l-0.04,0.33
        c-0.17-0.01-0.33-0.01-0.5-0.02c-0.17,0-0.33-0.01-0.5-0.01c-0.16,0-0.29,0-0.38,0.01s-0.16,0.04-0.2,0.09
        c-0.04,0.05-0.06,0.13-0.06,0.24c0,0.14,0.03,0.23,0.1,0.27c0.06,0.04,0.16,0.08,0.29,0.11l0.77,0.17
        c0.21,0.05,0.36,0.13,0.46,0.23c0.1,0.1,0.15,0.27,0.15,0.49c0,0.22-0.04,0.38-0.12,0.49c-0.08,0.11-0.2,0.18-0.37,0.22
        c-0.17,0.04-0.38,0.05-0.63,0.05c-0.09,0-0.23,0-0.42-0.01c-0.19-0.01-0.39-0.03-0.61-0.07l0.03-0.33c0.09,0,0.18,0.01,0.28,0.01
        c0.1,0.01,0.2,0.01,0.3,0.01s0.21,0,0.33,0c0.2,0,0.35-0.01,0.46-0.03c0.11-0.02,0.19-0.05,0.24-0.11
        c0.05-0.05,0.07-0.13,0.07-0.23c0-0.13-0.04-0.22-0.11-0.26c-0.08-0.04-0.18-0.08-0.3-0.11l-0.76-0.17
        c-0.14-0.04-0.26-0.08-0.35-0.14c-0.09-0.06-0.15-0.14-0.19-0.23c-0.04-0.1-0.06-0.22-0.06-0.36c0-0.21,0.04-0.36,0.11-0.46
        c0.07-0.1,0.18-0.17,0.33-0.21S282.95,59.08,283.19,59.08z"
          fill="#FFFFFF"
        />
        <path
          d="M286.88,60.15l-0.01,0.35l-1.26,0.01c-0.13,0-0.21,0.04-0.26,0.1c-0.05,0.06-0.08,0.15-0.08,0.26v0.17
        c0,0.14,0.03,0.24,0.1,0.3c0.07,0.06,0.17,0.09,0.31,0.09c0.1,0,0.21-0.02,0.34-0.06s0.26-0.1,0.39-0.18
        c0.13-0.08,0.25-0.18,0.36-0.3v0.34c-0.04,0.05-0.1,0.11-0.17,0.18c-0.07,0.07-0.16,0.13-0.26,0.19c-0.1,0.06-0.22,0.12-0.34,0.16
        c-0.12,0.04-0.26,0.06-0.4,0.06c-0.15,0-0.29-0.03-0.41-0.08s-0.21-0.14-0.28-0.25c-0.07-0.11-0.1-0.24-0.1-0.4V60.8
        c0-0.21,0.06-0.36,0.19-0.48c0.13-0.11,0.31-0.17,0.54-0.17H286.88z M286.19,59.08c0.19,0,0.36,0.03,0.49,0.08
        c0.13,0.05,0.24,0.14,0.3,0.26c0.07,0.12,0.1,0.29,0.1,0.5v1.85h-0.36l-0.07-0.57l-0.03-0.06v-1.22c0-0.16-0.04-0.28-0.11-0.35
        c-0.07-0.07-0.21-0.11-0.42-0.11c-0.14,0-0.31,0.01-0.52,0.02c-0.21,0.01-0.41,0.03-0.61,0.05l-0.04-0.34
        c0.12-0.02,0.26-0.04,0.4-0.06c0.15-0.02,0.29-0.03,0.45-0.04C285.92,59.09,286.06,59.08,286.19,59.08z"
          fill="#FFFFFF"
        />
        <path
          d="M289.3,59.14v0.36h-1.77v-0.33l0.52-0.03H289.3z M288.47,58.37v2.68c0,0.13,0.03,0.22,0.08,0.27
        c0.05,0.05,0.15,0.08,0.28,0.08h0.4l0.05,0.35c-0.06,0.01-0.12,0.03-0.2,0.04c-0.08,0.01-0.15,0.02-0.22,0.02
        c-0.07,0.01-0.13,0.01-0.17,0.01c-0.22,0-0.39-0.06-0.5-0.18s-0.17-0.3-0.17-0.53v-2.74H288.47z"
          fill="#FFFFFF"
        />
        <path
          d="M290.03,59.4c0.07,0,0.11,0.01,0.13,0.04c0.03,0.03,0.04,0.07,0.04,0.13v0.29c0,0.06-0.01,0.11-0.04,0.13
        c-0.02,0.03-0.07,0.04-0.13,0.04h-0.15c-0.06,0-0.1-0.01-0.13-0.04s-0.04-0.07-0.04-0.13v-0.29c0-0.06,0.01-0.11,0.04-0.13
        s0.07-0.04,0.13-0.04H290.03z M290.03,61.14c0.07,0,0.11,0.01,0.13,0.04c0.03,0.03,0.04,0.07,0.04,0.14v0.29
        c0,0.06-0.01,0.11-0.04,0.13c-0.02,0.03-0.07,0.04-0.13,0.04h-0.15c-0.06,0-0.1-0.01-0.13-0.04c-0.03-0.02-0.04-0.07-0.04-0.13
        v-0.29c0-0.07,0.01-0.11,0.04-0.14c0.03-0.02,0.07-0.04,0.13-0.04H290.03z"
          fill="#FFFFFF"
        />
        <path
          d="M294.24,59.14l-0.96,2.52c-0.01,0.04-0.04,0.06-0.06,0.08c-0.03,0.02-0.06,0.03-0.1,0.03h-0.42
        c-0.04,0-0.07-0.01-0.1-0.03c-0.03-0.02-0.05-0.05-0.06-0.08l-0.96-2.52h0.49l0.65,1.84c0.03,0.08,0.06,0.15,0.08,0.23
        s0.05,0.15,0.08,0.23h0.07c0.03-0.08,0.06-0.15,0.08-0.23s0.05-0.15,0.08-0.23l0.65-1.84H294.24z"
          fill="#FFFFFF"
        />
        <path
          d="M295.7,59.08c0.4,0,0.68,0.07,0.86,0.22c0.18,0.14,0.27,0.36,0.27,0.64c0,0.23-0.05,0.42-0.15,0.55
        c-0.1,0.13-0.26,0.2-0.48,0.2h-1.53v-0.36h1.42c0.12,0,0.2-0.04,0.23-0.12s0.05-0.17,0.05-0.27c0-0.17-0.05-0.29-0.15-0.36
        c-0.1-0.07-0.26-0.11-0.5-0.11c-0.19,0-0.34,0.03-0.45,0.08c-0.11,0.06-0.19,0.15-0.23,0.29c-0.04,0.14-0.07,0.33-0.07,0.58
        c0,0.28,0.03,0.5,0.08,0.64s0.15,0.25,0.27,0.3c0.12,0.05,0.28,0.08,0.49,0.08c0.14,0,0.29-0.01,0.46-0.02
        c0.17-0.01,0.32-0.03,0.45-0.05l0.05,0.31c-0.08,0.03-0.18,0.06-0.31,0.08c-0.12,0.02-0.24,0.04-0.37,0.05s-0.24,0.02-0.34,0.02
        c-0.3,0-0.55-0.05-0.73-0.14s-0.32-0.24-0.4-0.44c-0.08-0.2-0.13-0.47-0.13-0.81c0-0.34,0.04-0.62,0.13-0.82
        c0.08-0.2,0.22-0.34,0.39-0.43C295.19,59.12,295.42,59.08,295.7,59.08z"
          fill="#FFFFFF"
        />
        <path
          d="M298.29,59.08c0.12,0,0.24,0,0.37,0.01c0.13,0.01,0.26,0.02,0.39,0.03s0.25,0.03,0.35,0.04l-0.04,0.33
        c-0.17-0.01-0.33-0.01-0.5-0.02c-0.17,0-0.33-0.01-0.5-0.01c-0.16,0-0.29,0-0.38,0.01s-0.16,0.04-0.2,0.09
        c-0.04,0.05-0.06,0.13-0.06,0.24c0,0.14,0.03,0.23,0.1,0.27c0.06,0.04,0.16,0.08,0.29,0.11l0.77,0.17
        c0.21,0.05,0.36,0.13,0.46,0.23c0.1,0.1,0.15,0.27,0.15,0.49c0,0.22-0.04,0.38-0.12,0.49c-0.08,0.11-0.2,0.18-0.37,0.22
        c-0.17,0.04-0.38,0.05-0.63,0.05c-0.09,0-0.23,0-0.42-0.01c-0.19-0.01-0.39-0.03-0.61-0.07l0.03-0.33c0.09,0,0.18,0.01,0.28,0.01
        c0.1,0.01,0.2,0.01,0.3,0.01s0.21,0,0.33,0c0.2,0,0.35-0.01,0.46-0.03c0.11-0.02,0.19-0.05,0.24-0.11
        c0.05-0.05,0.07-0.13,0.07-0.23c0-0.13-0.04-0.22-0.11-0.26c-0.08-0.04-0.18-0.08-0.3-0.11l-0.76-0.17
        c-0.14-0.04-0.26-0.08-0.35-0.14c-0.09-0.06-0.15-0.14-0.19-0.23c-0.04-0.1-0.06-0.22-0.06-0.36c0-0.21,0.04-0.36,0.11-0.46
        c0.07-0.1,0.18-0.17,0.33-0.21S298.05,59.08,298.29,59.08z"
          fill="#FFFFFF"
        />
        <path
          d="M300.4,58.03c0.09,0,0.14,0.05,0.14,0.14v0.31c0,0.09-0.05,0.14-0.14,0.14h-0.22
        c-0.09,0-0.14-0.05-0.14-0.14v-0.31c0-0.09,0.05-0.14,0.14-0.14H300.4z M300.52,59.14v2.63h-0.45v-2.63H300.52z"
          fill="#FFFFFF"
        />
        <path
          d="M301.54,61.14c0.07,0,0.11,0.01,0.13,0.04c0.02,0.03,0.04,0.07,0.04,0.14v0.34c0,0.1-0.01,0.2-0.02,0.3
        c-0.02,0.1-0.04,0.19-0.07,0.28c-0.03,0.09-0.06,0.17-0.1,0.26l-0.22-0.07c0.02-0.11,0.03-0.21,0.04-0.31
        c0.01-0.09,0.01-0.21,0.01-0.34c-0.09,0-0.13-0.05-0.13-0.15v-0.31c0-0.07,0.02-0.11,0.05-0.14c0.03-0.02,0.07-0.04,0.13-0.04
        H301.54z"
          fill="#FFFFFF"
        />
        <path
          d="M304.67,59.04c0.33,0,0.6,0.05,0.8,0.14c0.2,0.09,0.35,0.24,0.43,0.45c0.09,0.21,0.13,0.48,0.13,0.83
        c0,0.34-0.04,0.61-0.13,0.82c-0.09,0.21-0.23,0.35-0.43,0.44c-0.2,0.09-0.47,0.13-0.8,0.13c-0.33,0-0.6-0.04-0.8-0.13
        s-0.35-0.24-0.44-0.44s-0.13-0.48-0.13-0.82c0-0.35,0.04-0.62,0.13-0.83s0.24-0.35,0.44-0.45S304.34,59.04,304.67,59.04z
         M304.67,59.62c-0.14,0-0.25,0.02-0.32,0.07s-0.13,0.13-0.17,0.25c-0.03,0.12-0.05,0.29-0.05,0.51c0,0.22,0.02,0.38,0.05,0.5
        s0.09,0.2,0.17,0.25s0.19,0.07,0.32,0.07s0.25-0.02,0.32-0.07s0.13-0.13,0.17-0.25s0.05-0.29,0.05-0.5c0-0.22-0.02-0.39-0.05-0.51
        c-0.03-0.12-0.09-0.2-0.17-0.25S304.81,59.62,304.67,59.62z"
          fill="#FFFFFF"
        />
        <path
          d="M307.28,58l0.01,0.92c0,0.14-0.01,0.27-0.02,0.4c-0.02,0.13-0.04,0.23-0.06,0.32l0.08,0.07v2.07h-0.8V58
        H307.28z M308.34,59.04c0.5,0,0.76,0.25,0.76,0.74v2h-0.8v-1.75c0-0.12-0.02-0.2-0.06-0.25c-0.04-0.04-0.11-0.07-0.22-0.07
        c-0.12,0-0.23,0.02-0.36,0.06c-0.12,0.04-0.27,0.11-0.45,0.21l-0.07-0.42c0.2-0.17,0.4-0.3,0.6-0.4
        C307.94,59.08,308.14,59.04,308.34,59.04z"
          fill="#FFFFFF"
        />
        <path
          d="M310.24,59.11l0.1,0.53l0.08,0.07v2.07h-0.8v-2.67H310.24z M311.54,59.04l-0.08,0.74h-0.22
        c-0.13,0-0.26,0.02-0.39,0.05s-0.31,0.09-0.52,0.16l-0.05-0.45c0.19-0.17,0.38-0.29,0.57-0.38c0.19-0.09,0.38-0.13,0.56-0.13
        H311.54z"
          fill="#FFFFFF"
        />
        <path
          d="M313.92,60.13l-0.01,0.45l-1.19,0.01c-0.1,0-0.17,0.02-0.21,0.07c-0.04,0.05-0.06,0.11-0.06,0.19v0.13
        c0,0.09,0.03,0.16,0.08,0.21c0.05,0.05,0.13,0.07,0.24,0.07c0.09,0,0.2-0.02,0.31-0.06c0.11-0.04,0.22-0.09,0.33-0.17
        c0.11-0.07,0.2-0.16,0.29-0.25v0.35c-0.03,0.05-0.08,0.12-0.14,0.2c-0.06,0.08-0.14,0.16-0.24,0.24c-0.1,0.08-0.21,0.14-0.34,0.2
        c-0.13,0.05-0.28,0.08-0.44,0.08c-0.16,0-0.31-0.03-0.44-0.09c-0.13-0.06-0.23-0.14-0.31-0.25c-0.08-0.11-0.11-0.25-0.11-0.42
        v-0.28c0-0.21,0.07-0.38,0.22-0.5c0.14-0.12,0.34-0.18,0.59-0.18H313.92z M313.21,59.04c0.22,0,0.41,0.03,0.57,0.09
        c0.16,0.06,0.27,0.16,0.35,0.3c0.08,0.14,0.12,0.34,0.12,0.59v1.76h-0.64l-0.11-0.58l-0.05-0.08v-1.11c0-0.13-0.03-0.22-0.09-0.28
        s-0.17-0.09-0.34-0.09c-0.14,0-0.31,0-0.51,0.01c-0.21,0.01-0.42,0.02-0.63,0.04l-0.08-0.53c0.13-0.03,0.28-0.05,0.45-0.07
        c0.17-0.02,0.34-0.03,0.51-0.05C312.92,59.04,313.07,59.04,313.21,59.04z"
          fill="#FFFFFF"
        />
        <path
          d="M315.24,59.14l0.03,0.5l0.05,0.08v2.05h-0.46v-2.63H315.24z M316.34,59.08c0.22,0,0.39,0.06,0.51,0.18
        c0.12,0.12,0.18,0.3,0.18,0.54v1.97h-0.44v-1.84c0-0.17-0.03-0.29-0.1-0.36s-0.17-0.11-0.31-0.11c-0.09,0-0.18,0.01-0.26,0.04
        c-0.08,0.03-0.18,0.08-0.28,0.14c-0.1,0.07-0.23,0.15-0.38,0.26l-0.03-0.32c0.19-0.17,0.38-0.3,0.56-0.38
        S316.15,59.08,316.34,59.08z M318.06,59.08c0.23,0,0.4,0.06,0.52,0.18c0.12,0.12,0.18,0.3,0.18,0.54v1.97h-0.45v-1.84
        c0-0.16-0.04-0.28-0.1-0.35c-0.06-0.07-0.17-0.11-0.31-0.11c-0.09,0-0.18,0.02-0.27,0.05c-0.08,0.03-0.18,0.08-0.28,0.14
        c-0.1,0.06-0.22,0.15-0.37,0.25l-0.03-0.32c0.19-0.17,0.38-0.3,0.56-0.38C317.7,59.12,317.88,59.08,318.06,59.08z"
          fill="#FFFFFF"
        />
        <path
          d="M321.41,60.15l-0.01,0.35l-1.26,0.01c-0.13,0-0.21,0.04-0.26,0.1c-0.05,0.06-0.08,0.15-0.08,0.26v0.17
        c0,0.14,0.03,0.24,0.1,0.3c0.07,0.06,0.17,0.09,0.31,0.09c0.1,0,0.21-0.02,0.34-0.06s0.26-0.1,0.39-0.18
        c0.13-0.08,0.25-0.18,0.36-0.3v0.34c-0.04,0.05-0.1,0.11-0.17,0.18c-0.07,0.07-0.16,0.13-0.26,0.19c-0.1,0.06-0.22,0.12-0.34,0.16
        c-0.12,0.04-0.26,0.06-0.4,0.06c-0.15,0-0.29-0.03-0.41-0.08s-0.21-0.14-0.28-0.25c-0.07-0.11-0.1-0.24-0.1-0.4V60.8
        c0-0.21,0.06-0.36,0.19-0.48c0.13-0.11,0.31-0.17,0.54-0.17H321.41z M320.73,59.08c0.19,0,0.36,0.03,0.49,0.08
        c0.13,0.05,0.24,0.14,0.3,0.26c0.07,0.12,0.1,0.29,0.1,0.5v1.85h-0.36l-0.07-0.57l-0.03-0.06v-1.22c0-0.16-0.04-0.28-0.11-0.35
        c-0.07-0.07-0.21-0.11-0.42-0.11c-0.14,0-0.31,0.01-0.52,0.02c-0.21,0.01-0.41,0.03-0.61,0.05l-0.04-0.34
        c0.12-0.02,0.26-0.04,0.4-0.06c0.15-0.02,0.29-0.03,0.45-0.04C320.46,59.09,320.6,59.08,320.73,59.08z"
          fill="#FFFFFF"
        />
        <path
          d="M322.8,58l-0.01,3.06c0,0.12,0.03,0.2,0.09,0.26c0.06,0.06,0.15,0.09,0.27,0.09h0.25l0.05,0.35
        c-0.03,0.01-0.08,0.03-0.14,0.04s-0.12,0.02-0.18,0.02c-0.06,0.01-0.12,0.01-0.16,0.01c-0.19,0-0.35-0.06-0.47-0.17
        c-0.12-0.12-0.17-0.28-0.17-0.5V58H322.8z"
          fill="#FFFFFF"
        />
        <path
          d="M324.3,58l-0.01,3.06c0,0.12,0.03,0.2,0.09,0.26c0.06,0.06,0.15,0.09,0.27,0.09h0.25l0.05,0.35
        c-0.03,0.01-0.08,0.03-0.14,0.04s-0.12,0.02-0.18,0.02c-0.06,0.01-0.12,0.01-0.16,0.01c-0.19,0-0.35-0.06-0.47-0.17
        c-0.12-0.12-0.17-0.28-0.17-0.5V58H324.3z"
          fill="#FFFFFF"
        />
        <path
          d="M327.26,60.15l-0.01,0.35L326,60.51c-0.13,0-0.21,0.04-0.26,0.1c-0.05,0.06-0.08,0.15-0.08,0.26v0.17
        c0,0.14,0.03,0.24,0.1,0.3c0.07,0.06,0.17,0.09,0.31,0.09c0.1,0,0.21-0.02,0.34-0.06s0.26-0.1,0.39-0.18
        c0.13-0.08,0.25-0.18,0.36-0.3v0.34c-0.04,0.05-0.1,0.11-0.17,0.18c-0.07,0.07-0.16,0.13-0.26,0.19c-0.1,0.06-0.22,0.12-0.34,0.16
        c-0.12,0.04-0.26,0.06-0.4,0.06c-0.15,0-0.29-0.03-0.41-0.08s-0.21-0.14-0.28-0.25c-0.07-0.11-0.1-0.24-0.1-0.4V60.8
        c0-0.21,0.06-0.36,0.19-0.48c0.13-0.11,0.31-0.17,0.54-0.17H327.26z M326.57,59.08c0.19,0,0.36,0.03,0.49,0.08
        c0.13,0.05,0.24,0.14,0.3,0.26c0.07,0.12,0.1,0.29,0.1,0.5v1.85h-0.36l-0.07-0.57l-0.03-0.06v-1.22c0-0.16-0.04-0.28-0.11-0.35
        c-0.07-0.07-0.21-0.11-0.42-0.11c-0.14,0-0.31,0.01-0.52,0.02c-0.21,0.01-0.41,0.03-0.61,0.05l-0.04-0.34
        c0.12-0.02,0.26-0.04,0.4-0.06c0.15-0.02,0.29-0.03,0.45-0.04C326.3,59.09,326.44,59.08,326.57,59.08z"
          fill="#FFFFFF"
        />
        <path
          d="M329.06,59.08c0.12,0,0.24,0,0.37,0.01c0.13,0.01,0.26,0.02,0.39,0.03s0.25,0.03,0.35,0.04l-0.04,0.33
        c-0.17-0.01-0.33-0.01-0.5-0.02c-0.17,0-0.33-0.01-0.5-0.01c-0.16,0-0.29,0-0.38,0.01s-0.16,0.04-0.2,0.09
        c-0.04,0.05-0.06,0.13-0.06,0.24c0,0.14,0.03,0.23,0.1,0.27c0.06,0.04,0.16,0.08,0.29,0.11l0.77,0.17
        c0.21,0.05,0.36,0.13,0.46,0.23c0.1,0.1,0.15,0.27,0.15,0.49c0,0.22-0.04,0.38-0.12,0.49c-0.08,0.11-0.2,0.18-0.37,0.22
        c-0.17,0.04-0.38,0.05-0.63,0.05c-0.09,0-0.23,0-0.42-0.01c-0.19-0.01-0.39-0.03-0.61-0.07l0.03-0.33c0.09,0,0.18,0.01,0.28,0.01
        c0.1,0.01,0.2,0.01,0.3,0.01s0.21,0,0.33,0c0.2,0,0.35-0.01,0.46-0.03c0.11-0.02,0.19-0.05,0.24-0.11
        c0.05-0.05,0.07-0.13,0.07-0.23c0-0.13-0.04-0.22-0.11-0.26c-0.08-0.04-0.18-0.08-0.3-0.11l-0.76-0.17
        c-0.14-0.04-0.26-0.08-0.35-0.14c-0.09-0.06-0.15-0.14-0.19-0.23c-0.04-0.1-0.06-0.22-0.06-0.36c0-0.21,0.04-0.36,0.11-0.46
        c0.07-0.1,0.18-0.17,0.33-0.21S328.82,59.08,329.06,59.08z"
          fill="#FFFFFF"
        />
        <path
          d="M331.09,61.14c0.07,0,0.11,0.01,0.13,0.04c0.02,0.03,0.04,0.07,0.04,0.14v0.34c0,0.1-0.01,0.2-0.02,0.3
        c-0.02,0.1-0.04,0.19-0.07,0.28c-0.03,0.09-0.06,0.17-0.1,0.26l-0.22-0.07c0.02-0.11,0.03-0.21,0.04-0.31
        c0.01-0.09,0.01-0.21,0.01-0.34c-0.09,0-0.13-0.05-0.13-0.15v-0.31c0-0.07,0.02-0.11,0.05-0.14c0.03-0.02,0.07-0.04,0.13-0.04
        H331.09z"
          fill="#FFFFFF"
        />
        <path
          d="M334.25,59.02c0.35,0,0.64,0.05,0.85,0.14c0.21,0.09,0.37,0.24,0.46,0.45s0.14,0.48,0.14,0.83
        c0,0.35-0.05,0.62-0.14,0.82s-0.25,0.35-0.46,0.44c-0.21,0.09-0.5,0.14-0.85,0.14s-0.63-0.05-0.85-0.14
        c-0.21-0.09-0.37-0.24-0.46-0.44s-0.14-0.48-0.14-0.82c0-0.35,0.05-0.63,0.14-0.83s0.25-0.36,0.46-0.45
        C333.61,59.06,333.89,59.02,334.25,59.02z M334.25,59.7c-0.11,0-0.2,0.02-0.26,0.06c-0.06,0.04-0.1,0.11-0.13,0.22
        c-0.03,0.1-0.04,0.26-0.04,0.46c0,0.2,0.01,0.35,0.04,0.45c0.02,0.1,0.07,0.18,0.13,0.21c0.06,0.04,0.14,0.06,0.26,0.06
        s0.2-0.02,0.26-0.06c0.06-0.04,0.1-0.11,0.13-0.21s0.04-0.25,0.04-0.45c0-0.2-0.01-0.35-0.04-0.46s-0.07-0.18-0.13-0.22
        C334.44,59.72,334.36,59.7,334.25,59.7z"
          fill="#FFFFFF"
        />
        <path
          d="M337.01,58l0.01,0.86c0,0.15-0.01,0.3-0.03,0.44c-0.02,0.14-0.05,0.25-0.07,0.34l0.1,0.07v2.07h-0.98V58
        H337.01z M338.02,59.02c0.52,0,0.78,0.25,0.78,0.75v2.01h-0.98v-1.7c0-0.09-0.02-0.15-0.05-0.18s-0.09-0.05-0.18-0.05
        c-0.09,0-0.19,0.01-0.29,0.04c-0.1,0.03-0.23,0.07-0.39,0.14l-0.1-0.45c0.19-0.18,0.39-0.32,0.6-0.42
        C337.62,59.06,337.83,59.02,338.02,59.02z"
          fill="#FFFFFF"
        />
        <path
          d="M340,59.09l0.12,0.54l0.1,0.07v2.07h-0.98v-2.68H340z M341.3,59.02l-0.09,0.9h-0.26
        c-0.11,0-0.23,0.01-0.36,0.03c-0.13,0.02-0.29,0.06-0.49,0.11l-0.06-0.54c0.18-0.17,0.37-0.3,0.55-0.38
        c0.19-0.08,0.37-0.13,0.55-0.13H341.3z"
          fill="#FFFFFF"
        />
        <path
          d="M343.69,60.13l-0.01,0.5h-1.15c-0.08,0-0.14,0.02-0.18,0.06c-0.04,0.04-0.06,0.09-0.06,0.15v0.1
        c0,0.07,0.02,0.12,0.07,0.16c0.04,0.04,0.11,0.06,0.2,0.06s0.19-0.02,0.29-0.06s0.2-0.09,0.3-0.16c0.1-0.07,0.18-0.14,0.25-0.22
        v0.35c-0.03,0.06-0.07,0.13-0.13,0.22c-0.06,0.09-0.14,0.17-0.23,0.26c-0.09,0.09-0.21,0.16-0.34,0.22s-0.29,0.09-0.46,0.09
        c-0.16,0-0.31-0.03-0.45-0.09s-0.25-0.14-0.33-0.25c-0.08-0.11-0.12-0.25-0.12-0.42v-0.27c0-0.22,0.08-0.38,0.23-0.5
        c0.15-0.12,0.36-0.18,0.62-0.18H343.69z M342.96,59.02c0.24,0,0.45,0.03,0.61,0.1c0.17,0.07,0.29,0.17,0.38,0.32
        c0.09,0.15,0.13,0.36,0.13,0.63v1.71h-0.78l-0.13-0.58l-0.06-0.1v-1.04c0-0.11-0.03-0.19-0.08-0.24
        c-0.05-0.05-0.15-0.07-0.28-0.07c-0.14,0-0.31,0-0.52,0.01c-0.21,0.01-0.42,0.02-0.64,0.03l-0.09-0.63
        c0.13-0.03,0.29-0.05,0.47-0.07c0.18-0.02,0.36-0.04,0.54-0.05S342.83,59.02,342.96,59.02z"
          fill="#FFFFFF"
        />
        <path
          d="M346.14,59.14v0.36h-1.77v-0.33l0.52-0.03H346.14z M345.3,58.37v2.68c0,0.13,0.03,0.22,0.08,0.27
        c0.05,0.05,0.15,0.08,0.28,0.08h0.4l0.05,0.35c-0.06,0.01-0.12,0.03-0.2,0.04c-0.08,0.01-0.15,0.02-0.22,0.02
        c-0.07,0.01-0.13,0.01-0.17,0.01c-0.22,0-0.39-0.06-0.5-0.18s-0.17-0.3-0.17-0.53v-2.74H345.3z"
          fill="#FFFFFF"
        />
        <path
          d="M348.52,60.15l-0.01,0.35l-1.26,0.01c-0.13,0-0.21,0.04-0.26,0.1c-0.05,0.06-0.08,0.15-0.08,0.26v0.17
        c0,0.14,0.03,0.24,0.1,0.3c0.07,0.06,0.17,0.09,0.31,0.09c0.1,0,0.21-0.02,0.34-0.06s0.26-0.1,0.39-0.18
        c0.13-0.08,0.25-0.18,0.36-0.3v0.34c-0.04,0.05-0.1,0.11-0.17,0.18c-0.07,0.07-0.16,0.13-0.26,0.19c-0.1,0.06-0.22,0.12-0.34,0.16
        c-0.12,0.04-0.26,0.06-0.4,0.06c-0.15,0-0.29-0.03-0.41-0.08s-0.21-0.14-0.28-0.25c-0.07-0.11-0.1-0.24-0.1-0.4V60.8
        c0-0.21,0.06-0.36,0.19-0.48c0.13-0.11,0.31-0.17,0.54-0.17H348.52z M347.84,59.08c0.19,0,0.36,0.03,0.49,0.08
        c0.13,0.05,0.24,0.14,0.3,0.26c0.07,0.12,0.1,0.29,0.1,0.5v1.85h-0.36l-0.07-0.57l-0.03-0.06v-1.22c0-0.16-0.04-0.28-0.11-0.35
        c-0.07-0.07-0.21-0.11-0.42-0.11c-0.14,0-0.31,0.01-0.52,0.02c-0.21,0.01-0.41,0.03-0.61,0.05l-0.04-0.34
        c0.12-0.02,0.26-0.04,0.4-0.06c0.15-0.02,0.29-0.03,0.45-0.04C347.56,59.09,347.71,59.08,347.84,59.08z M347.25,58.05
        c0.08,0,0.12,0.04,0.12,0.13v0.3c0,0.09-0.04,0.13-0.12,0.13h-0.16c-0.08,0-0.12-0.04-0.12-0.13v-0.3c0-0.09,0.04-0.13,0.12-0.13
        H347.25z M348.2,58.05c0.09,0,0.13,0.04,0.13,0.13v0.3c0,0.09-0.04,0.13-0.13,0.13h-0.15c-0.09,0-0.13-0.04-0.13-0.13v-0.3
        c0-0.09,0.04-0.13,0.13-0.13H348.2z"
          fill="#FFFFFF"
        />
        <path
          d="M349.81,59.14l0.06,0.5l0.04,0.08v2.05h-0.46v-2.63H349.81z M351.1,59.08l-0.05,0.43h-0.15
        c-0.15,0-0.31,0.03-0.46,0.09c-0.16,0.06-0.34,0.15-0.57,0.27l-0.04-0.29c0.19-0.16,0.39-0.28,0.59-0.37
        c0.2-0.09,0.39-0.13,0.57-0.13H351.1z"
          fill="#FFFFFF"
        />
        <path
          d="M351.96,58l0.01,1.68c0,0.11,0,0.22-0.01,0.31c-0.01,0.1-0.02,0.2-0.04,0.3c0.02,0.1,0.03,0.2,0.04,0.3
        s0.01,0.2,0.01,0.29v0.9h-0.46V58H351.96z M352.41,60.12v0.33h-0.61v-0.33H352.41z M353.66,59.14l-0.83,0.97
        c-0.03,0.03-0.06,0.07-0.09,0.1s-0.07,0.06-0.09,0.08v0.01c0.03,0.02,0.06,0.05,0.09,0.09s0.07,0.08,0.09,0.12l0.91,1.26h-0.53
        l-1.04-1.51l0.96-1.12H353.66z"
          fill="#FFFFFF"
        />
        <path
          d="M354.61,58l0.01,1.68c0,0.11,0,0.22-0.01,0.31c-0.01,0.1-0.02,0.2-0.04,0.3c0.02,0.1,0.03,0.2,0.04,0.3
        s0.01,0.2,0.01,0.29v0.9h-0.46V58H354.61z M355.05,60.12v0.33h-0.61v-0.33H355.05z M356.3,59.14l-0.83,0.97
        c-0.03,0.03-0.06,0.07-0.09,0.1s-0.07,0.06-0.09,0.08v0.01c0.03,0.02,0.06,0.05,0.09,0.09s0.07,0.08,0.09,0.12l0.91,1.26h-0.53
        l-1.04-1.51l0.96-1.12H356.3z"
          fill="#FFFFFF"
        />
        <path
          d="M357.79,59.08c0.4,0,0.68,0.07,0.86,0.22c0.18,0.14,0.27,0.36,0.27,0.64c0,0.23-0.05,0.42-0.15,0.55
        c-0.1,0.13-0.26,0.2-0.48,0.2h-1.53v-0.36h1.42c0.12,0,0.2-0.04,0.23-0.12s0.05-0.17,0.05-0.27c0-0.17-0.05-0.29-0.15-0.36
        c-0.1-0.07-0.26-0.11-0.5-0.11c-0.19,0-0.34,0.03-0.45,0.08c-0.11,0.06-0.19,0.15-0.23,0.29c-0.04,0.14-0.07,0.33-0.07,0.58
        c0,0.28,0.03,0.5,0.08,0.64s0.15,0.25,0.27,0.3c0.12,0.05,0.28,0.08,0.49,0.08c0.14,0,0.29-0.01,0.46-0.02
        c0.17-0.01,0.32-0.03,0.45-0.05l0.05,0.31c-0.08,0.03-0.18,0.06-0.31,0.08c-0.12,0.02-0.24,0.04-0.37,0.05s-0.24,0.02-0.34,0.02
        c-0.3,0-0.55-0.05-0.73-0.14s-0.32-0.24-0.4-0.44c-0.08-0.2-0.13-0.47-0.13-0.81c0-0.34,0.04-0.62,0.13-0.82
        c0.08-0.2,0.22-0.34,0.39-0.43C357.28,59.12,357.51,59.08,357.79,59.08z"
          fill="#FFFFFF"
        />
        <path
          d="M359.96,58l-0.01,3.06c0,0.12,0.03,0.2,0.09,0.26c0.06,0.06,0.15,0.09,0.27,0.09h0.25l0.05,0.35
        c-0.03,0.01-0.08,0.03-0.14,0.04s-0.12,0.02-0.18,0.02c-0.06,0.01-0.12,0.01-0.16,0.01c-0.19,0-0.35-0.06-0.47-0.17
        c-0.12-0.12-0.17-0.28-0.17-0.5V58H359.96z"
          fill="#FFFFFF"
        />
        <path
          d="M361.04,59.14l0.7,1.96c0.02,0.05,0.04,0.11,0.05,0.16c0.02,0.05,0.03,0.1,0.04,0.16h0.12l-0.15,0.35
        h-0.14c-0.04,0-0.07-0.01-0.1-0.03c-0.03-0.02-0.05-0.05-0.06-0.09l-0.96-2.51H361.04z M363.21,59.14l-1.05,2.78
        c-0.04,0.12-0.1,0.23-0.16,0.34c-0.07,0.11-0.14,0.21-0.24,0.3s-0.2,0.17-0.32,0.22c-0.12,0.06-0.27,0.09-0.43,0.1l-0.04-0.3
        c0.21-0.07,0.38-0.16,0.49-0.28s0.21-0.25,0.28-0.42l0.13-0.31c0.03-0.07,0.06-0.15,0.09-0.24c0.03-0.09,0.06-0.16,0.08-0.23
        l0.69-1.96H363.21z"
          fill="#FFFFFF"
        />
        <path
          d="M364.5,59.08c0.12,0,0.24,0,0.37,0.01c0.13,0.01,0.26,0.02,0.39,0.03s0.25,0.03,0.35,0.04l-0.04,0.33
        c-0.17-0.01-0.33-0.01-0.5-0.02c-0.17,0-0.33-0.01-0.5-0.01c-0.16,0-0.29,0-0.38,0.01s-0.16,0.04-0.2,0.09
        c-0.04,0.05-0.06,0.13-0.06,0.24c0,0.14,0.03,0.23,0.1,0.27c0.06,0.04,0.16,0.08,0.29,0.11l0.77,0.17
        c0.21,0.05,0.36,0.13,0.46,0.23c0.1,0.1,0.15,0.27,0.15,0.49c0,0.22-0.04,0.38-0.12,0.49c-0.08,0.11-0.2,0.18-0.37,0.22
        c-0.17,0.04-0.38,0.05-0.63,0.05c-0.09,0-0.23,0-0.42-0.01c-0.19-0.01-0.39-0.03-0.61-0.07l0.03-0.33c0.09,0,0.18,0.01,0.28,0.01
        c0.1,0.01,0.2,0.01,0.3,0.01s0.21,0,0.33,0c0.2,0,0.35-0.01,0.46-0.03c0.11-0.02,0.19-0.05,0.24-0.11
        c0.05-0.05,0.07-0.13,0.07-0.23c0-0.13-0.04-0.22-0.11-0.26c-0.08-0.04-0.18-0.08-0.3-0.11l-0.76-0.17
        c-0.14-0.04-0.26-0.08-0.35-0.14c-0.09-0.06-0.15-0.14-0.19-0.23c-0.04-0.1-0.06-0.22-0.06-0.36c0-0.21,0.04-0.36,0.11-0.46
        c0.07-0.1,0.18-0.17,0.33-0.21S364.26,59.08,364.5,59.08z"
          fill="#FFFFFF"
        />
        <path
          d="M366.52,61.14c0.07,0,0.11,0.01,0.13,0.04c0.02,0.03,0.04,0.07,0.04,0.14v0.34c0,0.1-0.01,0.2-0.02,0.3
        c-0.02,0.1-0.04,0.19-0.07,0.28c-0.03,0.09-0.06,0.17-0.1,0.26l-0.22-0.07c0.02-0.11,0.03-0.21,0.04-0.31
        c0.01-0.09,0.01-0.21,0.01-0.34c-0.09,0-0.13-0.05-0.13-0.15v-0.31c0-0.07,0.02-0.11,0.05-0.14c0.03-0.02,0.07-0.04,0.13-0.04
        H366.52z"
          fill="#FFFFFF"
        />
        <path
          d="M368.94,58l0.01,1.04c0,0.12,0,0.23-0.01,0.33s-0.02,0.2-0.04,0.28l0.05,0.08v2.05h-0.46V58H368.94z
         M370.08,59.08c0.47,0,0.71,0.24,0.71,0.72v1.97h-0.46v-1.84c0-0.18-0.03-0.3-0.08-0.37c-0.06-0.07-0.15-0.1-0.29-0.1
        c-0.16,0-0.32,0.04-0.49,0.11c-0.16,0.08-0.35,0.19-0.58,0.33l-0.02-0.33c0.21-0.15,0.41-0.28,0.61-0.37
        C369.68,59.12,369.88,59.08,370.08,59.08z"
          fill="#FFFFFF"
        />
        <path
          d="M371.95,59.14v1.85c0,0.17,0.03,0.28,0.09,0.35c0.06,0.07,0.17,0.1,0.32,0.1c0.15,0,0.29-0.04,0.44-0.11
        s0.32-0.17,0.53-0.3l0.06,0.32c-0.21,0.16-0.4,0.28-0.6,0.37c-0.19,0.08-0.39,0.12-0.59,0.12c-0.48,0-0.72-0.24-0.72-0.72v-1.98
        H371.95z M373.74,59.14v2.63h-0.37l-0.04-0.5l-0.05-0.08v-2.05H373.74z"
          fill="#FFFFFF"
        />
        <path
          d="M374.85,59.14l0.03,0.5l0.05,0.08v2.05h-0.46v-2.63H374.85z M375.96,59.08c0.22,0,0.39,0.06,0.51,0.18
        c0.12,0.12,0.18,0.3,0.18,0.54v1.97h-0.44v-1.84c0-0.17-0.03-0.29-0.1-0.36s-0.17-0.11-0.31-0.11c-0.09,0-0.18,0.01-0.26,0.04
        c-0.08,0.03-0.18,0.08-0.28,0.14c-0.1,0.07-0.23,0.15-0.38,0.26l-0.03-0.32c0.19-0.17,0.38-0.3,0.56-0.38
        S375.77,59.08,375.96,59.08z M377.68,59.08c0.23,0,0.4,0.06,0.52,0.18c0.12,0.12,0.18,0.3,0.18,0.54v1.97h-0.45v-1.84
        c0-0.16-0.04-0.28-0.1-0.35c-0.06-0.07-0.17-0.11-0.31-0.11c-0.09,0-0.18,0.02-0.27,0.05c-0.08,0.03-0.18,0.08-0.28,0.14
        c-0.1,0.06-0.22,0.15-0.37,0.25l-0.03-0.32c0.19-0.17,0.38-0.3,0.56-0.38C377.31,59.12,377.49,59.08,377.68,59.08z"
          fill="#FFFFFF"
        />
        <path
          d="M381.02,60.15l-0.01,0.35l-1.26,0.01c-0.13,0-0.21,0.04-0.26,0.1c-0.05,0.06-0.08,0.15-0.08,0.26v0.17
        c0,0.14,0.03,0.24,0.1,0.3c0.07,0.06,0.17,0.09,0.31,0.09c0.1,0,0.21-0.02,0.34-0.06s0.26-0.1,0.39-0.18
        c0.13-0.08,0.25-0.18,0.36-0.3v0.34c-0.04,0.05-0.1,0.11-0.17,0.18c-0.07,0.07-0.16,0.13-0.26,0.19c-0.1,0.06-0.22,0.12-0.34,0.16
        c-0.12,0.04-0.26,0.06-0.4,0.06c-0.15,0-0.29-0.03-0.41-0.08s-0.21-0.14-0.28-0.25c-0.07-0.11-0.1-0.24-0.1-0.4V60.8
        c0-0.21,0.06-0.36,0.19-0.48c0.13-0.11,0.31-0.17,0.54-0.17H381.02z M380.34,59.08c0.19,0,0.36,0.03,0.49,0.08
        c0.13,0.05,0.24,0.14,0.3,0.26c0.07,0.12,0.1,0.29,0.1,0.5v1.85h-0.36l-0.07-0.57l-0.03-0.06v-1.22c0-0.16-0.04-0.28-0.11-0.35
        c-0.07-0.07-0.21-0.11-0.42-0.11c-0.14,0-0.31,0.01-0.52,0.02c-0.21,0.01-0.41,0.03-0.61,0.05l-0.04-0.34
        c0.12-0.02,0.26-0.04,0.4-0.06c0.15-0.02,0.29-0.03,0.45-0.04C380.07,59.09,380.21,59.08,380.34,59.08z"
          fill="#FFFFFF"
        />
        <path
          d="M382.42,58l-0.01,3.06c0,0.12,0.03,0.2,0.09,0.26c0.06,0.06,0.15,0.09,0.27,0.09h0.25l0.05,0.35
        c-0.03,0.01-0.08,0.03-0.14,0.04s-0.12,0.02-0.18,0.02c-0.06,0.01-0.12,0.01-0.16,0.01c-0.19,0-0.35-0.06-0.47-0.17
        c-0.12-0.12-0.17-0.28-0.17-0.5V58H382.42z"
          fill="#FFFFFF"
        />
        <path
          d="M385.38,60.15l-0.01,0.35l-1.26,0.01c-0.13,0-0.21,0.04-0.26,0.1c-0.05,0.06-0.08,0.15-0.08,0.26v0.17
        c0,0.14,0.03,0.24,0.1,0.3c0.07,0.06,0.17,0.09,0.31,0.09c0.1,0,0.21-0.02,0.34-0.06s0.26-0.1,0.39-0.18
        c0.13-0.08,0.25-0.18,0.36-0.3v0.34c-0.04,0.05-0.1,0.11-0.17,0.18c-0.07,0.07-0.16,0.13-0.26,0.19c-0.1,0.06-0.22,0.12-0.34,0.16
        c-0.12,0.04-0.26,0.06-0.4,0.06c-0.15,0-0.29-0.03-0.41-0.08s-0.21-0.14-0.28-0.25c-0.07-0.11-0.1-0.24-0.1-0.4V60.8
        c0-0.21,0.06-0.36,0.19-0.48c0.13-0.11,0.31-0.17,0.54-0.17H385.38z M384.69,59.08c0.19,0,0.36,0.03,0.49,0.08
        c0.13,0.05,0.24,0.14,0.3,0.26c0.07,0.12,0.1,0.29,0.1,0.5v1.85h-0.36l-0.07-0.57l-0.03-0.06v-1.22c0-0.16-0.04-0.28-0.11-0.35
        c-0.07-0.07-0.21-0.11-0.42-0.11c-0.14,0-0.31,0.01-0.52,0.02c-0.21,0.01-0.41,0.03-0.61,0.05l-0.04-0.34
        c0.12-0.02,0.26-0.04,0.4-0.06c0.15-0.02,0.29-0.03,0.45-0.04C384.42,59.09,384.56,59.08,384.69,59.08z"
          fill="#FFFFFF"
        />
        <path
          d="M386.57,61.14c0.06,0,0.11,0.01,0.13,0.04c0.03,0.03,0.04,0.07,0.04,0.14v0.29c0,0.06-0.01,0.11-0.04,0.13
        c-0.03,0.03-0.07,0.04-0.13,0.04h-0.15c-0.06,0-0.11-0.01-0.13-0.04c-0.03-0.02-0.04-0.07-0.04-0.13v-0.29
        c0-0.07,0.01-0.11,0.04-0.14c0.03-0.02,0.07-0.04,0.13-0.04H386.57z"
          fill="#FFFFFF"
        />
        <path d="M266.95,64.35v3.73h-0.48v-3.73H266.95z" fill="#FFFFFF" />
        <path
          d="M268.12,65.44l0.04,0.5l0.03,0.08v2.05h-0.46v-2.63H268.12z M269.33,65.38c0.47,0,0.71,0.24,0.71,0.72
        v1.97h-0.46v-1.84c0-0.18-0.03-0.3-0.09-0.37c-0.06-0.07-0.15-0.1-0.29-0.1c-0.16,0-0.32,0.04-0.49,0.12s-0.35,0.19-0.58,0.32
        l-0.02-0.33c0.21-0.16,0.41-0.28,0.61-0.37C268.93,65.42,269.13,65.38,269.33,65.38z"
          fill="#FFFFFF"
        />
        <path
          d="M271.05,66.88l0.26,0.04c-0.06,0.05-0.1,0.12-0.12,0.2c-0.02,0.08-0.02,0.15,0.02,0.21
        s0.11,0.11,0.22,0.12l0.98,0.16c0.25,0.04,0.42,0.11,0.52,0.23c0.1,0.12,0.15,0.29,0.15,0.51c0,0.21-0.04,0.37-0.12,0.49
        c-0.08,0.12-0.21,0.2-0.39,0.25c-0.18,0.05-0.43,0.08-0.75,0.08c-0.24,0-0.44-0.01-0.6-0.04c-0.16-0.03-0.29-0.07-0.39-0.13
        c-0.1-0.06-0.17-0.14-0.21-0.25c-0.04-0.1-0.06-0.23-0.06-0.38c0-0.12,0.02-0.21,0.05-0.29s0.09-0.15,0.16-0.21
        s0.17-0.12,0.29-0.18l0.32-0.18l0.21,0.08l-0.26,0.2c-0.07,0.05-0.13,0.11-0.18,0.16c-0.05,0.05-0.08,0.11-0.11,0.17
        c-0.02,0.06-0.04,0.13-0.04,0.21c0,0.12,0.02,0.22,0.07,0.28c0.05,0.07,0.13,0.11,0.25,0.13c0.12,0.02,0.28,0.04,0.5,0.04
        c0.21,0,0.38-0.01,0.49-0.04s0.2-0.07,0.25-0.13c0.05-0.07,0.07-0.16,0.07-0.28c0-0.09-0.01-0.16-0.04-0.21s-0.07-0.08-0.13-0.11
        c-0.06-0.03-0.16-0.05-0.29-0.06l-0.94-0.13c-0.11-0.01-0.2-0.05-0.27-0.11s-0.11-0.13-0.14-0.21c-0.03-0.08-0.03-0.16-0.02-0.24
        c0.01-0.08,0.04-0.16,0.08-0.23S270.99,66.92,271.05,66.88z M271.8,65.38c0.28,0,0.5,0.03,0.67,0.09s0.28,0.15,0.35,0.27
        c0.07,0.12,0.11,0.28,0.11,0.48c0,0.19-0.04,0.35-0.11,0.47c-0.07,0.12-0.19,0.22-0.35,0.28c-0.17,0.06-0.39,0.09-0.67,0.09
        c-0.28,0-0.5-0.03-0.67-0.09s-0.28-0.15-0.35-0.27c-0.07-0.12-0.11-0.28-0.11-0.47c0-0.2,0.04-0.36,0.11-0.48
        c0.07-0.12,0.19-0.21,0.35-0.27C271.3,65.41,271.52,65.38,271.8,65.38z M271.79,65.73c-0.26,0-0.45,0.04-0.55,0.11
        c-0.1,0.07-0.15,0.2-0.15,0.38c0,0.17,0.05,0.3,0.15,0.37c0.1,0.08,0.29,0.11,0.55,0.11c0.27,0,0.45-0.04,0.55-0.11
        c0.1-0.08,0.15-0.2,0.15-0.37c0-0.18-0.05-0.3-0.15-0.38C272.24,65.77,272.06,65.73,271.79,65.73z M273.23,65.44l-0.03,0.25
        l-0.67,0.05l-0.19-0.31H273.23z"
          fill="#FFFFFF"
        />
        <path
          d="M274.02,65.44l0.06,0.5l0.04,0.08v2.05h-0.46v-2.63H274.02z M275.31,65.38l-0.05,0.43h-0.15
        c-0.15,0-0.31,0.03-0.46,0.09c-0.16,0.06-0.34,0.15-0.57,0.27l-0.04-0.29c0.19-0.16,0.39-0.28,0.59-0.37
        c0.2-0.09,0.39-0.13,0.57-0.13H275.31z"
          fill="#FFFFFF"
        />
        <path
          d="M276.73,65.38c0.4,0,0.68,0.07,0.86,0.22c0.18,0.14,0.27,0.36,0.27,0.64c0,0.23-0.05,0.42-0.15,0.55
        c-0.1,0.13-0.26,0.2-0.48,0.2h-1.53v-0.36h1.42c0.12,0,0.2-0.04,0.23-0.12s0.05-0.17,0.05-0.27c0-0.17-0.05-0.29-0.15-0.36
        c-0.1-0.07-0.26-0.11-0.5-0.11c-0.19,0-0.34,0.03-0.45,0.08c-0.11,0.06-0.19,0.15-0.23,0.29c-0.04,0.14-0.07,0.33-0.07,0.58
        c0,0.28,0.03,0.5,0.08,0.64s0.15,0.25,0.27,0.3c0.12,0.05,0.28,0.08,0.49,0.08c0.14,0,0.29-0.01,0.46-0.02
        c0.17-0.01,0.32-0.03,0.45-0.05l0.05,0.31c-0.08,0.03-0.18,0.06-0.31,0.08c-0.12,0.02-0.24,0.04-0.37,0.05s-0.24,0.02-0.34,0.02
        c-0.3,0-0.55-0.05-0.73-0.14s-0.32-0.24-0.4-0.44c-0.08-0.2-0.13-0.47-0.13-0.81c0-0.34,0.04-0.62,0.13-0.82
        c0.08-0.2,0.22-0.34,0.39-0.43C276.22,65.42,276.45,65.38,276.73,65.38z"
          fill="#FFFFFF"
        />
        <path
          d="M279.31,65.38c0.17,0,0.34,0.02,0.51,0.07s0.33,0.11,0.49,0.21l-0.03,0.29c-0.17-0.05-0.32-0.09-0.45-0.12
        c-0.13-0.03-0.27-0.04-0.42-0.04c-0.15,0-0.27,0.03-0.36,0.08c-0.09,0.05-0.16,0.15-0.2,0.29s-0.06,0.34-0.06,0.6
        s0.02,0.46,0.06,0.6c0.04,0.14,0.11,0.25,0.2,0.3s0.21,0.09,0.35,0.09c0.1,0,0.2-0.01,0.28-0.04c0.08-0.03,0.17-0.07,0.27-0.13
        c0.09-0.06,0.21-0.14,0.34-0.23l0.04,0.32c-0.14,0.13-0.31,0.25-0.49,0.34c-0.18,0.09-0.38,0.14-0.59,0.14
        c-0.33,0-0.57-0.12-0.71-0.35s-0.21-0.58-0.21-1.04c0-0.34,0.04-0.61,0.11-0.81c0.07-0.2,0.18-0.34,0.33-0.43
        C278.91,65.42,279.09,65.38,279.31,65.38z M280.69,64.29v3.78h-0.36l-0.05-0.46l-0.05-0.04v-1.8l0.03-0.08
        c-0.01-0.1-0.02-0.2-0.03-0.29c0-0.09-0.01-0.19-0.01-0.3v-0.81H280.69z"
          fill="#FFFFFF"
        />
        <path
          d="M281.78,64.33c0.09,0,0.14,0.05,0.14,0.14v0.31c0,0.09-0.05,0.14-0.14,0.14h-0.22
        c-0.09,0-0.14-0.05-0.14-0.14v-0.31c0-0.09,0.05-0.14,0.14-0.14H281.78z M281.9,65.44v2.63h-0.45v-2.63H281.9z"
          fill="#FFFFFF"
        />
        <path
          d="M283.74,65.38c0.4,0,0.68,0.07,0.86,0.22c0.18,0.14,0.27,0.36,0.27,0.64c0,0.23-0.05,0.42-0.15,0.55
        c-0.1,0.13-0.26,0.2-0.48,0.2h-1.53v-0.36h1.42c0.12,0,0.2-0.04,0.23-0.12s0.05-0.17,0.05-0.27c0-0.17-0.05-0.29-0.15-0.36
        c-0.1-0.07-0.26-0.11-0.5-0.11c-0.19,0-0.34,0.03-0.45,0.08c-0.11,0.06-0.19,0.15-0.23,0.29c-0.04,0.14-0.07,0.33-0.07,0.58
        c0,0.28,0.03,0.5,0.08,0.64s0.15,0.25,0.27,0.3c0.12,0.05,0.28,0.08,0.49,0.08c0.14,0,0.29-0.01,0.46-0.02
        c0.17-0.01,0.32-0.03,0.45-0.05l0.05,0.31c-0.08,0.03-0.18,0.06-0.31,0.08c-0.12,0.02-0.24,0.04-0.37,0.05s-0.24,0.02-0.34,0.02
        c-0.3,0-0.55-0.05-0.73-0.14s-0.32-0.24-0.4-0.44c-0.08-0.2-0.13-0.47-0.13-0.81c0-0.34,0.04-0.62,0.13-0.82
        c0.08-0.2,0.22-0.34,0.39-0.43C283.23,65.42,283.46,65.38,283.74,65.38z"
          fill="#FFFFFF"
        />
        <path
          d="M285.84,65.44l0.04,0.5l0.03,0.08v2.05h-0.46v-2.63H285.84z M287.05,65.38c0.47,0,0.71,0.24,0.71,0.72
        v1.97h-0.46v-1.84c0-0.18-0.03-0.3-0.09-0.37c-0.06-0.07-0.15-0.1-0.29-0.1c-0.16,0-0.32,0.04-0.49,0.12s-0.35,0.19-0.58,0.32
        l-0.02-0.33c0.21-0.16,0.41-0.28,0.61-0.37C286.65,65.42,286.85,65.38,287.05,65.38z"
          fill="#FFFFFF"
        />
        <path
          d="M289.35,65.38c0.12,0,0.24,0,0.37,0.01c0.13,0.01,0.26,0.02,0.39,0.03s0.25,0.03,0.35,0.04l-0.04,0.33
        c-0.17-0.01-0.33-0.01-0.5-0.02c-0.17,0-0.33-0.01-0.5-0.01c-0.16,0-0.29,0-0.38,0.01s-0.16,0.04-0.2,0.09
        c-0.04,0.05-0.06,0.13-0.06,0.24c0,0.14,0.03,0.23,0.1,0.27c0.06,0.04,0.16,0.08,0.29,0.11l0.77,0.17
        c0.21,0.05,0.36,0.13,0.46,0.23c0.1,0.1,0.15,0.27,0.15,0.49c0,0.22-0.04,0.38-0.12,0.49c-0.08,0.11-0.2,0.18-0.37,0.22
        c-0.17,0.04-0.38,0.05-0.63,0.05c-0.09,0-0.23,0-0.42-0.01c-0.19-0.01-0.39-0.03-0.61-0.07l0.03-0.33c0.09,0,0.18,0.01,0.28,0.01
        c0.1,0.01,0.2,0.01,0.3,0.01s0.21,0,0.33,0c0.2,0,0.35-0.01,0.46-0.03c0.11-0.02,0.19-0.05,0.24-0.11
        c0.05-0.05,0.07-0.13,0.07-0.23c0-0.13-0.04-0.22-0.11-0.26c-0.08-0.04-0.18-0.08-0.3-0.11l-0.76-0.17
        c-0.14-0.04-0.26-0.08-0.35-0.14c-0.09-0.06-0.15-0.14-0.19-0.23c-0.04-0.1-0.06-0.22-0.06-0.36c0-0.21,0.04-0.36,0.11-0.46
        c0.07-0.1,0.18-0.17,0.33-0.21S289.11,65.38,289.35,65.38z"
          fill="#FFFFFF"
        />
        <path
          d="M292.19,65.38c0.4,0,0.68,0.07,0.86,0.22c0.18,0.14,0.27,0.36,0.27,0.64c0,0.23-0.05,0.42-0.15,0.55
        c-0.1,0.13-0.26,0.2-0.48,0.2h-1.53v-0.36h1.42c0.12,0,0.2-0.04,0.23-0.12s0.05-0.17,0.05-0.27c0-0.17-0.05-0.29-0.15-0.36
        c-0.1-0.07-0.26-0.11-0.5-0.11c-0.19,0-0.34,0.03-0.45,0.08c-0.11,0.06-0.19,0.15-0.23,0.29c-0.04,0.14-0.07,0.33-0.07,0.58
        c0,0.28,0.03,0.5,0.08,0.64s0.15,0.25,0.27,0.3c0.12,0.05,0.28,0.08,0.49,0.08c0.14,0,0.29-0.01,0.46-0.02
        c0.17-0.01,0.32-0.03,0.45-0.05l0.05,0.31c-0.08,0.03-0.18,0.06-0.31,0.08c-0.12,0.02-0.24,0.04-0.37,0.05s-0.24,0.02-0.34,0.02
        c-0.3,0-0.55-0.05-0.73-0.14s-0.32-0.24-0.4-0.44c-0.08-0.2-0.13-0.47-0.13-0.81c0-0.34,0.04-0.62,0.13-0.82
        c0.08-0.2,0.22-0.34,0.39-0.43C291.68,65.42,291.91,65.38,292.19,65.38z"
          fill="#FFFFFF"
        />
        <path
          d="M294.26,65.44l0.06,0.5l0.04,0.08v2.05h-0.46v-2.63H294.26z M295.55,65.38l-0.05,0.43h-0.15
        c-0.15,0-0.31,0.03-0.46,0.09c-0.16,0.06-0.34,0.15-0.57,0.27l-0.04-0.29c0.19-0.16,0.39-0.28,0.59-0.37
        c0.2-0.09,0.39-0.13,0.57-0.13H295.55z"
          fill="#FFFFFF"
        />
        <path
          d="M296.24,65.7c0.07,0,0.11,0.01,0.13,0.04c0.03,0.03,0.04,0.07,0.04,0.13v0.29c0,0.06-0.01,0.11-0.04,0.13
        c-0.02,0.03-0.07,0.04-0.13,0.04h-0.15c-0.06,0-0.1-0.01-0.13-0.04s-0.04-0.07-0.04-0.13v-0.29c0-0.06,0.01-0.11,0.04-0.13
        s0.07-0.04,0.13-0.04H296.24z M296.24,67.44c0.07,0,0.11,0.01,0.13,0.04c0.03,0.03,0.04,0.07,0.04,0.14v0.29
        c0,0.06-0.01,0.11-0.04,0.13c-0.02,0.03-0.07,0.04-0.13,0.04h-0.15c-0.06,0-0.1-0.01-0.13-0.04c-0.03-0.02-0.04-0.07-0.04-0.13
        v-0.29c0-0.07,0.01-0.11,0.04-0.14c0.03-0.02,0.07-0.04,0.13-0.04H296.24z"
          fill="#FFFFFF"
        />
        <path
          d="M300.44,65.44l-0.96,2.52c-0.01,0.04-0.04,0.06-0.06,0.08c-0.03,0.02-0.06,0.03-0.1,0.03h-0.42
        c-0.04,0-0.07-0.01-0.1-0.03c-0.03-0.02-0.05-0.05-0.06-0.08l-0.96-2.52h0.49l0.65,1.84c0.03,0.08,0.06,0.15,0.08,0.23
        s0.05,0.15,0.08,0.23h0.07c0.03-0.08,0.06-0.15,0.08-0.23s0.05-0.15,0.08-0.23l0.65-1.84H300.44z"
          fill="#FFFFFF"
        />
        <path
          d="M302.76,66.45l-0.01,0.35l-1.26,0.01c-0.13,0-0.21,0.04-0.26,0.1c-0.05,0.06-0.08,0.15-0.08,0.26v0.17
        c0,0.14,0.03,0.24,0.1,0.3c0.07,0.06,0.17,0.09,0.31,0.09c0.1,0,0.21-0.02,0.34-0.06s0.26-0.1,0.39-0.18
        c0.13-0.08,0.25-0.18,0.36-0.3v0.34c-0.04,0.05-0.1,0.11-0.17,0.18c-0.07,0.07-0.16,0.13-0.26,0.19c-0.1,0.06-0.22,0.12-0.34,0.16
        c-0.12,0.04-0.26,0.06-0.4,0.06c-0.15,0-0.29-0.03-0.41-0.08s-0.21-0.14-0.28-0.25c-0.07-0.11-0.1-0.24-0.1-0.4V67.1
        c0-0.21,0.06-0.36,0.19-0.48c0.13-0.11,0.31-0.17,0.54-0.17H302.76z M302.07,65.38c0.19,0,0.36,0.03,0.49,0.08
        c0.13,0.05,0.24,0.14,0.3,0.26c0.07,0.12,0.1,0.29,0.1,0.5v1.85h-0.36l-0.07-0.57l-0.03-0.06v-1.22c0-0.16-0.04-0.28-0.11-0.35
        c-0.07-0.07-0.21-0.11-0.42-0.11c-0.14,0-0.31,0.01-0.52,0.02c-0.21,0.01-0.41,0.03-0.61,0.05l-0.04-0.34
        c0.12-0.02,0.26-0.04,0.4-0.06c0.15-0.02,0.29-0.03,0.45-0.04C301.8,65.38,301.94,65.38,302.07,65.38z"
          fill="#FFFFFF"
        />
        <path
          d="M305.18,65.44v0.36h-1.77v-0.33l0.52-0.03H305.18z M304.35,64.67v2.68c0,0.13,0.03,0.22,0.08,0.27
        c0.05,0.05,0.15,0.08,0.28,0.08h0.4l0.05,0.35c-0.06,0.01-0.12,0.03-0.2,0.04c-0.08,0.01-0.15,0.02-0.22,0.02
        c-0.07,0.01-0.13,0.01-0.17,0.01c-0.22,0-0.39-0.06-0.5-0.18s-0.17-0.3-0.17-0.53v-2.74H304.35z"
          fill="#FFFFFF"
        />
        <path
          d="M307.13,65.44v0.36h-1.77v-0.33l0.52-0.03H307.13z M306.29,64.67v2.68c0,0.13,0.03,0.22,0.08,0.27
        c0.05,0.05,0.15,0.08,0.28,0.08h0.4l0.05,0.35c-0.06,0.01-0.12,0.03-0.2,0.04c-0.08,0.01-0.15,0.02-0.22,0.02
        c-0.07,0.01-0.13,0.01-0.17,0.01c-0.22,0-0.39-0.06-0.5-0.18s-0.17-0.3-0.17-0.53v-2.74H306.29z"
          fill="#FFFFFF"
        />
        <path
          d="M308.66,65.38c0.4,0,0.68,0.07,0.86,0.22c0.18,0.14,0.27,0.36,0.27,0.64c0,0.23-0.05,0.42-0.15,0.55
        c-0.1,0.13-0.26,0.2-0.48,0.2h-1.53v-0.36h1.42c0.12,0,0.2-0.04,0.23-0.12s0.05-0.17,0.05-0.27c0-0.17-0.05-0.29-0.15-0.36
        c-0.1-0.07-0.26-0.11-0.5-0.11c-0.19,0-0.34,0.03-0.45,0.08c-0.11,0.06-0.19,0.15-0.23,0.29c-0.04,0.14-0.07,0.33-0.07,0.58
        c0,0.28,0.03,0.5,0.08,0.64s0.15,0.25,0.27,0.3c0.12,0.05,0.28,0.08,0.49,0.08c0.14,0,0.29-0.01,0.46-0.02
        c0.17-0.01,0.32-0.03,0.45-0.05l0.05,0.31c-0.08,0.03-0.18,0.06-0.31,0.08c-0.12,0.02-0.24,0.04-0.37,0.05s-0.24,0.02-0.34,0.02
        c-0.3,0-0.55-0.05-0.73-0.14s-0.32-0.24-0.4-0.44c-0.08-0.2-0.13-0.47-0.13-0.81c0-0.34,0.04-0.62,0.13-0.82
        c0.08-0.2,0.22-0.34,0.39-0.43C308.15,65.42,308.38,65.38,308.66,65.38z"
          fill="#FFFFFF"
        />
        <path
          d="M310.76,65.44l0.04,0.5l0.03,0.08v2.05h-0.46v-2.63H310.76z M311.97,65.38c0.47,0,0.71,0.24,0.71,0.72
        v1.97h-0.46v-1.84c0-0.18-0.03-0.3-0.09-0.37c-0.06-0.07-0.15-0.1-0.29-0.1c-0.16,0-0.32,0.04-0.49,0.12s-0.35,0.19-0.58,0.32
        l-0.02-0.33c0.21-0.16,0.41-0.28,0.61-0.37C311.58,65.42,311.78,65.38,311.97,65.38z"
          fill="#FFFFFF"
        />
        <path
          d="M313.67,67.44c0.07,0,0.11,0.01,0.13,0.04c0.02,0.03,0.04,0.07,0.04,0.14v0.34c0,0.1-0.01,0.2-0.02,0.3
        c-0.02,0.1-0.04,0.19-0.07,0.28c-0.03,0.09-0.06,0.17-0.1,0.26l-0.22-0.07c0.02-0.11,0.03-0.21,0.04-0.31
        c0.01-0.09,0.01-0.21,0.01-0.34c-0.09,0-0.13-0.05-0.13-0.15v-0.31c0-0.07,0.02-0.11,0.05-0.14c0.03-0.02,0.07-0.04,0.13-0.04
        H313.67z"
          fill="#FFFFFF"
        />
        <path
          d="M316.33,64.29l0.01,1.56c0,0.13-0.01,0.25-0.02,0.37c-0.01,0.12-0.04,0.24-0.06,0.35
        c0.03,0.11,0.04,0.23,0.06,0.34s0.02,0.23,0.02,0.33v0.83h-0.8v-3.78H316.33z M316.7,66.35v0.49h-0.62v-0.49H316.7z M318.11,65.41
        l-0.7,0.93c-0.03,0.05-0.07,0.1-0.12,0.15s-0.09,0.08-0.12,0.1v0.02c0.03,0.02,0.07,0.06,0.12,0.11s0.09,0.12,0.12,0.17l0.79,1.18
        h-0.89l-0.91-1.5l0.82-1.17H318.11z"
          fill="#FFFFFF"
        />
        <path
          d="M319.6,65.34c0.33,0,0.6,0.05,0.8,0.14c0.2,0.09,0.35,0.24,0.43,0.45c0.09,0.21,0.13,0.48,0.13,0.83
        c0,0.34-0.04,0.61-0.13,0.82c-0.09,0.21-0.23,0.35-0.43,0.44c-0.2,0.09-0.47,0.13-0.8,0.13c-0.33,0-0.6-0.04-0.8-0.13
        s-0.35-0.24-0.44-0.44s-0.13-0.48-0.13-0.82c0-0.35,0.04-0.62,0.13-0.83s0.24-0.35,0.44-0.45S319.27,65.34,319.6,65.34z
         M319.6,65.92c-0.14,0-0.25,0.02-0.32,0.07s-0.13,0.13-0.17,0.25c-0.03,0.12-0.05,0.29-0.05,0.51c0,0.22,0.02,0.38,0.05,0.5
        s0.09,0.2,0.17,0.25s0.19,0.07,0.32,0.07s0.25-0.02,0.32-0.07s0.13-0.13,0.17-0.25s0.05-0.29,0.05-0.5c0-0.22-0.02-0.39-0.05-0.51
        c-0.03-0.12-0.09-0.2-0.17-0.25S319.74,65.92,319.6,65.92z"
          fill="#FFFFFF"
        />
        <path
          d="M322.04,65.41l0.1,0.53l0.08,0.07v2.07h-0.8v-2.67H322.04z M323.34,65.34l-0.08,0.74h-0.22
        c-0.13,0-0.26,0.02-0.39,0.05s-0.31,0.09-0.52,0.16l-0.05-0.45c0.19-0.17,0.38-0.29,0.57-0.38c0.19-0.09,0.38-0.13,0.56-0.13
        H323.34z"
          fill="#FFFFFF"
        />
        <path
          d="M324.25,65.41l0.09,0.53l0.06,0.07v2.07h-0.8v-2.67H324.25z M325.47,65.34c0.5,0,0.76,0.25,0.76,0.74v2
        h-0.8v-1.75c0-0.12-0.02-0.2-0.06-0.25s-0.12-0.07-0.22-0.07c-0.12,0-0.23,0.02-0.36,0.07c-0.12,0.04-0.27,0.11-0.45,0.21
        l-0.04-0.45c0.19-0.16,0.39-0.29,0.59-0.37C325.08,65.38,325.27,65.34,325.47,65.34z"
          fill="#FFFFFF"
        />
        <path
          d="M327.22,65.44l0.03,0.5l0.05,0.08v2.05h-0.46v-2.63H327.22z M328.32,65.38c0.22,0,0.39,0.06,0.51,0.18
        c0.12,0.12,0.18,0.3,0.18,0.54v1.97h-0.44v-1.84c0-0.17-0.03-0.29-0.1-0.36s-0.17-0.11-0.31-0.11c-0.09,0-0.18,0.01-0.26,0.04
        c-0.08,0.03-0.18,0.08-0.28,0.14c-0.1,0.07-0.23,0.15-0.38,0.26l-0.03-0.32c0.19-0.17,0.38-0.3,0.56-0.38
        S328.14,65.38,328.32,65.38z M330.05,65.38c0.23,0,0.4,0.06,0.52,0.18c0.12,0.12,0.18,0.3,0.18,0.54v1.97h-0.45v-1.84
        c0-0.16-0.04-0.28-0.1-0.35c-0.06-0.07-0.17-0.11-0.31-0.11c-0.09,0-0.18,0.02-0.27,0.05c-0.08,0.03-0.18,0.08-0.28,0.14
        c-0.1,0.06-0.22,0.15-0.37,0.25l-0.03-0.32c0.19-0.17,0.38-0.3,0.56-0.38C329.68,65.42,329.86,65.38,330.05,65.38z"
          fill="#FFFFFF"
        />
        <path
          d="M333.39,66.45l-0.01,0.35l-1.26,0.01c-0.13,0-0.21,0.04-0.26,0.1c-0.05,0.06-0.08,0.15-0.08,0.26v0.17
        c0,0.14,0.03,0.24,0.1,0.3c0.07,0.06,0.17,0.09,0.31,0.09c0.1,0,0.21-0.02,0.34-0.06s0.26-0.1,0.39-0.18
        c0.13-0.08,0.25-0.18,0.36-0.3v0.34c-0.04,0.05-0.1,0.11-0.17,0.18c-0.07,0.07-0.16,0.13-0.26,0.19c-0.1,0.06-0.22,0.12-0.34,0.16
        c-0.12,0.04-0.26,0.06-0.4,0.06c-0.15,0-0.29-0.03-0.41-0.08s-0.21-0.14-0.28-0.25c-0.07-0.11-0.1-0.24-0.1-0.4V67.1
        c0-0.21,0.06-0.36,0.19-0.48c0.13-0.11,0.31-0.17,0.54-0.17H333.39z M332.71,65.38c0.19,0,0.36,0.03,0.49,0.08
        c0.13,0.05,0.24,0.14,0.3,0.26c0.07,0.12,0.1,0.29,0.1,0.5v1.85h-0.36l-0.07-0.57l-0.03-0.06v-1.22c0-0.16-0.04-0.28-0.11-0.35
        c-0.07-0.07-0.21-0.11-0.42-0.11c-0.14,0-0.31,0.01-0.52,0.02c-0.21,0.01-0.41,0.03-0.61,0.05l-0.04-0.34
        c0.12-0.02,0.26-0.04,0.4-0.06c0.15-0.02,0.29-0.03,0.45-0.04C332.44,65.38,332.58,65.38,332.71,65.38z"
          fill="#FFFFFF"
        />
        <path
          d="M334.79,64.29l-0.01,3.06c0,0.12,0.03,0.2,0.09,0.26c0.06,0.06,0.15,0.09,0.27,0.09h0.25l0.05,0.35
        c-0.03,0.01-0.08,0.03-0.14,0.04s-0.12,0.02-0.18,0.02c-0.06,0.01-0.12,0.01-0.16,0.01c-0.19,0-0.35-0.06-0.47-0.17
        c-0.12-0.12-0.17-0.28-0.17-0.5v-3.16H334.79z"
          fill="#FFFFFF"
        />
        <path
          d="M337.27,65.44v0.36h-1.77v-0.33l0.52-0.03H337.27z M336.44,64.67v2.68c0,0.13,0.03,0.22,0.08,0.27
        c0.05,0.05,0.15,0.08,0.28,0.08h0.4l0.05,0.35c-0.06,0.01-0.12,0.03-0.2,0.04c-0.08,0.01-0.15,0.02-0.22,0.02
        c-0.07,0.01-0.13,0.01-0.17,0.01c-0.22,0-0.39-0.06-0.5-0.18s-0.17-0.3-0.17-0.53v-2.74H336.44z"
          fill="#FFFFFF"
        />
        <path
          d="M337.99,67.44c0.07,0,0.11,0.01,0.13,0.04c0.02,0.03,0.04,0.07,0.04,0.14v0.34c0,0.1-0.01,0.2-0.02,0.3
        c-0.02,0.1-0.04,0.19-0.07,0.28c-0.03,0.09-0.06,0.17-0.1,0.26l-0.22-0.07c0.02-0.11,0.03-0.21,0.04-0.31
        c0.01-0.09,0.01-0.21,0.01-0.34c-0.09,0-0.13-0.05-0.13-0.15v-0.31c0-0.07,0.02-0.11,0.05-0.14c0.03-0.02,0.07-0.04,0.13-0.04
        H337.99z"
          fill="#FFFFFF"
        />
        <path
          d="M340.65,64.29l0.01,1.56c0,0.13-0.01,0.25-0.02,0.37c-0.01,0.12-0.04,0.24-0.06,0.35
        c0.03,0.11,0.04,0.23,0.06,0.34s0.02,0.23,0.02,0.33v0.83h-0.8v-3.78H340.65z M341.02,66.35v0.49h-0.62v-0.49H341.02z
         M342.43,65.41l-0.7,0.93c-0.03,0.05-0.07,0.1-0.12,0.15s-0.09,0.08-0.12,0.1v0.02c0.03,0.02,0.07,0.06,0.12,0.11
        s0.09,0.12,0.12,0.17l0.79,1.18h-0.89l-0.91-1.5l0.82-1.17H342.43z"
          fill="#FFFFFF"
        />
        <path
          d="M343.93,65.34c0.33,0,0.6,0.05,0.8,0.14c0.2,0.09,0.35,0.24,0.43,0.45c0.09,0.21,0.13,0.48,0.13,0.83
        c0,0.34-0.04,0.61-0.13,0.82c-0.09,0.21-0.23,0.35-0.43,0.44c-0.2,0.09-0.47,0.13-0.8,0.13c-0.33,0-0.6-0.04-0.8-0.13
        s-0.35-0.24-0.44-0.44s-0.13-0.48-0.13-0.82c0-0.35,0.04-0.62,0.13-0.83s0.24-0.35,0.44-0.45S343.6,65.34,343.93,65.34z
         M343.93,65.92c-0.14,0-0.25,0.02-0.32,0.07s-0.13,0.13-0.17,0.25c-0.03,0.12-0.05,0.29-0.05,0.51c0,0.22,0.02,0.38,0.05,0.5
        s0.09,0.2,0.17,0.25s0.19,0.07,0.32,0.07s0.25-0.02,0.32-0.07s0.13-0.13,0.17-0.25s0.05-0.29,0.05-0.5c0-0.22-0.02-0.39-0.05-0.51
        c-0.03-0.12-0.09-0.2-0.17-0.25S344.06,65.92,343.93,65.92z"
          fill="#FFFFFF"
        />
        <path
          d="M346.36,65.41l0.1,0.53l0.08,0.07v2.07h-0.8v-2.67H346.36z M347.66,65.34l-0.08,0.74h-0.22
        c-0.13,0-0.26,0.02-0.39,0.05s-0.31,0.09-0.52,0.16l-0.05-0.45c0.19-0.17,0.38-0.29,0.57-0.38c0.19-0.09,0.38-0.13,0.56-0.13
        H347.66z"
          fill="#FFFFFF"
        />
        <path
          d="M348.58,65.41l0.09,0.53l0.06,0.07v2.07h-0.8v-2.67H348.58z M349.79,65.34c0.5,0,0.76,0.25,0.76,0.74v2
        h-0.8v-1.75c0-0.12-0.02-0.2-0.06-0.25s-0.12-0.07-0.22-0.07c-0.12,0-0.23,0.02-0.36,0.07c-0.12,0.04-0.27,0.11-0.45,0.21
        l-0.04-0.45c0.19-0.16,0.39-0.29,0.59-0.37C349.4,65.38,349.6,65.34,349.79,65.34z"
          fill="#FFFFFF"
        />
        <path
          d="M352.04,65.38c0.12,0,0.24,0,0.37,0.01c0.13,0.01,0.26,0.02,0.39,0.03s0.25,0.03,0.35,0.04l-0.04,0.33
        c-0.17-0.01-0.33-0.01-0.5-0.02c-0.17,0-0.33-0.01-0.5-0.01c-0.16,0-0.29,0-0.38,0.01s-0.16,0.04-0.2,0.09
        c-0.04,0.05-0.06,0.13-0.06,0.24c0,0.14,0.03,0.23,0.1,0.27c0.06,0.04,0.16,0.08,0.29,0.11l0.77,0.17
        c0.21,0.05,0.36,0.13,0.46,0.23c0.1,0.1,0.15,0.27,0.15,0.49c0,0.22-0.04,0.38-0.12,0.49c-0.08,0.11-0.2,0.18-0.37,0.22
        c-0.17,0.04-0.38,0.05-0.63,0.05c-0.09,0-0.23,0-0.42-0.01c-0.19-0.01-0.39-0.03-0.61-0.07l0.03-0.33c0.09,0,0.18,0.01,0.28,0.01
        c0.1,0.01,0.2,0.01,0.3,0.01s0.21,0,0.33,0c0.2,0,0.35-0.01,0.46-0.03c0.11-0.02,0.19-0.05,0.24-0.11
        c0.05-0.05,0.07-0.13,0.07-0.23c0-0.13-0.04-0.22-0.11-0.26c-0.08-0.04-0.18-0.08-0.3-0.11l-0.76-0.17
        c-0.14-0.04-0.26-0.08-0.35-0.14c-0.09-0.06-0.15-0.14-0.19-0.23c-0.04-0.1-0.06-0.22-0.06-0.36c0-0.21,0.04-0.36,0.11-0.46
        c0.07-0.1,0.18-0.17,0.33-0.21S351.81,65.38,352.04,65.38z"
          fill="#FFFFFF"
        />
        <path
          d="M355.3,65.44v0.36h-1.77v-0.33l0.52-0.03H355.3z M354.46,64.67v2.68c0,0.13,0.03,0.22,0.08,0.27
        c0.05,0.05,0.15,0.08,0.28,0.08h0.4l0.05,0.35c-0.06,0.01-0.12,0.03-0.2,0.04c-0.08,0.01-0.15,0.02-0.22,0.02
        c-0.07,0.01-0.13,0.01-0.17,0.01c-0.22,0-0.39-0.06-0.5-0.18s-0.17-0.3-0.17-0.53v-2.74H354.46z"
          fill="#FFFFFF"
        />
        <path
          d="M357.68,66.45l-0.01,0.35l-1.26,0.01c-0.13,0-0.21,0.04-0.26,0.1c-0.05,0.06-0.08,0.15-0.08,0.26v0.17
        c0,0.14,0.03,0.24,0.1,0.3c0.07,0.06,0.17,0.09,0.31,0.09c0.1,0,0.21-0.02,0.34-0.06s0.26-0.1,0.39-0.18
        c0.13-0.08,0.25-0.18,0.36-0.3v0.34c-0.04,0.05-0.1,0.11-0.17,0.18c-0.07,0.07-0.16,0.13-0.26,0.19c-0.1,0.06-0.22,0.12-0.34,0.16
        c-0.12,0.04-0.26,0.06-0.4,0.06c-0.15,0-0.29-0.03-0.41-0.08s-0.21-0.14-0.28-0.25c-0.07-0.11-0.1-0.24-0.1-0.4V67.1
        c0-0.21,0.06-0.36,0.19-0.48c0.13-0.11,0.31-0.17,0.54-0.17H357.68z M356.99,65.38c0.19,0,0.36,0.03,0.49,0.08
        c0.13,0.05,0.24,0.14,0.3,0.26c0.07,0.12,0.1,0.29,0.1,0.5v1.85h-0.36l-0.07-0.57l-0.03-0.06v-1.22c0-0.16-0.04-0.28-0.11-0.35
        c-0.07-0.07-0.21-0.11-0.42-0.11c-0.14,0-0.31,0.01-0.52,0.02c-0.21,0.01-0.41,0.03-0.61,0.05l-0.04-0.34
        c0.12-0.02,0.26-0.04,0.4-0.06c0.15-0.02,0.29-0.03,0.45-0.04C356.72,65.38,356.86,65.38,356.99,65.38z M356.41,64.35
        c0.08,0,0.12,0.04,0.12,0.13v0.3c0,0.09-0.04,0.13-0.12,0.13h-0.16c-0.08,0-0.12-0.04-0.12-0.13v-0.3c0-0.09,0.04-0.13,0.12-0.13
        H356.41z M357.36,64.35c0.09,0,0.13,0.04,0.13,0.13v0.3c0,0.09-0.04,0.13-0.13,0.13h-0.15c-0.09,0-0.13-0.04-0.13-0.13v-0.3
        c0-0.09,0.04-0.13,0.13-0.13H357.36z"
          fill="#FFFFFF"
        />
        <path
          d="M358.96,65.44l0.06,0.5l0.04,0.08v2.05h-0.46v-2.63H358.96z M360.26,65.38l-0.05,0.43h-0.15
        c-0.15,0-0.31,0.03-0.46,0.09c-0.16,0.06-0.34,0.15-0.57,0.27l-0.04-0.29c0.19-0.16,0.39-0.28,0.59-0.37
        c0.2-0.09,0.39-0.13,0.57-0.13H360.26z"
          fill="#FFFFFF"
        />
        <path
          d="M361.12,64.29l0.01,1.68c0,0.11,0,0.22-0.01,0.31c-0.01,0.1-0.02,0.2-0.04,0.3c0.02,0.1,0.03,0.2,0.04,0.3
        s0.01,0.2,0.01,0.29v0.9h-0.46v-3.78H361.12z M361.57,66.42v0.33h-0.61v-0.33H361.57z M362.81,65.44l-0.83,0.97
        c-0.03,0.03-0.06,0.07-0.09,0.1s-0.07,0.06-0.09,0.08v0.01c0.03,0.02,0.06,0.05,0.09,0.09s0.07,0.08,0.09,0.12l0.91,1.26h-0.53
        l-1.04-1.51l0.96-1.12H362.81z"
          fill="#FFFFFF"
        />
        <path
          d="M364.3,65.38c0.4,0,0.68,0.07,0.86,0.22c0.18,0.14,0.27,0.36,0.27,0.64c0,0.23-0.05,0.42-0.15,0.55
        c-0.1,0.13-0.26,0.2-0.48,0.2h-1.53v-0.36h1.42c0.12,0,0.2-0.04,0.23-0.12s0.05-0.17,0.05-0.27c0-0.17-0.05-0.29-0.15-0.36
        c-0.1-0.07-0.26-0.11-0.5-0.11c-0.19,0-0.34,0.03-0.45,0.08c-0.11,0.06-0.19,0.15-0.23,0.29c-0.04,0.14-0.07,0.33-0.07,0.58
        c0,0.28,0.03,0.5,0.08,0.64s0.15,0.25,0.27,0.3c0.12,0.05,0.28,0.08,0.49,0.08c0.14,0,0.29-0.01,0.46-0.02
        c0.17-0.01,0.32-0.03,0.45-0.05l0.05,0.31c-0.08,0.03-0.18,0.06-0.31,0.08c-0.12,0.02-0.24,0.04-0.37,0.05s-0.24,0.02-0.34,0.02
        c-0.3,0-0.55-0.05-0.73-0.14s-0.32-0.24-0.4-0.44c-0.08-0.2-0.13-0.47-0.13-0.81c0-0.34,0.04-0.62,0.13-0.82
        c0.08-0.2,0.22-0.34,0.39-0.43C363.79,65.42,364.02,65.38,364.3,65.38z"
          fill="#FFFFFF"
        />
        <path
          d="M366.48,64.29l-0.01,3.06c0,0.12,0.03,0.2,0.09,0.26c0.06,0.06,0.15,0.09,0.27,0.09h0.25l0.05,0.35
        c-0.03,0.01-0.08,0.03-0.14,0.04s-0.12,0.02-0.18,0.02c-0.06,0.01-0.12,0.01-0.16,0.01c-0.19,0-0.35-0.06-0.47-0.17
        c-0.12-0.12-0.17-0.28-0.17-0.5v-3.16H366.48z"
          fill="#FFFFFF"
        />
        <path
          d="M368.39,65.38c0.12,0,0.24,0,0.37,0.01c0.13,0.01,0.26,0.02,0.39,0.03s0.25,0.03,0.35,0.04l-0.04,0.33
        c-0.17-0.01-0.33-0.01-0.5-0.02c-0.17,0-0.33-0.01-0.5-0.01c-0.16,0-0.29,0-0.38,0.01s-0.16,0.04-0.2,0.09
        c-0.04,0.05-0.06,0.13-0.06,0.24c0,0.14,0.03,0.23,0.1,0.27c0.06,0.04,0.16,0.08,0.29,0.11l0.77,0.17
        c0.21,0.05,0.36,0.13,0.46,0.23c0.1,0.1,0.15,0.27,0.15,0.49c0,0.22-0.04,0.38-0.12,0.49c-0.08,0.11-0.2,0.18-0.37,0.22
        c-0.17,0.04-0.38,0.05-0.63,0.05c-0.09,0-0.23,0-0.42-0.01c-0.19-0.01-0.39-0.03-0.61-0.07l0.03-0.33c0.09,0,0.18,0.01,0.28,0.01
        c0.1,0.01,0.2,0.01,0.3,0.01s0.21,0,0.33,0c0.2,0,0.35-0.01,0.46-0.03c0.11-0.02,0.19-0.05,0.24-0.11
        c0.05-0.05,0.07-0.13,0.07-0.23c0-0.13-0.04-0.22-0.11-0.26c-0.08-0.04-0.18-0.08-0.3-0.11l-0.76-0.17
        c-0.14-0.04-0.26-0.08-0.35-0.14c-0.09-0.06-0.15-0.14-0.19-0.23c-0.04-0.1-0.06-0.22-0.06-0.36c0-0.21,0.04-0.36,0.11-0.46
        c0.07-0.1,0.18-0.17,0.33-0.21S368.15,65.38,368.39,65.38z"
          fill="#FFFFFF"
        />
        <path
          d="M371.23,65.38c0.4,0,0.68,0.07,0.86,0.22c0.18,0.14,0.27,0.36,0.27,0.64c0,0.23-0.05,0.42-0.15,0.55
        c-0.1,0.13-0.26,0.2-0.48,0.2h-1.53v-0.36h1.42c0.12,0,0.2-0.04,0.23-0.12s0.05-0.17,0.05-0.27c0-0.17-0.05-0.29-0.15-0.36
        c-0.1-0.07-0.26-0.11-0.5-0.11c-0.19,0-0.34,0.03-0.45,0.08c-0.11,0.06-0.19,0.15-0.23,0.29c-0.04,0.14-0.07,0.33-0.07,0.58
        c0,0.28,0.03,0.5,0.08,0.64s0.15,0.25,0.27,0.3c0.12,0.05,0.28,0.08,0.49,0.08c0.14,0,0.29-0.01,0.46-0.02
        c0.17-0.01,0.32-0.03,0.45-0.05l0.05,0.31c-0.08,0.03-0.18,0.06-0.31,0.08c-0.12,0.02-0.24,0.04-0.37,0.05s-0.24,0.02-0.34,0.02
        c-0.3,0-0.55-0.05-0.73-0.14s-0.32-0.24-0.4-0.44c-0.08-0.2-0.13-0.47-0.13-0.81c0-0.34,0.04-0.62,0.13-0.82
        c0.08-0.2,0.22-0.34,0.39-0.43C370.72,65.42,370.95,65.38,371.23,65.38z"
          fill="#FFFFFF"
        />
        <path
          d="M373.2,67.44c0.07,0,0.11,0.01,0.13,0.04c0.02,0.03,0.04,0.07,0.04,0.14v0.34c0,0.1-0.01,0.2-0.02,0.3
        c-0.02,0.1-0.04,0.19-0.07,0.28c-0.03,0.09-0.06,0.17-0.1,0.26l-0.22-0.07c0.02-0.11,0.03-0.21,0.04-0.31
        c0.01-0.09,0.01-0.21,0.01-0.34c-0.09,0-0.13-0.05-0.13-0.15v-0.31c0-0.07,0.02-0.11,0.05-0.14c0.03-0.02,0.07-0.04,0.13-0.04
        H373.2z"
          fill="#FFFFFF"
        />
        <path
          d="M375.62,64.29l0.01,1.04c0,0.12,0,0.23-0.01,0.33s-0.02,0.2-0.04,0.28l0.05,0.08v2.05h-0.46v-3.78H375.62z
         M376.76,65.38c0.47,0,0.71,0.24,0.71,0.72v1.97H377v-1.84c0-0.18-0.03-0.3-0.08-0.37c-0.06-0.07-0.15-0.1-0.29-0.1
        c-0.16,0-0.32,0.04-0.49,0.11c-0.16,0.08-0.35,0.19-0.58,0.33l-0.02-0.33c0.21-0.15,0.41-0.28,0.61-0.37
        C376.36,65.42,376.56,65.38,376.76,65.38z"
          fill="#FFFFFF"
        />
        <path
          d="M378.63,65.44v1.85c0,0.17,0.03,0.28,0.09,0.35c0.06,0.07,0.17,0.1,0.32,0.1c0.15,0,0.29-0.04,0.44-0.11
        s0.32-0.17,0.53-0.3l0.06,0.32c-0.21,0.16-0.4,0.28-0.6,0.37c-0.19,0.08-0.39,0.12-0.59,0.12c-0.48,0-0.72-0.24-0.72-0.72v-1.98
        H378.63z M380.42,65.44v2.63h-0.37l-0.04-0.5l-0.05-0.08v-2.05H380.42z"
          fill="#FFFFFF"
        />
        <path
          d="M381.53,65.44l0.03,0.5l0.05,0.08v2.05h-0.46v-2.63H381.53z M382.63,65.38c0.22,0,0.39,0.06,0.51,0.18
        c0.12,0.12,0.18,0.3,0.18,0.54v1.97h-0.44v-1.84c0-0.17-0.03-0.29-0.1-0.36s-0.17-0.11-0.31-0.11c-0.09,0-0.18,0.01-0.26,0.04
        c-0.08,0.03-0.18,0.08-0.28,0.14c-0.1,0.07-0.23,0.15-0.38,0.26l-0.03-0.32c0.19-0.17,0.38-0.3,0.56-0.38
        S382.45,65.38,382.63,65.38z M384.36,65.38c0.23,0,0.4,0.06,0.52,0.18c0.12,0.12,0.18,0.3,0.18,0.54v1.97h-0.45v-1.84
        c0-0.16-0.04-0.28-0.1-0.35c-0.06-0.07-0.17-0.11-0.31-0.11c-0.09,0-0.18,0.02-0.27,0.05c-0.08,0.03-0.18,0.08-0.28,0.14
        c-0.1,0.06-0.22,0.15-0.37,0.25l-0.03-0.32c0.19-0.17,0.38-0.3,0.56-0.38C383.99,65.42,384.17,65.38,384.36,65.38z"
          fill="#FFFFFF"
        />
        <path
          d="M386.24,64.29l-0.01,3.06c0,0.12,0.03,0.2,0.09,0.26c0.06,0.06,0.15,0.09,0.27,0.09h0.25l0.05,0.35
        c-0.03,0.01-0.08,0.03-0.14,0.04s-0.12,0.02-0.18,0.02c-0.06,0.01-0.12,0.01-0.16,0.01c-0.19,0-0.35-0.06-0.47-0.17
        c-0.12-0.12-0.17-0.28-0.17-0.5v-3.16H386.24z"
          fill="#FFFFFF"
        />
        <path
          d="M388.35,65.38c0.4,0,0.68,0.07,0.86,0.22c0.18,0.14,0.27,0.36,0.27,0.64c0,0.23-0.05,0.42-0.15,0.55
        c-0.1,0.13-0.26,0.2-0.48,0.2h-1.53v-0.36h1.42c0.12,0,0.2-0.04,0.23-0.12s0.05-0.17,0.05-0.27c0-0.17-0.05-0.29-0.15-0.36
        c-0.1-0.07-0.26-0.11-0.5-0.11c-0.19,0-0.34,0.03-0.45,0.08c-0.11,0.06-0.19,0.15-0.23,0.29c-0.04,0.14-0.07,0.33-0.07,0.58
        c0,0.28,0.03,0.5,0.08,0.64s0.15,0.25,0.27,0.3c0.12,0.05,0.28,0.08,0.49,0.08c0.14,0,0.29-0.01,0.46-0.02
        c0.17-0.01,0.32-0.03,0.45-0.05l0.05,0.31c-0.08,0.03-0.18,0.06-0.31,0.08c-0.12,0.02-0.24,0.04-0.37,0.05s-0.24,0.02-0.34,0.02
        c-0.3,0-0.55-0.05-0.73-0.14s-0.32-0.24-0.4-0.44c-0.08-0.2-0.13-0.47-0.13-0.81c0-0.34,0.04-0.62,0.13-0.82
        c0.08-0.2,0.22-0.34,0.39-0.43C387.84,65.42,388.07,65.38,388.35,65.38z"
          fill="#FFFFFF"
        />
        <path
          d="M390.33,67.44c0.06,0,0.11,0.01,0.13,0.04c0.03,0.03,0.04,0.07,0.04,0.14v0.29c0,0.06-0.01,0.11-0.04,0.13
        c-0.03,0.03-0.07,0.04-0.13,0.04h-0.15c-0.06,0-0.11-0.01-0.13-0.04c-0.03-0.02-0.04-0.07-0.04-0.13v-0.29
        c0-0.07,0.01-0.11,0.04-0.14c0.03-0.02,0.07-0.04,0.13-0.04H390.33z"
          fill="#FFFFFF"
        />
        <path
          d="M267.16,70.64c0.18,0,0.36,0,0.54,0s0.36,0,0.54,0.01c0.18,0,0.35,0.01,0.52,0.02l-0.02,0.37h-1.5
        c-0.11,0-0.19,0.03-0.25,0.08c-0.05,0.06-0.08,0.15-0.08,0.27v2.21c0,0.13,0.03,0.22,0.08,0.28c0.05,0.06,0.13,0.09,0.25,0.09h1.5
        l0.02,0.37c-0.17,0.01-0.34,0.02-0.52,0.02c-0.18,0-0.36,0.01-0.54,0.01s-0.36,0-0.54,0c-0.22,0-0.39-0.06-0.52-0.18
        c-0.13-0.12-0.19-0.27-0.2-0.47v-2.44c0-0.2,0.07-0.36,0.2-0.47C266.76,70.7,266.94,70.64,267.16,70.64z M266.53,72.21h1.99v0.38
        h-1.99V72.21z"
          fill="#FFFFFF"
        />
        <path
          d="M269.72,71.74l0.04,0.5l0.03,0.08v2.05h-0.46v-2.63H269.72z M270.93,71.68c0.47,0,0.71,0.24,0.71,0.72
        v1.97h-0.46v-1.84c0-0.18-0.03-0.3-0.09-0.37c-0.06-0.07-0.15-0.1-0.29-0.1c-0.16,0-0.32,0.04-0.49,0.12s-0.35,0.19-0.58,0.32
        l-0.02-0.33c0.21-0.16,0.41-0.28,0.61-0.37C270.54,71.72,270.74,71.68,270.93,71.68z"
          fill="#FFFFFF"
        />
        <path
          d="M273.45,71.68c0.4,0,0.68,0.07,0.86,0.22c0.18,0.14,0.27,0.36,0.27,0.64c0,0.23-0.05,0.42-0.15,0.55
        c-0.1,0.13-0.26,0.2-0.48,0.2h-1.53v-0.36h1.42c0.12,0,0.2-0.04,0.23-0.12s0.05-0.17,0.05-0.27c0-0.17-0.05-0.29-0.15-0.36
        c-0.1-0.07-0.26-0.11-0.5-0.11c-0.19,0-0.34,0.03-0.45,0.08c-0.11,0.06-0.19,0.15-0.23,0.29c-0.04,0.14-0.07,0.33-0.07,0.58
        c0,0.28,0.03,0.5,0.08,0.64s0.15,0.25,0.27,0.3c0.12,0.05,0.28,0.08,0.49,0.08c0.14,0,0.29-0.01,0.46-0.02
        c0.17-0.01,0.32-0.03,0.45-0.05l0.05,0.31c-0.08,0.03-0.18,0.06-0.31,0.08c-0.12,0.02-0.24,0.04-0.37,0.05s-0.24,0.02-0.34,0.02
        c-0.3,0-0.55-0.05-0.73-0.14s-0.32-0.24-0.4-0.44c-0.08-0.2-0.13-0.47-0.13-0.81c0-0.34,0.04-0.62,0.13-0.82
        c0.08-0.2,0.22-0.34,0.39-0.43C272.94,71.72,273.17,71.68,273.45,71.68z"
          fill="#FFFFFF"
        />
        <path
          d="M275.51,71.74l0.06,0.5l0.04,0.08v2.05h-0.46v-2.63H275.51z M276.8,71.68l-0.05,0.43h-0.15
        c-0.15,0-0.31,0.03-0.46,0.09c-0.16,0.06-0.34,0.15-0.57,0.27l-0.04-0.29c0.19-0.16,0.39-0.28,0.59-0.37
        c0.2-0.09,0.39-0.13,0.57-0.13H276.8z"
          fill="#FFFFFF"
        />
        <path
          d="M277.49,73.18l0.26,0.04c-0.06,0.05-0.1,0.12-0.12,0.2c-0.02,0.08-0.02,0.15,0.02,0.21
        s0.11,0.11,0.22,0.12l0.98,0.16c0.25,0.04,0.42,0.11,0.52,0.23c0.1,0.12,0.15,0.29,0.15,0.51c0,0.21-0.04,0.37-0.12,0.49
        c-0.08,0.12-0.21,0.2-0.39,0.25c-0.18,0.05-0.43,0.08-0.75,0.08c-0.24,0-0.44-0.01-0.6-0.04c-0.16-0.03-0.29-0.07-0.39-0.13
        c-0.1-0.06-0.17-0.14-0.21-0.25c-0.04-0.1-0.06-0.23-0.06-0.38c0-0.12,0.02-0.21,0.05-0.29s0.09-0.15,0.16-0.21
        s0.17-0.12,0.29-0.18l0.32-0.18l0.21,0.08l-0.26,0.2c-0.07,0.05-0.13,0.11-0.18,0.16c-0.05,0.05-0.08,0.11-0.11,0.17
        c-0.02,0.06-0.04,0.13-0.04,0.21c0,0.12,0.02,0.22,0.07,0.28c0.05,0.07,0.13,0.11,0.25,0.13c0.12,0.02,0.28,0.04,0.5,0.04
        c0.21,0,0.38-0.01,0.49-0.04s0.2-0.07,0.25-0.13c0.05-0.07,0.07-0.16,0.07-0.28c0-0.09-0.01-0.16-0.04-0.21s-0.07-0.08-0.13-0.11
        c-0.06-0.03-0.16-0.05-0.29-0.06l-0.94-0.13c-0.11-0.01-0.2-0.05-0.27-0.11s-0.11-0.13-0.14-0.21c-0.03-0.08-0.03-0.16-0.02-0.24
        c0.01-0.08,0.04-0.16,0.08-0.23S277.42,73.21,277.49,73.18z M278.23,71.68c0.28,0,0.5,0.03,0.67,0.09s0.28,0.15,0.35,0.27
        c0.07,0.12,0.11,0.28,0.11,0.48c0,0.19-0.04,0.35-0.11,0.47c-0.07,0.12-0.19,0.22-0.35,0.28c-0.17,0.06-0.39,0.09-0.67,0.09
        c-0.28,0-0.5-0.03-0.67-0.09s-0.28-0.15-0.35-0.27c-0.07-0.12-0.11-0.28-0.11-0.47c0-0.2,0.04-0.36,0.11-0.48
        c0.07-0.12,0.19-0.21,0.35-0.27C277.73,71.71,277.95,71.68,278.23,71.68z M278.23,72.03c-0.26,0-0.45,0.04-0.55,0.11
        c-0.1,0.07-0.15,0.2-0.15,0.38c0,0.17,0.05,0.3,0.15,0.37c0.1,0.08,0.29,0.11,0.55,0.11c0.27,0,0.45-0.04,0.55-0.11
        c0.1-0.08,0.15-0.2,0.15-0.37c0-0.18-0.05-0.3-0.15-0.38C278.68,72.07,278.5,72.03,278.23,72.03z M279.67,71.74L279.64,72
        l-0.67,0.05l-0.19-0.31H279.67z"
          fill="#FFFFFF"
        />
        <path
          d="M280.45,70.62c0.09,0,0.14,0.05,0.14,0.14v0.31c0,0.09-0.05,0.14-0.14,0.14h-0.22
        c-0.09,0-0.14-0.05-0.14-0.14v-0.31c0-0.09,0.05-0.14,0.14-0.14H280.45z M280.57,71.74v2.63h-0.45v-2.63H280.57z"
          fill="#FFFFFF"
        />
        <path
          d="M283.26,72.75l-0.01,0.35l-1.26,0.01c-0.13,0-0.21,0.04-0.26,0.1c-0.05,0.06-0.08,0.15-0.08,0.26v0.17
        c0,0.14,0.03,0.24,0.1,0.3c0.07,0.06,0.17,0.09,0.31,0.09c0.1,0,0.21-0.02,0.34-0.06s0.26-0.1,0.39-0.18
        c0.13-0.08,0.25-0.18,0.36-0.3v0.34c-0.04,0.05-0.1,0.11-0.17,0.18c-0.07,0.07-0.16,0.13-0.26,0.19c-0.1,0.06-0.22,0.12-0.34,0.16
        c-0.12,0.04-0.26,0.06-0.4,0.06c-0.15,0-0.29-0.03-0.41-0.08s-0.21-0.14-0.28-0.25c-0.07-0.11-0.1-0.24-0.1-0.4V73.4
        c0-0.21,0.06-0.36,0.19-0.48c0.13-0.11,0.31-0.17,0.54-0.17H283.26z M282.57,71.68c0.19,0,0.36,0.03,0.49,0.08
        c0.13,0.05,0.24,0.14,0.3,0.26c0.07,0.12,0.1,0.29,0.1,0.5v1.85h-0.36l-0.07-0.57l-0.03-0.06v-1.22c0-0.16-0.04-0.28-0.11-0.35
        c-0.07-0.07-0.21-0.11-0.42-0.11c-0.14,0-0.31,0.01-0.52,0.02c-0.21,0.01-0.41,0.03-0.61,0.05l-0.04-0.34
        c0.12-0.02,0.26-0.04,0.4-0.06c0.15-0.02,0.29-0.03,0.45-0.04C282.3,71.68,282.44,71.68,282.57,71.68z"
          fill="#FFFFFF"
        />
        <path
          d="M285.06,71.68c0.12,0,0.24,0,0.37,0.01c0.13,0.01,0.26,0.02,0.39,0.03s0.25,0.03,0.35,0.04l-0.04,0.33
        c-0.17-0.01-0.33-0.01-0.5-0.02c-0.17,0-0.33-0.01-0.5-0.01c-0.16,0-0.29,0-0.38,0.01s-0.16,0.04-0.2,0.09
        c-0.04,0.05-0.06,0.13-0.06,0.24c0,0.14,0.03,0.23,0.1,0.27c0.06,0.04,0.16,0.08,0.29,0.11l0.77,0.17
        c0.21,0.05,0.36,0.13,0.46,0.23c0.1,0.1,0.15,0.27,0.15,0.49c0,0.22-0.04,0.38-0.12,0.49c-0.08,0.11-0.2,0.18-0.37,0.22
        c-0.17,0.04-0.38,0.05-0.63,0.05c-0.09,0-0.23,0-0.42-0.01c-0.19-0.01-0.39-0.03-0.61-0.07l0.03-0.33c0.09,0,0.18,0.01,0.28,0.01
        c0.1,0.01,0.2,0.01,0.3,0.01s0.21,0,0.33,0c0.2,0,0.35-0.01,0.46-0.03c0.11-0.02,0.19-0.05,0.24-0.11
        c0.05-0.05,0.07-0.13,0.07-0.23c0-0.13-0.04-0.22-0.11-0.26c-0.08-0.04-0.18-0.08-0.3-0.11l-0.76-0.17
        c-0.14-0.04-0.26-0.08-0.35-0.14c-0.09-0.06-0.15-0.14-0.19-0.23c-0.04-0.1-0.06-0.22-0.06-0.36c0-0.21,0.04-0.36,0.11-0.46
        c0.07-0.1,0.18-0.17,0.33-0.21S284.82,71.67,285.06,71.68z"
          fill="#FFFFFF"
        />
        <path
          d="M287.17,70.62c0.09,0,0.14,0.05,0.14,0.14v0.31c0,0.09-0.05,0.14-0.14,0.14h-0.22
        c-0.09,0-0.14-0.05-0.14-0.14v-0.31c0-0.09,0.05-0.14,0.14-0.14H287.17z M287.29,71.74v2.63h-0.45v-2.63H287.29z"
          fill="#FFFFFF"
        />
        <path
          d="M288.92,71.68c0.12,0,0.24,0,0.37,0.01c0.13,0.01,0.26,0.02,0.39,0.03s0.25,0.03,0.35,0.04l-0.04,0.33
        c-0.17-0.01-0.33-0.01-0.5-0.02c-0.17,0-0.33-0.01-0.5-0.01c-0.16,0-0.29,0-0.38,0.01s-0.16,0.04-0.2,0.09
        c-0.04,0.05-0.06,0.13-0.06,0.24c0,0.14,0.03,0.23,0.1,0.27c0.06,0.04,0.16,0.08,0.29,0.11l0.77,0.17
        c0.21,0.05,0.36,0.13,0.46,0.23c0.1,0.1,0.15,0.27,0.15,0.49c0,0.22-0.04,0.38-0.12,0.49c-0.08,0.11-0.2,0.18-0.37,0.22
        c-0.17,0.04-0.38,0.05-0.63,0.05c-0.09,0-0.23,0-0.42-0.01c-0.19-0.01-0.39-0.03-0.61-0.07l0.03-0.33c0.09,0,0.18,0.01,0.28,0.01
        c0.1,0.01,0.2,0.01,0.3,0.01s0.21,0,0.33,0c0.2,0,0.35-0.01,0.46-0.03c0.11-0.02,0.19-0.05,0.24-0.11
        c0.05-0.05,0.07-0.13,0.07-0.23c0-0.13-0.04-0.22-0.11-0.26c-0.08-0.04-0.18-0.08-0.3-0.11l-0.76-0.17
        c-0.14-0.04-0.26-0.08-0.35-0.14c-0.09-0.06-0.15-0.14-0.19-0.23c-0.04-0.1-0.06-0.22-0.06-0.36c0-0.21,0.04-0.36,0.11-0.46
        c0.07-0.1,0.18-0.17,0.33-0.21S288.69,71.67,288.92,71.68z"
          fill="#FFFFFF"
        />
        <path
          d="M292.61,72.75l-0.01,0.35l-1.26,0.01c-0.13,0-0.21,0.04-0.26,0.1c-0.05,0.06-0.08,0.15-0.08,0.26v0.17
        c0,0.14,0.03,0.24,0.1,0.3c0.07,0.06,0.17,0.09,0.31,0.09c0.1,0,0.21-0.02,0.34-0.06s0.26-0.1,0.39-0.18
        c0.13-0.08,0.25-0.18,0.36-0.3v0.34c-0.04,0.05-0.1,0.11-0.17,0.18c-0.07,0.07-0.16,0.13-0.26,0.19c-0.1,0.06-0.22,0.12-0.34,0.16
        c-0.12,0.04-0.26,0.06-0.4,0.06c-0.15,0-0.29-0.03-0.41-0.08s-0.21-0.14-0.28-0.25c-0.07-0.11-0.1-0.24-0.1-0.4V73.4
        c0-0.21,0.06-0.36,0.19-0.48c0.13-0.11,0.31-0.17,0.54-0.17H292.61z M291.93,71.68c0.19,0,0.36,0.03,0.49,0.08
        c0.13,0.05,0.24,0.14,0.3,0.26c0.07,0.12,0.1,0.29,0.1,0.5v1.85h-0.36l-0.07-0.57l-0.03-0.06v-1.22c0-0.16-0.04-0.28-0.11-0.35
        c-0.07-0.07-0.21-0.11-0.42-0.11c-0.14,0-0.31,0.01-0.52,0.02c-0.21,0.01-0.41,0.03-0.61,0.05l-0.04-0.34
        c0.12-0.02,0.26-0.04,0.4-0.06c0.15-0.02,0.29-0.03,0.45-0.04C291.66,71.68,291.8,71.68,291.93,71.68z M291.34,70.65
        c0.08,0,0.12,0.04,0.12,0.13v0.3c0,0.09-0.04,0.13-0.12,0.13h-0.16c-0.08,0-0.12-0.04-0.12-0.13v-0.3c0-0.09,0.04-0.13,0.12-0.13
        H291.34z M292.29,70.65c0.09,0,0.13,0.04,0.13,0.13v0.3c0,0.09-0.04,0.13-0.13,0.13h-0.15c-0.09,0-0.13-0.04-0.13-0.13v-0.3
        c0-0.09,0.04-0.13,0.13-0.13H292.29z"
          fill="#FFFFFF"
        />
        <path
          d="M294.01,70.59L294,73.65c0,0.12,0.03,0.2,0.09,0.26c0.06,0.06,0.15,0.09,0.27,0.09h0.25l0.05,0.35
        c-0.03,0.01-0.08,0.03-0.14,0.04s-0.12,0.02-0.18,0.02c-0.06,0.01-0.12,0.01-0.16,0.01c-0.19,0-0.35-0.06-0.47-0.17
        c-0.12-0.12-0.17-0.28-0.17-0.5v-3.16H294.01z"
          fill="#FFFFFF"
        />
        <path
          d="M296.49,71.74v0.36h-1.77v-0.33l0.52-0.03H296.49z M295.66,70.96v2.68c0,0.13,0.03,0.22,0.08,0.27
        c0.05,0.05,0.15,0.08,0.28,0.08h0.4l0.05,0.35c-0.06,0.01-0.12,0.03-0.2,0.04c-0.08,0.01-0.15,0.02-0.22,0.02
        c-0.07,0.01-0.13,0.01-0.17,0.01c-0.22,0-0.39-0.06-0.5-0.18s-0.17-0.3-0.17-0.53v-2.74H295.66z"
          fill="#FFFFFF"
        />
        <path
          d="M298.06,71.68c0.3,0,0.54,0.04,0.73,0.13c0.18,0.09,0.31,0.24,0.39,0.44s0.12,0.47,0.12,0.81
        c0,0.33-0.04,0.6-0.12,0.81s-0.21,0.35-0.39,0.44s-0.42,0.13-0.73,0.13c-0.3,0-0.54-0.04-0.72-0.13s-0.31-0.24-0.39-0.44
        c-0.08-0.2-0.12-0.47-0.12-0.81c0-0.33,0.04-0.6,0.12-0.81c0.08-0.2,0.21-0.35,0.39-0.44C297.52,71.72,297.76,71.68,298.06,71.68z
         M298.06,72.07c-0.19,0-0.34,0.03-0.45,0.09c-0.11,0.06-0.19,0.16-0.24,0.31c-0.05,0.14-0.07,0.34-0.07,0.6
        c0,0.25,0.02,0.46,0.07,0.6c0.05,0.14,0.12,0.25,0.24,0.31c0.11,0.06,0.26,0.09,0.45,0.09c0.19,0,0.34-0.03,0.45-0.09
        c0.11-0.06,0.19-0.16,0.24-0.31c0.05-0.14,0.07-0.34,0.07-0.6c0-0.26-0.02-0.46-0.07-0.6c-0.05-0.14-0.13-0.25-0.24-0.31
        C298.4,72.1,298.25,72.07,298.06,72.07z M297.67,70.67c0.08,0,0.12,0.04,0.12,0.13v0.3c0,0.09-0.04,0.13-0.12,0.13h-0.16
        c-0.08,0-0.12-0.04-0.12-0.13v-0.3c0-0.09,0.04-0.13,0.12-0.13H297.67z M298.62,70.67c0.09,0,0.13,0.04,0.13,0.13v0.3
        c0,0.09-0.04,0.13-0.13,0.13h-0.15c-0.09,0-0.13-0.04-0.13-0.13v-0.3c0-0.09,0.04-0.13,0.13-0.13H298.62z"
          fill="#FFFFFF"
        />
        <path d="M302.03,70.65l-2.12,4.08h-0.38l2.12-4.08H302.03z" fill="#FFFFFF" />
        <path
          d="M303.23,70.64c0.18,0,0.36,0,0.54,0s0.36,0,0.54,0.01c0.18,0,0.35,0.01,0.52,0.02l-0.02,0.37h-1.5
        c-0.11,0-0.19,0.03-0.25,0.08c-0.05,0.06-0.08,0.15-0.08,0.27v2.21c0,0.13,0.03,0.22,0.08,0.28c0.05,0.06,0.13,0.09,0.25,0.09h1.5
        l0.02,0.37c-0.17,0.01-0.34,0.02-0.52,0.02c-0.18,0-0.36,0.01-0.54,0.01s-0.36,0-0.54,0c-0.22,0-0.39-0.06-0.52-0.18
        c-0.13-0.12-0.19-0.27-0.2-0.47v-2.44c0-0.2,0.07-0.36,0.2-0.47C302.83,70.7,303.01,70.64,303.23,70.64z M302.6,72.21h1.99v0.38
        h-1.99V72.21z"
          fill="#FFFFFF"
        />
        <path
          d="M305.8,71.74l0.04,0.5l0.03,0.08v2.05h-0.46v-2.63H305.8z M307.01,71.68c0.47,0,0.71,0.24,0.71,0.72v1.97
        h-0.46v-1.84c0-0.18-0.03-0.3-0.09-0.37c-0.06-0.07-0.15-0.1-0.29-0.1c-0.16,0-0.32,0.04-0.49,0.12s-0.35,0.19-0.58,0.32
        l-0.02-0.33c0.21-0.16,0.41-0.28,0.61-0.37C306.61,71.72,306.81,71.68,307.01,71.68z"
          fill="#FFFFFF"
        />
        <path
          d="M309.52,71.68c0.4,0,0.68,0.07,0.86,0.22c0.18,0.14,0.27,0.36,0.27,0.64c0,0.23-0.05,0.42-0.15,0.55
        c-0.1,0.13-0.26,0.2-0.48,0.2h-1.53v-0.36h1.42c0.12,0,0.2-0.04,0.23-0.12s0.05-0.17,0.05-0.27c0-0.17-0.05-0.29-0.15-0.36
        c-0.1-0.07-0.26-0.11-0.5-0.11c-0.19,0-0.34,0.03-0.45,0.08c-0.11,0.06-0.19,0.15-0.23,0.29c-0.04,0.14-0.07,0.33-0.07,0.58
        c0,0.28,0.03,0.5,0.08,0.64s0.15,0.25,0.27,0.3c0.12,0.05,0.28,0.08,0.49,0.08c0.14,0,0.29-0.01,0.46-0.02
        c0.17-0.01,0.32-0.03,0.45-0.05l0.05,0.31c-0.08,0.03-0.18,0.06-0.31,0.08c-0.12,0.02-0.24,0.04-0.37,0.05s-0.24,0.02-0.34,0.02
        c-0.3,0-0.55-0.05-0.73-0.14s-0.32-0.24-0.4-0.44c-0.08-0.2-0.13-0.47-0.13-0.81c0-0.34,0.04-0.62,0.13-0.82
        c0.08-0.2,0.22-0.34,0.39-0.43C309.01,71.72,309.24,71.68,309.52,71.68z"
          fill="#FFFFFF"
        />
        <path
          d="M311.58,71.74l0.06,0.5l0.04,0.08v2.05h-0.46v-2.63H311.58z M312.88,71.68l-0.05,0.43h-0.15
        c-0.15,0-0.31,0.03-0.46,0.09c-0.16,0.06-0.34,0.15-0.57,0.27l-0.04-0.29c0.19-0.16,0.39-0.28,0.59-0.37
        c0.2-0.09,0.39-0.13,0.57-0.13H312.88z"
          fill="#FFFFFF"
        />
        <path
          d="M313.56,73.18l0.26,0.04c-0.06,0.05-0.1,0.12-0.12,0.2c-0.02,0.08-0.02,0.15,0.02,0.21
        s0.11,0.11,0.22,0.12l0.98,0.16c0.25,0.04,0.42,0.11,0.52,0.23c0.1,0.12,0.15,0.29,0.15,0.51c0,0.21-0.04,0.37-0.12,0.49
        c-0.08,0.12-0.21,0.2-0.39,0.25c-0.18,0.05-0.43,0.08-0.75,0.08c-0.24,0-0.44-0.01-0.6-0.04c-0.16-0.03-0.29-0.07-0.39-0.13
        c-0.1-0.06-0.17-0.14-0.21-0.25c-0.04-0.1-0.06-0.23-0.06-0.38c0-0.12,0.02-0.21,0.05-0.29s0.09-0.15,0.16-0.21
        s0.17-0.12,0.29-0.18l0.32-0.18l0.21,0.08l-0.26,0.2c-0.07,0.05-0.13,0.11-0.18,0.16c-0.05,0.05-0.08,0.11-0.11,0.17
        c-0.02,0.06-0.04,0.13-0.04,0.21c0,0.12,0.02,0.22,0.07,0.28c0.05,0.07,0.13,0.11,0.25,0.13c0.12,0.02,0.28,0.04,0.5,0.04
        c0.21,0,0.38-0.01,0.49-0.04s0.2-0.07,0.25-0.13c0.05-0.07,0.07-0.16,0.07-0.28c0-0.09-0.01-0.16-0.04-0.21s-0.07-0.08-0.13-0.11
        c-0.06-0.03-0.16-0.05-0.29-0.06l-0.94-0.13c-0.11-0.01-0.2-0.05-0.27-0.11s-0.11-0.13-0.14-0.21c-0.03-0.08-0.03-0.16-0.02-0.24
        c0.01-0.08,0.04-0.16,0.08-0.23S313.49,73.21,313.56,73.18z M314.3,71.68c0.28,0,0.5,0.03,0.67,0.09s0.28,0.15,0.35,0.27
        c0.07,0.12,0.11,0.28,0.11,0.48c0,0.19-0.04,0.35-0.11,0.47c-0.07,0.12-0.19,0.22-0.35,0.28c-0.17,0.06-0.39,0.09-0.67,0.09
        c-0.28,0-0.5-0.03-0.67-0.09s-0.28-0.15-0.35-0.27c-0.07-0.12-0.11-0.28-0.11-0.47c0-0.2,0.04-0.36,0.11-0.48
        c0.07-0.12,0.19-0.21,0.35-0.27C313.8,71.71,314.02,71.68,314.3,71.68z M314.3,72.03c-0.26,0-0.45,0.04-0.55,0.11
        c-0.1,0.07-0.15,0.2-0.15,0.38c0,0.17,0.05,0.3,0.15,0.37c0.1,0.08,0.29,0.11,0.55,0.11c0.27,0,0.45-0.04,0.55-0.11
        c0.1-0.08,0.15-0.2,0.15-0.37c0-0.18-0.05-0.3-0.15-0.38C314.75,72.07,314.57,72.03,314.3,72.03z M315.74,71.74L315.71,72
        l-0.67,0.05l-0.19-0.31H315.74z"
          fill="#FFFFFF"
        />
        <path
          d="M316.52,70.62c0.09,0,0.14,0.05,0.14,0.14v0.31c0,0.09-0.05,0.14-0.14,0.14h-0.22
        c-0.09,0-0.14-0.05-0.14-0.14v-0.31c0-0.09,0.05-0.14,0.14-0.14H316.52z M316.64,71.74v2.63h-0.45v-2.63H316.64z"
          fill="#FFFFFF"
        />
        <path
          d="M317.76,70.62c0.09,0,0.14,0.05,0.14,0.14v0.31c0,0.09-0.05,0.14-0.14,0.14h-0.22
        c-0.09,0-0.14-0.05-0.14-0.14v-0.31c0-0.09,0.05-0.14,0.14-0.14H317.76z M317.87,71.74v2.63h-0.45v-2.63H317.87z"
          fill="#FFFFFF"
        />
        <path
          d="M319.02,71.74l0.04,0.5l0.03,0.08v2.05h-0.46v-2.63H319.02z M320.23,71.68c0.47,0,0.71,0.24,0.71,0.72
        v1.97h-0.46v-1.84c0-0.18-0.03-0.3-0.09-0.37c-0.06-0.07-0.15-0.1-0.29-0.1c-0.16,0-0.32,0.04-0.49,0.12s-0.35,0.19-0.58,0.32
        l-0.02-0.33c0.21-0.16,0.41-0.28,0.61-0.37C319.83,71.72,320.03,71.68,320.23,71.68z"
          fill="#FFFFFF"
        />
        <path
          d="M322.05,71.74l0.04,0.5l0.03,0.08v2.05h-0.46v-2.63H322.05z M323.26,71.68c0.47,0,0.71,0.24,0.71,0.72
        v1.97h-0.46v-1.84c0-0.18-0.03-0.3-0.09-0.37c-0.06-0.07-0.15-0.1-0.29-0.1c-0.16,0-0.32,0.04-0.49,0.12s-0.35,0.19-0.58,0.32
        l-0.02-0.33c0.21-0.16,0.41-0.28,0.61-0.37C322.86,71.72,323.06,71.68,323.26,71.68z"
          fill="#FFFFFF"
        />
        <path
          d="M325.77,71.68c0.4,0,0.68,0.07,0.86,0.22c0.18,0.14,0.27,0.36,0.27,0.64c0,0.23-0.05,0.42-0.15,0.55
        c-0.1,0.13-0.26,0.2-0.48,0.2h-1.53v-0.36h1.42c0.12,0,0.2-0.04,0.23-0.12s0.05-0.17,0.05-0.27c0-0.17-0.05-0.29-0.15-0.36
        c-0.1-0.07-0.26-0.11-0.5-0.11c-0.19,0-0.34,0.03-0.45,0.08c-0.11,0.06-0.19,0.15-0.23,0.29c-0.04,0.14-0.07,0.33-0.07,0.58
        c0,0.28,0.03,0.5,0.08,0.64s0.15,0.25,0.27,0.3c0.12,0.05,0.28,0.08,0.49,0.08c0.14,0,0.29-0.01,0.46-0.02
        c0.17-0.01,0.32-0.03,0.45-0.05l0.05,0.31c-0.08,0.03-0.18,0.06-0.31,0.08c-0.12,0.02-0.24,0.04-0.37,0.05s-0.24,0.02-0.34,0.02
        c-0.3,0-0.55-0.05-0.73-0.14s-0.32-0.24-0.4-0.44c-0.08-0.2-0.13-0.47-0.13-0.81c0-0.34,0.04-0.62,0.13-0.82
        c0.08-0.2,0.22-0.34,0.39-0.43C325.26,71.72,325.49,71.68,325.77,71.68z"
          fill="#FFFFFF"
        />
        <path
          d="M327.94,70.59l0.01,1.04c0,0.12,0,0.23-0.01,0.33s-0.02,0.2-0.04,0.28l0.05,0.08v2.05h-0.46v-3.78H327.94z
         M329.08,71.68c0.47,0,0.71,0.24,0.71,0.72v1.97h-0.46v-1.84c0-0.18-0.03-0.3-0.08-0.37c-0.06-0.07-0.15-0.1-0.29-0.1
        c-0.16,0-0.32,0.04-0.49,0.11c-0.16,0.08-0.35,0.19-0.58,0.33l-0.02-0.33c0.21-0.15,0.41-0.28,0.61-0.37
        C328.68,71.72,328.88,71.68,329.08,71.68z"
          fill="#FFFFFF"
        />
        <path
          d="M332.43,72.75l-0.01,0.35l-1.26,0.01c-0.13,0-0.21,0.04-0.26,0.1c-0.05,0.06-0.08,0.15-0.08,0.26v0.17
        c0,0.14,0.03,0.24,0.1,0.3c0.07,0.06,0.17,0.09,0.31,0.09c0.1,0,0.21-0.02,0.34-0.06s0.26-0.1,0.39-0.18
        c0.13-0.08,0.25-0.18,0.36-0.3v0.34c-0.04,0.05-0.1,0.11-0.17,0.18c-0.07,0.07-0.16,0.13-0.26,0.19c-0.1,0.06-0.22,0.12-0.34,0.16
        c-0.12,0.04-0.26,0.06-0.4,0.06c-0.15,0-0.29-0.03-0.41-0.08s-0.21-0.14-0.28-0.25c-0.07-0.11-0.1-0.24-0.1-0.4V73.4
        c0-0.21,0.06-0.36,0.19-0.48c0.13-0.11,0.31-0.17,0.54-0.17H332.43z M331.75,71.68c0.19,0,0.36,0.03,0.49,0.08
        c0.13,0.05,0.24,0.14,0.3,0.26c0.07,0.12,0.1,0.29,0.1,0.5v1.85h-0.36l-0.07-0.57l-0.03-0.06v-1.22c0-0.16-0.04-0.28-0.11-0.35
        c-0.07-0.07-0.21-0.11-0.42-0.11c-0.14,0-0.31,0.01-0.52,0.02c-0.21,0.01-0.41,0.03-0.61,0.05l-0.04-0.34
        c0.12-0.02,0.26-0.04,0.4-0.06c0.15-0.02,0.29-0.03,0.45-0.04C331.48,71.68,331.62,71.68,331.75,71.68z M331.56,70.32
        c0.1,0,0.2,0.03,0.29,0.08c0.09,0.05,0.16,0.12,0.21,0.2c0.05,0.08,0.08,0.18,0.08,0.29c0,0.1-0.03,0.2-0.08,0.29
        c-0.05,0.09-0.12,0.16-0.21,0.21c-0.09,0.05-0.18,0.08-0.29,0.08c-0.1,0-0.2-0.03-0.29-0.08c-0.09-0.05-0.16-0.12-0.21-0.2
        c-0.05-0.08-0.08-0.18-0.08-0.29c0-0.11,0.03-0.2,0.08-0.29c0.05-0.08,0.12-0.15,0.21-0.2C331.36,70.34,331.46,70.32,331.56,70.32
        z M331.56,70.55c-0.09,0-0.17,0.03-0.23,0.09c-0.06,0.06-0.09,0.14-0.09,0.24c0,0.1,0.03,0.18,0.09,0.24
        c0.06,0.06,0.14,0.09,0.23,0.09c0.09,0,0.17-0.03,0.23-0.09c0.06-0.06,0.09-0.14,0.09-0.24c0-0.1-0.03-0.18-0.09-0.24
        C331.73,70.58,331.65,70.55,331.56,70.55z"
          fill="#FFFFFF"
        />
        <path
          d="M333.83,70.59l-0.01,3.06c0,0.12,0.03,0.2,0.09,0.26c0.06,0.06,0.15,0.09,0.27,0.09h0.25l0.05,0.35
        c-0.03,0.01-0.08,0.03-0.14,0.04s-0.12,0.02-0.18,0.02c-0.06,0.01-0.12,0.01-0.16,0.01c-0.19,0-0.35-0.06-0.47-0.17
        c-0.12-0.12-0.17-0.28-0.17-0.5v-3.16H333.83z"
          fill="#FFFFFF"
        />
        <path
          d="M335.32,70.59l-0.01,3.06c0,0.12,0.03,0.2,0.09,0.26c0.06,0.06,0.15,0.09,0.27,0.09h0.25l0.05,0.35
        c-0.03,0.01-0.08,0.03-0.14,0.04s-0.12,0.02-0.18,0.02c-0.06,0.01-0.12,0.01-0.16,0.01c-0.19,0-0.35-0.06-0.47-0.17
        c-0.12-0.12-0.17-0.28-0.17-0.5v-3.16H335.32z"
          fill="#FFFFFF"
        />
        <path
          d="M338.61,70.11l0.06,0.22c-0.36,0.18-0.62,0.46-0.79,0.85s-0.25,0.89-0.25,1.5c0,0.4,0.04,0.75,0.11,1.06
        s0.18,0.57,0.33,0.79s0.35,0.38,0.59,0.51l-0.06,0.22c-0.49-0.19-0.85-0.49-1.08-0.91c-0.23-0.42-0.34-0.97-0.34-1.67
        c0-0.7,0.11-1.26,0.34-1.67C337.76,70.6,338.12,70.29,338.61,70.11z"
          fill="#FFFFFF"
        />
        <path
          d="M340.27,70.65v3.73h-0.47v-2.95c0-0.05,0-0.11,0-0.16c0-0.05,0.01-0.11,0.02-0.16l-0.91,0.29l-0.06-0.35
        l0.95-0.38H340.27z"
          fill="#FFFFFF"
        />
        <path
          d="M342.39,70.58c0.32,0,0.57,0.07,0.76,0.2c0.19,0.13,0.32,0.34,0.4,0.63s0.12,0.65,0.12,1.11
        c0,0.45-0.04,0.82-0.12,1.11s-0.22,0.49-0.4,0.63c-0.19,0.13-0.44,0.2-0.76,0.2c-0.32,0-0.57-0.07-0.76-0.2
        c-0.19-0.13-0.32-0.34-0.4-0.63s-0.12-0.65-0.12-1.11c0-0.45,0.04-0.82,0.12-1.11s0.21-0.49,0.4-0.63
        C341.82,70.64,342.07,70.58,342.39,70.58z M342.39,70.99c-0.15,0-0.28,0.03-0.39,0.08s-0.19,0.14-0.25,0.26
        c-0.06,0.12-0.11,0.27-0.13,0.47c-0.03,0.19-0.04,0.43-0.04,0.71c0,0.27,0.01,0.51,0.04,0.7c0.03,0.19,0.07,0.35,0.13,0.47
        c0.06,0.12,0.15,0.21,0.25,0.26c0.1,0.06,0.23,0.08,0.39,0.08c0.16,0,0.29-0.03,0.39-0.08c0.11-0.06,0.19-0.14,0.25-0.26
        c0.06-0.12,0.11-0.28,0.13-0.47s0.04-0.43,0.04-0.7c0-0.28-0.01-0.51-0.04-0.71s-0.07-0.35-0.13-0.47
        c-0.06-0.12-0.15-0.21-0.25-0.26S342.55,70.99,342.39,70.99z"
          fill="#FFFFFF"
        />
        <path
          d="M345.54,70.58c0.32,0,0.57,0.07,0.76,0.2c0.19,0.13,0.32,0.34,0.4,0.63s0.12,0.65,0.12,1.11
        c0,0.45-0.04,0.82-0.12,1.11s-0.22,0.49-0.4,0.63c-0.19,0.13-0.44,0.2-0.76,0.2c-0.32,0-0.57-0.07-0.76-0.2
        c-0.19-0.13-0.32-0.34-0.4-0.63s-0.12-0.65-0.12-1.11c0-0.45,0.04-0.82,0.12-1.11s0.21-0.49,0.4-0.63
        C344.97,70.64,345.22,70.58,345.54,70.58z M345.54,70.99c-0.15,0-0.28,0.03-0.39,0.08s-0.19,0.14-0.25,0.26
        c-0.06,0.12-0.11,0.27-0.13,0.47c-0.03,0.19-0.04,0.43-0.04,0.71c0,0.27,0.01,0.51,0.04,0.7c0.03,0.19,0.07,0.35,0.13,0.47
        c0.06,0.12,0.15,0.21,0.25,0.26c0.1,0.06,0.23,0.08,0.39,0.08c0.16,0,0.29-0.03,0.39-0.08c0.11-0.06,0.19-0.14,0.25-0.26
        c0.06-0.12,0.11-0.28,0.13-0.47s0.04-0.43,0.04-0.7c0-0.28-0.01-0.51-0.04-0.71s-0.07-0.35-0.13-0.47
        c-0.06-0.12-0.15-0.21-0.25-0.26S345.69,70.99,345.54,70.99z"
          fill="#FFFFFF"
        />
        <path
          d="M348.96,71.74l0.03,0.5l0.05,0.08v2.05h-0.46v-2.63H348.96z M350.06,71.68c0.22,0,0.39,0.06,0.51,0.18
        c0.12,0.12,0.18,0.3,0.18,0.54v1.97h-0.44v-1.84c0-0.17-0.03-0.29-0.1-0.36s-0.17-0.11-0.31-0.11c-0.09,0-0.18,0.01-0.26,0.04
        c-0.08,0.03-0.18,0.08-0.28,0.14c-0.1,0.07-0.23,0.15-0.38,0.26l-0.03-0.32c0.19-0.17,0.38-0.3,0.56-0.38
        S349.87,71.68,350.06,71.68z M351.78,71.68c0.23,0,0.4,0.06,0.52,0.18c0.12,0.12,0.18,0.3,0.18,0.54v1.97h-0.45v-1.84
        c0-0.16-0.04-0.28-0.1-0.35c-0.06-0.07-0.17-0.11-0.31-0.11c-0.09,0-0.18,0.02-0.27,0.05c-0.08,0.03-0.18,0.08-0.28,0.14
        c-0.1,0.06-0.22,0.15-0.37,0.25l-0.03-0.32c0.19-0.17,0.38-0.3,0.56-0.38C351.42,71.72,351.6,71.68,351.78,71.68z"
          fill="#FFFFFF"
        />
        <path
          d="M353.66,70.59l-0.01,3.06c0,0.12,0.03,0.2,0.09,0.26c0.06,0.06,0.15,0.09,0.27,0.09h0.25l0.05,0.35
        c-0.03,0.01-0.08,0.03-0.14,0.04s-0.12,0.02-0.18,0.02c-0.06,0.01-0.12,0.01-0.16,0.01c-0.19,0-0.35-0.06-0.47-0.17
        c-0.12-0.12-0.17-0.28-0.17-0.5v-3.16H353.66z"
          fill="#FFFFFF"
        />
        <path
          d="M354.37,70.11c0.49,0.19,0.85,0.49,1.08,0.91c0.23,0.42,0.34,0.98,0.34,1.67c0,0.69-0.11,1.25-0.34,1.67
        c-0.23,0.42-0.59,0.72-1.08,0.91l-0.05-0.22c0.24-0.13,0.44-0.3,0.59-0.51s0.26-0.48,0.33-0.79s0.11-0.66,0.11-1.06
        c0-0.61-0.08-1.11-0.24-1.5c-0.16-0.39-0.42-0.67-0.78-0.85L354.37,70.11z"
          fill="#FFFFFF"
        />
        <path
          d="M356.67,72c0.07,0,0.11,0.01,0.13,0.04c0.03,0.03,0.04,0.07,0.04,0.13v0.29c0,0.06-0.01,0.11-0.04,0.13
        c-0.02,0.03-0.07,0.04-0.13,0.04h-0.15c-0.06,0-0.1-0.01-0.13-0.04s-0.04-0.07-0.04-0.13v-0.29c0-0.06,0.01-0.11,0.04-0.13
        s0.07-0.04,0.13-0.04H356.67z M356.67,73.74c0.07,0,0.11,0.01,0.13,0.04c0.03,0.03,0.04,0.07,0.04,0.14v0.29
        c0,0.06-0.01,0.11-0.04,0.13c-0.02,0.03-0.07,0.04-0.13,0.04h-0.15c-0.06,0-0.1-0.01-0.13-0.04c-0.03-0.02-0.04-0.07-0.04-0.13
        v-0.29c0-0.07,0.01-0.11,0.04-0.14c0.03-0.02,0.07-0.04,0.13-0.04H356.67z"
          fill="#FFFFFF"
        />
        <path
          d="M359.78,70.65v3.73h-0.47v-2.95c0-0.05,0-0.11,0-0.16c0-0.05,0.01-0.11,0.02-0.16l-0.91,0.29l-0.06-0.35
        l0.95-0.38H359.78z"
          fill="#FFFFFF"
        />
        <path
          d="M362.08,70.58c0.08,0,0.18,0,0.32,0.01c0.14,0.01,0.28,0.03,0.43,0.07l-0.04,0.35
        c-0.08-0.01-0.15-0.01-0.22-0.02s-0.14-0.01-0.21-0.01c-0.08,0-0.17,0-0.28,0c-0.18,0-0.34,0.02-0.46,0.06
        c-0.13,0.04-0.23,0.12-0.3,0.23c-0.08,0.11-0.13,0.28-0.16,0.49c-0.03,0.22-0.05,0.5-0.05,0.85c0,0.27,0.01,0.5,0.02,0.68
        s0.05,0.33,0.1,0.43c0.05,0.11,0.13,0.18,0.24,0.23c0.11,0.05,0.25,0.07,0.43,0.07c0.21,0,0.37-0.03,0.47-0.1
        c0.1-0.07,0.17-0.16,0.2-0.29s0.05-0.29,0.05-0.47c0-0.21-0.02-0.37-0.06-0.49s-0.11-0.21-0.21-0.27s-0.24-0.08-0.43-0.08
        c-0.12,0-0.23,0.01-0.33,0.04c-0.1,0.03-0.2,0.08-0.31,0.15c-0.1,0.07-0.23,0.18-0.36,0.31l-0.02-0.24
        c0.12-0.17,0.23-0.3,0.34-0.4c0.11-0.1,0.23-0.16,0.35-0.2s0.28-0.06,0.45-0.06c0.26,0,0.47,0.04,0.62,0.12s0.25,0.21,0.31,0.39
        c0.06,0.18,0.09,0.42,0.09,0.72c0,0.19-0.01,0.36-0.04,0.52c-0.03,0.16-0.08,0.29-0.17,0.4c-0.08,0.11-0.2,0.2-0.36,0.26
        c-0.16,0.06-0.36,0.09-0.61,0.09c-0.26,0-0.48-0.03-0.64-0.09c-0.17-0.06-0.29-0.16-0.38-0.3c-0.09-0.14-0.15-0.33-0.18-0.56
        c-0.03-0.24-0.05-0.53-0.05-0.88c0-0.44,0.03-0.79,0.09-1.06s0.15-0.47,0.27-0.61c0.12-0.14,0.27-0.23,0.45-0.28
        C361.62,70.61,361.83,70.58,362.08,70.58z"
          fill="#FFFFFF"
        />
        <path
          d="M364.71,70.58c0.24,0,0.44,0.02,0.61,0.06c0.17,0.04,0.3,0.12,0.39,0.23s0.14,0.29,0.14,0.51
        c0,0.15-0.02,0.3-0.06,0.44c-0.04,0.14-0.11,0.28-0.21,0.42c-0.1,0.15-0.23,0.31-0.39,0.49l-1.07,1.19
        c0.09-0.01,0.19-0.01,0.3-0.02c0.11,0,0.21-0.01,0.3-0.01H366v0.46h-2.45v-0.3c0-0.04,0.01-0.08,0.02-0.11s0.03-0.07,0.05-0.09
        l1.08-1.22c0.24-0.25,0.41-0.48,0.52-0.68c0.11-0.2,0.16-0.39,0.16-0.56c0-0.12-0.03-0.21-0.09-0.27s-0.13-0.09-0.24-0.11
        c-0.1-0.02-0.23-0.03-0.37-0.03c-0.12,0-0.22,0-0.33,0.01c-0.1,0-0.21,0.01-0.33,0.02c-0.12,0.01-0.25,0.03-0.41,0.06l-0.03-0.37
        c0.17-0.04,0.32-0.07,0.45-0.09c0.13-0.02,0.25-0.03,0.36-0.04C364.49,70.58,364.6,70.58,364.71,70.58z"
          fill="#FFFFFF"
        />
        <path
          d="M368.15,70.59l0.01,1.68c0,0.11,0,0.22-0.01,0.31c-0.01,0.1-0.02,0.2-0.04,0.3c0.02,0.1,0.03,0.2,0.04,0.3
        s0.01,0.2,0.01,0.29v0.9h-0.46v-3.78H368.15z M368.6,72.72v0.33h-0.61v-0.33H368.6z M369.84,71.74l-0.83,0.97
        c-0.03,0.03-0.06,0.07-0.09,0.1s-0.07,0.06-0.09,0.08v0.01c0.03,0.02,0.06,0.05,0.09,0.09s0.07,0.08,0.09,0.12l0.91,1.26h-0.53
        l-1.04-1.51l0.96-1.12H369.84z"
          fill="#FFFFFF"
        />
        <path
          d="M371.3,70.65v2.94c0,0.27-0.06,0.48-0.19,0.62c-0.12,0.14-0.3,0.21-0.54,0.21c-0.05,0-0.1,0-0.17-0.01
        c-0.06,0-0.12-0.01-0.18-0.02s-0.11-0.02-0.15-0.04l0.05-0.33h0.27c0.28,0,0.42-0.14,0.42-0.41v-2.96H371.3z"
          fill="#FFFFFF"
        />
        <path d="M375.38,70.65l-2.12,4.08h-0.38l2.12-4.08H375.38z" fill="#FFFFFF" />
        <path
          d="M377.91,70.58c0.27,0,0.49,0.03,0.66,0.08c0.17,0.05,0.29,0.14,0.37,0.27s0.12,0.3,0.12,0.52
        c0,0.16-0.02,0.3-0.05,0.43s-0.09,0.24-0.16,0.33c-0.08,0.09-0.19,0.15-0.33,0.18v0.02c0.24,0.04,0.41,0.14,0.5,0.3
        c0.09,0.16,0.14,0.37,0.14,0.63c0,0.25-0.03,0.46-0.11,0.62c-0.08,0.16-0.2,0.29-0.37,0.36s-0.41,0.12-0.7,0.12
        c-0.2,0-0.4-0.01-0.6-0.03c-0.2-0.02-0.39-0.04-0.59-0.07l0.03-0.38c0.12,0.01,0.24,0.02,0.35,0.03s0.23,0.02,0.34,0.02
        c0.11,0,0.23,0,0.34,0c0.2,0,0.36-0.02,0.48-0.07c0.12-0.04,0.21-0.12,0.26-0.23c0.06-0.11,0.08-0.25,0.08-0.43
        c0-0.14-0.02-0.26-0.06-0.36s-0.11-0.18-0.22-0.24s-0.26-0.09-0.47-0.09h-0.69v-0.37h0.69c0.15,0,0.26-0.02,0.35-0.06
        c0.09-0.04,0.15-0.09,0.2-0.15c0.04-0.06,0.07-0.14,0.08-0.22c0.01-0.08,0.02-0.17,0.02-0.25c0-0.16-0.03-0.28-0.08-0.35
        c-0.05-0.08-0.12-0.13-0.23-0.16c-0.11-0.03-0.26-0.04-0.45-0.04c-0.18,0-0.35,0-0.51,0.01c-0.16,0.01-0.31,0.02-0.43,0.03
        l-0.04-0.37c0.18-0.03,0.36-0.06,0.53-0.07C377.57,70.58,377.74,70.58,377.91,70.58z"
          fill="#FFFFFF"
        />
        <path
          d="M380.9,70.58c0.27,0,0.49,0.03,0.65,0.09c0.16,0.06,0.29,0.17,0.37,0.31c0.08,0.14,0.14,0.34,0.17,0.58
        c0.03,0.24,0.04,0.54,0.04,0.9c0,0.42-0.03,0.76-0.08,1.02c-0.05,0.26-0.14,0.46-0.25,0.6s-0.26,0.23-0.45,0.28
        s-0.41,0.08-0.66,0.08c-0.08,0-0.2,0-0.34-0.01c-0.14-0.01-0.3-0.03-0.47-0.07l0.04-0.35c0.09,0.01,0.16,0.01,0.23,0.02
        c0.07,0,0.14,0,0.22,0s0.18,0,0.31,0c0.19,0,0.34-0.02,0.47-0.06s0.23-0.12,0.3-0.24c0.08-0.11,0.13-0.27,0.16-0.48
        s0.05-0.47,0.05-0.79c0-0.28-0.01-0.52-0.02-0.71c-0.01-0.19-0.04-0.34-0.09-0.45c-0.05-0.11-0.12-0.19-0.23-0.24
        s-0.25-0.07-0.44-0.07c-0.21,0-0.36,0.04-0.47,0.1c-0.11,0.07-0.18,0.17-0.21,0.3c-0.04,0.13-0.05,0.29-0.05,0.47
        c0,0.18,0.02,0.33,0.06,0.46c0.04,0.12,0.11,0.22,0.21,0.28c0.1,0.06,0.24,0.1,0.42,0.1c0.12,0,0.23-0.02,0.33-0.05
        c0.1-0.03,0.2-0.08,0.3-0.16c0.1-0.07,0.22-0.18,0.36-0.31l0.02,0.23c-0.12,0.17-0.23,0.3-0.34,0.4c-0.11,0.1-0.23,0.16-0.35,0.2
        s-0.28,0.06-0.45,0.06c-0.26,0-0.46-0.05-0.6-0.16s-0.25-0.25-0.31-0.43s-0.09-0.39-0.09-0.63c0-0.28,0.04-0.52,0.11-0.71
        c0.07-0.19,0.2-0.33,0.37-0.43C380.37,70.63,380.6,70.58,380.9,70.58z"
          fill="#FFFFFF"
        />
        <path
          d="M384.37,70.59l0.01,1.68c0,0.11,0,0.22-0.01,0.31c-0.01,0.1-0.02,0.2-0.04,0.3c0.02,0.1,0.03,0.2,0.04,0.3
        s0.01,0.2,0.01,0.29v0.9h-0.46v-3.78H384.37z M384.82,72.72v0.33h-0.61v-0.33H384.82z M386.06,71.74l-0.83,0.97
        c-0.03,0.03-0.06,0.07-0.09,0.1s-0.07,0.06-0.09,0.08v0.01c0.03,0.02,0.06,0.05,0.09,0.09s0.07,0.08,0.09,0.12l0.91,1.26h-0.53
        l-1.04-1.51l0.96-1.12H386.06z"
          fill="#FFFFFF"
        />
        <path
          d="M387.59,71.68c0.08,0,0.17,0,0.26,0.01c0.1,0.01,0.2,0.02,0.3,0.04c0.1,0.02,0.2,0.04,0.28,0.08l-0.06,0.3
        c-0.12-0.01-0.24-0.02-0.38-0.02c-0.13,0-0.24-0.01-0.34-0.01c-0.21,0-0.38,0.03-0.5,0.09s-0.21,0.16-0.26,0.3
        c-0.05,0.14-0.08,0.34-0.08,0.6s0.03,0.46,0.08,0.6c0.05,0.15,0.14,0.25,0.26,0.31s0.29,0.09,0.5,0.09c0.05,0,0.12,0,0.21,0
        c0.09,0,0.18-0.01,0.28-0.02c0.1-0.01,0.19-0.02,0.28-0.03l0.05,0.31c-0.13,0.05-0.28,0.08-0.43,0.1s-0.31,0.03-0.47,0.03
        c-0.3,0-0.55-0.04-0.73-0.13s-0.32-0.24-0.4-0.44c-0.08-0.2-0.12-0.47-0.12-0.81s0.04-0.61,0.12-0.81c0.08-0.2,0.22-0.35,0.4-0.43
        C387.05,71.72,387.29,71.68,387.59,71.68z"
          fill="#FFFFFF"
        />
        <path
          d="M390.93,72.75l-0.01,0.35l-1.26,0.01c-0.13,0-0.21,0.04-0.26,0.1c-0.05,0.06-0.08,0.15-0.08,0.26v0.17
        c0,0.14,0.03,0.24,0.1,0.3c0.07,0.06,0.17,0.09,0.31,0.09c0.1,0,0.21-0.02,0.34-0.06s0.26-0.1,0.39-0.18
        c0.13-0.08,0.25-0.18,0.36-0.3v0.34c-0.04,0.05-0.1,0.11-0.17,0.18c-0.07,0.07-0.16,0.13-0.26,0.19c-0.1,0.06-0.22,0.12-0.34,0.16
        c-0.12,0.04-0.26,0.06-0.4,0.06c-0.15,0-0.29-0.03-0.41-0.08s-0.21-0.14-0.28-0.25c-0.07-0.11-0.1-0.24-0.1-0.4V73.4
        c0-0.21,0.06-0.36,0.19-0.48c0.13-0.11,0.31-0.17,0.54-0.17H390.93z M390.24,71.68c0.19,0,0.36,0.03,0.49,0.08
        c0.13,0.05,0.24,0.14,0.3,0.26c0.07,0.12,0.1,0.29,0.1,0.5v1.85h-0.36l-0.07-0.57l-0.03-0.06v-1.22c0-0.16-0.04-0.28-0.11-0.35
        c-0.07-0.07-0.21-0.11-0.42-0.11c-0.14,0-0.31,0.01-0.52,0.02c-0.21,0.01-0.41,0.03-0.61,0.05l-0.04-0.34
        c0.12-0.02,0.26-0.04,0.4-0.06c0.15-0.02,0.29-0.03,0.45-0.04C389.97,71.68,390.11,71.68,390.24,71.68z"
          fill="#FFFFFF"
        />
        <path
          d="M392.32,70.59l-0.01,3.06c0,0.12,0.03,0.2,0.09,0.26c0.06,0.06,0.15,0.09,0.27,0.09h0.25l0.05,0.35
        c-0.03,0.01-0.08,0.03-0.14,0.04s-0.12,0.02-0.18,0.02c-0.06,0.01-0.12,0.01-0.16,0.01c-0.19,0-0.35-0.06-0.47-0.17
        c-0.12-0.12-0.17-0.28-0.17-0.5v-3.16H392.32z"
          fill="#FFFFFF"
        />
      </g>
      <g>
        <path
          d="M324.01,41.06c0.45,0,0.81,0.08,1.08,0.24c0.27,0.16,0.46,0.42,0.58,0.78c0.12,0.36,0.17,0.84,0.17,1.44
        c0,0.6-0.06,1.08-0.17,1.44c-0.12,0.36-0.31,0.62-0.58,0.78c-0.27,0.16-0.63,0.24-1.08,0.24s-0.81-0.08-1.08-0.24
        c-0.27-0.16-0.46-0.42-0.57-0.78s-0.17-0.84-0.17-1.44c0-0.6,0.06-1.08,0.17-1.44c0.11-0.36,0.31-0.62,0.57-0.78
        C323.19,41.14,323.55,41.06,324.01,41.06z M324,41.88c-0.15,0-0.28,0.03-0.38,0.08c-0.1,0.05-0.18,0.13-0.24,0.25
        s-0.1,0.28-0.13,0.5c-0.02,0.21-0.04,0.48-0.04,0.81c0,0.32,0.01,0.59,0.04,0.81c0.03,0.21,0.07,0.38,0.13,0.5
        c0.06,0.12,0.14,0.2,0.24,0.25c0.1,0.05,0.23,0.08,0.38,0.08c0.16,0,0.28-0.03,0.39-0.08c0.1-0.05,0.19-0.13,0.25-0.25
        c0.06-0.12,0.11-0.29,0.13-0.5c0.03-0.21,0.04-0.48,0.04-0.81c0-0.33-0.01-0.6-0.04-0.81c-0.02-0.21-0.07-0.38-0.13-0.5
        s-0.14-0.2-0.25-0.25C324.28,41.91,324.15,41.88,324,41.88z"
          fill="#FFFFFF"
        />
        <path
          d="M327.11,44.84c0.13,0,0.22,0.03,0.27,0.08c0.05,0.05,0.08,0.14,0.08,0.28v0.44c0,0.18-0.01,0.34-0.04,0.49
        c-0.03,0.15-0.07,0.28-0.12,0.4c-0.05,0.12-0.11,0.24-0.18,0.36l-0.41-0.12c0.04-0.15,0.06-0.3,0.08-0.43
        c0.01-0.14,0.02-0.29,0.03-0.45c-0.19,0-0.29-0.11-0.29-0.32l0.01-0.37c0-0.13,0.03-0.22,0.09-0.28c0.05-0.05,0.14-0.08,0.27-0.08
        H327.11z"
          fill="#FFFFFF"
        />
        <path
          d="M329.69,41.06c0.38,0,0.67,0.03,0.9,0.1s0.39,0.18,0.49,0.34c0.1,0.16,0.15,0.38,0.15,0.67
        c0,0.22-0.02,0.41-0.06,0.58s-0.11,0.3-0.22,0.41c-0.1,0.1-0.26,0.17-0.45,0.21v0.03c0.32,0.05,0.55,0.17,0.67,0.37
        s0.19,0.47,0.19,0.82c0,0.33-0.05,0.6-0.15,0.81s-0.28,0.36-0.52,0.46c-0.24,0.1-0.56,0.15-0.96,0.15c-0.27,0-0.55-0.01-0.83-0.04
        c-0.28-0.03-0.55-0.06-0.81-0.09l0.04-0.77c0.18,0.01,0.35,0.02,0.49,0.03c0.14,0.01,0.28,0.01,0.41,0.02
        c0.13,0,0.27,0.01,0.4,0.01c0.22,0,0.4-0.02,0.53-0.06c0.13-0.04,0.22-0.11,0.27-0.21c0.05-0.1,0.08-0.25,0.08-0.44
        c0-0.15-0.02-0.27-0.06-0.37c-0.04-0.1-0.11-0.17-0.22-0.23s-0.26-0.08-0.46-0.07l-0.9,0.01v-0.69h0.9c0.15,0,0.27-0.02,0.36-0.05
        s0.15-0.08,0.2-0.14c0.04-0.06,0.07-0.12,0.09-0.2c0.01-0.08,0.02-0.16,0.02-0.25c0-0.15-0.03-0.27-0.07-0.34
        c-0.04-0.08-0.12-0.13-0.23-0.16s-0.26-0.04-0.45-0.04c-0.24,0-0.47,0-0.69,0.01s-0.42,0.01-0.59,0.02l-0.05-0.73
        c0.26-0.04,0.51-0.08,0.76-0.1C329.2,41.07,329.45,41.06,329.69,41.06z"
          fill="#FFFFFF"
        />
        <path
          d="M333.58,41.06c0.38,0,0.67,0.03,0.9,0.1s0.39,0.18,0.49,0.34c0.1,0.16,0.15,0.38,0.15,0.67
        c0,0.22-0.02,0.41-0.06,0.58s-0.11,0.3-0.22,0.41c-0.1,0.1-0.26,0.17-0.45,0.21v0.03c0.32,0.05,0.55,0.17,0.67,0.37
        s0.19,0.47,0.19,0.82c0,0.33-0.05,0.6-0.15,0.81s-0.28,0.36-0.52,0.46c-0.24,0.1-0.56,0.15-0.96,0.15c-0.27,0-0.55-0.01-0.83-0.04
        c-0.28-0.03-0.55-0.06-0.81-0.09l0.04-0.77c0.18,0.01,0.35,0.02,0.49,0.03c0.14,0.01,0.28,0.01,0.41,0.02
        c0.13,0,0.27,0.01,0.4,0.01c0.22,0,0.4-0.02,0.53-0.06c0.13-0.04,0.22-0.11,0.27-0.21c0.05-0.1,0.08-0.25,0.08-0.44
        c0-0.15-0.02-0.27-0.06-0.37c-0.04-0.1-0.11-0.17-0.22-0.23s-0.26-0.08-0.46-0.07l-0.9,0.01v-0.69h0.9c0.15,0,0.27-0.02,0.36-0.05
        s0.15-0.08,0.2-0.14c0.04-0.06,0.07-0.12,0.09-0.2c0.01-0.08,0.02-0.16,0.02-0.25c0-0.15-0.03-0.27-0.07-0.34
        c-0.04-0.08-0.12-0.13-0.23-0.16s-0.26-0.04-0.45-0.04c-0.24,0-0.47,0-0.69,0.01s-0.42,0.01-0.59,0.02l-0.05-0.73
        c0.26-0.04,0.51-0.08,0.76-0.1C333.09,41.07,333.33,41.06,333.58,41.06z"
          fill="#FFFFFF"
        />
        <path
          d="M337.11,41.16v3.54c0,0.11,0.03,0.2,0.1,0.26c0.06,0.06,0.16,0.09,0.27,0.09h1.65l0.05,0.79
        c-0.33,0.04-0.66,0.06-1.02,0.07c-0.35,0.01-0.71,0.01-1.06,0.01c-0.34,0-0.6-0.09-0.78-0.28c-0.18-0.19-0.26-0.42-0.26-0.71
        v-3.76H337.11z"
          fill="#FFFFFF"
        />
        <path
          d="M267.8,49.29c0.38,0,0.69,0.04,0.95,0.12s0.47,0.21,0.63,0.4c0.16,0.18,0.27,0.44,0.35,0.75
        c0.07,0.32,0.11,0.71,0.11,1.19c0,0.47-0.04,0.87-0.11,1.19c-0.07,0.32-0.19,0.57-0.35,0.75c-0.16,0.19-0.37,0.32-0.63,0.4
        c-0.26,0.08-0.58,0.12-0.95,0.12c-0.37,0-0.69-0.04-0.95-0.12c-0.26-0.08-0.47-0.21-0.63-0.4c-0.16-0.18-0.28-0.44-0.35-0.75
        c-0.07-0.32-0.11-0.71-0.11-1.19c0-0.48,0.04-0.87,0.11-1.19s0.19-0.57,0.35-0.75c0.16-0.19,0.37-0.32,0.63-0.4
        C267.11,49.33,267.43,49.29,267.8,49.29z M267.8,50.12c-0.24,0-0.43,0.05-0.57,0.14c-0.13,0.1-0.23,0.26-0.29,0.5
        c-0.06,0.24-0.09,0.57-0.09,0.99c0,0.42,0.03,0.75,0.09,0.99c0.06,0.24,0.15,0.4,0.29,0.5c0.13,0.1,0.32,0.14,0.57,0.14
        c0.24,0,0.42-0.05,0.56-0.14c0.13-0.1,0.23-0.26,0.29-0.5c0.06-0.24,0.09-0.57,0.09-0.99c0-0.43-0.03-0.76-0.09-0.99
        c-0.06-0.23-0.15-0.4-0.29-0.5C268.23,50.16,268.04,50.12,267.8,50.12z"
          fill="#FFFFFF"
        />
        <path
          d="M271.67,49.38v3.54c0,0.11,0.03,0.2,0.1,0.26c0.06,0.06,0.16,0.09,0.27,0.09h1.65l0.05,0.79
        c-0.33,0.04-0.66,0.06-1.02,0.07c-0.35,0.01-0.71,0.01-1.06,0.01c-0.34,0-0.6-0.09-0.78-0.28c-0.18-0.19-0.26-0.42-0.26-0.71
        v-3.76H271.67z"
          fill="#FFFFFF"
        />
        <path
          d="M278,49.38v2.84c0,0.48-0.06,0.86-0.18,1.16c-0.12,0.29-0.31,0.5-0.59,0.63c-0.27,0.13-0.63,0.2-1.09,0.2
        c-0.48,0-0.86-0.06-1.15-0.2c-0.29-0.13-0.5-0.34-0.62-0.63c-0.13-0.29-0.19-0.68-0.19-1.16v-2.84h1.05v2.84
        c0,0.3,0.03,0.53,0.08,0.7c0.05,0.17,0.14,0.29,0.27,0.35s0.3,0.1,0.51,0.1s0.38-0.03,0.51-0.1s0.21-0.19,0.27-0.35
        c0.05-0.17,0.08-0.4,0.08-0.7v-2.84H278z"
          fill="#FFFFFF"
        />
        <path d="M282.46,49.38v0.82h-3.89v-0.82H282.46z M281.03,49.38v4.73h-1.05v-4.73H281.03z" fill="#FFFFFF" />
        <path
          d="M286.48,49.38c0.09,0,0.16,0.03,0.23,0.08c0.07,0.05,0.11,0.12,0.13,0.2l1.37,4.46h-1.07l-0.97-3.47
        c-0.02-0.08-0.04-0.16-0.06-0.24c-0.02-0.08-0.03-0.16-0.05-0.23h-0.18c-0.01,0.07-0.03,0.15-0.04,0.23
        c-0.02,0.08-0.04,0.16-0.06,0.24l-0.97,3.47h-1.08l1.38-4.46c0.02-0.08,0.07-0.15,0.13-0.2c0.07-0.05,0.14-0.08,0.23-0.08H286.48z
         M287.21,52.08v0.79h-2.45v-0.79H287.21z"
          fill="#FFFFFF"
        />
        <path
          d="M289.73,49.38v3.54c0,0.11,0.03,0.2,0.1,0.26c0.06,0.06,0.16,0.09,0.27,0.09h1.65l0.05,0.79
        c-0.33,0.04-0.66,0.06-1.02,0.07c-0.35,0.01-0.71,0.01-1.06,0.01c-0.34,0-0.6-0.09-0.78-0.28c-0.18-0.19-0.26-0.42-0.26-0.71
        v-3.76H289.73z"
          fill="#FFFFFF"
        />
        <path
          d="M293.42,49.38v1.34c0,0.16-0.01,0.31-0.03,0.46c-0.02,0.15-0.06,0.3-0.11,0.45
        c0.04,0.14,0.07,0.29,0.1,0.44c0.03,0.15,0.04,0.29,0.04,0.42v1.63h-1.04v-4.73H293.42z M293.95,51.29v0.73h-0.85v-0.73H293.95z
         M296.12,49.38l-1.23,1.91c-0.05,0.08-0.11,0.15-0.16,0.21c-0.06,0.06-0.12,0.11-0.18,0.15v0.02c0.06,0.04,0.13,0.09,0.21,0.17
        s0.13,0.15,0.18,0.23l1.28,2.04h-1.17l-1.46-2.47l1.42-2.25H296.12z"
          fill="#FFFFFF"
        />
        <path
          d="M297.21,53.07c0.13,0,0.22,0.03,0.28,0.08s0.08,0.14,0.08,0.28v0.33c0,0.13-0.03,0.23-0.08,0.28
        c-0.05,0.05-0.15,0.08-0.28,0.08h-0.26c-0.13,0-0.23-0.03-0.28-0.08c-0.05-0.05-0.08-0.14-0.08-0.28v-0.33
        c0-0.13,0.03-0.22,0.08-0.28c0.05-0.05,0.14-0.08,0.28-0.08H297.21z"
          fill="#FFFFFF"
        />
        <path
          d="M301.83,49.38l-1.14,2.72c-0.03,0.07-0.03,0.13,0,0.17c0.03,0.04,0.08,0.06,0.14,0.06h2.71v0.62l-0.58,0.1
        h-2.89c-0.13,0-0.24-0.04-0.33-0.11c-0.09-0.07-0.15-0.16-0.18-0.27c-0.03-0.11-0.02-0.23,0.03-0.34l1.24-2.95H301.83z
         M302.98,50.3v3.81h-0.97v-3.06l0.17-0.75H302.98z"
          fill="#FFFFFF"
        />
        <path
          d="M304.7,53.07c0.13,0,0.22,0.03,0.27,0.08c0.05,0.05,0.08,0.14,0.08,0.28v0.44c0,0.18-0.01,0.34-0.04,0.49
        c-0.03,0.15-0.07,0.28-0.12,0.4c-0.05,0.12-0.11,0.24-0.18,0.36L304.29,55c0.04-0.15,0.06-0.3,0.08-0.43
        c0.01-0.14,0.02-0.29,0.03-0.45c-0.19,0-0.29-0.11-0.29-0.32l0.01-0.37c0-0.13,0.03-0.22,0.09-0.28c0.05-0.05,0.14-0.08,0.27-0.08
        H304.7z"
          fill="#FFFFFF"
        />
        <path
          d="M308.24,49.38c0.11,0,0.21,0.03,0.28,0.08c0.08,0.05,0.13,0.12,0.15,0.2s0.02,0.18-0.01,0.28l-1.45,4.17
        h-1.03l1.21-3.43c0.03-0.08,0.06-0.15,0.09-0.23s0.07-0.15,0.12-0.22h-2.17l-0.05-0.85H308.24z"
          fill="#FFFFFF"
        />
        <path d="M314.16,49.38v0.82h-3.89v-0.82H314.16z M312.73,49.38v4.73h-1.05v-4.73H312.73z" fill="#FFFFFF" />
        <path d="M315.8,49.38v4.73h-1.04v-4.73H315.8z" fill="#FFFFFF" />
        <path
          d="M317.78,49.38v3.54c0,0.11,0.03,0.2,0.1,0.26c0.06,0.06,0.16,0.09,0.27,0.09h1.65l0.05,0.79
        c-0.33,0.04-0.66,0.06-1.02,0.07c-0.35,0.01-0.71,0.01-1.06,0.01c-0.34,0-0.6-0.09-0.78-0.28c-0.18-0.19-0.26-0.42-0.26-0.71
        v-3.76H317.78z"
          fill="#FFFFFF"
        />
        <path
          d="M320.92,53.07c0.13,0,0.22,0.03,0.28,0.08s0.08,0.14,0.08,0.28v0.33c0,0.13-0.03,0.23-0.08,0.28
        c-0.05,0.05-0.15,0.08-0.28,0.08h-0.26c-0.13,0-0.23-0.03-0.28-0.08c-0.05-0.05-0.08-0.14-0.08-0.28v-0.33
        c0-0.13,0.03-0.22,0.08-0.28c0.05-0.05,0.14-0.08,0.28-0.08H320.92z"
          fill="#FFFFFF"
        />
        <path d="M324.06,52.04v0.64h-2.04v-0.64H324.06z" fill="#FFFFFF" />
        <path
          d="M325.86,49.29c0.29,0,0.53,0.04,0.7,0.13c0.17,0.09,0.3,0.24,0.37,0.44c0.07,0.21,0.11,0.48,0.11,0.84
        c0,0.35-0.04,0.62-0.11,0.83c-0.07,0.21-0.2,0.35-0.37,0.45c-0.17,0.09-0.41,0.14-0.7,0.14c-0.29,0-0.52-0.05-0.69-0.14
        c-0.17-0.09-0.29-0.24-0.37-0.45c-0.08-0.21-0.11-0.48-0.11-0.83c0-0.35,0.04-0.63,0.11-0.84c0.08-0.21,0.2-0.35,0.37-0.44
        C325.35,49.33,325.58,49.29,325.86,49.29z M325.86,49.85c-0.11,0-0.19,0.02-0.25,0.07s-0.1,0.13-0.13,0.25s-0.04,0.3-0.04,0.53
        c0,0.23,0.01,0.41,0.04,0.53s0.07,0.2,0.13,0.25s0.14,0.07,0.25,0.07c0.11,0,0.2-0.02,0.26-0.07c0.06-0.05,0.1-0.13,0.13-0.25
        c0.03-0.12,0.04-0.3,0.04-0.53c0-0.23-0.01-0.41-0.04-0.53c-0.02-0.12-0.07-0.2-0.13-0.25C326.06,49.87,325.98,49.85,325.86,49.85
        z M329.3,49.38l-2.83,4.73h-0.64l2.83-4.73H329.3z M329.27,51.38c0.29,0,0.52,0.04,0.7,0.13c0.17,0.09,0.29,0.24,0.37,0.44
        s0.11,0.48,0.11,0.83c0,0.35-0.04,0.62-0.11,0.83s-0.2,0.36-0.37,0.45c-0.17,0.09-0.4,0.13-0.7,0.13c-0.29,0-0.52-0.04-0.69-0.13
        c-0.17-0.09-0.29-0.24-0.37-0.45c-0.08-0.21-0.11-0.49-0.11-0.83c0-0.35,0.04-0.63,0.11-0.83c0.08-0.2,0.2-0.35,0.37-0.44
        C328.75,51.42,328.98,51.38,329.27,51.38z M329.26,51.94c-0.11,0-0.2,0.02-0.26,0.07c-0.06,0.04-0.1,0.13-0.13,0.25
        c-0.02,0.12-0.03,0.3-0.03,0.53c0,0.23,0.01,0.41,0.03,0.54c0.02,0.12,0.07,0.21,0.13,0.25c0.06,0.04,0.15,0.07,0.26,0.07
        c0.11,0,0.2-0.02,0.26-0.07c0.06-0.04,0.1-0.13,0.13-0.25s0.04-0.3,0.04-0.54c0-0.23-0.01-0.41-0.04-0.53
        c-0.03-0.12-0.07-0.2-0.13-0.25C329.46,51.96,329.38,51.94,329.26,51.94z"
          fill="#FFFFFF"
        />
        <path d="M333.42,48.76v6.51h-0.72v-6.51H333.42z" fill="#FFFFFF" />
        <path
          d="M337.79,49.29c0.38,0,0.69,0.04,0.95,0.12s0.47,0.21,0.63,0.4c0.16,0.18,0.27,0.44,0.35,0.75
        c0.07,0.32,0.11,0.71,0.11,1.19c0,0.47-0.04,0.87-0.11,1.19c-0.07,0.32-0.19,0.57-0.35,0.75c-0.16,0.19-0.37,0.32-0.63,0.4
        c-0.26,0.08-0.58,0.12-0.95,0.12c-0.37,0-0.69-0.04-0.95-0.12c-0.26-0.08-0.47-0.21-0.63-0.4c-0.16-0.18-0.28-0.44-0.35-0.75
        c-0.07-0.32-0.11-0.71-0.11-1.19c0-0.48,0.04-0.87,0.11-1.19s0.19-0.57,0.35-0.75c0.16-0.19,0.37-0.32,0.63-0.4
        C337.1,49.33,337.42,49.29,337.79,49.29z M337.32,48.03c0.14,0,0.21,0.07,0.21,0.22v0.4c0,0.15-0.07,0.22-0.21,0.22h-0.34
        c-0.14,0-0.21-0.07-0.21-0.22v-0.4c0-0.15,0.07-0.22,0.21-0.22H337.32z M337.79,50.12c-0.24,0-0.43,0.05-0.57,0.14
        c-0.13,0.1-0.23,0.26-0.29,0.5c-0.06,0.24-0.09,0.57-0.09,0.99c0,0.42,0.03,0.75,0.09,0.99c0.06,0.24,0.15,0.4,0.29,0.5
        c0.13,0.1,0.32,0.14,0.57,0.14c0.24,0,0.42-0.05,0.56-0.14c0.13-0.1,0.23-0.26,0.29-0.5c0.06-0.24,0.09-0.57,0.09-0.99
        c0-0.43-0.03-0.76-0.09-0.99c-0.06-0.23-0.15-0.4-0.29-0.5C338.21,50.16,338.03,50.12,337.79,50.12z M338.58,48.03
        c0.15,0,0.22,0.07,0.22,0.22v0.4c0,0.15-0.07,0.22-0.22,0.22h-0.33c-0.14,0-0.21-0.07-0.21-0.22v-0.4c0-0.15,0.07-0.22,0.21-0.22
        H338.58z"
          fill="#FFFFFF"
        />
        <path
          d="M341.65,49.38v3.54c0,0.11,0.03,0.2,0.1,0.26c0.06,0.06,0.16,0.09,0.27,0.09h1.65l0.05,0.79
        c-0.33,0.04-0.66,0.06-1.02,0.07c-0.35,0.01-0.71,0.01-1.06,0.01c-0.34,0-0.6-0.09-0.78-0.28c-0.18-0.19-0.26-0.42-0.26-0.71
        v-3.76H341.65z"
          fill="#FFFFFF"
        />
        <path d="M347.47,49.38l-2.69,5.23h-0.81l2.69-5.23H347.47z" fill="#FFFFFF" />
        <path
          d="M349.88,49.33c0.41,0,0.74,0.04,0.98,0.11c0.25,0.07,0.42,0.2,0.53,0.37c0.11,0.18,0.17,0.43,0.17,0.75
        c0,0.34-0.07,0.6-0.2,0.77c-0.13,0.18-0.34,0.28-0.64,0.3v0.04c0.37,0.03,0.62,0.15,0.77,0.37c0.15,0.21,0.22,0.51,0.22,0.89
        c0,0.32-0.06,0.58-0.17,0.75c-0.11,0.18-0.29,0.3-0.52,0.37c-0.24,0.07-0.54,0.11-0.92,0.11c-0.31,0-0.57,0-0.8,0
        c-0.23,0-0.44-0.01-0.63-0.02c-0.19-0.01-0.39-0.02-0.59-0.03l0.1-0.73c0.11,0,0.32,0.01,0.63,0.01c0.31,0,0.68,0.01,1.09,0.01
        c0.19,0,0.34-0.02,0.45-0.05c0.11-0.04,0.19-0.1,0.23-0.2c0.04-0.1,0.06-0.24,0.06-0.42c0-0.2-0.02-0.35-0.07-0.45
        c-0.05-0.1-0.12-0.17-0.24-0.22c-0.11-0.04-0.27-0.06-0.46-0.07h-1.71v-0.67h1.7c0.17,0,0.3-0.02,0.4-0.05
        c0.1-0.03,0.17-0.09,0.21-0.18c0.04-0.09,0.06-0.22,0.06-0.38c0-0.17-0.02-0.3-0.07-0.39c-0.04-0.09-0.12-0.15-0.22-0.18
        c-0.11-0.03-0.25-0.05-0.44-0.05c-0.26,0-0.48,0-0.68,0s-0.37,0-0.53,0c-0.16,0-0.3,0.01-0.43,0.02l-0.1-0.73
        c0.18-0.02,0.36-0.03,0.53-0.04c0.17-0.01,0.36-0.01,0.56-0.01C349.37,49.33,349.61,49.33,349.88,49.33z M349.11,49.38v4.73h-1.03
        v-4.73H349.11z"
          fill="#FFFFFF"
        />
        <path
          d="M353.44,49.36c0.26,0,0.51,0,0.77,0c0.26,0,0.52,0.01,0.77,0.02c0.25,0.01,0.49,0.03,0.71,0.05l-0.05,0.76
        h-1.82c-0.11,0-0.2,0.03-0.26,0.09c-0.06,0.06-0.09,0.15-0.09,0.26v2.41c0,0.11,0.03,0.2,0.09,0.26c0.06,0.06,0.14,0.09,0.26,0.09
        h1.82l0.05,0.76c-0.22,0.02-0.46,0.03-0.71,0.04c-0.25,0.01-0.51,0.02-0.77,0.02c-0.26,0-0.52,0-0.77,0
        c-0.3,0-0.54-0.09-0.73-0.26c-0.18-0.18-0.28-0.41-0.28-0.69v-2.86c0-0.29,0.09-0.52,0.28-0.69
        C352.9,49.45,353.14,49.36,353.44,49.36z M352.56,51.28h2.83v0.74h-2.83V51.28z"
          fill="#FFFFFF"
        />
        <path
          d="M357.41,49.36c0.26,0,0.51,0,0.77,0c0.26,0,0.52,0.01,0.77,0.02c0.25,0.01,0.49,0.03,0.71,0.05l-0.05,0.76
        h-1.82c-0.11,0-0.2,0.03-0.26,0.09c-0.06,0.06-0.09,0.15-0.09,0.26v2.41c0,0.11,0.03,0.2,0.09,0.26c0.06,0.06,0.14,0.09,0.26,0.09
        h1.82l0.05,0.76c-0.22,0.02-0.46,0.03-0.71,0.04c-0.25,0.01-0.51,0.02-0.77,0.02c-0.26,0-0.52,0-0.77,0
        c-0.3,0-0.54-0.09-0.73-0.26c-0.18-0.18-0.28-0.41-0.28-0.69v-2.86c0-0.29,0.09-0.52,0.28-0.69
        C356.86,49.45,357.1,49.36,357.41,49.36z M356.52,51.28h2.83v0.74h-2.83V51.28z"
          fill="#FFFFFF"
        />
        <path
          d="M362.22,49.31c0.42,0,0.75,0.04,1.01,0.13c0.26,0.08,0.45,0.23,0.57,0.43s0.18,0.49,0.18,0.85
        c0,0.22-0.03,0.41-0.09,0.58c-0.06,0.17-0.15,0.3-0.28,0.4c-0.13,0.1-0.31,0.17-0.54,0.19v0.03c0.08,0.01,0.17,0.05,0.26,0.1
        c0.09,0.05,0.17,0.12,0.25,0.22c0.08,0.1,0.13,0.23,0.17,0.39l0.41,1.47h-1.1l-0.36-1.44c-0.04-0.16-0.1-0.26-0.18-0.32
        c-0.08-0.06-0.19-0.09-0.33-0.09c-0.31,0-0.57,0-0.79,0c-0.21,0-0.39,0-0.53-0.01s-0.27-0.01-0.39-0.01l0.02-0.76h1.71
        c0.18,0,0.33-0.02,0.44-0.06c0.11-0.04,0.18-0.11,0.23-0.21c0.04-0.1,0.07-0.24,0.07-0.42c0-0.18-0.02-0.32-0.07-0.42
        c-0.04-0.1-0.12-0.17-0.23-0.21s-0.25-0.06-0.44-0.06c-0.43,0-0.79,0-1.09,0.01c-0.3,0-0.5,0.01-0.61,0.02l-0.1-0.75
        c0.19-0.02,0.37-0.04,0.54-0.05c0.17-0.01,0.35-0.01,0.55-0.02C361.69,49.31,361.93,49.31,362.22,49.31z M361.43,49.38v4.73h-1.04
        v-4.73H361.43z"
          fill="#FFFFFF"
        />
        <path
          d="M368.28,49.38l-1.14,2.72c-0.03,0.07-0.03,0.13,0,0.17c0.03,0.04,0.08,0.06,0.14,0.06H370v0.62l-0.58,0.1
        h-2.89c-0.13,0-0.24-0.04-0.33-0.11c-0.09-0.07-0.15-0.16-0.18-0.27c-0.03-0.11-0.02-0.23,0.03-0.34l1.24-2.95H368.28z
         M369.42,50.3v3.81h-0.97v-3.06l0.17-0.75H369.42z"
          fill="#FFFFFF"
        />
        <path
          d="M371.14,53.07c0.13,0,0.22,0.03,0.27,0.08c0.05,0.05,0.08,0.14,0.08,0.28v0.44c0,0.18-0.01,0.34-0.04,0.49
        c-0.03,0.15-0.07,0.28-0.12,0.4c-0.05,0.12-0.11,0.24-0.18,0.36L370.74,55c0.04-0.15,0.06-0.3,0.08-0.43
        c0.01-0.14,0.02-0.29,0.03-0.45c-0.19,0-0.29-0.11-0.29-0.32l0.01-0.37c0-0.13,0.03-0.22,0.09-0.28c0.05-0.05,0.14-0.08,0.27-0.08
        H371.14z"
          fill="#FFFFFF"
        />
        <path
          d="M374.68,49.38c0.11,0,0.21,0.03,0.28,0.08c0.08,0.05,0.13,0.12,0.15,0.2s0.02,0.18-0.01,0.28l-1.45,4.17
        h-1.03l1.21-3.43c0.03-0.08,0.06-0.15,0.09-0.23s0.07-0.15,0.12-0.22h-2.17l-0.05-0.85H374.68z"
          fill="#FFFFFF"
        />
        <path
          d="M380.91,49.38l-1.29,4.44c-0.02,0.08-0.07,0.15-0.13,0.21s-0.14,0.08-0.24,0.08h-0.96
        c-0.1,0-0.18-0.03-0.24-0.08c-0.07-0.05-0.11-0.12-0.13-0.21l-1.3-4.44h1.08l0.86,3.41c0.02,0.09,0.04,0.17,0.06,0.26
        c0.02,0.09,0.04,0.18,0.05,0.26h0.18c0.01-0.09,0.03-0.18,0.05-0.27c0.02-0.09,0.04-0.18,0.06-0.27l0.86-3.41H380.91z"
          fill="#FFFFFF"
        />
        <path
          d="M383.27,49.29c0.38,0,0.69,0.04,0.95,0.12s0.47,0.21,0.63,0.4c0.16,0.18,0.27,0.44,0.35,0.75
        c0.07,0.32,0.11,0.71,0.11,1.19c0,0.47-0.04,0.87-0.11,1.19c-0.07,0.32-0.19,0.57-0.35,0.75c-0.16,0.19-0.37,0.32-0.63,0.4
        c-0.26,0.08-0.58,0.12-0.95,0.12c-0.37,0-0.69-0.04-0.95-0.12c-0.26-0.08-0.47-0.21-0.63-0.4c-0.16-0.18-0.28-0.44-0.35-0.75
        c-0.07-0.32-0.11-0.71-0.11-1.19c0-0.48,0.04-0.87,0.11-1.19s0.19-0.57,0.35-0.75c0.16-0.19,0.37-0.32,0.63-0.4
        C382.58,49.33,382.9,49.29,383.27,49.29z M383.27,50.12c-0.24,0-0.43,0.05-0.57,0.14c-0.13,0.1-0.23,0.26-0.29,0.5
        c-0.06,0.24-0.09,0.57-0.09,0.99c0,0.42,0.03,0.75,0.09,0.99c0.06,0.24,0.15,0.4,0.29,0.5c0.13,0.1,0.32,0.14,0.57,0.14
        c0.24,0,0.42-0.05,0.56-0.14c0.13-0.1,0.23-0.26,0.29-0.5c0.06-0.24,0.09-0.57,0.09-0.99c0-0.43-0.03-0.76-0.09-0.99
        c-0.06-0.23-0.15-0.4-0.29-0.5C383.7,50.16,383.51,50.12,383.27,50.12z"
          fill="#FFFFFF"
        />
        <path
          d="M387.14,49.38v3.54c0,0.11,0.03,0.2,0.1,0.26c0.06,0.06,0.16,0.09,0.27,0.09h1.65l0.05,0.79
        c-0.33,0.04-0.66,0.06-1.02,0.07c-0.35,0.01-0.71,0.01-1.06,0.01c-0.34,0-0.6-0.09-0.78-0.28c-0.18-0.19-0.26-0.42-0.26-0.71
        v-3.76H387.14z"
          fill="#FFFFFF"
        />
        <path
          d="M390.71,49.29c0.29,0,0.53,0.04,0.7,0.13c0.17,0.09,0.3,0.24,0.37,0.44c0.07,0.21,0.11,0.48,0.11,0.84
        c0,0.35-0.04,0.62-0.11,0.83c-0.07,0.21-0.2,0.35-0.37,0.45c-0.17,0.09-0.41,0.14-0.7,0.14c-0.29,0-0.52-0.05-0.69-0.14
        c-0.17-0.09-0.29-0.24-0.37-0.45c-0.08-0.21-0.11-0.48-0.11-0.83c0-0.35,0.04-0.63,0.11-0.84c0.08-0.21,0.2-0.35,0.37-0.44
        C390.19,49.33,390.42,49.29,390.71,49.29z M390.71,49.85c-0.11,0-0.19,0.02-0.25,0.07s-0.1,0.13-0.13,0.25s-0.04,0.3-0.04,0.53
        c0,0.23,0.01,0.41,0.04,0.53s0.07,0.2,0.13,0.25s0.14,0.07,0.25,0.07c0.11,0,0.2-0.02,0.26-0.07c0.06-0.05,0.1-0.13,0.13-0.25
        c0.03-0.12,0.04-0.3,0.04-0.53c0-0.23-0.01-0.41-0.04-0.53c-0.02-0.12-0.07-0.2-0.13-0.25C390.91,49.87,390.82,49.85,390.71,49.85
        z M394.14,49.38l-2.83,4.73h-0.64l2.83-4.73H394.14z M394.11,51.38c0.29,0,0.52,0.04,0.7,0.13c0.17,0.09,0.29,0.24,0.37,0.44
        s0.11,0.48,0.11,0.83c0,0.35-0.04,0.62-0.11,0.83s-0.2,0.36-0.37,0.45c-0.17,0.09-0.4,0.13-0.7,0.13c-0.29,0-0.52-0.04-0.69-0.13
        c-0.17-0.09-0.29-0.24-0.37-0.45c-0.08-0.21-0.11-0.49-0.11-0.83c0-0.35,0.04-0.63,0.11-0.83c0.08-0.2,0.2-0.35,0.37-0.44
        C393.6,51.42,393.83,51.38,394.11,51.38z M394.11,51.94c-0.11,0-0.2,0.02-0.26,0.07c-0.06,0.04-0.1,0.13-0.13,0.25
        c-0.02,0.12-0.03,0.3-0.03,0.53c0,0.23,0.01,0.41,0.03,0.54c0.02,0.12,0.07,0.21,0.13,0.25c0.06,0.04,0.15,0.07,0.26,0.07
        c0.11,0,0.2-0.02,0.26-0.07c0.06-0.04,0.1-0.13,0.13-0.25s0.04-0.3,0.04-0.54c0-0.23-0.01-0.41-0.04-0.53
        c-0.03-0.12-0.07-0.2-0.13-0.25C394.31,51.96,394.22,51.94,394.11,51.94z"
          fill="#FFFFFF"
        />
      </g>
      <g>
        <path
          d="M268.91,119.84l-0.94,3.24c-0.02,0.06-0.05,0.11-0.1,0.15c-0.05,0.04-0.11,0.06-0.17,0.06H267
        c-0.07,0-0.13-0.02-0.18-0.06c-0.05-0.04-0.08-0.09-0.09-0.15l-0.95-3.24h0.79l0.63,2.49c0.01,0.06,0.03,0.13,0.04,0.19
        c0.02,0.07,0.03,0.13,0.04,0.19h0.13c0.01-0.06,0.02-0.13,0.04-0.19c0.02-0.07,0.03-0.13,0.05-0.2l0.62-2.49H268.91z"
          fill="#FFFFFF"
        />
        <path
          d="M270.97,121.77l0,0.41l-1.1,0.01c-0.09,0-0.15,0.02-0.19,0.06c-0.04,0.04-0.06,0.1-0.06,0.17v0.12
        c0,0.08,0.02,0.15,0.07,0.19c0.05,0.04,0.12,0.07,0.22,0.07c0.09,0,0.18-0.02,0.28-0.05c0.1-0.04,0.2-0.09,0.3-0.16
        c0.1-0.07,0.19-0.14,0.26-0.23v0.32c-0.03,0.05-0.07,0.11-0.13,0.19c-0.06,0.08-0.13,0.15-0.22,0.22
        c-0.09,0.07-0.2,0.13-0.32,0.18c-0.12,0.05-0.26,0.07-0.41,0.07c-0.15,0-0.28-0.03-0.4-0.08c-0.12-0.05-0.21-0.13-0.29-0.24
        s-0.11-0.23-0.11-0.38v-0.25c0-0.2,0.07-0.35,0.2-0.46c0.13-0.11,0.32-0.16,0.55-0.16H270.97z M270.31,120.75
        c0.21,0,0.38,0.03,0.52,0.08c0.14,0.05,0.25,0.15,0.33,0.28c0.08,0.13,0.11,0.31,0.11,0.54v1.63h-0.59l-0.11-0.54l-0.05-0.08
        v-1.02c0-0.12-0.03-0.2-0.08-0.25c-0.05-0.05-0.16-0.08-0.31-0.08c-0.13,0-0.29,0-0.47,0.01c-0.19,0.01-0.38,0.02-0.58,0.03
        l-0.07-0.49c0.12-0.02,0.26-0.04,0.42-0.06c0.16-0.02,0.31-0.03,0.47-0.04C270.05,120.76,270.19,120.75,270.31,120.75z"
          fill="#FFFFFF"
        />
        <path
          d="M272.5,119.79l0,2.71c0,0.08,0.03,0.15,0.08,0.19s0.12,0.06,0.22,0.06h0.2l0.08,0.5
        c-0.04,0.02-0.1,0.04-0.17,0.05c-0.07,0.01-0.15,0.03-0.23,0.04c-0.08,0.01-0.14,0.01-0.19,0.01c-0.22,0-0.4-0.06-0.53-0.17
        c-0.13-0.12-0.2-0.28-0.2-0.49v-2.89H272.5z"
          fill="#FFFFFF"
        />
        <path
          d="M273.89,120.82l0.08,0.49l0.08,0.07v1.92h-0.74v-2.47H273.89z M274.89,120.75c0.22,0,0.39,0.06,0.5,0.17
        s0.17,0.29,0.17,0.51v1.85h-0.71v-1.62c0-0.11-0.02-0.18-0.07-0.22c-0.04-0.04-0.11-0.07-0.21-0.07c-0.06,0-0.11,0.01-0.17,0.02
        c-0.05,0.02-0.12,0.04-0.19,0.08c-0.07,0.04-0.16,0.09-0.26,0.15l-0.04-0.41c0.16-0.16,0.32-0.27,0.47-0.35
        C274.54,120.79,274.71,120.75,274.89,120.75z M276.43,120.75c0.22,0,0.39,0.06,0.51,0.17c0.12,0.12,0.17,0.29,0.17,0.51v1.85
        h-0.74v-1.62c0-0.1-0.02-0.18-0.06-0.22s-0.11-0.07-0.21-0.07c-0.06,0-0.12,0.01-0.17,0.03c-0.05,0.02-0.12,0.04-0.19,0.08
        c-0.07,0.04-0.16,0.09-0.25,0.15l-0.04-0.41c0.16-0.16,0.32-0.27,0.47-0.35C276.08,120.79,276.25,120.75,276.43,120.75z"
          fill="#FFFFFF"
        />
        <path
          d="M278.15,119.73c0.15,0,0.22,0.07,0.22,0.22v0.26c0,0.15-0.07,0.23-0.22,0.23h-0.32
        c-0.15,0-0.22-0.08-0.22-0.23v-0.26c0-0.15,0.07-0.22,0.22-0.22H278.15z M278.36,120.82v2.47h-0.73v-2.47H278.36z"
          fill="#FFFFFF"
        />
        <path
          d="M279.82,120.75c0.12,0,0.25,0,0.39,0.01s0.28,0.02,0.41,0.03c0.13,0.01,0.25,0.03,0.35,0.05l-0.05,0.46
        c-0.16,0-0.32,0-0.48-0.01c-0.16,0-0.32,0-0.47,0c-0.13,0-0.23,0-0.3,0c-0.07,0.01-0.12,0.02-0.15,0.05s-0.04,0.07-0.04,0.13
        c0,0.07,0.02,0.12,0.07,0.15c0.05,0.02,0.13,0.05,0.25,0.08l0.65,0.16c0.21,0.05,0.36,0.13,0.46,0.24
        c0.1,0.11,0.15,0.26,0.15,0.47c0,0.21-0.04,0.37-0.12,0.47c-0.08,0.11-0.2,0.19-0.37,0.23c-0.17,0.04-0.38,0.06-0.64,0.06
        c-0.1,0-0.26,0-0.46-0.01c-0.2-0.01-0.43-0.04-0.67-0.08l0.05-0.46c0.06,0,0.14,0.01,0.23,0.01c0.09,0,0.2,0,0.3,0
        c0.11,0,0.22,0,0.33,0c0.17,0,0.3,0,0.39-0.01c0.09-0.01,0.15-0.03,0.18-0.06c0.03-0.03,0.05-0.08,0.05-0.14
        c0-0.07-0.03-0.12-0.08-0.15s-0.15-0.05-0.27-0.08l-0.62-0.16c-0.16-0.04-0.28-0.1-0.37-0.17s-0.15-0.15-0.18-0.25
        s-0.05-0.21-0.05-0.34c0-0.17,0.03-0.31,0.1-0.41s0.18-0.18,0.33-0.22C279.36,120.78,279.56,120.75,279.82,120.75z"
          fill="#FFFFFF"
        />
        <path
          d="M283.09,120.82v0.48h-1.87v-0.46l0.48-0.02H283.09z M282.4,120.13v2.37c0,0.09,0.02,0.15,0.06,0.19
        c0.04,0.04,0.11,0.06,0.21,0.06h0.32l0.08,0.5c-0.06,0.02-0.13,0.04-0.22,0.05c-0.09,0.01-0.17,0.03-0.26,0.04
        c-0.09,0.01-0.16,0.01-0.22,0.01c-0.22,0-0.4-0.06-0.52-0.18s-0.19-0.29-0.19-0.5l-0.01-2.53H282.4z"
          fill="#FFFFFF"
        />
        <path
          d="M285.35,121.77l0,0.41l-1.1,0.01c-0.09,0-0.15,0.02-0.19,0.06c-0.04,0.04-0.06,0.1-0.06,0.17v0.12
        c0,0.08,0.02,0.15,0.07,0.19c0.05,0.04,0.12,0.07,0.22,0.07c0.09,0,0.18-0.02,0.28-0.05c0.1-0.04,0.2-0.09,0.3-0.16
        c0.1-0.07,0.19-0.14,0.26-0.23v0.32c-0.03,0.05-0.07,0.11-0.13,0.19c-0.06,0.08-0.13,0.15-0.22,0.22
        c-0.09,0.07-0.2,0.13-0.32,0.18c-0.12,0.05-0.26,0.07-0.41,0.07c-0.15,0-0.28-0.03-0.4-0.08c-0.12-0.05-0.21-0.13-0.29-0.24
        s-0.11-0.23-0.11-0.38v-0.25c0-0.2,0.07-0.35,0.2-0.46c0.13-0.11,0.32-0.16,0.55-0.16H285.35z M284.69,120.75
        c0.21,0,0.38,0.03,0.52,0.08c0.14,0.05,0.25,0.15,0.33,0.28c0.08,0.13,0.11,0.31,0.11,0.54v1.63h-0.59l-0.11-0.54l-0.05-0.08
        v-1.02c0-0.12-0.03-0.2-0.08-0.25c-0.05-0.05-0.16-0.08-0.31-0.08c-0.13,0-0.29,0-0.47,0.01c-0.19,0.01-0.38,0.02-0.58,0.03
        l-0.07-0.49c0.12-0.02,0.26-0.04,0.42-0.06c0.16-0.02,0.31-0.03,0.47-0.04C284.43,120.76,284.57,120.75,284.69,120.75z"
          fill="#FFFFFF"
        />
        <path
          d="M286.9,120.82v2.35c0,0.15-0.03,0.3-0.08,0.44s-0.12,0.27-0.22,0.38s-0.21,0.21-0.34,0.28l-0.41-0.32
        c0.11-0.13,0.19-0.25,0.24-0.37s0.07-0.25,0.07-0.41v-2.35H286.9z M286.69,119.7c0.14,0,0.22,0.07,0.22,0.22v0.27
        c0,0.15-0.07,0.22-0.22,0.22h-0.32c-0.14,0-0.22-0.08-0.22-0.22v-0.27c0-0.15,0.07-0.22,0.22-0.22H286.69z"
          fill="#FFFFFF"
        />
        <path
          d="M289.39,121.77l0,0.41l-1.1,0.01c-0.09,0-0.15,0.02-0.19,0.06c-0.04,0.04-0.06,0.1-0.06,0.17v0.12
        c0,0.08,0.02,0.15,0.07,0.19c0.05,0.04,0.12,0.07,0.22,0.07c0.09,0,0.18-0.02,0.28-0.05c0.1-0.04,0.2-0.09,0.3-0.16
        c0.1-0.07,0.19-0.14,0.26-0.23v0.32c-0.03,0.05-0.07,0.11-0.13,0.19c-0.06,0.08-0.13,0.15-0.22,0.22
        c-0.09,0.07-0.2,0.13-0.32,0.18c-0.12,0.05-0.26,0.07-0.41,0.07c-0.15,0-0.28-0.03-0.4-0.08c-0.12-0.05-0.21-0.13-0.29-0.24
        s-0.11-0.23-0.11-0.38v-0.25c0-0.2,0.07-0.35,0.2-0.46c0.13-0.11,0.32-0.16,0.55-0.16H289.39z M288.73,120.75
        c0.21,0,0.38,0.03,0.52,0.08c0.14,0.05,0.25,0.15,0.33,0.28c0.08,0.13,0.11,0.31,0.11,0.54v1.63h-0.59l-0.11-0.54l-0.05-0.08
        v-1.02c0-0.12-0.03-0.2-0.08-0.25c-0.05-0.05-0.16-0.08-0.31-0.08c-0.13,0-0.29,0-0.47,0.01c-0.19,0.01-0.38,0.02-0.58,0.03
        l-0.07-0.49c0.12-0.02,0.26-0.04,0.42-0.06c0.16-0.02,0.31-0.03,0.47-0.04C288.46,120.76,288.6,120.75,288.73,120.75z"
          fill="#FFFFFF"
        />
        <path d="M292.53,119.84l-1.96,3.82h-0.59l1.96-3.82H292.53z" fill="#FFFFFF" />
        <path d="M295.46,119.84v0.6h-2.84v-0.6H295.46z M294.42,119.84v3.45h-0.76v-3.45H294.42z" fill="#FFFFFF" />
        <path
          d="M296.3,119.73c0.15,0,0.22,0.07,0.22,0.22v0.26c0,0.15-0.07,0.23-0.22,0.23h-0.32
        c-0.15,0-0.22-0.08-0.22-0.23v-0.26c0-0.15,0.07-0.22,0.22-0.22H296.3z M296.5,120.82v2.47h-0.73v-2.47H296.5z"
          fill="#FFFFFF"
        />
        <path
          d="M297.77,119.79l0,2.71c0,0.08,0.03,0.15,0.08,0.19s0.12,0.06,0.22,0.06h0.2l0.08,0.5
        c-0.04,0.02-0.1,0.04-0.17,0.05c-0.07,0.01-0.15,0.03-0.23,0.04c-0.08,0.01-0.14,0.01-0.19,0.01c-0.22,0-0.4-0.06-0.53-0.17
        c-0.13-0.12-0.2-0.28-0.2-0.49v-2.89H297.77z"
          fill="#FFFFFF"
        />
        <path
          d="M299.31,119.79l0,2.71c0,0.08,0.03,0.15,0.08,0.19s0.12,0.06,0.22,0.06h0.2l0.08,0.5
        c-0.04,0.02-0.1,0.04-0.17,0.05c-0.07,0.01-0.15,0.03-0.23,0.04c-0.08,0.01-0.14,0.01-0.19,0.01c-0.22,0-0.4-0.06-0.53-0.17
        c-0.13-0.12-0.2-0.28-0.2-0.49v-2.89H299.31z"
          fill="#FFFFFF"
        />
        <path
          d="M302.47,120.82l-0.82,2.29c-0.02,0.05-0.05,0.1-0.09,0.13c-0.04,0.03-0.09,0.05-0.15,0.05h-0.65
        c-0.06,0-0.11-0.02-0.15-0.05c-0.04-0.03-0.07-0.07-0.09-0.13l-0.81-2.29h0.79l0.46,1.54c0.02,0.07,0.04,0.15,0.05,0.22
        c0.02,0.08,0.03,0.15,0.05,0.22h0.07c0.02-0.07,0.04-0.15,0.06-0.22c0.02-0.08,0.04-0.15,0.06-0.22l0.45-1.54H302.47z"
          fill="#FFFFFF"
        />
        <path
          d="M303.84,120.75c0.42,0,0.72,0.07,0.9,0.21s0.27,0.35,0.27,0.63c0,0.23-0.05,0.4-0.17,0.51
        c-0.12,0.11-0.32,0.17-0.6,0.17h-1.38v-0.42h1.17c0.11,0,0.19-0.02,0.22-0.07c0.03-0.05,0.05-0.12,0.05-0.2
        c0-0.12-0.04-0.2-0.1-0.24c-0.06-0.04-0.17-0.07-0.33-0.07c-0.14,0-0.24,0.02-0.32,0.05c-0.08,0.04-0.13,0.11-0.16,0.21
        c-0.03,0.1-0.04,0.25-0.04,0.45c0,0.21,0.02,0.37,0.06,0.48c0.04,0.11,0.1,0.19,0.19,0.23c0.09,0.04,0.21,0.06,0.37,0.06
        c0.11,0,0.25,0,0.42-0.01c0.17-0.01,0.34-0.02,0.51-0.04l0.07,0.46c-0.1,0.05-0.21,0.08-0.33,0.11c-0.12,0.03-0.25,0.05-0.38,0.06
        c-0.13,0.01-0.25,0.02-0.38,0.02c-0.31,0-0.57-0.05-0.76-0.14c-0.19-0.09-0.33-0.23-0.42-0.42s-0.13-0.43-0.13-0.73
        c0-0.32,0.04-0.58,0.13-0.77s0.22-0.33,0.41-0.41C303.29,120.79,303.53,120.75,303.84,120.75z"
          fill="#FFFFFF"
        />
        <path
          d="M305.96,120.82l0.09,0.49l0.07,0.07v1.92h-0.74v-2.47H305.96z M307.17,120.75l-0.07,0.69h-0.21
        c-0.12,0-0.24,0.02-0.36,0.05c-0.13,0.03-0.29,0.08-0.48,0.15l-0.05-0.42c0.17-0.15,0.35-0.27,0.53-0.35
        c0.18-0.08,0.35-0.12,0.51-0.12H307.17z"
          fill="#FFFFFF"
        />
        <path
          d="M308.16,119.79l0,1.45c0,0.12-0.01,0.23-0.02,0.34c-0.01,0.11-0.03,0.22-0.06,0.33
        c0.02,0.1,0.04,0.21,0.05,0.32c0.01,0.11,0.02,0.21,0.02,0.3v0.77h-0.74v-3.5H308.16z M308.51,121.69v0.45h-0.58v-0.45H308.51z
         M309.81,120.82l-0.65,0.86c-0.03,0.05-0.07,0.09-0.12,0.14c-0.05,0.04-0.08,0.07-0.11,0.09v0.02c0.03,0.02,0.07,0.05,0.11,0.11
        s0.08,0.11,0.12,0.16l0.73,1.09h-0.83l-0.84-1.39l0.76-1.08H309.81z"
          fill="#FFFFFF"
        />
        <path
          d="M312.08,121.77l0,0.41l-1.1,0.01c-0.09,0-0.15,0.02-0.19,0.06c-0.04,0.04-0.06,0.1-0.06,0.17v0.12
        c0,0.08,0.02,0.15,0.07,0.19c0.05,0.04,0.12,0.07,0.22,0.07c0.09,0,0.18-0.02,0.28-0.05c0.1-0.04,0.2-0.09,0.3-0.16
        c0.1-0.07,0.19-0.14,0.26-0.23v0.32c-0.03,0.05-0.07,0.11-0.13,0.19c-0.06,0.08-0.13,0.15-0.22,0.22
        c-0.09,0.07-0.2,0.13-0.32,0.18c-0.12,0.05-0.26,0.07-0.41,0.07c-0.15,0-0.28-0.03-0.4-0.08c-0.12-0.05-0.21-0.13-0.29-0.24
        s-0.11-0.23-0.11-0.38v-0.25c0-0.2,0.07-0.35,0.2-0.46c0.13-0.11,0.32-0.16,0.55-0.16H312.08z M311.42,120.75
        c0.21,0,0.38,0.03,0.52,0.08c0.14,0.05,0.25,0.15,0.33,0.28c0.08,0.13,0.11,0.31,0.11,0.54v1.63h-0.59l-0.11-0.54l-0.05-0.08
        v-1.02c0-0.12-0.03-0.2-0.08-0.25c-0.05-0.05-0.16-0.08-0.31-0.08c-0.13,0-0.29,0-0.47,0.01c-0.19,0.01-0.38,0.02-0.58,0.03
        l-0.07-0.49c0.12-0.02,0.26-0.04,0.42-0.06c0.16-0.02,0.31-0.03,0.47-0.04C311.16,120.76,311.3,120.75,311.42,120.75z"
          fill="#FFFFFF"
        />
        <path
          d="M313.45,120.82l0.09,0.49l0.07,0.07v1.92h-0.74v-2.47H313.45z M314.65,120.75l-0.07,0.69h-0.21
        c-0.12,0-0.24,0.02-0.36,0.05c-0.13,0.03-0.29,0.08-0.48,0.15l-0.05-0.42c0.17-0.15,0.35-0.27,0.53-0.35
        c0.18-0.08,0.35-0.12,0.51-0.12H314.65z"
          fill="#FFFFFF"
        />
        <path
          d="M316.03,120.75c0.42,0,0.72,0.07,0.9,0.21s0.27,0.35,0.27,0.63c0,0.23-0.05,0.4-0.17,0.51
        c-0.12,0.11-0.32,0.17-0.6,0.17h-1.38v-0.42h1.17c0.11,0,0.19-0.02,0.22-0.07c0.03-0.05,0.05-0.12,0.05-0.2
        c0-0.12-0.04-0.2-0.1-0.24c-0.06-0.04-0.17-0.07-0.33-0.07c-0.14,0-0.24,0.02-0.32,0.05c-0.08,0.04-0.13,0.11-0.16,0.21
        c-0.03,0.1-0.04,0.25-0.04,0.45c0,0.21,0.02,0.37,0.06,0.48c0.04,0.11,0.1,0.19,0.19,0.23c0.09,0.04,0.21,0.06,0.37,0.06
        c0.11,0,0.25,0,0.42-0.01c0.17-0.01,0.34-0.02,0.51-0.04l0.07,0.46c-0.1,0.05-0.21,0.08-0.33,0.11c-0.12,0.03-0.25,0.05-0.38,0.06
        c-0.13,0.01-0.25,0.02-0.38,0.02c-0.31,0-0.57-0.05-0.76-0.14c-0.19-0.09-0.33-0.23-0.42-0.42s-0.13-0.43-0.13-0.73
        c0-0.32,0.04-0.58,0.13-0.77s0.22-0.33,0.41-0.41C315.48,120.79,315.73,120.75,316.03,120.75z"
          fill="#FFFFFF"
        />
        <path
          d="M317.99,121.03c0.1,0,0.16,0.02,0.2,0.06c0.04,0.04,0.06,0.11,0.06,0.2v0.24c0,0.1-0.02,0.16-0.06,0.2
        c-0.04,0.04-0.11,0.06-0.2,0.06h-0.19c-0.1,0-0.16-0.02-0.2-0.06c-0.04-0.04-0.06-0.11-0.06-0.2v-0.24c0-0.1,0.02-0.16,0.06-0.2
        s0.11-0.06,0.2-0.06H317.99z M317.99,122.52c0.1,0,0.16,0.02,0.2,0.06c0.04,0.04,0.06,0.11,0.06,0.2v0.24
        c0,0.1-0.02,0.16-0.06,0.2c-0.04,0.04-0.11,0.06-0.2,0.06h-0.19c-0.1,0-0.16-0.02-0.2-0.06s-0.06-0.11-0.06-0.2v-0.24
        c0-0.1,0.02-0.16,0.06-0.2c0.04-0.04,0.11-0.06,0.2-0.06H317.99z"
          fill="#FFFFFF"
        />
        <path
          d="M267.6,125.49c0.29,0,0.54,0.03,0.75,0.09c0.21,0.06,0.37,0.17,0.49,0.31s0.21,0.34,0.27,0.59
        c0.06,0.25,0.09,0.56,0.09,0.94c0,0.37-0.03,0.69-0.09,0.94c-0.06,0.25-0.15,0.45-0.27,0.59s-0.29,0.25-0.49,0.31
        c-0.21,0.06-0.46,0.09-0.75,0.09s-0.55-0.03-0.75-0.09c-0.21-0.06-0.37-0.17-0.5-0.31s-0.22-0.34-0.28-0.59s-0.09-0.56-0.09-0.94
        c0-0.38,0.03-0.69,0.09-0.94c0.06-0.25,0.15-0.45,0.28-0.59s0.29-0.25,0.5-0.31C267.06,125.52,267.31,125.49,267.6,125.49z
         M267.6,126.14c-0.19,0-0.34,0.04-0.45,0.11c-0.11,0.08-0.18,0.21-0.23,0.39c-0.05,0.19-0.07,0.45-0.07,0.78
        c0,0.33,0.02,0.59,0.07,0.78c0.04,0.19,0.12,0.32,0.23,0.39c0.11,0.08,0.25,0.11,0.45,0.11c0.19,0,0.33-0.04,0.44-0.11
        c0.11-0.08,0.18-0.21,0.23-0.39c0.04-0.19,0.07-0.45,0.07-0.78c0-0.33-0.02-0.59-0.07-0.78s-0.12-0.32-0.23-0.39
        C267.94,126.18,267.79,126.14,267.6,126.14z"
          fill="#FFFFFF"
        />
        <path
          d="M270.48,125.51l-0.01,2.93c0,0.09,0.03,0.16,0.08,0.2s0.13,0.07,0.24,0.07h0.22l0.09,0.54
        c-0.04,0.02-0.1,0.04-0.18,0.06c-0.08,0.02-0.16,0.03-0.24,0.04c-0.08,0.01-0.15,0.01-0.21,0.01c-0.24,0-0.43-0.06-0.57-0.19
        s-0.21-0.3-0.21-0.53v-3.13H270.48z"
          fill="#FFFFFF"
        />
        <path
          d="M273.89,126.62l-0.88,2.48c-0.02,0.06-0.05,0.1-0.1,0.14c-0.05,0.04-0.1,0.05-0.16,0.05h-0.7
        c-0.06,0-0.11-0.02-0.16-0.05c-0.04-0.03-0.08-0.08-0.1-0.14l-0.88-2.48h0.85l0.49,1.67c0.02,0.08,0.04,0.16,0.06,0.24
        c0.02,0.08,0.04,0.16,0.05,0.24h0.07c0.02-0.08,0.04-0.16,0.06-0.24c0.02-0.08,0.04-0.16,0.06-0.24l0.48-1.67H273.89z"
          fill="#FFFFFF"
        />
        <path
          d="M274.75,125.44c0.16,0,0.24,0.08,0.24,0.24v0.29c0,0.16-0.08,0.24-0.24,0.24h-0.35
        c-0.16,0-0.24-0.08-0.24-0.24v-0.29c0-0.16,0.08-0.24,0.24-0.24H274.75z M274.97,126.62v2.67h-0.79v-2.67H274.97z"
          fill="#FFFFFF"
        />
        <path
          d="M278.13,125.49c0.29,0,0.54,0.03,0.75,0.09c0.21,0.06,0.37,0.17,0.49,0.31s0.21,0.34,0.27,0.59
        c0.06,0.25,0.09,0.56,0.09,0.94c0,0.37-0.03,0.69-0.09,0.94c-0.06,0.25-0.15,0.45-0.27,0.59s-0.29,0.25-0.49,0.31
        c-0.21,0.06-0.46,0.09-0.75,0.09s-0.55-0.03-0.75-0.09c-0.21-0.06-0.37-0.17-0.5-0.31s-0.22-0.34-0.28-0.59s-0.09-0.56-0.09-0.94
        c0-0.38,0.03-0.69,0.09-0.94c0.06-0.25,0.15-0.45,0.28-0.59s0.29-0.25,0.5-0.31C277.58,125.52,277.83,125.49,278.13,125.49z
         M278.13,126.14c-0.19,0-0.34,0.04-0.45,0.11c-0.11,0.08-0.18,0.21-0.23,0.39c-0.05,0.19-0.07,0.45-0.07,0.78
        c0,0.33,0.02,0.59,0.07,0.78c0.04,0.19,0.12,0.32,0.23,0.39c0.11,0.08,0.25,0.11,0.45,0.11c0.19,0,0.33-0.04,0.44-0.11
        c0.11-0.08,0.18-0.21,0.23-0.39c0.04-0.19,0.07-0.45,0.07-0.78c0-0.33-0.02-0.59-0.07-0.78s-0.12-0.32-0.23-0.39
        C278.46,126.18,278.31,126.14,278.13,126.14z"
          fill="#FFFFFF"
        />
        <path
          d="M280.77,126.62l0.51,1.73c0.01,0.07,0.03,0.14,0.04,0.21s0.03,0.14,0.04,0.21h0.15l-0.25,0.53h-0.19
        c-0.06,0-0.12-0.02-0.16-0.05s-0.08-0.08-0.1-0.14l-0.88-2.47H280.77z M282.88,126.62l-0.96,2.7c-0.04,0.13-0.1,0.27-0.18,0.4
        c-0.08,0.13-0.17,0.26-0.29,0.36c-0.12,0.11-0.26,0.19-0.42,0.25s-0.36,0.07-0.58,0.04l-0.06-0.45c0.22-0.06,0.39-0.13,0.51-0.23
        c0.12-0.1,0.22-0.22,0.29-0.38l0.17-0.37c0.03-0.08,0.06-0.18,0.09-0.29s0.06-0.21,0.08-0.29l0.5-1.73H282.88z"
          fill="#FFFFFF"
        />
        <path
          d="M283.97,126.62v2.54c0,0.16-0.03,0.32-0.08,0.47c-0.06,0.15-0.13,0.29-0.24,0.41
        c-0.1,0.12-0.23,0.22-0.37,0.3l-0.44-0.35c0.12-0.14,0.21-0.27,0.26-0.4c0.05-0.13,0.08-0.28,0.08-0.44v-2.54H283.97z
         M283.74,125.42c0.15,0,0.23,0.08,0.23,0.24v0.29c0,0.16-0.08,0.24-0.23,0.24h-0.35c-0.16,0-0.23-0.08-0.23-0.24v-0.29
        c0-0.16,0.08-0.24,0.23-0.24H283.74z"
          fill="#FFFFFF"
        />
        <path
          d="M284.95,128.47c0.1,0,0.18,0.02,0.22,0.06c0.04,0.04,0.06,0.11,0.06,0.22v0.35c0,0.14-0.01,0.27-0.04,0.38
        c-0.02,0.11-0.05,0.22-0.09,0.32c-0.04,0.1-0.09,0.19-0.15,0.29l-0.32-0.1c0.03-0.12,0.05-0.23,0.06-0.34
        c0.01-0.11,0.02-0.23,0.02-0.35c-0.15,0-0.23-0.08-0.23-0.25l0.01-0.29c0-0.1,0.03-0.18,0.07-0.22c0.04-0.04,0.11-0.06,0.21-0.06
        H284.95z"
          fill="#FFFFFF"
        />
        <path
          d="M288.33,125.49c0.29,0,0.54,0.03,0.75,0.09c0.21,0.06,0.37,0.17,0.49,0.31s0.21,0.34,0.27,0.59
        c0.06,0.25,0.09,0.56,0.09,0.94c0,0.37-0.03,0.69-0.09,0.94c-0.06,0.25-0.15,0.45-0.27,0.59s-0.29,0.25-0.49,0.31
        c-0.21,0.06-0.46,0.09-0.75,0.09s-0.55-0.03-0.75-0.09c-0.21-0.06-0.37-0.17-0.5-0.31s-0.22-0.34-0.28-0.59s-0.09-0.56-0.09-0.94
        c0-0.38,0.03-0.69,0.09-0.94c0.06-0.25,0.15-0.45,0.28-0.59s0.29-0.25,0.5-0.31C287.78,125.52,288.03,125.49,288.33,125.49z
         M288.33,126.14c-0.19,0-0.34,0.04-0.45,0.11c-0.11,0.08-0.18,0.21-0.23,0.39c-0.05,0.19-0.07,0.45-0.07,0.78
        c0,0.33,0.02,0.59,0.07,0.78c0.04,0.19,0.12,0.32,0.23,0.39c0.11,0.08,0.25,0.11,0.45,0.11c0.19,0,0.33-0.04,0.44-0.11
        c0.11-0.08,0.18-0.21,0.23-0.39c0.04-0.19,0.07-0.45,0.07-0.78c0-0.33-0.02-0.59-0.07-0.78s-0.12-0.32-0.23-0.39
        C288.66,126.18,288.51,126.14,288.33,126.14z"
          fill="#FFFFFF"
        />
        <path
          d="M291.2,125.51l-0.01,2.93c0,0.09,0.03,0.16,0.08,0.2s0.13,0.07,0.24,0.07h0.22l0.09,0.54
        c-0.04,0.02-0.1,0.04-0.18,0.06c-0.08,0.02-0.16,0.03-0.24,0.04c-0.08,0.01-0.15,0.01-0.21,0.01c-0.24,0-0.43-0.06-0.57-0.19
        s-0.21-0.3-0.21-0.53v-3.13H291.2z"
          fill="#FFFFFF"
        />
        <path
          d="M294.62,126.62l-0.88,2.48c-0.02,0.06-0.05,0.1-0.1,0.14c-0.05,0.04-0.1,0.05-0.16,0.05h-0.7
        c-0.06,0-0.11-0.02-0.16-0.05c-0.04-0.03-0.08-0.08-0.1-0.14l-0.88-2.48h0.85l0.49,1.67c0.02,0.08,0.04,0.16,0.06,0.24
        c0.02,0.08,0.04,0.16,0.05,0.24h0.07c0.02-0.08,0.04-0.16,0.06-0.24c0.02-0.08,0.04-0.16,0.06-0.24l0.48-1.67H294.62z"
          fill="#FFFFFF"
        />
        <path
          d="M295.47,125.44c0.16,0,0.24,0.08,0.24,0.24v0.29c0,0.16-0.08,0.24-0.24,0.24h-0.35
        c-0.16,0-0.24-0.08-0.24-0.24v-0.29c0-0.16,0.08-0.24,0.24-0.24H295.47z M295.69,126.62v2.67h-0.79v-2.67H295.69z"
          fill="#FFFFFF"
        />
        <path
          d="M298.04,126.62v0.52h-2.01v-0.5l0.52-0.03H298.04z M297.3,125.88v2.55c0,0.1,0.02,0.17,0.07,0.21
        c0.04,0.04,0.12,0.06,0.22,0.06h0.35l0.09,0.54c-0.07,0.02-0.14,0.04-0.24,0.06c-0.09,0.02-0.19,0.03-0.28,0.04
        c-0.09,0.01-0.17,0.01-0.24,0.01c-0.24,0-0.43-0.06-0.56-0.19c-0.13-0.13-0.2-0.31-0.2-0.54l-0.01-2.73H297.3z"
          fill="#FFFFFF"
        />
        <path
          d="M298.96,125.44c0.16,0,0.24,0.08,0.24,0.24v0.29c0,0.16-0.08,0.24-0.24,0.24h-0.35
        c-0.16,0-0.24-0.08-0.24-0.24v-0.29c0-0.16,0.08-0.24,0.24-0.24H298.96z M299.18,126.62v2.67h-0.79v-2.67H299.18z"
          fill="#FFFFFF"
        />
        <path
          d="M301.01,126.55c0.45,0,0.77,0.08,0.97,0.23s0.29,0.38,0.29,0.68c0,0.25-0.06,0.43-0.18,0.55
        c-0.13,0.12-0.34,0.18-0.65,0.18h-1.49v-0.45h1.27c0.12,0,0.2-0.03,0.23-0.08c0.03-0.05,0.05-0.12,0.05-0.22
        c0-0.13-0.04-0.21-0.11-0.26c-0.07-0.05-0.19-0.07-0.35-0.07c-0.15,0-0.26,0.02-0.35,0.06c-0.08,0.04-0.14,0.12-0.17,0.23
        c-0.03,0.11-0.05,0.27-0.05,0.49c0,0.22,0.02,0.39,0.06,0.52c0.04,0.12,0.11,0.2,0.2,0.25c0.09,0.05,0.23,0.07,0.4,0.07
        c0.12,0,0.27,0,0.46-0.01c0.18-0.01,0.36-0.02,0.55-0.05l0.08,0.49c-0.1,0.05-0.22,0.09-0.36,0.12c-0.13,0.03-0.27,0.05-0.41,0.06
        s-0.28,0.02-0.41,0.02c-0.34,0-0.61-0.05-0.82-0.15c-0.21-0.1-0.36-0.25-0.45-0.46s-0.14-0.47-0.14-0.79
        c0-0.35,0.05-0.63,0.14-0.83c0.09-0.21,0.24-0.35,0.44-0.44C300.42,126.59,300.68,126.55,301.01,126.55z"
          fill="#FFFFFF"
        />
        <path d="M304.61,125.56v3.73h-0.82v-3.73H304.61z" fill="#FFFFFF" />
        <path d="M306.81,127.66v0.51h-1.61v-0.51H306.81z" fill="#FFFFFF" />
        <path d="M308.21,125.56v3.73h-0.82v-3.73H308.21z" fill="#FFFFFF" />
        <path
          d="M311.89,125.56l-1.01,3.5c-0.02,0.06-0.05,0.12-0.11,0.16c-0.05,0.04-0.11,0.06-0.19,0.06h-0.76
        c-0.08,0-0.14-0.02-0.19-0.06c-0.05-0.04-0.09-0.1-0.1-0.16l-1.02-3.5h0.85l0.68,2.69c0.01,0.07,0.03,0.14,0.05,0.21
        c0.02,0.07,0.03,0.14,0.04,0.21h0.14c0.01-0.07,0.02-0.14,0.04-0.21c0.02-0.07,0.03-0.14,0.05-0.21l0.67-2.68H311.89z"
          fill="#FFFFFF"
        />
        <path
          d="M312.35,128.47c0.1,0,0.18,0.02,0.22,0.06c0.04,0.04,0.06,0.11,0.06,0.22v0.35c0,0.14-0.01,0.27-0.04,0.38
        c-0.02,0.11-0.05,0.22-0.09,0.32c-0.04,0.1-0.09,0.19-0.15,0.29l-0.32-0.1c0.03-0.12,0.05-0.23,0.06-0.34
        c0.01-0.11,0.02-0.23,0.02-0.35c-0.15,0-0.23-0.08-0.23-0.25l0.01-0.29c0-0.1,0.03-0.18,0.07-0.22c0.04-0.04,0.11-0.06,0.21-0.06
        H312.35z"
          fill="#FFFFFF"
        />
        <path
          d="M268.14,131.56c0.09,0,0.17,0.02,0.22,0.06s0.1,0.09,0.12,0.16c0.02,0.07,0.02,0.14-0.01,0.22l-1.14,3.29
        h-0.81l0.95-2.71c0.02-0.06,0.04-0.12,0.07-0.18s0.06-0.12,0.09-0.17h-1.71l-0.04-0.67H268.14z"
          fill="#FFFFFF"
        />
        <path
          d="M270.41,131.56l-0.9,2.14c-0.02,0.06-0.02,0.1,0,0.13c0.03,0.03,0.06,0.05,0.11,0.05h2.14v0.49l-0.46,0.08
        h-2.27c-0.1,0-0.19-0.03-0.26-0.08s-0.12-0.13-0.14-0.22c-0.02-0.09-0.02-0.18,0.02-0.27l0.98-2.33H270.41z M271.31,132.29v3
        h-0.76v-2.41l0.13-0.59H271.31z"
          fill="#FFFFFF"
        />
        <path
          d="M273.66,131.56v3.73h-0.82v-2.64c0-0.07,0-0.14,0.01-0.2s0.01-0.13,0.03-0.2l-0.89,0.23l-0.1-0.53
        l0.95-0.37H273.66z"
          fill="#FFFFFF"
        />
        <path
          d="M275.75,131.49c0.36,0,0.64,0.06,0.85,0.19c0.21,0.13,0.36,0.33,0.45,0.61c0.09,0.28,0.14,0.66,0.14,1.14
        c0,0.47-0.05,0.85-0.14,1.14c-0.09,0.28-0.24,0.49-0.45,0.61c-0.21,0.13-0.49,0.19-0.85,0.19c-0.36,0-0.64-0.06-0.85-0.19
        c-0.21-0.13-0.36-0.33-0.45-0.61c-0.09-0.28-0.13-0.66-0.13-1.14c0-0.48,0.04-0.85,0.13-1.14c0.09-0.28,0.24-0.49,0.45-0.61
        C275.11,131.55,275.39,131.49,275.75,131.49z M275.74,132.13c-0.12,0-0.22,0.02-0.3,0.06s-0.14,0.11-0.19,0.2
        c-0.05,0.09-0.08,0.22-0.1,0.39c-0.02,0.17-0.03,0.38-0.03,0.64c0,0.25,0.01,0.47,0.03,0.64s0.05,0.3,0.1,0.39
        c0.05,0.09,0.11,0.16,0.19,0.2c0.08,0.04,0.18,0.06,0.3,0.06c0.12,0,0.22-0.02,0.3-0.06c0.08-0.04,0.15-0.11,0.19-0.2
        c0.05-0.09,0.08-0.23,0.1-0.39c0.02-0.17,0.03-0.38,0.03-0.64c0-0.26-0.01-0.47-0.03-0.64c-0.02-0.17-0.05-0.3-0.1-0.39
        c-0.05-0.09-0.11-0.16-0.19-0.2S275.86,132.13,275.74,132.13z"
          fill="#FFFFFF"
        />
        <path
          d="M279.05,131.49c0.36,0,0.64,0.06,0.85,0.19c0.21,0.13,0.36,0.33,0.45,0.61c0.09,0.28,0.14,0.66,0.14,1.14
        c0,0.47-0.05,0.85-0.14,1.14c-0.09,0.28-0.24,0.49-0.45,0.61c-0.21,0.13-0.49,0.19-0.85,0.19c-0.36,0-0.64-0.06-0.85-0.19
        c-0.21-0.13-0.36-0.33-0.45-0.61c-0.09-0.28-0.13-0.66-0.13-1.14c0-0.48,0.04-0.85,0.13-1.14c0.09-0.28,0.24-0.49,0.45-0.61
        C278.4,131.55,278.69,131.49,279.05,131.49z M279.04,132.13c-0.12,0-0.22,0.02-0.3,0.06s-0.14,0.11-0.19,0.2
        c-0.05,0.09-0.08,0.22-0.1,0.39c-0.02,0.17-0.03,0.38-0.03,0.64c0,0.25,0.01,0.47,0.03,0.64s0.05,0.3,0.1,0.39
        c0.05,0.09,0.11,0.16,0.19,0.2c0.08,0.04,0.18,0.06,0.3,0.06c0.12,0,0.22-0.02,0.3-0.06c0.08-0.04,0.15-0.11,0.19-0.2
        c0.05-0.09,0.08-0.23,0.1-0.39c0.02-0.17,0.03-0.38,0.03-0.64c0-0.26-0.01-0.47-0.03-0.64c-0.02-0.17-0.05-0.3-0.1-0.39
        c-0.05-0.09-0.11-0.16-0.19-0.2S279.16,132.13,279.04,132.13z"
          fill="#FFFFFF"
        />
        <path d="M282.9,131.56v3.73h-0.82v-3.73H282.9z" fill="#FFFFFF" />
        <path d="M284.37,131.56v3.73h-0.82v-3.73H284.37z" fill="#FFFFFF" />
        <path
          d="M286.24,131.49c0.18,0,0.38,0.01,0.6,0.03c0.22,0.02,0.43,0.06,0.63,0.12l-0.05,0.54
        c-0.16,0-0.34-0.01-0.56-0.01c-0.21,0-0.42,0-0.63,0c-0.1,0-0.19,0-0.26,0.01c-0.07,0.01-0.13,0.02-0.17,0.04
        s-0.07,0.06-0.09,0.11c-0.02,0.05-0.03,0.11-0.03,0.19c0,0.13,0.03,0.22,0.09,0.28c0.06,0.06,0.16,0.11,0.31,0.16l0.72,0.23
        c0.3,0.1,0.51,0.24,0.62,0.41c0.12,0.17,0.17,0.4,0.17,0.68c0,0.21-0.03,0.39-0.08,0.53c-0.05,0.14-0.13,0.25-0.24,0.33
        c-0.11,0.08-0.25,0.14-0.43,0.17c-0.18,0.03-0.39,0.05-0.65,0.05c-0.12,0-0.3-0.01-0.52-0.02c-0.23-0.02-0.49-0.06-0.78-0.12
        l0.05-0.58c0.21,0,0.4,0.01,0.55,0.01c0.16,0.01,0.29,0.01,0.4,0.01c0.11,0,0.21,0,0.29,0c0.14,0,0.26-0.01,0.34-0.03
        c0.08-0.02,0.14-0.05,0.17-0.11c0.03-0.05,0.05-0.13,0.05-0.23c0-0.09-0.01-0.16-0.04-0.21c-0.02-0.05-0.06-0.09-0.11-0.12
        c-0.05-0.03-0.12-0.06-0.21-0.08l-0.76-0.26c-0.28-0.1-0.49-0.24-0.6-0.41c-0.12-0.17-0.18-0.39-0.18-0.67
        c0-0.22,0.03-0.39,0.08-0.53c0.05-0.13,0.13-0.24,0.24-0.32c0.11-0.08,0.25-0.13,0.43-0.16
        C285.79,131.51,286,131.49,286.24,131.49z"
          fill="#FFFFFF"
        />
        <path
          d="M289.85,131.56c0.07,0,0.13,0.02,0.18,0.06c0.05,0.04,0.09,0.09,0.11,0.16l1.08,3.51h-0.84l-0.76-2.74
        c-0.01-0.06-0.03-0.12-0.04-0.19c-0.01-0.06-0.03-0.12-0.04-0.18h-0.15c-0.01,0.06-0.02,0.12-0.04,0.18
        c-0.01,0.06-0.03,0.12-0.05,0.19l-0.76,2.74h-0.85l1.08-3.51c0.02-0.06,0.05-0.12,0.11-0.16s0.11-0.06,0.18-0.06H289.85z
         M290.42,133.68v0.62h-1.93v-0.62H290.42z"
          fill="#FFFFFF"
        />
        <path
          d="M292.3,131.56v2.79c0,0.09,0.03,0.16,0.08,0.21c0.05,0.05,0.12,0.07,0.22,0.07h1.3l0.04,0.62
        c-0.26,0.03-0.52,0.05-0.8,0.05c-0.28,0.01-0.56,0.01-0.83,0.01c-0.27,0-0.47-0.07-0.61-0.22c-0.14-0.15-0.21-0.33-0.21-0.56
        v-2.96H292.3z"
          fill="#FFFFFF"
        />
        <path
          d="M298.09,131.56c0.19,0,0.3,0.1,0.3,0.3l0.14,3.42h-0.76l-0.12-3.09h-0.1l-0.6,2.32
        c-0.03,0.17-0.14,0.26-0.32,0.26h-0.52c-0.18,0-0.29-0.09-0.33-0.26l-0.6-2.32h-0.09l-0.11,3.09h-0.77l0.14-3.42
        c0.01-0.2,0.11-0.3,0.31-0.3h0.77c0.18,0,0.28,0.09,0.32,0.26l0.48,1.87c0.02,0.09,0.04,0.17,0.05,0.26
        c0.01,0.09,0.03,0.18,0.05,0.27h0.07c0.02-0.09,0.04-0.18,0.05-0.27c0.02-0.09,0.03-0.18,0.05-0.27l0.47-1.87
        c0.04-0.18,0.14-0.26,0.32-0.26H298.09z"
          fill="#FFFFFF"
        />
        <path d="M299.93,131.56v3.73h-0.82v-3.73H299.93z" fill="#FFFFFF" />
        <path
          d="M267.35,137.49c0.18,0,0.38,0.01,0.6,0.03c0.22,0.02,0.43,0.06,0.63,0.12l-0.05,0.54
        c-0.16,0-0.34-0.01-0.56-0.01c-0.21,0-0.42,0-0.63,0c-0.1,0-0.19,0-0.26,0.01c-0.07,0.01-0.13,0.02-0.17,0.04
        s-0.07,0.06-0.09,0.11c-0.02,0.05-0.03,0.11-0.03,0.19c0,0.13,0.03,0.22,0.09,0.28c0.06,0.06,0.16,0.11,0.31,0.16l0.72,0.23
        c0.3,0.1,0.51,0.24,0.62,0.41c0.12,0.17,0.17,0.4,0.17,0.68c0,0.21-0.03,0.39-0.08,0.53c-0.05,0.14-0.13,0.25-0.24,0.33
        c-0.11,0.08-0.25,0.14-0.43,0.17c-0.18,0.03-0.39,0.05-0.65,0.05c-0.12,0-0.3-0.01-0.52-0.02c-0.23-0.02-0.49-0.06-0.78-0.12
        l0.05-0.58c0.21,0,0.4,0.01,0.55,0.01c0.16,0.01,0.29,0.01,0.4,0.01c0.11,0,0.21,0,0.29,0c0.14,0,0.26-0.01,0.34-0.03
        c0.08-0.02,0.14-0.05,0.17-0.11c0.03-0.05,0.05-0.13,0.05-0.23c0-0.09-0.01-0.16-0.04-0.21c-0.02-0.05-0.06-0.09-0.11-0.12
        c-0.05-0.03-0.12-0.06-0.21-0.08l-0.76-0.26c-0.28-0.1-0.49-0.24-0.6-0.41c-0.12-0.17-0.18-0.39-0.18-0.67
        c0-0.22,0.03-0.39,0.08-0.53c0.05-0.13,0.13-0.24,0.24-0.32c0.11-0.08,0.25-0.13,0.43-0.16
        C266.9,137.51,267.1,137.49,267.35,137.49z"
          fill="#FFFFFF"
        />
        <path
          d="M272.15,137.56v2.24c0,0.38-0.05,0.68-0.14,0.91c-0.09,0.23-0.25,0.39-0.46,0.5s-0.5,0.15-0.86,0.15
        c-0.38,0-0.68-0.05-0.91-0.15s-0.39-0.27-0.49-0.5c-0.1-0.23-0.15-0.53-0.15-0.91v-2.24h0.83v2.24c0,0.23,0.02,0.42,0.06,0.55
        c0.04,0.13,0.11,0.22,0.21,0.28c0.1,0.05,0.24,0.08,0.4,0.08c0.17,0,0.3-0.03,0.4-0.08c0.1-0.05,0.17-0.15,0.21-0.28
        c0.04-0.13,0.06-0.31,0.06-0.55v-2.24H272.15z"
          fill="#FFFFFF"
        />
        <path
          d="M274.26,137.49c0.29,0,0.54,0.03,0.75,0.09c0.21,0.06,0.37,0.17,0.49,0.31s0.21,0.34,0.27,0.59
        c0.06,0.25,0.09,0.56,0.09,0.94c0,0.37-0.03,0.69-0.09,0.94c-0.06,0.25-0.15,0.45-0.27,0.59s-0.29,0.25-0.49,0.31
        c-0.21,0.06-0.46,0.09-0.75,0.09s-0.55-0.03-0.75-0.09c-0.21-0.06-0.37-0.17-0.5-0.31s-0.22-0.34-0.28-0.59s-0.09-0.56-0.09-0.94
        c0-0.38,0.03-0.69,0.09-0.94c0.06-0.25,0.15-0.45,0.28-0.59s0.29-0.25,0.5-0.31C273.71,137.52,273.96,137.49,274.26,137.49z
         M274.26,138.14c-0.19,0-0.34,0.04-0.45,0.11c-0.11,0.08-0.18,0.21-0.23,0.39c-0.05,0.19-0.07,0.45-0.07,0.78
        c0,0.33,0.02,0.59,0.07,0.78c0.04,0.19,0.12,0.32,0.23,0.39c0.11,0.08,0.25,0.11,0.45,0.11c0.19,0,0.33-0.04,0.44-0.11
        c0.11-0.08,0.18-0.21,0.23-0.39c0.04-0.19,0.07-0.45,0.07-0.78c0-0.33-0.02-0.59-0.07-0.78s-0.12-0.32-0.23-0.39
        C274.59,138.18,274.44,138.14,274.26,138.14z"
          fill="#FFFFFF"
        />
        <path
          d="M280.19,137.56c0.19,0,0.3,0.1,0.3,0.3l0.14,3.42h-0.76l-0.12-3.09h-0.1l-0.6,2.32
        c-0.03,0.17-0.14,0.26-0.32,0.26h-0.52c-0.18,0-0.29-0.09-0.33-0.26l-0.6-2.32h-0.09l-0.11,3.09h-0.77l0.14-3.42
        c0.01-0.2,0.11-0.3,0.31-0.3h0.77c0.18,0,0.28,0.09,0.32,0.26l0.48,1.87c0.02,0.09,0.04,0.17,0.05,0.26
        c0.01,0.09,0.03,0.18,0.05,0.27h0.07c0.02-0.09,0.04-0.18,0.05-0.27c0.02-0.09,0.03-0.18,0.05-0.27l0.47-1.87
        c0.04-0.18,0.14-0.26,0.32-0.26H280.19z"
          fill="#FFFFFF"
        />
        <path d="M282.04,137.56v3.73h-0.82v-3.73H282.04z" fill="#FFFFFF" />
        <path d="M285.18,137.56l-2.12,4.12h-0.64l2.12-4.12H285.18z" fill="#FFFFFF" />
        <path
          d="M286.35,137.55c0.19,0,0.39,0,0.59,0c0.2,0,0.4,0.01,0.59,0.02s0.37,0.02,0.54,0.04l-0.04,0.59h-1.35
        c-0.1,0-0.17,0.02-0.22,0.07c-0.05,0.05-0.08,0.12-0.08,0.21v2.81h-0.83v-2.98c0-0.23,0.07-0.42,0.22-0.55
        C285.91,137.62,286.11,137.55,286.35,137.55z M285.64,139.12h2.18v0.58h-2.18V139.12z"
          fill="#FFFFFF"
        />
        <path d="M289.28,137.56v3.73h-0.82v-3.73H289.28z" fill="#FFFFFF" />
        <path
          d="M293.08,137.56v3.43c0,0.2-0.1,0.3-0.3,0.3h-0.45c-0.09,0-0.16-0.02-0.21-0.06
        c-0.05-0.04-0.09-0.1-0.13-0.19l-1.03-1.96c-0.04-0.08-0.09-0.17-0.13-0.28c-0.04-0.1-0.08-0.2-0.11-0.29h-0.06
        c0.01,0.1,0.02,0.2,0.03,0.29s0.01,0.2,0.01,0.29v2.18h-0.78v-3.43c0-0.2,0.1-0.3,0.3-0.3h0.45c0.09,0,0.15,0.02,0.2,0.06
        c0.04,0.04,0.09,0.1,0.13,0.19l1,1.9c0.04,0.08,0.09,0.17,0.13,0.28s0.09,0.21,0.14,0.31h0.06c-0.01-0.11-0.02-0.21-0.02-0.32
        s-0.01-0.2-0.01-0.3l-0.01-2.11H293.08z"
          fill="#FFFFFF"
        />
        <path
          d="M294.53,137.56v2.79c0,0.09,0.03,0.16,0.08,0.21c0.05,0.05,0.12,0.07,0.22,0.07h1.3l0.04,0.62
        c-0.26,0.03-0.52,0.05-0.8,0.05c-0.28,0.01-0.56,0.01-0.83,0.01c-0.27,0-0.47-0.07-0.61-0.22c-0.14-0.15-0.21-0.33-0.21-0.56
        v-2.96H294.53z"
          fill="#FFFFFF"
        />
        <path
          d="M298.28,137.56c0.07,0,0.13,0.02,0.18,0.06c0.05,0.04,0.09,0.09,0.11,0.16l1.08,3.51h-0.84l-0.76-2.74
        c-0.01-0.06-0.03-0.12-0.04-0.19c-0.01-0.06-0.03-0.12-0.04-0.18h-0.15c-0.01,0.06-0.02,0.12-0.04,0.18
        c-0.01,0.06-0.03,0.12-0.05,0.19l-0.76,2.74h-0.85l1.08-3.51c0.02-0.06,0.05-0.12,0.11-0.16s0.11-0.06,0.18-0.06H298.28z
         M298.85,139.68v0.62h-1.93v-0.62H298.85z"
          fill="#FFFFFF"
        />
        <path
          d="M303.07,137.56v3.43c0,0.2-0.1,0.3-0.3,0.3h-0.45c-0.09,0-0.16-0.02-0.21-0.06
        c-0.05-0.04-0.09-0.1-0.13-0.19l-1.03-1.96c-0.04-0.08-0.09-0.17-0.13-0.28c-0.04-0.1-0.08-0.2-0.11-0.29h-0.06
        c0.01,0.1,0.02,0.2,0.03,0.29s0.01,0.2,0.01,0.29v2.18h-0.78v-3.43c0-0.2,0.1-0.3,0.3-0.3h0.45c0.09,0,0.15,0.02,0.2,0.06
        c0.04,0.04,0.09,0.1,0.13,0.19l1,1.9c0.04,0.08,0.09,0.17,0.13,0.28s0.09,0.21,0.14,0.31h0.06c-0.01-0.11-0.02-0.21-0.02-0.32
        s-0.01-0.2-0.01-0.3l-0.01-2.11H303.07z"
          fill="#FFFFFF"
        />
        <path
          d="M305.17,137.51c0.29,0,0.54,0.03,0.75,0.09c0.2,0.06,0.37,0.17,0.49,0.31c0.12,0.14,0.21,0.34,0.27,0.59
        c0.06,0.25,0.09,0.56,0.09,0.93c0,0.37-0.03,0.68-0.09,0.93c-0.06,0.25-0.15,0.44-0.27,0.59c-0.12,0.14-0.29,0.25-0.49,0.31
        c-0.2,0.06-0.45,0.09-0.75,0.09c-0.28,0-0.55,0-0.78-0.02s-0.46-0.02-0.68-0.04l0.35-0.61c0.14,0.01,0.3,0.01,0.49,0.02
        c0.19,0,0.4,0.01,0.63,0.01c0.18,0,0.33-0.04,0.43-0.11c0.11-0.07,0.18-0.21,0.23-0.39c0.04-0.19,0.07-0.45,0.07-0.78
        c0-0.33-0.02-0.59-0.07-0.78c-0.04-0.19-0.12-0.32-0.23-0.39c-0.11-0.08-0.25-0.11-0.43-0.11c-0.22,0-0.42,0-0.59,0
        c-0.17,0-0.34,0-0.51,0l-0.37-0.58c0.22-0.01,0.44-0.03,0.68-0.04C304.62,137.51,304.88,137.51,305.17,137.51z M304.53,137.56
        v3.73h-0.83v-3.73H304.53z"
          fill="#FFFFFF"
        />
        <path
          d="M267.55,143.51c0.35,0,0.62,0.04,0.82,0.11c0.2,0.07,0.34,0.2,0.42,0.37s0.13,0.42,0.13,0.74
        c0,0.32-0.04,0.56-0.12,0.74c-0.08,0.18-0.21,0.3-0.4,0.37c-0.18,0.07-0.43,0.1-0.75,0.1c-0.17,0-0.33-0.01-0.49-0.02
        c-0.16-0.01-0.31-0.03-0.44-0.05s-0.25-0.04-0.35-0.07c-0.1-0.02-0.16-0.05-0.2-0.07l0.02-0.41c0.21,0,0.43,0,0.66,0
        s0.45,0,0.68,0c0.14,0,0.26-0.02,0.34-0.05s0.14-0.09,0.17-0.18c0.03-0.09,0.05-0.21,0.05-0.36c0-0.16-0.02-0.28-0.05-0.37
        c-0.04-0.09-0.09-0.15-0.17-0.18s-0.19-0.05-0.34-0.05c-0.33,0-0.62,0-0.85,0c-0.24,0-0.4,0.01-0.48,0.02l-0.08-0.59
        c0.15-0.02,0.29-0.03,0.43-0.04c0.13-0.01,0.28-0.01,0.43-0.01C267.14,143.51,267.33,143.51,267.55,143.51z M266.94,143.56v3.73
        h-0.83v-3.73H266.94z"
          fill="#FFFFFF"
        />
        <path
          d="M272.22,143.56v2.24c0,0.38-0.05,0.68-0.14,0.91c-0.09,0.23-0.25,0.39-0.46,0.5s-0.5,0.15-0.86,0.15
        c-0.38,0-0.68-0.05-0.91-0.15s-0.39-0.27-0.49-0.5c-0.1-0.23-0.15-0.53-0.15-0.91v-2.24h0.83v2.24c0,0.23,0.02,0.42,0.06,0.55
        c0.04,0.13,0.11,0.22,0.21,0.28c0.1,0.05,0.24,0.08,0.4,0.08c0.17,0,0.3-0.03,0.4-0.08c0.1-0.05,0.17-0.15,0.21-0.28
        c0.04-0.13,0.06-0.31,0.06-0.55v-2.24H272.22z"
          fill="#FFFFFF"
        />
        <path
          d="M273.56,143.56v3.73h-0.83v-3.73H273.56z M274.94,144.99v0.62h-1.42v-0.62H274.94z M275.71,143.56v3.73
        h-0.83v-3.73H275.71z"
          fill="#FFFFFF"
        />
        <path d="M278.74,143.56l-2.12,4.12h-0.64l2.12-4.12H278.74z" fill="#FFFFFF" />
        <path d="M281.8,143.56v0.65h-3.07v-0.65H281.8z M280.67,143.56v3.73h-0.83v-3.73H280.67z" fill="#FFFFFF" />
        <path
          d="M282.81,143.55c0.2,0,0.4,0,0.61,0s0.41,0.01,0.61,0.01c0.2,0.01,0.38,0.02,0.56,0.04l-0.04,0.6h-1.43
        c-0.09,0-0.16,0.02-0.2,0.07c-0.04,0.05-0.07,0.12-0.07,0.21v1.9c0,0.09,0.02,0.16,0.07,0.21s0.11,0.07,0.2,0.07h1.43l0.04,0.6
        c-0.17,0.01-0.36,0.03-0.56,0.04c-0.2,0.01-0.4,0.01-0.61,0.02s-0.41,0-0.61,0c-0.24,0-0.43-0.07-0.58-0.21
        c-0.15-0.14-0.22-0.32-0.22-0.54v-2.26c0-0.23,0.07-0.41,0.22-0.54C282.38,143.61,282.58,143.55,282.81,143.55z M282.12,145.06
        h2.23v0.58h-2.23V145.06z"
          fill="#FFFFFF"
        />
        <path
          d="M285.77,143.56v2.79c0,0.09,0.03,0.16,0.08,0.21c0.05,0.05,0.12,0.07,0.22,0.07h1.3l0.04,0.62
        c-0.26,0.03-0.52,0.05-0.8,0.05c-0.28,0.01-0.56,0.01-0.83,0.01c-0.27,0-0.47-0.07-0.61-0.22c-0.14-0.15-0.21-0.33-0.21-0.56
        v-2.96H285.77z"
          fill="#FFFFFF"
        />
        <path
          d="M288.02,144.85c0.1,0,0.18,0.02,0.22,0.06c0.04,0.04,0.06,0.11,0.06,0.22v0.26c0,0.1-0.02,0.18-0.06,0.22
        c-0.04,0.04-0.11,0.06-0.22,0.06h-0.21c-0.1,0-0.18-0.02-0.22-0.06c-0.04-0.04-0.06-0.11-0.06-0.22v-0.26
        c0-0.1,0.02-0.18,0.06-0.22c0.04-0.04,0.11-0.06,0.22-0.06H288.02z M288.02,146.46c0.1,0,0.18,0.02,0.22,0.06
        c0.04,0.04,0.06,0.11,0.06,0.22V147c0,0.1-0.02,0.18-0.06,0.22s-0.11,0.06-0.22,0.06h-0.21c-0.1,0-0.18-0.02-0.22-0.06
        s-0.06-0.11-0.06-0.22v-0.26c0-0.1,0.02-0.18,0.06-0.22c0.04-0.04,0.11-0.06,0.22-0.06H288.02z"
          fill="#FFFFFF"
        />
        <path d="M268.42,151.37v0.59h-2.46v-0.59H268.42z M267.49,150.46v2.4h-0.59l0-2.4H267.49z" fill="#FFFFFF" />
        <path
          d="M269.9,149.49c0.29,0,0.53,0.03,0.71,0.08c0.18,0.05,0.31,0.14,0.39,0.27s0.12,0.3,0.12,0.52
        c0,0.17-0.02,0.33-0.05,0.46c-0.03,0.13-0.09,0.24-0.17,0.32c-0.08,0.08-0.2,0.14-0.36,0.16v0.02c0.25,0.04,0.43,0.14,0.53,0.29
        c0.1,0.15,0.15,0.37,0.15,0.64c0,0.26-0.04,0.47-0.12,0.63s-0.22,0.28-0.41,0.36c-0.19,0.08-0.44,0.12-0.76,0.12
        c-0.22,0-0.43-0.01-0.65-0.03c-0.22-0.02-0.43-0.04-0.64-0.07l0.03-0.6c0.14,0.01,0.27,0.02,0.39,0.02s0.22,0.01,0.33,0.01
        s0.21,0,0.32,0c0.18,0,0.32-0.01,0.42-0.05c0.1-0.03,0.17-0.09,0.22-0.17s0.07-0.2,0.07-0.34c0-0.12-0.02-0.21-0.05-0.29
        c-0.03-0.08-0.09-0.14-0.17-0.18c-0.08-0.04-0.2-0.06-0.36-0.06l-0.71,0v-0.54h0.71c0.12,0,0.21-0.01,0.28-0.04
        c0.07-0.03,0.12-0.06,0.15-0.11s0.06-0.1,0.07-0.16c0.01-0.06,0.02-0.13,0.02-0.2c0-0.12-0.02-0.21-0.06-0.27
        c-0.03-0.06-0.09-0.1-0.18-0.12c-0.08-0.02-0.2-0.03-0.35-0.03c-0.19,0-0.37,0-0.54,0.01c-0.17,0-0.33,0.01-0.46,0.01l-0.04-0.58
        c0.2-0.04,0.4-0.06,0.6-0.08C269.51,149.49,269.7,149.49,269.9,149.49z"
          fill="#FFFFFF"
        />
        <path
          d="M273.92,149.56l-0.03,0.62h-1.55l-0.04,0.7l0.55,0.06c0.23,0.02,0.42,0.06,0.57,0.13
        c0.15,0.07,0.27,0.15,0.36,0.25s0.16,0.22,0.19,0.36c0.04,0.14,0.06,0.29,0.06,0.46c0,0.16-0.02,0.32-0.06,0.46
        c-0.04,0.15-0.11,0.27-0.2,0.39c-0.1,0.11-0.23,0.2-0.4,0.26c-0.17,0.06-0.39,0.09-0.65,0.09c-0.17,0-0.37-0.01-0.59-0.03
        s-0.44-0.05-0.66-0.08l0.08-0.63c0.18,0.02,0.35,0.03,0.52,0.05c0.17,0.01,0.32,0.02,0.45,0.02c0.19,0,0.33-0.02,0.43-0.06
        c0.1-0.04,0.17-0.1,0.2-0.18c0.04-0.08,0.06-0.18,0.07-0.31c0-0.12-0.01-0.21-0.04-0.28s-0.08-0.12-0.15-0.17
        c-0.07-0.04-0.16-0.07-0.28-0.08c-0.11-0.02-0.25-0.03-0.42-0.04l-0.46-0.05c-0.08-0.01-0.14-0.04-0.18-0.09
        c-0.05-0.05-0.07-0.12-0.06-0.19l0.09-1.65H273.92z"
          fill="#FFFFFF"
        />
        <path
          d="M275.72,149.5c0.27,0,0.49,0.02,0.66,0.06c0.17,0.04,0.3,0.1,0.4,0.18c0.09,0.08,0.16,0.18,0.2,0.31
        c0.04,0.13,0.05,0.28,0.05,0.45c0,0.14-0.02,0.28-0.06,0.4s-0.1,0.23-0.18,0.31c-0.08,0.08-0.19,0.13-0.32,0.14v0.02
        c0.17,0.01,0.31,0.07,0.4,0.16c0.09,0.09,0.16,0.21,0.19,0.35c0.04,0.14,0.05,0.3,0.05,0.48c0,0.24-0.04,0.43-0.11,0.58
        c-0.08,0.15-0.21,0.25-0.42,0.32c-0.2,0.06-0.49,0.1-0.87,0.1c-0.37,0-0.66-0.03-0.86-0.1c-0.2-0.07-0.34-0.17-0.42-0.32
        s-0.12-0.34-0.12-0.58c0-0.18,0.02-0.34,0.05-0.48s0.1-0.26,0.19-0.35s0.23-0.14,0.4-0.16v-0.02c-0.13-0.01-0.24-0.06-0.32-0.14
        s-0.14-0.18-0.18-0.31c-0.04-0.12-0.06-0.26-0.06-0.4c0-0.17,0.02-0.32,0.06-0.45c0.04-0.13,0.1-0.23,0.2-0.31s0.23-0.14,0.4-0.18
        C275.24,149.52,275.46,149.5,275.72,149.5z M275.73,151.63c-0.13,0-0.24,0.01-0.32,0.02c-0.08,0.02-0.15,0.04-0.19,0.09
        s-0.08,0.1-0.1,0.17c-0.02,0.07-0.03,0.17-0.03,0.29c0,0.14,0.02,0.24,0.05,0.32c0.03,0.08,0.1,0.13,0.19,0.17
        s0.22,0.05,0.39,0.05c0.17,0,0.29-0.02,0.38-0.05c0.09-0.03,0.15-0.08,0.18-0.17c0.03-0.08,0.05-0.19,0.05-0.32
        c0-0.12-0.01-0.21-0.03-0.29c-0.02-0.07-0.05-0.13-0.09-0.17c-0.04-0.04-0.1-0.07-0.18-0.09
        C275.96,151.64,275.86,151.63,275.73,151.63z M275.73,150.07c-0.16,0-0.27,0.01-0.35,0.04c-0.08,0.03-0.14,0.08-0.17,0.16
        c-0.03,0.08-0.05,0.18-0.05,0.32c0,0.13,0.02,0.24,0.05,0.31c0.03,0.07,0.09,0.12,0.18,0.14c0.08,0.03,0.2,0.04,0.36,0.04
        c0.15,0,0.27-0.01,0.35-0.04c0.08-0.03,0.13-0.08,0.16-0.15s0.04-0.17,0.04-0.3c0-0.14-0.01-0.25-0.04-0.32
        c-0.03-0.08-0.08-0.13-0.16-0.16C276,150.08,275.88,150.07,275.73,150.07z"
          fill="#FFFFFF"
        />
        <path
          d="M279.92,149.02l0.09,0.35c-0.32,0.14-0.55,0.41-0.68,0.79s-0.2,0.86-0.2,1.44c0,0.38,0.03,0.72,0.09,1.02
        s0.15,0.55,0.28,0.75c0.13,0.2,0.3,0.36,0.51,0.46l-0.09,0.35c-0.54-0.17-0.94-0.47-1.19-0.88c-0.25-0.42-0.38-0.98-0.38-1.69
        c0-0.72,0.13-1.28,0.38-1.7C278.98,149.48,279.38,149.19,279.92,149.02z"
          fill="#FFFFFF"
        />
        <path
          d="M281.6,149.49c0.36,0,0.64,0.06,0.85,0.19c0.21,0.13,0.36,0.33,0.45,0.61c0.09,0.28,0.14,0.66,0.14,1.14
        c0,0.47-0.05,0.85-0.14,1.14c-0.09,0.28-0.24,0.49-0.45,0.61c-0.21,0.13-0.49,0.19-0.85,0.19c-0.36,0-0.64-0.06-0.85-0.19
        c-0.21-0.13-0.36-0.33-0.45-0.61c-0.09-0.28-0.13-0.66-0.13-1.14c0-0.48,0.04-0.85,0.13-1.14c0.09-0.28,0.24-0.49,0.45-0.61
        C280.96,149.55,281.24,149.49,281.6,149.49z M281.59,150.13c-0.12,0-0.22,0.02-0.3,0.06s-0.14,0.11-0.19,0.2
        c-0.05,0.09-0.08,0.22-0.1,0.39c-0.02,0.17-0.03,0.38-0.03,0.64c0,0.25,0.01,0.47,0.03,0.64s0.05,0.3,0.1,0.39
        c0.05,0.09,0.11,0.16,0.19,0.2c0.08,0.04,0.18,0.06,0.3,0.06c0.12,0,0.22-0.02,0.3-0.06c0.08-0.04,0.15-0.11,0.19-0.2
        c0.05-0.09,0.08-0.23,0.1-0.39c0.02-0.17,0.03-0.38,0.03-0.64c0-0.26-0.01-0.47-0.03-0.64c-0.02-0.17-0.05-0.3-0.1-0.39
        c-0.05-0.09-0.11-0.16-0.19-0.2S281.71,150.13,281.59,150.13z"
          fill="#FFFFFF"
        />
        <path
          d="M283.23,149.02c0.54,0.17,0.94,0.46,1.19,0.88c0.25,0.42,0.38,0.99,0.38,1.7c0,0.71-0.13,1.28-0.38,1.69
        c-0.25,0.42-0.65,0.71-1.19,0.88l-0.09-0.35c0.21-0.1,0.38-0.26,0.51-0.46c0.13-0.2,0.22-0.45,0.28-0.75
        c0.06-0.3,0.09-0.64,0.09-1.02c0-0.58-0.07-1.06-0.2-1.44c-0.13-0.38-0.36-0.64-0.68-0.79L283.23,149.02z"
          fill="#FFFFFF"
        />
        <path
          d="M287.28,149.5c0.26,0,0.48,0.02,0.67,0.07c0.19,0.04,0.33,0.13,0.44,0.26c0.1,0.13,0.16,0.31,0.16,0.54
        c0,0.14-0.02,0.27-0.05,0.39c-0.03,0.12-0.09,0.25-0.18,0.38c-0.09,0.13-0.21,0.28-0.38,0.46l-0.98,1.01
        c0.09-0.01,0.18-0.02,0.27-0.03c0.09,0,0.18-0.01,0.28-0.01h1.15v0.72h-2.67v-0.44c0-0.07,0.01-0.12,0.03-0.18
        c0.02-0.06,0.05-0.11,0.09-0.15l0.93-0.99c0.22-0.23,0.39-0.43,0.5-0.61c0.12-0.18,0.17-0.33,0.17-0.46
        c0-0.08-0.02-0.14-0.06-0.17c-0.04-0.04-0.09-0.06-0.17-0.07c-0.08-0.01-0.17-0.02-0.28-0.02c-0.13,0-0.24,0-0.35,0
        s-0.23,0.01-0.35,0.01c-0.12,0.01-0.27,0.02-0.43,0.03l-0.03-0.62c0.18-0.04,0.34-0.07,0.48-0.09c0.14-0.02,0.28-0.03,0.4-0.04
        C287.02,149.5,287.15,149.5,287.28,149.5z"
          fill="#FFFFFF"
        />
        <path
          d="M290.21,149.5c0.29,0,0.52,0.03,0.7,0.09c0.18,0.06,0.32,0.16,0.41,0.3c0.1,0.14,0.16,0.33,0.2,0.58
        c0.04,0.24,0.05,0.55,0.05,0.93c0,0.42-0.03,0.76-0.09,1.02c-0.06,0.26-0.15,0.46-0.27,0.59c-0.12,0.14-0.28,0.23-0.48,0.28
        c-0.2,0.05-0.44,0.07-0.73,0.07c-0.1,0-0.23,0-0.4-0.01c-0.17-0.01-0.36-0.04-0.55-0.09l0.04-0.52c0.1,0,0.2,0.01,0.28,0.01
        c0.08,0,0.17,0,0.27,0c0.1,0,0.22,0,0.37,0c0.16,0,0.29-0.02,0.39-0.06c0.1-0.04,0.18-0.11,0.23-0.21s0.09-0.25,0.11-0.43
        c0.02-0.18,0.03-0.41,0.03-0.69c0-0.25,0-0.46-0.01-0.62s-0.03-0.29-0.07-0.38s-0.09-0.15-0.17-0.19
        c-0.08-0.03-0.18-0.05-0.32-0.05c-0.16,0-0.28,0.02-0.35,0.08c-0.08,0.05-0.13,0.13-0.15,0.23c-0.02,0.11-0.04,0.24-0.04,0.4
        c0,0.14,0.01,0.26,0.04,0.35c0.03,0.09,0.08,0.16,0.16,0.2c0.08,0.04,0.18,0.06,0.32,0.06c0.1,0,0.19-0.01,0.27-0.04
        c0.08-0.03,0.17-0.07,0.26-0.13c0.09-0.06,0.21-0.14,0.35-0.25l0.02,0.31c-0.12,0.18-0.23,0.32-0.34,0.42
        c-0.11,0.1-0.23,0.17-0.35,0.21c-0.13,0.04-0.28,0.06-0.46,0.06c-0.27,0-0.48-0.05-0.63-0.15c-0.15-0.1-0.26-0.24-0.32-0.41
        c-0.06-0.18-0.09-0.38-0.09-0.61c0-0.31,0.04-0.56,0.12-0.76c0.08-0.2,0.21-0.34,0.4-0.44C289.6,149.54,289.87,149.5,290.21,149.5
        z"
          fill="#FFFFFF"
        />
        <path
          d="M293.34,149.49c0.36,0,0.64,0.06,0.85,0.19c0.21,0.13,0.36,0.33,0.45,0.61c0.09,0.28,0.14,0.66,0.14,1.14
        c0,0.47-0.05,0.85-0.14,1.14c-0.09,0.28-0.24,0.49-0.45,0.61c-0.21,0.13-0.49,0.19-0.85,0.19c-0.36,0-0.64-0.06-0.85-0.19
        c-0.21-0.13-0.36-0.33-0.45-0.61c-0.09-0.28-0.13-0.66-0.13-1.14c0-0.48,0.04-0.85,0.13-1.14c0.09-0.28,0.24-0.49,0.45-0.61
        C292.7,149.55,292.98,149.49,293.34,149.49z M293.33,150.13c-0.12,0-0.22,0.02-0.3,0.06s-0.14,0.11-0.19,0.2
        c-0.05,0.09-0.08,0.22-0.1,0.39c-0.02,0.17-0.03,0.38-0.03,0.64c0,0.25,0.01,0.47,0.03,0.64s0.05,0.3,0.1,0.39
        c0.05,0.09,0.11,0.16,0.19,0.2c0.08,0.04,0.18,0.06,0.3,0.06c0.12,0,0.22-0.02,0.3-0.06c0.08-0.04,0.15-0.11,0.19-0.2
        c0.05-0.09,0.08-0.23,0.1-0.39c0.02-0.17,0.03-0.38,0.03-0.64c0-0.26-0.01-0.47-0.03-0.64c-0.02-0.17-0.05-0.3-0.1-0.39
        c-0.05-0.09-0.11-0.16-0.19-0.2S293.45,150.13,293.33,150.13z"
          fill="#FFFFFF"
        />
        <path
          d="M297.48,149.49c0.36,0,0.64,0.06,0.85,0.19c0.21,0.13,0.36,0.33,0.45,0.61c0.09,0.28,0.14,0.66,0.14,1.14
        c0,0.47-0.05,0.85-0.14,1.14c-0.09,0.28-0.24,0.49-0.45,0.61c-0.21,0.13-0.49,0.19-0.85,0.19c-0.36,0-0.64-0.06-0.85-0.19
        c-0.21-0.13-0.36-0.33-0.45-0.61c-0.09-0.28-0.13-0.66-0.13-1.14c0-0.48,0.04-0.85,0.13-1.14c0.09-0.28,0.24-0.49,0.45-0.61
        C296.84,149.55,297.12,149.49,297.48,149.49z M297.47,150.13c-0.12,0-0.22,0.02-0.3,0.06s-0.14,0.11-0.19,0.2
        c-0.05,0.09-0.08,0.22-0.1,0.39c-0.02,0.17-0.03,0.38-0.03,0.64c0,0.25,0.01,0.47,0.03,0.64s0.05,0.3,0.1,0.39
        c0.05,0.09,0.11,0.16,0.19,0.2c0.08,0.04,0.18,0.06,0.3,0.06c0.12,0,0.22-0.02,0.3-0.06c0.08-0.04,0.15-0.11,0.19-0.2
        c0.05-0.09,0.08-0.23,0.1-0.39c0.02-0.17,0.03-0.38,0.03-0.64c0-0.26-0.01-0.47-0.03-0.64c-0.02-0.17-0.05-0.3-0.1-0.39
        c-0.05-0.09-0.11-0.16-0.19-0.2S297.59,150.13,297.47,150.13z"
          fill="#FFFFFF"
        />
        <path
          d="M300.67,149.49c0.36,0,0.64,0.06,0.85,0.19c0.21,0.13,0.36,0.33,0.45,0.61c0.09,0.28,0.14,0.66,0.14,1.14
        c0,0.47-0.05,0.85-0.14,1.14c-0.09,0.28-0.24,0.49-0.45,0.61c-0.21,0.13-0.49,0.19-0.85,0.19c-0.36,0-0.64-0.06-0.85-0.19
        c-0.21-0.13-0.36-0.33-0.45-0.61c-0.09-0.28-0.13-0.66-0.13-1.14c0-0.48,0.04-0.85,0.13-1.14c0.09-0.28,0.24-0.49,0.45-0.61
        C300.03,149.55,300.31,149.49,300.67,149.49z M300.66,150.13c-0.12,0-0.22,0.02-0.3,0.06s-0.14,0.11-0.19,0.2
        c-0.05,0.09-0.08,0.22-0.1,0.39c-0.02,0.17-0.03,0.38-0.03,0.64c0,0.25,0.01,0.47,0.03,0.64s0.05,0.3,0.1,0.39
        c0.05,0.09,0.11,0.16,0.19,0.2c0.08,0.04,0.18,0.06,0.3,0.06c0.12,0,0.22-0.02,0.3-0.06c0.08-0.04,0.15-0.11,0.19-0.2
        c0.05-0.09,0.08-0.23,0.1-0.39c0.02-0.17,0.03-0.38,0.03-0.64c0-0.26-0.01-0.47-0.03-0.64c-0.02-0.17-0.05-0.3-0.1-0.39
        c-0.05-0.09-0.11-0.16-0.19-0.2S300.78,150.13,300.66,150.13z"
          fill="#FFFFFF"
        />
        <path
          d="M303.99,149.56v3.73h-0.82v-2.64c0-0.07,0-0.14,0.01-0.2s0.01-0.13,0.03-0.2l-0.89,0.23l-0.1-0.53
        l0.95-0.37H303.99z"
          fill="#FFFFFF"
        />
        <path
          d="M305.98,149.49c0.36,0,0.64,0.06,0.85,0.19c0.21,0.13,0.36,0.33,0.45,0.61c0.09,0.28,0.14,0.66,0.14,1.14
        c0,0.47-0.05,0.85-0.14,1.14c-0.09,0.28-0.24,0.49-0.45,0.61c-0.21,0.13-0.49,0.19-0.85,0.19c-0.36,0-0.64-0.06-0.85-0.19
        c-0.21-0.13-0.36-0.33-0.45-0.61c-0.09-0.28-0.13-0.66-0.13-1.14c0-0.48,0.04-0.85,0.13-1.14c0.09-0.28,0.24-0.49,0.45-0.61
        C305.34,149.55,305.62,149.49,305.98,149.49z M305.97,150.13c-0.12,0-0.22,0.02-0.3,0.06s-0.14,0.11-0.19,0.2
        c-0.05,0.09-0.08,0.22-0.1,0.39c-0.02,0.17-0.03,0.38-0.03,0.64c0,0.25,0.01,0.47,0.03,0.64s0.05,0.3,0.1,0.39
        c0.05,0.09,0.11,0.16,0.19,0.2c0.08,0.04,0.18,0.06,0.3,0.06c0.12,0,0.22-0.02,0.3-0.06c0.08-0.04,0.15-0.11,0.19-0.2
        c0.05-0.09,0.08-0.23,0.1-0.39c0.02-0.17,0.03-0.38,0.03-0.64c0-0.26-0.01-0.47-0.03-0.64c-0.02-0.17-0.05-0.3-0.1-0.39
        c-0.05-0.09-0.11-0.16-0.19-0.2S306.09,150.13,305.97,150.13z"
          fill="#FFFFFF"
        />
        <path
          d="M310.11,149.56l-0.03,0.62h-1.55l-0.04,0.7l0.55,0.06c0.23,0.02,0.42,0.06,0.57,0.13
        c0.15,0.07,0.27,0.15,0.36,0.25s0.16,0.22,0.19,0.36c0.04,0.14,0.06,0.29,0.06,0.46c0,0.16-0.02,0.32-0.06,0.46
        c-0.04,0.15-0.11,0.27-0.2,0.39c-0.1,0.11-0.23,0.2-0.4,0.26c-0.17,0.06-0.39,0.09-0.65,0.09c-0.17,0-0.37-0.01-0.59-0.03
        s-0.44-0.05-0.66-0.08l0.08-0.63c0.18,0.02,0.35,0.03,0.52,0.05c0.17,0.01,0.32,0.02,0.45,0.02c0.19,0,0.33-0.02,0.43-0.06
        c0.1-0.04,0.17-0.1,0.2-0.18c0.04-0.08,0.06-0.18,0.07-0.31c0-0.12-0.01-0.21-0.04-0.28s-0.08-0.12-0.15-0.17
        c-0.07-0.04-0.16-0.07-0.28-0.08c-0.11-0.02-0.25-0.03-0.42-0.04l-0.46-0.05c-0.08-0.01-0.14-0.04-0.18-0.09
        c-0.05-0.05-0.07-0.12-0.06-0.19l0.09-1.65H310.11z"
          fill="#FFFFFF"
        />
        <path
          d="M311.93,149.49c0.36,0,0.64,0.06,0.85,0.19c0.21,0.13,0.36,0.33,0.45,0.61c0.09,0.28,0.14,0.66,0.14,1.14
        c0,0.47-0.05,0.85-0.14,1.14c-0.09,0.28-0.24,0.49-0.45,0.61c-0.21,0.13-0.49,0.19-0.85,0.19c-0.36,0-0.64-0.06-0.85-0.19
        c-0.21-0.13-0.36-0.33-0.45-0.61c-0.09-0.28-0.13-0.66-0.13-1.14c0-0.48,0.04-0.85,0.13-1.14c0.09-0.28,0.24-0.49,0.45-0.61
        C311.29,149.55,311.57,149.49,311.93,149.49z M311.92,150.13c-0.12,0-0.22,0.02-0.3,0.06s-0.14,0.11-0.19,0.2
        c-0.05,0.09-0.08,0.22-0.1,0.39c-0.02,0.17-0.03,0.38-0.03,0.64c0,0.25,0.01,0.47,0.03,0.64s0.05,0.3,0.1,0.39
        c0.05,0.09,0.11,0.16,0.19,0.2c0.08,0.04,0.18,0.06,0.3,0.06c0.12,0,0.22-0.02,0.3-0.06c0.08-0.04,0.15-0.11,0.19-0.2
        c0.05-0.09,0.08-0.23,0.1-0.39c0.02-0.17,0.03-0.38,0.03-0.64c0-0.26-0.01-0.47-0.03-0.64c-0.02-0.17-0.05-0.3-0.1-0.39
        c-0.05-0.09-0.11-0.16-0.19-0.2S312.05,150.13,311.92,150.13z"
          fill="#FFFFFF"
        />
      </g>
    </g>
    <g>
      <rect fill="#FFFFFF" height="109.47" width="76.23" x="323.33" y="100.15" />
      <g id="barcode_16_">
        <defs>
          <rect height="105.8" id="SVGID_1_" width="77.02" x="322.38" y="99.32" />
        </defs>
        <clipPath id="SVGID_2_">
          <use xlink:href="#SVGID_1_" />
        </clipPath>
        <g clip-path="url(#SVGID_2_)" transform="matrix(1 3.469447e-18 0 1 0 0)">
          <image
            height="1295"
            id="_x36_419800023036_1_"
            transform="matrix(2.633640e-12 -0.0588 0.0595 2.675640e-12 322.3785 205.1233)"
            width="1800"
            xlink:href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAABwgAAAUPCAYAAACiCbQXAAAACXBIWXMAAAsSAAALEgHS3X78AAAA
  GXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAPQBJREFUeNrs2cENgCAQAEGx/57P
  Gnwp7EwDGgS5ZK8LAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA
  AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA
  AAC2sSwBL8zHe2x+sMcnfM7mg2cu7+8bHnJ+Z8Mzf9KMMPauO8j84O4xf2bmf3eo+cf7u7+snf+n
  +9f5221+O21+tX98PzZxWwIAAAAAAADoEAgBAAAAAAAgRCAEAAAAAACAEIEQAAAAAAAAQgRCAAAA
  AAAACBEIAQAAAAAAIEQgBAAAAAAAgBCBEAAAAAAAAEIEQgAAAAAAAAgRCAEAAAAAACBEIAQAAAAA
  AIAQgRAAAAAAAABCBEIAAAAAAAAIEQgBAAAAAAAgRCAEAAAAAACAEIEQAAAAAAAAQgRCAAAAAAAA
  CBEIAQAAAAAAIEQgBAAAAAAAgBCBEAAAAAAAAEIEQgAAAAAAAAgRCAEAAAAAACBEIAQAAAAAAIAQ
  gRAAAAAAAABCBEIAAAAAAAAIEQgBAAAAAAAgRCAEAAAAAACAEIEQAAAAAAAAQgRCAAAAAAAACBEI
  AQAAAAAAIEQgBAAAAAAAgBCBEAAAAAAAAEIEQgAAAAAAAAgRCAEAAAAAACBEIAQAAAAAAIAQgRAA
  AAAAAABCBEIAAAAAAAAIEQgBAAAAAAAgRCAEAAAAAACAEIEQAAAAAAAAQgRCAAAAAAAACBEIAQAA
  AAAAIEQgBAAAAAAAgBCBEAAAAAAAAEIEQgAAAAAAAAgRCAEAAAAAACBEIAQAAAAAAIAQgRAAAAAA
  AABCBEIAAAAAAAAIEQgBAAAAAAAgRCAEAAAAAACAEIEQAAAAAAAAQgRCAAAAAAAACBEIAQAAAAAA
  IEQgBAAAAAAAgBCBEAAAAAAAAEIEQgAAAAAAAAgRCAEAAAAAACBEIAQAAAAAAIAQgRAAAAAAAABC
  BEIAAAAAAAAIEQgBAAAAAAAgRCAEAAAAAACAEIEQAAAAAAAAQgRCAAAAAAAACBEIAQAAAAAAIEQg
  BAAAAAAAgBCBEAAAAAAAAEIEQgAAAAAAAAgRCAEAAAAAACBEIAQAAAAAAIAQgRAAAAAAAABCBEIA
  AAAAAAAIEQgBAAAAAAAgRCAEAAAAAACAEIEQAAAAAAAAQgRCAAAAAAAACBEIAQAAAAAAIEQgBAAA
  AAAAgBCBEAAAAAAAAEIEQgAAAAAAAAgRCAEAAAAAACBEIAQAAAAAAIAQgRAAAAAAAABCBEIAAAAA
  AAAIEQgBAAAAAAAgRCAEAAAAAACAEIEQAAAAAAAAQgRCAAAAAAAACBEIAQAAAAAAIEQgBAAAAAAA
  gBCBEAAAAAAAAEIEQgAAAAAAAAgRCAEAAAAAACBEIAQAAAAAAIAQgRAAAAAAAABCBEIAAAAAAAAI
  EQgBAAAAAAAgRCAEAAAAAACAEIEQAAAAAAAAQgRCAAAAAAAACBEIAQAAAAAAIEQgBAAAAAAAgBCB
  EAAAAAAAAEIEQgAAAAAAAAgRCAEAAAAAACBEIAQAAAAAAIAQgRAAAAAAAABCBEIAAAAAAAAIEQgB
  AAAAAAAgRCAEAAAAAACAEIEQAAAAAAAAQgRCAAAAAAAACBEIAQAAAAAAIEQgBAAAAAAAgBCBEAAA
  AAAAAEIEQgAAAAAAAAgRCAEAAAAAACBEIAQAAAAAAIAQgRAAAAAAAABCBEIAAAAAAAAIEQgBAAAA
  AAAgRCAEAAAAAACAEIEQAAAAAAAAQgRCAAAAAAAACBEIAQAAAAAAIEQgBAAAAAAAgBCBEAAAAAAA
  AEIEQgAAAAAAAAgRCAEAAAAAACBEIAQAAAAAAIAQgRAAAAAAAABCBEIAAAAAAAAIEQgBAAAAAAAg
  RCAEAAAAAACAEIEQAAAAAAAAQgRCAAAAAAAACBEIAQAAAAAAIEQgBAAAAAAAgBCBEAAAAAAAAEIE
  QgAAAAAAAAgRCAEAAAAAACBEIAQAAAAAAIAQgRAAAAAAAABCBEIAAAAAAAAIEQgBAAAAAAAgRCAE
  AAAAAACAEIEQAAAAAAAAQgRCAAAAAAAACBEIAQAAAAAAIEQgBAAAAAAAgBCBEAAAAAAAAEIEQgAA
  AAAAAAgRCAEAAAAAACBEIAQAAAAAAIAQgRAAAAAAAABCBEIAAAAAAAAIEQgBAAAAAAAgRCAEAAAA
  AACAEIEQAAAAAAAAQgRCAAAAAAAACBEIAQAAAAAAIEQgBAAAAAAAgBCBEAAAAAAAAEIEQgAAAAAA
  AAgRCAEAAAAAACBEIAQAAAAAAIAQgRAAAAAAAABCBEIAAAAAAAAIEQgBAAAAAAAgRCAEAAAAAACA
  EIEQAAAAAAAAQgRCAAAAAAAACBEIAQAAAAAAIEQgBAAAAAAAgBCBEAAAAAAAAEIEQgAAAAAAAAgR
  CAEAAAAAACBEIAQAAAAAAIAQgRAAAAAAAABCBEIAAAAAAAAIEQgBAAAAAAAgRCAEAAAAAACAEIEQ
  AAAAAAAAQgRCAAAAAAAACBEIAQAAAAAAIEQgBAAAAAAAgBCBEAAAAAAAAEIEQgAAAAAAAAgRCAEA
  AAAAACBEIAQAAAAAAIAQgRAAAAAAAABCBEIAAAAAAAAIEQgBAAAAAAAgRCAEAAAAAACAEIEQAAAA
  AAAAQgRCAAAAAAAACBEIAQAAAAAAIEQgBAAAAAAAgBCBEAAAAAAAAEIEQgAAAAAAAAgRCAEAAAAA
  ACBEIAQAAAAAAIAQgRAAAAAAAABCBEIAAAAAAAAIEQgBAAAAAAAgRCAEAAAAAACAEIEQAAAAAAAA
  QgRCAAAAAAAACBEIAQAAAAAAIEQgBAAAAAAAgBCBEAAAAAAAAEIEQgAAAAAAAAgRCAEAAAAAACBE
  IAQAAAAAAIAQgRAAAAAAAABCBEIAAAAAAAAIEQgBAAAAAAAgRCAEAAAAAACAEIEQAAAAAAAAQgRC
  AAAAAAAACBEIAQAAAAAAIEQgBAAAAAAAgBCBEAAAAAAAAEIEQgAAAAAAAAgRCAEAAAAAACBEIAQA
  AAAAAIAQgRAAAAAAAABCBEIAAAAAAAAIEQgBAAAAAAAgRCAEAAAAAACAEIEQAAAAAAAAQgRCAAAA
  AAAACBEIAQAAAAAAIEQgBAAAAAAAgBCBEAAAAAAAAEIEQgAAAAAAAAgRCAEAAAAAACBEIAQAAAAA
  AIAQgRAAAAAAAABCBEIAAAAAAAAIEQgBAAAAAAAgRCAEAAAAAACAEIEQAAAAAAAAQgRCAAAAAAAA
  CBEIAQAAAAAAIEQgBAAAAAAAgBCBEAAAAAAAAEIEQgAAAAAAAAgRCAEAAAAAACBEIAQAAAAAAIAQ
  gRAAAAAAAABCBEIAAAAAAAAIEQgBAAAAAAAgRCAEAAAAAACAEIEQAAAAAAAAQgRCAAAAAAAACBEI
  AQAAAAAAIEQgBAAAAAAAgBCBEAAAAAAAAEIEQgAAAAAAAAgRCAEAAAAAACBEIAQAAAAAAIAQgRAA
  AAAAAABCBEIAAAAAAAAIEQgBAAAAAAAgRCAEAAAAAACAEIEQAAAAAAAAQgRCAAAAAAAACBEIAQAA
  AAAAIEQgBAAAAAAAgBCBEAAAAAAAAEIEQgAAAAAAAAgRCAEAAAAAACBEIAQAAAAAAIAQgRAAAAAA
  AABCBEIAAAAAAAAIEQgBAAAAAAAgRCAEAAAAAACAEIEQAAAAAAAAQgRCAAAAAAAACBEIAQAAAAAA
  IEQgBAAAAAAAgBCBEAAAAAAAAEIEQgAAAAAAAAgRCAEAAAAAACBEIAQAAAAAAIAQgRAAAAAAAABC
  BEIAAAAAAAAIEQgBAAAAAAAgRCAEAAAAAACAEIEQAAAAAAAAQgRCAAAAAAAACBEIAQAAAAAAIEQg
  BAAAAAAAgBCBEAAAAAAAAEIEQgAAAAAAAAgRCAEAAAAAACBEIAQAAAAAAIAQgRAAAAAAAABCBEIA
  AAAAAAAIEQgBAAAAAAAgRCAEAAAAAACAEIEQAAAAAAAAQgRCAAAAAAAACBEIAQAAAAAAIEQgBAAA
  AAAAgBCBEAAAAAAAAEIEQgAAAAAAAAgRCAEAAAAAACBEIAQAAAAAAIAQgRAAAAAAAABCBEIAAAAA
  AAAIEQgBAAAAAAAgRCAEAAAAAACAEIEQAAAAAAAAQgRCAAAAAAAACBEIAQAAAAAAIEQgBAAAAAAA
  gBCBEAAAAAAAAEIEQgAAAAAAAAgRCAEAAAAAACBEIAQAAAAAAIAQgRAAAAAAAABCBEIAAAAAAAAI
  EQgBAAAAAAAgRCAEAAAAAACAEIEQAAAAAAAAQgRCAAAAAAAACBEIAQAAAAAAIEQgBAAAAAAAgBCB
  EAAAAAAAAEIEQgAAAAAAAAgRCAEAAAAAACBEIAQAAAAAAIAQgRAAAAAAAABCBEIAAAAAAAAIEQgB
  AAAAAAAgRCAEAAAAAACAEIEQAAAAAAAAQgRCAAAAAAAACBEIAQAAAAAAIEQgBAAAAAAAgBCBEAAA
  AAAAAEIEQgAAAAAAAAgRCAEAAAAAACBEIAQAAAAAAIAQgRAAAAAAAABCBEIAAAAAAAAIEQgBAAAA
  AAAgRCAEAAAAAACAEIEQAAAAAAAAQgRCAAAAAAAACBEIAQAAAAAAIEQgBAAAAAAAgBCBEAAAAAAA
  AEIEQgAAAAAAAAgRCAEAAAAAACBEIAQAAAAAAIAQgRAAAAAAAABCBEIAAAAAAAAIEQgBAAAAAAAg
  RCAEAAAAAACAEIEQAAAAAAAAQgRCAAAAAAAACBEIAQAAAAAAIEQgBAAAAAAAgBCBEAAAAAAAAEIE
  QgAAAAAAAAgRCAEAAAAAACBEIAQAAAAAAIAQgRAAAAAAAABCBEIAAAAAAAAIEQgBAAAAAAAgRCAE
  AAAAAACAEIEQAAAAAAAAQgRCAAAAAAAACBEIAQAAAAAAIEQgBAAAAAAAgBCBEAAAAAAAAEIEQgAA
  AAAAAAgRCAEAAAAAACBEIAQAAAAAAIAQgRAAAAAAAABCBEIAAAAAAAAIEQgBAAAAAAAgRCAEAAAA
  AACAEIEQAAAAAAAAQgRCAAAAAAAACBEIAQAAAAAAIEQgBAAAAAAAgBCBEAAAAAAAAEIEQgAAAAAA
  AAgRCAEAAAAAACBEIAQAAAAAAIAQgRAAAAAAAABCBEIAAAAAAAAIEQgBAAAAAAAgRCAEAAAAAACA
  EIEQAAAAAAAAQgRCAAAAAAAACBEIAQAAAAAAIEQgBAAAAAAAgBCBEAAAAAAAAEIEQgAAAAAAAAgR
  CAEAAAAAACBEIAQAAAAAAIAQgRAAAAAAAABCBEIAAAAAAAAIEQgBAAAAAAAgRCAEAAAAAACAEIEQ
  AAAAAAAAQgRCAAAAAAAACBEIAQAAAAAAIEQgBAAAAAAAgBCBEAAAAAAAAEIEQgAAAAAAAAgRCAEA
  AAAAACBEIAQAAAAAAIAQgRAAAAAAAABCBEIAAAAAAAAIEQgBAAAAAAAgRCAEAAAAAACAEIEQAAAA
  AAAAQgRCAAAAAAAACBEIAQAAAAAAIEQgBAAAAAAAgBCBEAAAAAAAAEIEQgAAAAAAAAgRCAEAAAAA
  ACBEIAQAAAAAAIAQgRAAAAAAAABCBEIAAAAAAAAIEQgBAAAAAAAgRCAEAAAAAACAEIEQAAAAAAAA
  QgRCAAAAAAAACBEIAQAAAAAAIEQgBAAAAAAAgBCBEAAAAAAAAEIEQgAAAAAAAAgRCAEAAAAAACBE
  IAQAAAAAAIAQgRAAAAAAAABCBEIAAAAAAAAIEQgBAAAAAAAgRCAEAAAAAACAEIEQAAAAAAAAQgRC
  AAAAAAAACBEIAQAAAAAAIEQgBAAAAAAAgBCBEAAAAAAAAEIEQgAAAAAAAAgRCAEAAAAAACBEIAQA
  AAAAAIAQgRAAAAAAAABCBEIAAAAAAAAIEQgBAAAAAAAgRCAEAAAAAACAEIEQAAAAAAAAQgRCAAAA
  AAAACBEIAQAAAAAAIEQgBAAAAAAAgBCBEAAAAAAAAEIEQgAAAAAAAAgRCAEAAAAAACBEIAQAAAAA
  AIAQgRAAAAAAAABCBEIAAAAAAAAIEQgBAAAAAAAgRCAEAAAAAACAEIEQAAAAAAAAQgRCAAAAAAAA
  CBEIAQAAAAAAIEQgBAAAAAAAgBCBEAAAAAAAAEIEQgAAAAAAAAgRCAEAAAAAACBEIAQAAAAAAIAQ
  gRAAAAAAAABCBEIAAAAAAAAIEQgBAAAAAAAgRCAEAAAAAACAEIEQAAAAAAAAQgRCAAAAAAAACBEI
  AQAAAAAAIEQgBAAAAAAAgBCBEAAAAAAAAEIEQgAAAAAAAAgRCAEAAAAAACBEIAQAAAAAAIAQgRAA
  AAAAAABCBEIAAAAAAAAIEQgBAAAAAAAgRCAEAAAAAACAEIEQAAAAAAAAQgRCAAAAAAAACBEIAQAA
  AAAAIEQgBAAAAAAAgBCBEAAAAAAAAEIEQgAAAAAAAAgRCAEAAAAAACBEIAQAAAAAAIAQgRAAAAAA
  AABCBEIAAAAAAAAIEQgBAAAAAAAgRCAEAAAAAACAEIEQAAAAAAAAQgRCAAAAAAAACBEIAQAAAAAA
  IEQgBAAAAAAAgBCBEAAAAAAAAEIEQgAAAAAAAAgRCAEAAAAAACBEIAQAAAAAAIAQgRAAAAAAAABC
  BEIAAAAAAAAIEQgBAAAAAAAgRCAEAAAAAACAEIEQAAAAAAAAQgRCAAAAAAAACBEIAQAAAAAAIEQg
  BAAAAAAAgBCBEAAAAAAAAEIEQgAAAAAAAAgRCAEAAAAAACBEIAQAAAAAAIAQgRAAAAAAAABCBEIA
  AAAAAAAIEQgBAAAAAAAgRCAEAAAAAACAEIEQAAAAAAAAQgRCAAAAAAAACBEIAQAAAAAAIEQgBAAA
  AAAAgBCBEAAAAAAAAEIEQgAAAAAAAAgRCAEAAAAAACBEIAQAAAAAAIAQgRAAAAAAAABCBEIAAAAA
  AAAIEQgBAAAAAAAgRCAEAAAAAACAEIEQAAAAAAAAQgRCAAAAAAAACBEIAQAAAAAAIEQgBAAAAAAA
  gBCBEAAAAAAAAEIEQgAAAAAAAAgRCAEAAAAAACBEIAQAAAAAAIAQgRAAAAAAAABCBEIAAAAAAAAI
  EQgBAAAAAAAgRCAEAAAAAACAEIEQAAAAAAAAQgRCAAAAAAAACBEIAQAAAAAAIEQgBAAAAAAAgBCB
  EAAAAAAAAEIEQgAAAAAAAAgRCAEAAAAAACBEIAQAAAAAAIAQgRAAAAAAAABCBEIAAAAAAAAIEQgB
  AAAAAAAgRCAEAAAAAACAEIEQAAAAAAAAQgRCAAAAgIe9O9hx24YCKAoW+f9fdoEuCnQxje2IEsl7
  DtBdm4z1SEryRVIAAAgRCAEAAAAAACBEIAQAAAAAAIAQgRAAAAAAAABCBEIAAAAAAAAIEQgBAAAA
  AAAgRCAEAAAAAACAEIEQAAAAAAAAQgRCAAAAAAAACBEIAQAAAAAAIEQgBAAAAAAAgBCBEAAAAAAA
  AEIEQgAAAAAAAAgRCAEAAAAAACBEIAQAAAAAAIAQgRAAAAAAAABCBEIAAAAAAAAIEQgBAAAAAAAg
  RCAEAAAAAACAEIEQAAAAAAAAQgRCAAAAAAAACBEIAQAAAAAAIEQgBAAAAAAAgBCBEAAAAAAAAEIE
  QgAAAAAAAAgRCAEAAAAAACBEIAQAAAAAAIAQgRAAAAAAAABCBEIAAAAAAAAIEQgBAAAAAAAgRCAE
  AAAAAACAEIEQAAAAAAAAQgRCAAAAAAAACBEIAQAAAAAAIEQgBAAAAAAAgBCBEAAAAAAAAEIEQgAA
  AAAAAAgRCAEAAAAAACBEIAQAAAAAAIAQgRAAAAAAAABCBEIAAAAAAAAIEQgBAAAAAAAgRCAEAAAA
  AACAEIEQAAAAAAAAQgRCAAAAAAAACBEIAQAAAAAAIEQgBAAAAAAAgBCBEAAAAAAAAEIEQgAAAAAA
  AAgRCAEAAAAAACBEIAQAAAAAAIAQgRAAAAAAAABCBEIAAAAAAAAIEQgBAAAAAAAgRCAEAAAAAACA
  EIEQAAAAAAAAQgRCAAAAAAAACBEIAQAAAAAAIEQgBAAAAAAAgBCBEAAAAAAAAEIEQgAAAAAAAAgR
  CAEAAAAAACBEIAQAAAAAAIAQgRAAAAAAAABCBEIAAAAAAAAIEQgBAAAAAAAgRCAEAAAAAACAEIEQ
  AAAAAAAAQgRCAAAAAAAACBEIAQAAAAAAIEQgBAAAAAAAgBCBEAAAAAAAAEIEQgAAAAAAAAgRCAEA
  AAAAACBEIAQAAAAAAIAQgRAAAAAAAABCBEIAAAAAAAAIEQgBAAAAAAAgRCAEAAAAAACAEIEQAAAA
  AAAAQgRCAAAAAAAACBEIAQAAAAAAIEQgBAAAAAAAgBCBEAAAAAAAAEIEQgAAAAAAAAgRCAEAAAAA
  ACBEIAQAAAAAAIAQgRAAAAAAAABCBEIAAAAAAAAIEQgBAAAAAAAgRCAEAAAAAACAEIEQAAAAAAAA
  QgRCAAAAAAAACBEIAQAAAAAAIEQgBAAAAAAAgBCBEAAAAAAAAEIEQgAAAAAAAAgRCAEAAAAAACBE
  IAQAAAAAAIAQgRAAAAAAAABCBEIAAAAAAAAIEQgBAAAAAAAgRCAEAAAAAACAEIEQAAAAAAAAQgRC
  AAAAAAAACBEIAQAAAAAAIEQgBAAAAAAAgBCBEAAAAAAAAEIEQgAAAAAAAAgRCAEAAAAAACBEIAQA
  AAAAAIAQgRAAAAAAAABCBEIAAAAAAAAIEQgBAAAAAAAgRCAEAAAAAACAEIEQAAAAAAAAQgRCAAAA
  AAAACBEIAQAAAAAAIEQgBAAAAAAAgBCBEAAAAAAAAEIEQgAAAAAAAAgRCAEAAAAAACBEIAQAAAAA
  AIAQgRAAAAAAAABCBEIAAAAAAAAIEQgBAAAAAAAgRCAEAAAAAACAEIEQAAAAAAAAQgRCAAAAAAAA
  CBEIAQAAAAAAIEQgBAAAAAAAgBCBEAAAAAAAAEIEQgAAAAAAAAgRCAEAAAAAACBEIAQAAAAAAIAQ
  gRAAAAAAAABCBEIAAAAAAAAIEQgBAAAAAAAgRCAEAAAAAACAEIEQAAAAAAAAQgRCAAAAAAAACBEI
  AQAAAAAAIEQgBAAAAAAAgBCBEAAAAAAAAEIEQgAAAAAAAAgRCAEAAAAAACBEIAQAAAAAAIAQgRAA
  AAAAAABCBEIAAAAAAAAIEQgBAAAAAAAgRCAEAAAAAACAEIEQAAAAAAAAQgRCAAAAAAAACBEIAQAA
  AAAAIEQgBAAAAAAAgBCBEAAAAAAAAEIEQgAAAAAAAAgRCAEAAAAAACBEIAQAAAAAAIAQgRAAAAAA
  AABCBEIAAAAAAAAIEQgBAAAAAAAgRCAEAAAAAACAEIEQAAAAAAAAQgRCAAAAAAAACBEIAQAAAAAA
  IEQgBAAAAAAAgBCBEAAAAAAAAEIEQgAAAAAAAAgRCAEAAAAAACBEIAQAAAAAAIAQgRAAAAAAAABC
  BEIAAAAAAAAIEQgBAAAAAAAgRCAEAAAAAACAEIEQAAAAAAAAQgRCAAAAAAAACBEIAQAAAAAAIEQg
  BAAAAAAAgBCBEAAAAAAAAEIEQgAAAAAAAAgRCAEAAAAAACBEIAQAAAAAAIAQgRAAAAAAAABCBEIA
  AAAAAAAIEQgBAAAAAAAgRCAEAAAAAACAEIEQAAAAAAAAQgRCAAAAAAAACBEIAQAAAAAAIEQgBAAA
  AAAAgBCBEAAAAAAAAEIEQgAAAAAAAAgRCAEAAAAAACBEIAQAAAAAAIAQgRAAAAAAAABCBEIAAAAA
  AAAIEQgBAAAAAAAgRCAEAAAAAACAEIEQAAAAAAAAQgRCAAAAAAAACBEIAQAAAAAAIEQgBAAAAAAA
  gBCBEAAAAAAAAEIEQgAAAAAAAAgRCAEAAAAAACBEIAQAAAAAAIAQgRAAAAAAAABCBEIAAAAAAAAI
  EQgBAAAAAAAgRCAEAAAAAACAEIEQAAAAAAAAQgRCAAAAAAAACBEIAQAAAAAAIEQgBAAAAAAAgBCB
  EAAAAAAAAEIEQgAAAAAAAAgRCAEAAAAAACBEIAQAAAAAAIAQgRAAAAAAAABCBEIAAAAAAAAIEQgB
  AAAAAAAgRCAEAAAAAACAEIEQAAAAAAAAQgRCAAAAAAAACBEIAQAAAAAAIEQgBAAAAAAAgBCBEAAA
  AAAAAEIEQgAAAAAAAAgRCAEAAAAAACBEIAQAAAAAAIAQgRAAAAAAAABCBEIAAAAAAAAIEQgBAAAA
  AAAgRCAEAAAAAACAEIEQAAAAAAAAQgRCAAAAAAAACBEIAQAAAAAAIEQgBAAAAAAAgBCBEAAAAAAA
  AEIEQgAAAAAAAAgRCAEAAAAAACBEIAQAAAAAAIAQgRAAAAAAAABCBEIAAAAAAAAIEQgBAAAAAAAg
  RCAEAAAAAACAEIEQAAAAAAAAQgRCAAAAAAAACBEIAQAAAAAAIEQgBAAAAAAAgBCBEAAAAAAAAEIE
  QgAAAAAAAAgRCAEAAAAAACBEIAQAAAAAAIAQgRAAAAAAAABCBEIAAAAAAAAIEQgBAAAAAAAgRCAE
  AAAAAACAEIEQAAAAAAAAQgRCAAAAAAAACBEIAQAAAAAAIEQgBAAAAAAAgBCBEAAAAAAAAEIEQgAA
  AAAAAAgRCAEAAAAAACBEIAQAAAAAAIAQgRAAAAAAAABCBEIAAAAAAAAIEQgBAAAAAAAgRCAEAAAA
  AACAEIEQAAAAAAAAQgRCAAAAAAAACBEIAQAAAAAAIEQgBAAAAAAAgBCBEAAAAAAAAEIEQgAAAAAA
  AAgRCAEAAAAAACBEIAQAAAAAAIAQgRAAAAAAAABCBEIAAAAAAAAIEQgBAAAAAAAgRCAEAAAAAACA
  EIEQAAAAAAAAQgRCAAAAAAAACBEIAQAAAAAAIEQgBAAAAAAAgBCBEAAAAAAAAEIEQgAAAAAAAAgR
  CAEAAAAAACBEIAQAAAAAAIAQgRAAAAAAAABCBEIAAAAAAAAIEQgBAAAAAAAgRCAEAAAAAACAEIEQ
  AAAAAAAAQgRCAAAAAAAACBEIAQAAAAAAIEQgBAAAAAAAgBCBEAAAAAAAAEIEQgAAAAAAAAgRCAEA
  AAAAACBEIAQAAAAAAIAQgRAAAAAAAABCBEIAAAAAAAAIEQgBAAAAAAAgRCAEAAAAAACAEIEQAAAA
  AAAAQgRCAAAAAAAACBEIAQAAAAAAIEQgBAAAAAAAgBCBEAAAAAAAAEIEQgAAAAAAAAgRCAEAAAAA
  ACBEIAQAAAAAAIAQgRAAAAAAAABCBEIAAAAAAAAIEQgBAAAAAAAgRCAEAAAAAACAEIEQAAAAAAAA
  QgRCAAAAAAAACBEIAQAAAAAAIEQgBAAAAAAAgBCBEAAAAAAAAEIEQgAAAAAAAAgRCAEAAAAAACBE
  IAQAAAAAAIAQgRAAAAAAAABCBEIAAAAAAAAIEQgBAAAAAAAgRCAEAAAAAACAEIEQAAAAAAAAQgRC
  AAAAAAAACBEIAQAAAAAAIEQgBAAAAAAAgBCBEAAAAAAAAEIEQgAAAAAAAAgRCAEAAAAAACBEIAQA
  AAAAAIAQgRAAAAAAAABCBEIAAAAAAAAIEQgBAAAAAAAgRCAEAAAAAACAEIEQAAAAAAAAQgRCAAAA
  AAAACBEIAQAAAAAAIEQgBAAAAAAAgBCBEAAAAAAAAEIEQgAAAAAAAAgRCAEAAAAAACBEIAQAAAAA
  AIAQgRAAAAAAAABCBEIAAAAAAAAIEQgBAAAAAAAgRCAEAAAAAACAEIEQAAAAAAAAQgRCAAAAAAAA
  CBEIAQAAAAAAIEQgBAAAAAAAgBCBEAAAAAAAAEIEQgAAAAAAAAgRCAEAAAAAACBEIAQAAAAAAIAQ
  gRAAAAAAAABCBEIAAAAAAAAIEQgBAAAAAAAgRCAEAAAAAACAEIEQAAAAAAAAQgRCAAAAAAAACBEI
  AQAAAAAAIEQgBAAAAAAAgBCBEAAAAAAAAEIEQgAAAAAAAAgRCAEAAAAAACBEIAQAAAAAAIAQgRAA
  AAAAAABCBEIAAAAAAAAIEQgBAAAAAAAgRCAEAAAAAACAEIEQAAAAAAAAQgRCAAAAAAAACBEIAQAA
  AAAAIEQgBAAAAAAAgBCBEAAAAAAAAEIEQgAAAAAAAAgRCAEAAAAAACBEIAQAAAAAAIAQgRAAAAAA
  AABCBEIAAAAAAAAIEQgBAAAAAAAgRCAEAAAAAACAEIEQAAAAAAAAQgRCAAAAAAAACBEIAQAAAAAA
  IEQgBAAAAAAAgBCBEAAAAAAAAEIEQgAAAAAAAAgRCAEAAAAAACBEIAQAAAAAAIAQgRAAAAAAAABC
  BEIAAAAAAAAIEQgBAAAAAAAgRCAEAAAAAACAEIEQAAAAAAAAQgRCAAAAAAAACBEIAQAAAAAAIEQg
  BAAAAAAAgBCBEAAAAAAAAEIEQgAAAAAAAAgRCAEAAAAAACBEIAQAAAAAAIAQgRAAAAAAAABCBEIA
  AAAAAAAIEQgBAAAAAAAgRCAEAAAAAACAEIEQAAAAAAAAQgRCAAAAAAAACBEIAQAAAAAAIEQgBAAA
  AAAAgBCBEAAAAAAAAEIEQgAAAAAAAAgRCAEAAAAAACBEIAQAAAAAAIAQgRAAAAAAAABCBEIAAAAA
  AAAIEQgBAAAAAAAgRCAEAAAAAACAEIEQAAAAAAAAQgRCAAAAAAAACBEIAQAAAAAAIEQgBAAAAAAA
  gBCBEAAAAAAAAEIEQgAAAAAAAAgRCAEAAAAAACBEIAQAAAAAAIAQgRAAAAAAAABCBEIAAAAAAAAI
  EQgBAAAAAAAgRCAEAAAAAACAEIEQAAAAAAAAQgRCAAAAAAAACBEIAQAAAAAAIEQgBAAAAAAAgBCB
  EAAAAAAAAEIEQgAAAAAAAAgRCAEAAAAAACBEIAQAAAAAAIAQgRAAAAAAAABCBEIAAAAAAAAIEQgB
  AAAAAAAgRCAEAAAAAACAEIEQAAAAAAAAQgRCAAAAAAAACBEIAQAAAAAAIEQgBAAAAAAAgBCBEAAA
  AAAAAEIEQgAAAAAAAAgRCAEAAAAAACBEIAQAAAAAAIAQgRAAAAAAAABCBEIAAAAAAAAIEQgBAAAA
  AAAgRCAEAAAAAACAEIEQAAAAAAAAQgRCAAAAAAAACBEIAQAAAAAAIEQgBAAAAAAAgBCBEAAAAAAA
  AEIEQgAAAAAAAAgRCAEAAAAAACBEIAQAAAAAAIAQgRAAAAAAAABCBEIAAAAAAAAIEQgBAAAAAAAg
  RCAEAAAAAACAEIEQAAAAAAAAQgRCAAAAAAAACBEIAQAAAAAAIEQgBAAAAAAAgBCBEAAAAAAAAEIE
  QgAAAAAAAAgRCAEAAAAAACBEIAQAAAAAAIAQgRAAAAAAAABCBEIAAAAAAAAIEQgBAAAAAAAgRCAE
  AAAAAACAEIEQAAAAAAAAQgRCAAAAAAAACBEIAQAAAAAAIEQgBAAAAAAAgBCBEAAAAAAAAEIEQgAA
  AAAAAAgRCAEAAAAAACBEIAQAAAAAAIAQgRAAAAAAAABCBEIAAAAAAAAIEQgBAAAAAAAgRCAEAAAA
  AACAEIEQAAAAAAAAQgRCAAAAAAAACBEIAQAAAAAAIEQgBAAAAAAAgBCBEAAAAAAAAEIEQgAAAAAA
  AAgRCAEAAAAAACBEIAQAAAAAAIAQgRAAAAAAAABCBEIAAAAAAAAIEQgBAAAAAAAgRCAEAAAAAACA
  EIEQAAAAAAAAQgRCAAAAAAAACBEIAQAAAAAAIEQgBAAAAAAAgBCBEAAAAAAAAEIEQgAAAAAAAAgR
  CAEAAAAAACBEIAQAAAAAAIAQgRAAAAAAAABCBEIAAAAAAAAIEQgBAAAAAAAgRCAEAAAAAACAEIEQ
  AAAAAAAAQgRCAAAAAAAACBEIAQAAAAAAIEQgBAAAAAAAgBCBEAAAAAAAAEIEQgAAAAAAAAgRCAEA
  AAAAACBEIAQAAAAAAIAQgRAAAAAAAABCBEIAAAAAAAAIEQgBAAAAAAAgRCAEAAAAAACAkOES8IHX
  w2vsZY0DAOAZ/LZnYM/fAAB49r7v+dfzN7fyJwgBAAAAAAAgRCAEAAAAAACAEIEQAAAAAAAAQgRC
  AAAAAAAACBEIAQAAAAAAIEQgBAAAAAAAgBCBEAAAAAAAAEIEQgAAAAAAAAgRCAEAAAAAACBEIAQA
  AAAAAIAQgRAAAAAAAABCBEIAAAAAAAAIEQgBAAAAAAAgRCAEAAAAAACAEIEQAAAAAAAAQgRCAAAA
  AAAACBEIAQAAAAAAIOSXS7Ct1wf/7nC5rBVrZPm5mIHzlWdmaJ57z9DMzA+sX3Nyn+/M0Qydn9h7
  7LV+rB1YnEB47ssPWDtmwvyZCL1n7Svz3G+OL/MyP7B+zch9PjNHM3R+Yu+x57s2sCiB0OEL2Nfm
  MefX9dK0/54yz71m+TIr8wPr14zc55MzND/nJ/Ye+7xnAwsRCM88gN2gcVM3B56fh5ems/aUee4z
  S7MyP7B+zcfsPHfj/DQje4/714y1AJsRCPc/gB28rPJSjRmYx8+/t7P6nD3lJXifWdp75gfWrxm5
  z/eeu83P+WlG9h73rBfzhgMIhPsdwg5f8FDO3JmMCTP2wrT+PfNlpsvNc1zw65iT+YH16xn625/H
  7Pado/k5P83I3uPZ92tgEzb2Hofw2PjnHwf9/ievM2fB8w/kZvD8TIb95jy7ce2Y53XX82VO5ufa
  T7tep9znrN+zn6NnM7+952h+zk8zsvfY/zvpyrP3Sc/fbMLicQg7IK01Z8EaD+Nm8NxcBKUz9tY4
  YB2Z5ffX0ZdM5mcGc67V7s/f1m/nWXo289t7hubn/LT37D3rxOx2ef71/Te3+sslWPZQsbFh/b3t
  rxPtvsz+6a9j/Vw7w1n3zJl/RalZXjvHd/97czI/rF/rd+93ZT+r64LzE9eEeevEd9IQIxDOPYS/
  +dMPDmE8IJ63tznvZfanX9O+vfcsu+Oe+claMc/v96P7pfmZH9avGfmZMTvnpxnae1xz3X0nDbxF
  IFznBugQxkPXeQ9Y9F5mneX3nWV3X2uRcI/7kjmZH1i/ZuRndy3MzvlpTn5e62Pdd2tgIb9cgscP
  YocweJjlrDmND3+el3vBxzMci8/WTJ+dpTmZH1i/ntlmf4b6+TPzC+iX2Tk/zdDe45b1YSaAQLjJ
  TRpOf8l2XblrVneduyLh/jNk/jzHAnvQlxXmh/Vr/e7zfO1PGTWe24Z5Oj+dn/Ye0++vnqGBf/gr
  Rh3EtF+ycV29JM19wbXunr2mT/4ML3vSc5D5gfXLJffdT/+/SOPL/85z27X7bFy8BszO+WlG9h7X
  XFd7GfiXQOggBt7bo+M3/7DfTE+5pxTvoz6bz3vlvvdlhflh/Vq/a83o6mfsWc/rzp/7rvVOz/PO
  T+envcfK+9c8gf8QCO89iIUEvJDu+ZA93vx3sQesi3kzHOZ61NnqPDE/88P67azfVe737t/z5njX
  dx1X/T7uG85Pe8/eK91b71wrwGYEwnsPYpi9/qyzZ1+AXP81z+Bx8GfDPH1O57L5Yf1av6c8e48N
  fx/3+WfWuv3l/DQje8+6MDfgAgKhg5izXqqBM/amSOh8O/m5yOfwuX0OzL2zft/5bL7k9oxmhs4d
  56e9x5w1b17A/xIIHcR4AAZ7AHP0Yuy6OV/MD+vX+n1iJid8yX367E7922vcM5yf9p55nX7tvLMC
  vyUQOojxgAheZtf8Obw4dZ8ffLYzzwHzMz+sX854RsP8nJ9ma++x+r41I+AtAuFZD1A016Cbvgd2
  +wBwPjpnzM/8sH69vzy3Pkp/gmnY385P56e9Z+9Z38AZBMJ5B7EbJQBeBLzgWJ+YH1i/rM/7u+vk
  /MTe45Q9a70AbxMIHcTsvQ6tNewDL3XA9fvPl2fmB9av5yHP3vvMzrpyXpx0ftp7/MmaNhPgIwKh
  g5h916G1Bu5FYF1ifli/7DKn1d9fvF/9fD1cG+cn9h7r71frBfjYL5fAQQzgTAYmsx/ND6xfCs+r
  w/7B/HHtueB+Ys0At/AnCK87iOHOtejGD60Xvfp9yX0Z6w7zw/ptPgP5OTE73P/tPWsYYBKB8LqD
  2A0RDwUAuD+ty7Oa+WH9Wr+A89P5CaftU/sE+NrfArR3Z7ux3EYAQC8N/f8v0w824Cy+0sw0l1rO
  AfISO5KGtbDYVCsuCA0syDcAYO0hHfGDzvk7k59fnLecq9E/UXvWE2jBBaEhgly5aONHPVgHnz/f
  zzPEAfED+QugfwLqFIjEBeHzRuzCBkMBYN8AOS9+IH8RA1C7wLs8kwaucUFoYEK+AVQ/OIG8Ez+Q
  v2fPLcP5BWdr9E/UnrUEYnNBaHAgRy7a+AGHGZ/VvCQe4of8lb+IF+ifoEYBlnBB6DVuDAWgLqyH
  NRAHAIAOPMsBtZf9bGstgWVcEIIhCqDLQUr/BgAAAIBfLgj9pgbRc1GuQU1q+94envX70jPfED+Q
  v2AGR/9Ue/LTWgJbeIMQDK0AWQ+Js/jns1chfiB/6TXbsK9XDGsCag+A/9b5gtBvauCACcCO2UH/
  thbiB/IXQP8Enpwr1SawnTcIIeZQYNMHVh8mIlr1FuG07gAAOF+D2gPgdV0vCP2mBpnzEICzPfWd
  r21WkGOIH8hfiFMjZjO5gdqrkJPWE9jCG4QQj00fHAb1vDgxdzkIAJgBxAVQewDldLwg9MCYyHlo
  iALIuc8Pa5rGUBPih/yVv1Be93rTPxErdQnwI28Q2uAwEABU22Pn4a9jRgAAiHO+NpuB2nNeBnhB
  twtCFzPY8AF69MD5cN93OQgAffkT43njJB6g9rLvKwDHfFkChwtC5IK4A/zVC1cfnOabPdbFIABA
  3lkSUHu8f74VR2iq0wXhkweO4+D3mpqzHARgef8dL/57Dk4AAPnO1+YzUHtZ1uwdnkkDW3mDMEaz
  f+Xrasp1iS3s7c1qLF9PnBdyweUgAOAsl2fGFwdQe7x3lv3064ozFPaHJXjcKJ/+fxy9+72otVnb
  ZOF7akTcT+yl88WfST6q3yl+4of8lb8QqhbMZ/qn/qn2usbDM2ngsS4XhKtf5b7ZGDVlwyhABzsP
  nPPNHt358GsPEz+Qv8g984G+YJ2QU5xfO8+kge38idG8G6I/m5ef+AH83Cd3/7kUfRoAIL75w39v
  bgO11zEOt34WcYci/InRnI048s/EazGykYJeyWtGs+8LAJhPeH+e92YLqL3OcfAzAR/p8Abh01e5
  55v//unm6bc2bOAA1e18k/DdvRwAcIYjbjy81QRqr8Ke4pk0cIQ/Mfq8eb7TCMemxqwh5yJWsHcw
  1idr98554HsAAJgX7pmLvoZYgdqrHAvPpIHH/InRZ4eCcfF/v2MTZ388bJqAnv18/xUXAMAcUDMG
  U0xB7fHjmdgzaWAJF4T3m6iG7GAJqE3u7Z3yBACIMIN0n9Nnwq8Nak/tnd6DPJMGlnJBeK8Ja8gO
  k4D6sRYx18FhFgAwg+01D89cZjtQe5n3H8+kgS2qXxCuaEw7m7DDhpwD1Chx9075AgBmRNau881f
  xBJn1J7ay/SZPZMGtvMG4f0mvKMhG3rjbf42WzC0kjMv7KkAgDn02SwV6a8zmO1Qe2ovw57jmTRw
  hAvCugcPDdlhEtAfkTMAwLN93nkOgA48k4aGvixB2IasmdY6VAKx+uP85WGP/itnAMAcwYn5PVoM
  zHWoPbXH7/PG3giNeIPQYYe962zwgfMHncg9MtKfeLHPiR8AcGbu5Ps1vvHn9Mx0qD21hzhDey4I
  HUTYt+GJIRhaV38vfeWftbzZfx12AKD+HGjuOu/WhQWoPbWH/Q9aqnxB6OGddQAMrBH6pLfOzu5r
  438OufZXAICc875f+gK1V/HM6uwOhOENwvibMjk3OrGDXD1yx+DqYvD8AWMc7sfiCwB9Zgruzfzi
  AWqP8/EHGnBBqAmy/kApfyBnv15xoTd/uRi80Xd/ygWXhADAipmCu3P/uJAPoPbU3un1BjjGBWGP
  jcGmC+iT7/XMVy/55pv//gj0ObN4ZW3Hi+s3Nv6MAIC5EjECtYeYOytDGl+WAJZubgYmuDe4zsP1
  /slgbThev/4jUK7YAwDAWY4zs7/ZGtQeAA95gxAcKKHaYcXPVb/fPl1bbxICgNkC87/cALUH0JoL
  wl4bL0CHfjkK/izd9oF5YE1cEqIWxQ/kL9ZX7JAjWFfEGdpyQQiv8/YgGGJf+b6/uxh0cfS81+6K
  V+bP4ECI+CF/wVlOv8B6INcAeJsLQgciHCih8mFlFPxe9qr1ay12AOAsBwDcO+cDF7ggzMPBBeDz
  /rnjAm/X19X/76zH6q/rEOSAiviB/I295mYsM6L61D9Re9w7gwMBfFkCcKiExoPtqTfWHHpyrNFY
  /HNM+wQApJgJAQCgHReE8L358J9X+ZwO11Q1/HypetA4tOYudAGg7nzh/FJ7tjfHgdoD4EX+xCgA
  cFPEg+Qo/vk6xxbxQ/4iBwC1AwD8ckEIAFEP2X67/e56WH8AqDd72d/NjIDaA+BvLggBgFui/4by
  aPI5HfZrr4P4WQdxsw5mC+uIvmEdAID/44LQYAPAPt4etPcCAPZzxBvUHmILhFP5glDjAoC43rk8
  vb2nj8OfFwBYt9d6NgBAtvMjwBFfloBEG5wNFsjE24P19kCXfEC3GdieRPa5Sw4DAJnmX7MLR/kT
  owDAaVkv2gzqAJBnrrBvAwDAN1wQAsB63h6sa/jcalP8xA/5K3/Dr6t1Qw7on3JA3gHwAxeEa4cP
  AMAB03xhphI/kL/ci7GH06B/gvoDeIELwj4ckgBiD/T6tD0VAHg2b9mjMd+B2kOMgRd9NWhAfvOC
  bpvYbPI5Qa/JaRaK2WyYp1N9ip/4IX/lb9BZwnqB/qkfoPYA3uANQgBYxwEAAOD8rOUyAAAA3uSC
  EADu8kBL7ACAn7kcBACAhTpcEH56WJjWAIDN+4b+TIeZYqpR8RM/5K/83bSG1gn0T32BSrUXqf5u
  rgFwiDcIAcDBFQAg25xllmIVuQRqD6AlF4QAAA61ABCRy0EAANikywWhwwMAu3h7kG6Gz+lz+ZyI
  q895ccayPqCv6J/ISYAFvEH42YHERgTAp/uEvgwA8NmMZY5i9Zwup0DtZV7HyMQYEuh0QVihKU0p
  C5C6JxuQexuNP8tMVq9DLoof8lf+hluzkfSzTGd59E/9E7VXdG8GkvMGoQ0IAD058jo4iABAH9Uu
  BzGfA2pPjIGwXBAa6gHYvzcYjnHo87l8Tp8LcZa/n81XFS4H9Sb0GZ8LVu2LAMt0uyAcPisAh4d0
  /RgccMUP5C9iRKx8MqOrTdTeSZ5JAyF1fIPwkyYVYSAylAHk68UGY+tS/TOPYnU75KX4IX/lb6h1
  8vYg6J/6J51rzzNpYCt/YtSGA8CeoVgv7nEgcVgCgD376Ej4WcwFOWYzczqoPfYQY0im6wVh1t/Y
  ACDuYcdQTNd8GBtr6WTt+nNH4of8lb9x1mgU+izEioP9Qv9E7WU7G9pjgG28QVjvsGHDBbjXg/Xh
  fevkUAQA9eerkWyW+umtQXMhABX3a3seFNH5grDib2xoxAD3hmF9WH50zomIv4XuMCt+yF/5m2v/
  zPL/i/af/8Fcpn/qn2pPrHbW3ukaPPmZgAC+NOTwDdahAyB+/zUMn9mHp7U2VznMip/4IX/LzlfO
  vuyc2+0X+idqT+2tjy+QnD8xGvtPnHk4DRB/ENZ/6+7DDsN5D5sOs+IH8rff2mefcaLH7t/+YyZT
  w3qE2lN7Nc/CnolAEy4I4w4tGjFA/B6v/9Y/GDkM742ln9d6iB/y13xF7LjNF/75DJxX9gr9U+2p
  Pfvpvu8hzmAIaN1gR4CfRwyJnsvWXxwqxk+s+vYuh+H4taSOxQ/5K3/rzMdZjeZxG0F+PnuF/qn2
  1J6zsHoEFHK6hqwRU2mwsP7iUC1u4tS3fzkMx59nzFDiB/K3zmyc2RC3beug1vRP66/2xF6cAUNr
  yYbsATWVhgrrLw4OwVTqYS4H49eXWhY/kL915uLsXBCuXxPPS/RPa6/2xH/fmoszGFq53JA1YioM
  FNZeX64aL3HJUUt+gzJ3PIdaFj/xQ/6aiwsY4rdsjWbDtdc/e8dV7eGZNGBwbdaQbbpUGCasuf5c
  NV7ikLO2hjxJG8shTuInfshfs3FyLgitu/6pf6o9TueBZ9KAJh5scx4bvq54EWmQsM56deWYWfM6
  tTY2fx+5cv4A6iArfiB/zciRDbG03vqn/qn2uJgLnkkDirvY5ixO7M5XOZZ3MBe7Mwda1KJcyRlL
  cRI/kL9mZ3GvGU97hP5pvdWefBBvQJEbimBBnsq1nIO52KkBsVVzYilO4gfy1z5tNu8UU3uE/mmt
  1Z6cEG9AsZduyOLC6RyVc3mGcrFbFyN5rzblS704ipP4gfw1O5vN68VWfemf1lvt4Zk0oOhLN2ax
  ANjbb/VZuSFn6sZRrMQP5C/knrvUlv6J2uNOTog5NKYB3GvO1h5g3/Crx8oXc1Ht+ImV+IH8hbwz
  l5rSP1F73MsJsQcAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA
  AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA
  AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA
  AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA
  AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA
  AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAvvUnd88g6Uucl5sAAAAASUVORK5CYII="
          />
        </g>
      </g>
    </g>
    <text
      fill="#FFFFFF"
      font-family="ArialMT"
      font-size="8px"
      id="expiry"
      transform="matrix(1 0 0 1 265.7864 202.5906)"
    >
      12.12.2012
    </text>
    <text
      fill="#FFFFFF"
      font-family="ArialMT"
      font-size="7px"
      id="order-id"
      transform="matrix(1 0 0 1 195.1691 203.2975)"
    >
      #{{ orderNumber }}
    </text>
    <rect clip-rule="evenodd" fill="none" fill-rule="evenodd" height="36.85" width="130.76" x="265.76" y="4.31" />
    <g id="back-text-area-3">
      <rect fill="none" height="61.31" width="124.79" x="270.89" y="8.45" />
      <text id="back-text-container" transform="matrix(1 0 0 1 270.894 13.4312)" />
    </g>
    <path
      d="M30.17,77.74c-0.19,0-0.4-0.03-0.62-0.11c-0.77-0.29-1.37-1.1-1.78-2.41c-2.25-7.31-6.11-7.88-7.24-7.88
    c-0.87,0-1.67,0.34-2.23,0.95c-0.55,0.6-0.86,1.42-0.86,2.3c0,1.18,0.92,1.97,2.29,1.97c0.83,0,1.6-0.34,1.96-0.87
    c0.23-0.34,0.28-0.73,0.12-1.13c-0.08-0.21-0.19-0.33-0.33-0.38c-0.27-0.1-0.65,0.07-0.77,0.14c-0.16,0.09-0.37,0.04-0.47-0.12
    c-0.1-0.16-0.04-0.37,0.12-0.47c0.07-0.04,0.72-0.41,1.34-0.19c0.24,0.08,0.56,0.28,0.75,0.78c0.23,0.61,0.16,1.23-0.2,1.76
    c-0.5,0.72-1.46,1.17-2.52,1.17c-1.75,0-2.97-1.09-2.97-2.65c0-1.05,0.37-2.04,1.04-2.76c0.7-0.76,1.67-1.17,2.73-1.17
    c1.87,0,3.57,0.93,5.06,2.75c1.48,1.82,2.36,4.05,2.84,5.6c0.33,1.08,0.8,1.76,1.35,1.97c0.51,0.2,0.95-0.06,0.97-0.07
    c0.16-0.1,0.37-0.05,0.47,0.11c0.1,0.16,0.05,0.37-0.11,0.47C31.09,77.51,30.7,77.74,30.17,77.74z"
      fill="#FFFFFF"
    />
    <path
      d="M33.83,61.24c-1.64,0-3.21-0.57-4.68-1.69c-1.31-1.13-1.97-2.71-1.84-4.36c0.14-1.64,1.03-3.07,2.46-3.94
    c1.24-0.75,2.63-0.83,3.82-0.22c1.21,0.62,1.99,1.84,2.1,3.26c0.1,1.36-1.03,2.44-2.14,2.75c-1.12,0.32-2.12-0.08-2.62-1.05
    c-0.09-0.17-0.02-0.37,0.14-0.46c0.17-0.09,0.37-0.02,0.46,0.14c0.5,0.96,1.45,0.82,1.83,0.71c0.87-0.25,1.72-1.08,1.65-2.05
    c-0.09-1.18-0.73-2.19-1.73-2.7c-0.98-0.5-2.12-0.43-3.15,0.19c-1.24,0.75-2.02,2-2.13,3.41c-0.12,1.43,0.46,2.8,1.59,3.77
    c1.6,1.22,3.35,1.72,5.16,1.47c1.99-0.27,5.13-1.6,8.75-6.38c0.1-0.13,0.26-0.17,0.41-0.11c5.4,2.36,11.84,1.82,16.79-1.42
    c4.2-2.75,6.61-6.92,6.61-11.46c0-9.79-5.91-11.26-9.44-11.26c-4.2,0-6.71,3.3-6.71,6.48c0,3.03,1.87,6.25,5.34,6.25
    c2.92,0,4.66-2.02,4.66-3.98c0-1.28-0.36-2.32-1.01-2.93c-0.47-0.44-1.08-0.64-1.81-0.6c-0.68,0.04-1.22,0.33-1.6,0.73
    c0.13-0.03,0.28-0.05,0.44-0.05c1.12,0,1.71,0.86,1.71,1.71c0,0.79-0.53,1.66-1.69,1.71c-0.42,0.02-0.8-0.13-1.1-0.42
    c-0.37-0.36-0.62-0.95-0.62-1.51v-0.01c0-1.31,1.06-2.72,2.82-2.84c0.91-0.06,1.71,0.21,2.32,0.78c0.79,0.74,1.23,1.96,1.23,3.43
    c0,2.61-2.35,4.66-5.34,4.66c-2.92,0-6.03-2.43-6.03-6.94c0-2.02,0.81-3.86,2.27-5.2c1.37-1.25,3.23-1.97,5.12-1.97
    c3.04,0,10.12,1.16,10.12,11.94c0,4.77-2.52,9.15-6.92,12.03c-5.07,3.31-11.63,3.91-17.19,1.58c-2.08,2.7-5.3,5.92-9.04,6.43
    C34.49,61.22,34.16,61.24,33.83,61.24z M56.15,37.3c0.01,0.31,0.14,0.72,0.41,0.99c0.17,0.16,0.37,0.24,0.6,0.23
    c0.77-0.03,1.04-0.57,1.04-1.02c0-0.51-0.32-1.02-1.02-1.02C56.41,36.47,56.19,37.14,56.15,37.3z"
      fill="#FFFFFF"
    />
    <path
      d="M57.61,54.89c-0.13,0-0.26-0.08-0.31-0.21c-0.07-0.17,0.01-0.37,0.18-0.45c3.07-1.28,4.16-3.64,4.16-5.43
    c0-0.61-0.39-1.5-1.05-1.95c-0.44-0.3-0.92-0.34-1.42-0.14c-1.1,0.45-0.58,1.92-0.55,1.99c0.06,0.18-0.03,0.37-0.2,0.44
    c-0.18,0.06-0.37-0.02-0.44-0.2c-0.28-0.74-0.43-2.3,0.94-2.85c0.72-0.29,1.43-0.22,2.06,0.2c0.89,0.6,1.35,1.72,1.35,2.51
    c0,2-1.2,4.64-4.58,6.06C57.7,54.88,57.66,54.89,57.61,54.89z"
      fill="#FFFFFF"
    />
    <path
      d="M51.01,52.03c-0.13,0-0.25-0.01-0.38-0.02c-1.03-0.1-1.82-0.63-2.22-1.48c-0.6-1.27-0.38-2.22-0.09-2.79
    c0.47-0.94,1.39-1.43,1.89-1.53c1.18-0.24,1.84,0.65,2.03,1.15c0.07,0.18-0.02,0.37-0.2,0.44c-0.18,0.07-0.37-0.02-0.44-0.2
    c-0.04-0.09-0.37-0.9-1.25-0.72c-0.38,0.08-1.07,0.49-1.42,1.17c-0.32,0.64-0.29,1.38,0.1,2.2c0.38,0.8,1.12,1.04,1.67,1.09
    c1.16,0.11,2.62-0.46,3.21-1.25c0.77-1.04,0.84-3.84-0.78-6.05c-2.46-3.36-3.21-6.28-2.44-9.48c0.04-0.18,0.23-0.3,0.41-0.25
    c0.18,0.04,0.3,0.23,0.25,0.41c-0.72,3.03-0.03,5.7,2.33,8.92c0.82,1.12,1.33,2.51,1.42,3.89c0.08,1.21-0.16,2.32-0.64,2.97
    C53.79,51.39,52.33,52.03,51.01,52.03z"
      fill="#FFFFFF"
    />
    <path
      d="M64.19,32c-0.07,0-0.15-0.02-0.21-0.07c-5.15-4.01-5.69-6.66-5.23-8.18c0.37-1.22,1.5-1.94,2.37-2
    c0.97-0.07,1.57,0.32,1.91,0.66c0.5,0.51,0.75,1.28,0.67,2.04c-0.12,1.13-1.28,1.47-1.88,1.5c-0.19,0.01-0.35-0.14-0.35-0.33
    c-0.01-0.19,0.14-0.35,0.33-0.35c0.05,0,1.14-0.07,1.23-0.89c0.06-0.57-0.12-1.12-0.48-1.49c-0.33-0.34-0.8-0.5-1.37-0.46
    c-0.56,0.04-1.48,0.54-1.77,1.52c-0.33,1.08-0.11,3.47,5,7.44c0.15,0.12,0.18,0.33,0.06,0.48C64.4,31.96,64.3,32,64.19,32z"
      fill="#FFFFFF"
    />
    <path
      d="M30.93,77.54c-0.12,0-0.24-0.07-0.3-0.19c-0.01-0.02-1.06-2.1-2-4.89c-1.26-3.76-1.73-6.95-1.4-9.48
    c0.01-0.09,0.06-0.18,0.14-0.23c0.08-0.05,0.17-0.08,0.27-0.06c8.44,1.7,14.58-0.38,16.43-5.55c0.06-0.18,0.26-0.27,0.44-0.21
    c3.6,1.28,16.08,4.37,25.6-9.33c0.07-0.1,0.18-0.15,0.3-0.15c0.12,0.01,0.22,0.07,0.28,0.17c2.93,5.13,2.07,7.27,2.03,7.36
    c-0.07,0.17-0.27,0.25-0.45,0.18c-0.17-0.07-0.25-0.27-0.18-0.45c0.01-0.01,0.68-1.85-1.74-6.28c-6.03,8.4-13.16,10.4-18.6,10.4
    c-3.12,0-5.68-0.66-7.16-1.15c-2.07,5.22-8.28,7.35-16.73,5.75c-0.56,5.77,3.33,13.54,3.37,13.61c0.09,0.17,0.02,0.37-0.15,0.46
    C31.04,77.53,30.98,77.54,30.93,77.54z"
      fill="#FFFFFF"
    />
    <path
      d="M73.18,41.1c-3.43,0-4.36-4.2-4.37-6.42V17.31c0-0.19,0.15-0.34,0.34-0.34c0.19,0,0.34,0.15,0.34,0.34v17.37
    c0,0.06,0.07,5.74,3.69,5.74c0.19,0,0.34,0.15,0.34,0.34C73.53,40.95,73.37,41.1,73.18,41.1z"
      fill="#FFFFFF"
    />
    <path
      d="M72.92,25.78c-0.09,0-0.18-0.04-0.25-0.11l-3.69-4.04c-0.13-0.14-0.12-0.35,0.02-0.48
    c0.14-0.13,0.35-0.12,0.48,0.02l3.69,4.04c0.13,0.14,0.12,0.35-0.02,0.48C73.08,25.75,73,25.78,72.92,25.78z"
      fill="#FFFFFF"
    />
    <path
      d="M72.92,32.31c-0.09,0-0.18-0.04-0.25-0.11l-3.69-4.04c-0.13-0.14-0.12-0.35,0.02-0.48
    c0.14-0.13,0.35-0.12,0.48,0.02l3.69,4.04c0.13,0.14,0.12,0.35-0.02,0.48C73.08,32.28,73,32.31,72.92,32.31z"
      fill="#FFFFFF"
    />
    <path
      d="M72.92,38.83c-0.09,0-0.18-0.04-0.25-0.11l-3.69-4.04c-0.13-0.14-0.12-0.35,0.02-0.48
    c0.14-0.13,0.35-0.12,0.48,0.02l3.69,4.04c0.13,0.14,0.12,0.35-0.02,0.48C73.08,38.8,73,38.83,72.92,38.83z"
      fill="#FFFFFF"
    />
    <path
      d="M72.92,35.57c-0.09,0-0.18-0.04-0.25-0.11l-3.69-4.04c-0.13-0.14-0.12-0.35,0.02-0.48
    c0.14-0.13,0.35-0.12,0.48,0.02L73.17,35c0.13,0.14,0.12,0.35-0.02,0.48C73.08,35.54,73,35.57,72.92,35.57z"
      fill="#FFFFFF"
    />
    <path
      d="M73.14,56.18c-0.19,0-0.34-0.15-0.34-0.34V13.25c0-0.19,0.15-0.34,0.34-0.34c0.19,0,0.34,0.15,0.34,0.34
    v42.59C73.48,56.03,73.32,56.18,73.14,56.18z"
      fill="#FFFFFF"
    />
    <path
      d="M72.92,29.04c-0.09,0-0.18-0.04-0.25-0.11l-3.69-4.04c-0.13-0.14-0.12-0.35,0.02-0.48
    c0.14-0.13,0.35-0.12,0.48,0.02l3.69,4.04c0.13,0.14,0.12,0.35-0.02,0.48C73.08,29.02,73,29.04,72.92,29.04z"
      fill="#FFFFFF"
    />
    <path
      d="M23.01,77.94c-0.19,0-0.34-0.15-0.34-0.34c0-2.92-4.62-2.98-4.67-2.98H0.03c-0.19,0-0.34-0.15-0.34-0.34
    c0-0.19,0.15-0.34,0.34-0.34h17.98c1.85,0.01,5.35,0.78,5.35,3.66C23.36,77.79,23.2,77.94,23.01,77.94z"
      fill="#FFFFFF"
    />
    <path
      d="M10.4,77.72c-0.08,0-0.16-0.03-0.23-0.09l-3.33-3.04c-0.14-0.13-0.15-0.34-0.02-0.48
    c0.13-0.14,0.34-0.15,0.48-0.02l3.33,3.04c0.14,0.13,0.15,0.34,0.02,0.48C10.59,77.68,10.5,77.72,10.4,77.72z"
      fill="#FFFFFF"
    />
    <path
      d="M15.78,77.72c-0.08,0-0.16-0.03-0.23-0.09l-3.33-3.04c-0.14-0.13-0.15-0.34-0.02-0.48
    c0.13-0.14,0.34-0.15,0.48-0.02l3.33,3.04c0.14,0.13,0.15,0.34,0.02,0.48C15.96,77.68,15.87,77.72,15.78,77.72z"
      fill="#FFFFFF"
    />
    <path
      d="M21.15,77.72c-0.08,0-0.16-0.03-0.23-0.09l-3.33-3.04c-0.14-0.13-0.15-0.34-0.02-0.48
    c0.13-0.14,0.34-0.15,0.48-0.02l3.33,3.04c0.14,0.13,0.15,0.34,0.02,0.48C21.33,77.68,21.24,77.72,21.15,77.72z"
      fill="#FFFFFF"
    />
    <path
      d="M18.46,77.72c-0.08,0-0.16-0.03-0.23-0.09l-3.33-3.04c-0.14-0.13-0.15-0.34-0.02-0.48
    c0.13-0.14,0.34-0.15,0.48-0.02l3.33,3.04c0.14,0.13,0.15,0.34,0.02,0.48C18.65,77.68,18.55,77.72,18.46,77.72z"
      fill="#FFFFFF"
    />
    <path
      d="M35.51,77.9H4.78c-0.19,0-0.34-0.15-0.34-0.34c0-0.19,0.15-0.34,0.34-0.34h30.73c0.19,0,0.34,0.15,0.34,0.34
    C35.85,77.75,35.7,77.9,35.51,77.9z"
      fill="#FFFFFF"
    />
    <path
      d="M13.09,77.72c-0.08,0-0.16-0.03-0.23-0.09l-3.33-3.04c-0.14-0.13-0.15-0.34-0.02-0.48
    c0.13-0.14,0.34-0.15,0.48-0.02l3.33,3.04c0.14,0.13,0.15,0.34,0.02,0.48C13.28,77.68,13.18,77.72,13.09,77.72z"
      fill="#FFFFFF"
    />
    <path
      d="M73.14,59.82c-1.46,0-2.65-1.19-2.65-2.65c0-1.46,1.19-2.65,2.65-2.65c1.46,0,2.65,1.19,2.65,2.65
    C75.78,58.63,74.6,59.82,73.14,59.82z M73.14,55.21c-1.08,0-1.96,0.88-1.96,1.96c0,1.08,0.88,1.96,1.96,1.96
    c1.08,0,1.96-0.88,1.96-1.96C75.1,56.09,74.22,55.21,73.14,55.21z"
      fill="#FFFFFF"
    />
    <path
      d="M71.17,23.49c-0.19,0-0.34-0.15-0.34-0.34v-7.21c0-0.19,0.15-0.34,0.34-0.34c0.19,0,0.34,0.15,0.34,0.34
    v7.21C71.51,23.34,71.36,23.49,71.17,23.49z"
      fill="#FFFFFF"
    />
    <g>
      <defs>
        <rect
          height="12.97"
          id="SVGID_3_"
          transform="matrix(0.9755 -0.2198 0.2198 0.9755 -9.6812 9.9149)"
          width="4.8"
          x="37.32"
          y="41.98"
        />
      </defs>
      <clipPath id="SVGID_4_">
        <use xlink:href="#SVGID_3_" />
      </clipPath>
      <path
        clip-path="url(#SVGID_4_)"
        d="M44.3,53.38c-0.04,0-0.08-0.01-0.13-0.02c-0.13-0.05-3.19-1.3-3.93-4.61
      c-0.75-3.33,1.56-5.48,1.66-5.57c0.14-0.13,0.36-0.12,0.48,0.02c0.13,0.14,0.12,0.35-0.02,0.48c-0.09,0.08-2.12,1.99-1.46,4.92
      c0.67,2.95,3.49,4.12,3.52,4.13c0.17,0.07,0.26,0.27,0.19,0.44C44.56,53.29,44.44,53.38,44.3,53.38z"
        fill="#FFFFFF"
      />
      <path
        clip-path="url(#SVGID_4_)"
        d="M41.88,54.56c-0.64,0-1.4-0.12-2.1-0.51c-1.02-0.57-1.7-1.6-2.03-3.05
      c-0.04-0.18,0.07-0.37,0.26-0.41c1.48-0.33,2.75-0.17,3.77,0.48c1.55,1,1.94,2.77,1.96,2.84c0.04,0.17-0.06,0.34-0.22,0.39
      C43.47,54.33,42.78,54.56,41.88,54.56z M38.5,51.19c0.3,1.07,0.84,1.83,1.61,2.26c1.05,0.59,2.3,0.43,2.87,0.3
      c-0.17-0.5-0.62-1.49-1.57-2.1C40.62,51.15,39.65,50.99,38.5,51.19z"
        fill="#FFFFFF"
      />
      <path
        clip-path="url(#SVGID_4_)"
        d="M39.5,51.11c-0.05,0-0.1-0.01-0.15-0.03c-0.94-0.45-1.45-1.52-1.51-3.19
      c-0.01-0.18,0.12-0.33,0.3-0.35c1.72-0.21,2.67,0.96,2.71,1.01c0.12,0.15,0.09,0.36-0.05,0.48c-0.15,0.12-0.36,0.09-0.48-0.05
      c-0.03-0.03-0.65-0.77-1.78-0.77h0c0.09,1.2,0.47,1.96,1.1,2.27c0.17,0.08,0.24,0.28,0.16,0.45C39.75,51.04,39.63,51.11,39.5,51.11
      z"
        fill="#FFFFFF"
      />
      <path
        clip-path="url(#SVGID_4_)"
        d="M39.63,48.41c-0.12,0-0.24-0.07-0.3-0.18c-0.03-0.06-0.82-1.58-0.52-2.75
      c0.04-0.14,0.16-0.25,0.31-0.26c0.97-0.06,1.69,0.59,1.72,0.62c0.14,0.13,0.15,0.34,0.02,0.48c-0.13,0.14-0.34,0.15-0.48,0.02
      c-0.01-0.01-0.41-0.36-0.95-0.43c-0.05,0.74,0.33,1.66,0.51,1.99c0.09,0.17,0.02,0.37-0.14,0.46
      C39.74,48.4,39.68,48.41,39.63,48.41z"
        fill="#FFFFFF"
      />
      <path
        clip-path="url(#SVGID_4_)"
        d="M40.51,46.03c-0.11,0-0.22-0.05-0.28-0.15c-0.02-0.03-0.52-0.79-0.39-1.74
      c0.12-0.86,0.74-2.38,0.77-2.45c0.07-0.17,0.27-0.26,0.45-0.19c0.17,0.07,0.26,0.27,0.19,0.45c-0.01,0.02-0.62,1.5-0.72,2.28
      c-0.09,0.68,0.28,1.26,0.28,1.27c0.1,0.16,0.06,0.37-0.1,0.47C40.64,46.01,40.58,46.03,40.51,46.03z"
        fill="#FFFFFF"
      />
    </g>
    <g>
      <defs>
        <rect
          height="12.97"
          id="SVGID_5_"
          transform="matrix(0.9755 -0.2198 0.2198 0.9755 -9.3351 10.9174)"
          width="4.8"
          x="42"
          y="40.92"
        />
      </defs>
      <clipPath id="SVGID_6_">
        <use xlink:href="#SVGID_5_" />
      </clipPath>
      <path
        clip-path="url(#SVGID_6_)"
        d="M42.21,53.85c-0.08,0-0.17-0.03-0.23-0.09c-0.14-0.13-0.15-0.34-0.02-0.48
      c0.02-0.02,2.07-2.29,1.41-5.24c-0.66-2.93-3.31-3.78-3.43-3.82c-0.18-0.06-0.28-0.25-0.23-0.43c0.05-0.18,0.24-0.28,0.42-0.23
      c0.13,0.04,3.14,0.99,3.89,4.32c0.75,3.31-1.48,5.75-1.58,5.85C42.39,53.81,42.3,53.85,42.21,53.85z"
        fill="#FFFFFF"
      />
      <path
        clip-path="url(#SVGID_6_)"
        d="M43.43,54.33c-0.16,0-0.29-0.11-0.33-0.26c-0.02-0.07-0.43-1.84,0.55-3.41
      c0.64-1.03,1.72-1.72,3.2-2.06c0.09-0.02,0.18,0,0.26,0.04c0.08,0.05,0.13,0.12,0.15,0.21c0.33,1.45,0.15,2.67-0.53,3.62
      c-1.12,1.58-3.17,1.83-3.26,1.84C43.46,54.33,43.44,54.33,43.43,54.33z M46.66,49.35c-1.12,0.31-1.94,0.87-2.43,1.66
      c-0.6,0.97-0.58,2.05-0.52,2.57c0.57-0.13,1.76-0.51,2.46-1.5C46.68,51.37,46.84,50.45,46.66,49.35z"
        fill="#FFFFFF"
      />
      <path
        clip-path="url(#SVGID_6_)"
        d="M45.57,49.74c-0.08,0-0.15-0.03-0.22-0.08c-0.15-0.12-0.17-0.33-0.05-0.48
      c0.45-0.55,0.45-1.4,0.02-2.52c-1.03,0.49-1.27,1.42-1.28,1.46c-0.04,0.18-0.23,0.29-0.41,0.25c-0.18-0.04-0.3-0.23-0.25-0.41
      c0.01-0.06,0.37-1.52,2.02-2.07c0.17-0.06,0.35,0.03,0.42,0.19c0.66,1.53,0.66,2.72,0,3.53C45.77,49.7,45.67,49.74,45.57,49.74z"
        fill="#FFFFFF"
      />
      <path
        clip-path="url(#SVGID_6_)"
        d="M44.3,47.36l-0.01,0c-0.19-0.01-0.33-0.17-0.33-0.35c0-0.01,0.05-1.25-0.4-2.02
      c-0.46,0.29-0.67,0.79-0.68,0.8c-0.07,0.17-0.27,0.26-0.44,0.19c-0.17-0.07-0.26-0.27-0.19-0.44c0.02-0.04,0.39-0.94,1.29-1.3
      c0.14-0.05,0.29-0.01,0.39,0.1c0.77,0.93,0.71,2.63,0.7,2.71C44.63,47.21,44.48,47.36,44.3,47.36z"
        fill="#FFFFFF"
      />
      <path
        clip-path="url(#SVGID_6_)"
        d="M42.48,45.58c-0.02,0-0.03,0-0.05,0c-0.19-0.03-0.31-0.2-0.29-0.39
      c0-0.01,0.09-0.69-0.29-1.27c-0.43-0.66-1.62-1.74-1.63-1.75c-0.14-0.13-0.15-0.34-0.02-0.48c0.13-0.14,0.34-0.15,0.48-0.02
      c0.05,0.05,1.27,1.15,1.74,1.88c0.52,0.8,0.4,1.7,0.39,1.74C42.79,45.46,42.65,45.58,42.48,45.58z"
        fill="#FFFFFF"
      />
    </g>
    <path
      d="M30.93,56.11c-0.73,0-1.33-0.6-1.33-1.33c0-0.73,0.6-1.33,1.33-1.33c0.73,0,1.33,0.6,1.33,1.33
    C32.26,55.52,31.66,56.11,30.93,56.11z M30.93,54.14c-0.36,0-0.65,0.29-0.65,0.65c0,0.36,0.29,0.64,0.65,0.64
    c0.36,0,0.65-0.29,0.65-0.64C31.58,54.43,31.29,54.14,30.93,54.14z"
      fill="#FFFFFF"
    />
    <path
      d="M65.18,29.25c-0.73,0-1.33-0.6-1.33-1.33c0-0.73,0.6-1.33,1.33-1.33c0.73,0,1.33,0.6,1.33,1.33
    C66.51,28.66,65.91,29.25,65.18,29.25z M65.18,27.28c-0.36,0-0.65,0.29-0.65,0.65c0,0.36,0.29,0.64,0.65,0.64
    c0.36,0,0.65-0.29,0.65-0.64C65.83,27.57,65.54,27.28,65.18,27.28z"
      fill="#FFFFFF"
    />
    <path
      d="M51.57,49.79c-0.73,0-1.33-0.6-1.33-1.33c0-0.73,0.6-1.33,1.33-1.33c0.73,0,1.33,0.6,1.33,1.33
    C52.9,49.19,52.3,49.79,51.57,49.79z M51.57,47.82c-0.36,0-0.65,0.29-0.65,0.64c0,0.36,0.29,0.65,0.65,0.65
    c0.36,0,0.65-0.29,0.65-0.65C52.22,48.11,51.93,47.82,51.57,47.82z"
      fill="#FFFFFF"
    />
    <path
      d="M58.97,51.19c-0.73,0-1.33-0.6-1.33-1.33c0-0.73,0.6-1.33,1.33-1.33s1.33,0.6,1.33,1.33
    C60.3,50.59,59.7,51.19,58.97,51.19z M58.97,49.22c-0.36,0-0.65,0.29-0.65,0.65c0,0.36,0.29,0.65,0.65,0.65
    c0.36,0,0.65-0.29,0.65-0.65C59.61,49.51,59.32,49.22,58.97,49.22z"
      fill="#FFFFFF"
    />
    <path
      d="M19.74,71.92c-0.73,0-1.33-0.6-1.33-1.33c0-0.73,0.6-1.33,1.33-1.33c0.73,0,1.33,0.6,1.33,1.33
    C21.07,71.33,20.47,71.92,19.74,71.92z M19.74,69.95c-0.36,0-0.65,0.29-0.65,0.65c0,0.36,0.29,0.64,0.65,0.64
    c0.36,0,0.65-0.29,0.65-0.64C20.39,70.24,20.1,69.95,19.74,69.95z"
      fill="#FFFFFF"
    />
    <path
      d="M116.1,77.74c-0.53,0-0.92-0.24-0.94-0.25c-0.16-0.1-0.21-0.31-0.11-0.47c0.1-0.16,0.31-0.21,0.47-0.11
    c0.02,0.01,0.46,0.27,0.97,0.07c0.55-0.21,1.02-0.89,1.35-1.97c0.6-1.96,2.96-8.36,7.89-8.36c1.07,0,2.04,0.42,2.73,1.17
    c0.67,0.73,1.04,1.71,1.04,2.76c0,1.56-1.22,2.65-2.97,2.65c-1.06,0-2.03-0.45-2.52-1.17c-0.36-0.53-0.43-1.15-0.2-1.76
    c0.19-0.5,0.51-0.7,0.75-0.78c0.62-0.21,1.27,0.15,1.34,0.19c0.16,0.1,0.22,0.31,0.12,0.47c-0.1,0.16-0.31,0.22-0.47,0.12
    c-0.12-0.07-0.49-0.23-0.77-0.14c-0.15,0.05-0.25,0.18-0.33,0.38c-0.15,0.4-0.11,0.79,0.12,1.13c0.36,0.53,1.13,0.87,1.96,0.87
    c1.37,0,2.29-0.79,2.29-1.97c0-0.88-0.3-1.7-0.86-2.3c-0.56-0.61-1.36-0.95-2.23-0.95c-1.13,0-4.99,0.57-7.24,7.88
    c-0.4,1.31-1,2.12-1.78,2.41C116.5,77.71,116.29,77.74,116.1,77.74z"
      fill="#FFFFFF"
    />
    <path
      d="M112.44,61.24c-0.33,0-0.66-0.02-0.99-0.07c-3.75-0.51-6.96-3.73-9.04-6.43
    c-5.56,2.33-12.12,1.73-17.19-1.58c-4.4-2.88-6.92-7.26-6.92-12.03c0-4.71,1.35-8.11,4-10.08c2.07-1.54,4.47-1.86,6.12-1.86
    c1.89,0,3.76,0.72,5.12,1.97c1.46,1.34,2.27,3.18,2.27,5.2c0,2.02-0.63,3.77-1.82,5.06c-1.12,1.21-2.61,1.87-4.2,1.87
    c-3,0-5.34-2.05-5.34-4.66c0-1.47,0.44-2.68,1.23-3.43c0.61-0.57,1.41-0.84,2.32-0.78c1.76,0.11,2.82,1.53,2.82,2.84v0.01
    c0,0.56-0.25,1.15-0.62,1.51c-0.3,0.29-0.68,0.44-1.1,0.42c-1.16-0.05-1.69-0.92-1.69-1.71c0-0.85,0.59-1.71,1.71-1.71
    c0.16,0,0.3,0.02,0.44,0.05c-0.37-0.4-0.92-0.69-1.6-0.73c-0.73-0.05-1.33,0.15-1.81,0.6c-0.65,0.61-1.01,1.65-1.01,2.93
    c0,1.96,1.74,3.98,4.66,3.98c2.59,0,5.34-2.19,5.34-6.25c0-3.19-2.51-6.48-6.71-6.48c-3.52,0-9.44,1.46-9.44,11.26
    c0,4.53,2.41,8.71,6.61,11.46c4.95,3.24,11.39,3.78,16.79,1.42c0.14-0.06,0.31-0.02,0.41,0.11c3.62,4.78,6.76,6.11,8.75,6.38
    c1.81,0.25,3.55-0.25,5.17-1.49c1.11-0.96,1.69-2.33,1.57-3.76c-0.12-1.42-0.89-2.66-2.13-3.41c-1.03-0.62-2.18-0.7-3.15-0.19
    c-0.99,0.51-1.64,1.52-1.73,2.7c-0.07,0.97,0.78,1.8,1.65,2.05c0.38,0.11,1.33,0.26,1.83-0.71c0.09-0.17,0.29-0.23,0.46-0.14
    c0.17,0.09,0.23,0.29,0.14,0.46c-0.5,0.96-1.51,1.37-2.62,1.05c-1.12-0.32-2.25-1.39-2.14-2.75c0.11-1.42,0.89-2.64,2.1-3.26
    c1.19-0.61,2.58-0.53,3.82,0.22c1.43,0.87,2.32,2.3,2.46,3.94c0.14,1.65-0.53,3.23-1.82,4.35
    C115.66,60.67,114.08,61.24,112.44,61.24z M89.1,36.47c-0.71,0-1.02,0.51-1.02,1.02c0,0.45,0.27,0.99,1.04,1.02
    c0.23,0.01,0.43-0.07,0.6-0.23c0.27-0.26,0.4-0.68,0.41-0.98C90.08,37.13,89.86,36.47,89.1,36.47z"
      fill="#FFFFFF"
    />
    <path
      d="M88.66,54.89c-0.04,0-0.09-0.01-0.13-0.03c-3.38-1.41-4.58-4.05-4.58-6.06c0-0.8,0.46-1.92,1.35-2.51
    c0.63-0.43,1.35-0.5,2.06-0.2c1.37,0.56,1.21,2.11,0.94,2.85c-0.07,0.18-0.26,0.27-0.44,0.2c-0.18-0.07-0.27-0.26-0.2-0.44
    c0.02-0.06,0.55-1.54-0.55-1.99c-0.5-0.21-0.98-0.16-1.42,0.14c-0.66,0.44-1.05,1.34-1.05,1.95c0,1.78,1.09,4.14,4.16,5.43
    c0.17,0.07,0.26,0.27,0.18,0.45C88.92,54.81,88.79,54.89,88.66,54.89z"
      fill="#FFFFFF"
    />
    <path
      d="M95.26,52.03c-1.32,0-2.78-0.64-3.44-1.54c-0.87-1.18-1.09-4.3,0.77-6.86c2.36-3.22,3.06-5.89,2.33-8.92
    c-0.04-0.18,0.07-0.37,0.25-0.41c0.18-0.04,0.37,0.07,0.41,0.25c0.77,3.2,0.01,6.13-2.44,9.48c-1.62,2.21-1.54,5.01-0.78,6.05
    c0.58,0.79,2.05,1.36,3.21,1.25c0.55-0.05,1.29-0.29,1.67-1.09c0.39-0.82,0.42-1.56,0.1-2.2c-0.34-0.68-1.03-1.09-1.42-1.17
    c-0.88-0.18-1.22,0.63-1.25,0.72c-0.07,0.18-0.26,0.26-0.44,0.2c-0.18-0.07-0.27-0.26-0.2-0.44c0.19-0.5,0.85-1.39,2.03-1.15
    c0.5,0.1,1.42,0.59,1.89,1.53c0.29,0.57,0.51,1.52-0.09,2.79c-0.4,0.85-1.19,1.38-2.22,1.48C95.51,52.02,95.39,52.03,95.26,52.03z"
      fill="#FFFFFF"
    />
    <path
      d="M82.08,32c-0.1,0-0.2-0.05-0.27-0.13c-0.12-0.15-0.09-0.36,0.06-0.48c5.1-3.97,5.32-6.37,5-7.44
    c-0.3-0.98-1.21-1.48-1.77-1.52c-0.56-0.04-1.04,0.12-1.37,0.46c-0.36,0.37-0.54,0.93-0.48,1.49c0.09,0.82,1.18,0.89,1.23,0.89
    c0.19,0.01,0.33,0.17,0.32,0.36c-0.01,0.19-0.17,0.33-0.35,0.33c-0.6-0.02-1.76-0.37-1.88-1.5c-0.08-0.76,0.17-1.53,0.67-2.04
    c0.33-0.34,0.93-0.74,1.91-0.66c0.88,0.06,2.01,0.79,2.37,2c0.46,1.52-0.08,4.17-5.23,8.18C82.22,31.98,82.15,32,82.08,32z"
      fill="#FFFFFF"
    />
    <path
      d="M115.34,77.54c-0.05,0-0.1-0.01-0.15-0.04c-0.17-0.09-0.24-0.29-0.15-0.46c0.04-0.08,3.93-7.85,3.37-13.61
    c-8.45,1.6-14.65-0.53-16.73-5.75c-4.04,1.35-16.26,3.97-25.75-9.25c-2.42,4.43-1.74,6.27-1.73,6.29c0.07,0.17-0.01,0.37-0.19,0.44
    c-0.17,0.07-0.37-0.01-0.44-0.18c-0.04-0.09-0.9-2.23,2.03-7.36c0.06-0.1,0.16-0.17,0.28-0.17c0.12,0,0.23,0.05,0.3,0.15
    c9.52,13.7,22,10.61,25.6,9.33c0.18-0.06,0.37,0.03,0.44,0.21c1.85,5.17,7.99,7.25,16.43,5.55c0.09-0.02,0.19,0,0.27,0.06
    c0.08,0.05,0.13,0.14,0.14,0.23c0.34,2.53-0.13,5.72-1.4,9.48c-0.94,2.79-1.99,4.87-2,4.89C115.59,77.48,115.47,77.54,115.34,77.54z
    "
      fill="#FFFFFF"
    />
    <path
      d="M73.09,41.1c-0.19,0-0.34-0.15-0.34-0.34c0-0.19,0.15-0.34,0.34-0.34c3.61,0,3.69-5.68,3.69-5.74V17.31
    c0-0.19,0.15-0.34,0.34-0.34c0.19,0,0.34,0.15,0.34,0.34v17.37C77.45,36.9,76.52,41.1,73.09,41.1z"
      fill="#FFFFFF"
    />
    <path
      d="M73.35,25.78c-0.08,0-0.16-0.03-0.23-0.09c-0.14-0.13-0.15-0.34-0.02-0.48l3.69-4.04
    c0.13-0.14,0.34-0.15,0.48-0.02c0.14,0.13,0.15,0.34,0.02,0.48l-3.69,4.04C73.54,25.75,73.45,25.78,73.35,25.78z"
      fill="#FFFFFF"
    />
    <path
      d="M73.35,32.31c-0.08,0-0.16-0.03-0.23-0.09c-0.14-0.13-0.15-0.34-0.02-0.48l3.69-4.04
    c0.13-0.14,0.34-0.15,0.48-0.02c0.14,0.13,0.15,0.34,0.02,0.48l-3.69,4.04C73.54,32.27,73.45,32.31,73.35,32.31z"
      fill="#FFFFFF"
    />
    <path
      d="M73.35,38.83c-0.08,0-0.16-0.03-0.23-0.09c-0.14-0.13-0.15-0.34-0.02-0.48l3.69-4.04
    c0.13-0.14,0.34-0.15,0.48-0.02c0.14,0.13,0.15,0.34,0.02,0.48l-3.69,4.04C73.54,38.79,73.45,38.83,73.35,38.83z"
      fill="#FFFFFF"
    />
    <path
      d="M73.35,35.57c-0.08,0-0.16-0.03-0.23-0.09c-0.14-0.13-0.15-0.34-0.02-0.48l3.69-4.04
    c0.13-0.14,0.34-0.15,0.48-0.02c0.14,0.13,0.15,0.34,0.02,0.48l-3.69,4.04C73.54,35.53,73.45,35.57,73.35,35.57z"
      fill="#FFFFFF"
    />
    <path
      d="M73.14,56.18c-0.19,0-0.34-0.15-0.34-0.34V13.25c0-0.19,0.15-0.34,0.34-0.34c0.19,0,0.34,0.15,0.34,0.34
    v42.59C73.48,56.03,73.32,56.18,73.14,56.18z"
      fill="#FFFFFF"
    />
    <path
      d="M73.35,29.04c-0.08,0-0.16-0.03-0.23-0.09c-0.14-0.13-0.15-0.34-0.02-0.48l3.69-4.04
    c0.13-0.14,0.34-0.15,0.48-0.02c0.14,0.13,0.15,0.34,0.02,0.48l-3.69,4.04C73.54,29.01,73.45,29.04,73.35,29.04z"
      fill="#FFFFFF"
    />
    <path
      d="M123.26,77.94c-0.19,0-0.34-0.15-0.34-0.34c0-2.87,3.5-3.65,5.35-3.66h17.98c0.19,0,0.34,0.15,0.34,0.34
    c0,0.19-0.15,0.34-0.34,0.34h-17.98c-0.04,0-4.67,0.06-4.67,2.98C123.6,77.79,123.45,77.94,123.26,77.94z"
      fill="#FFFFFF"
    />
    <path
      d="M135.87,77.72c-0.09,0-0.18-0.04-0.25-0.11c-0.13-0.14-0.12-0.35,0.02-0.48l3.33-3.04
    c0.14-0.13,0.35-0.12,0.48,0.02c0.13,0.14,0.12,0.35-0.02,0.48l-3.33,3.04C136.03,77.69,135.95,77.72,135.87,77.72z"
      fill="#FFFFFF"
    />
    <path
      d="M130.5,77.72c-0.09,0-0.18-0.04-0.25-0.11c-0.13-0.14-0.12-0.35,0.02-0.48l3.33-3.04
    c0.14-0.13,0.35-0.12,0.48,0.02c0.13,0.14,0.12,0.35-0.02,0.48l-3.33,3.04C130.66,77.69,130.58,77.72,130.5,77.72z"
      fill="#FFFFFF"
    />
    <path
      d="M125.12,77.72c-0.09,0-0.18-0.04-0.25-0.11c-0.13-0.14-0.12-0.35,0.02-0.48l3.33-3.04
    c0.14-0.13,0.36-0.12,0.48,0.02c0.13,0.14,0.12,0.35-0.02,0.48l-3.33,3.04C125.29,77.69,125.21,77.72,125.12,77.72z"
      fill="#FFFFFF"
    />
    <path
      d="M127.81,77.72c-0.09,0-0.18-0.04-0.25-0.11c-0.13-0.14-0.12-0.35,0.02-0.48l3.33-3.04
    c0.14-0.13,0.35-0.12,0.48,0.02c0.13,0.14,0.12,0.35-0.02,0.48l-3.33,3.04C127.97,77.69,127.89,77.72,127.81,77.72z"
      fill="#FFFFFF"
    />
    <path
      d="M141.49,77.9h-30.73c-0.19,0-0.34-0.15-0.34-0.34c0-0.19,0.15-0.34,0.34-0.34h30.73
    c0.19,0,0.34,0.15,0.34,0.34C141.83,77.75,141.68,77.9,141.49,77.9z"
      fill="#FFFFFF"
    />
    <path
      d="M133.18,77.72c-0.09,0-0.18-0.04-0.25-0.11c-0.13-0.14-0.12-0.35,0.02-0.48l3.33-3.04
    c0.14-0.13,0.36-0.12,0.48,0.02c0.13,0.14,0.12,0.35-0.02,0.48l-3.33,3.04C133.35,77.69,133.26,77.72,133.18,77.72z"
      fill="#FFFFFF"
    />
    <path
      d="M73.14,59.82c-1.46,0-2.65-1.19-2.65-2.65c0-1.46,1.19-2.65,2.65-2.65c1.46,0,2.65,1.19,2.65,2.65
    C75.78,58.63,74.6,59.82,73.14,59.82z M73.14,55.21c-1.08,0-1.96,0.88-1.96,1.96c0,1.08,0.88,1.96,1.96,1.96
    c1.08,0,1.96-0.88,1.96-1.96C75.1,56.09,74.22,55.21,73.14,55.21z"
      fill="#FFFFFF"
    />
    <path
      d="M75.1,23.49c-0.19,0-0.34-0.15-0.34-0.34v-7.21c0-0.19,0.15-0.34,0.34-0.34c0.19,0,0.34,0.15,0.34,0.34v7.21
    C75.44,23.34,75.29,23.49,75.1,23.49z"
      fill="#FFFFFF"
    />
    <g>
      <defs>
        <rect
          height="4.8"
          id="SVGID_7_"
          transform="matrix(0.2198 -0.9755 0.9755 0.2198 35.8576 141.761)"
          width="12.97"
          x="100.07"
          y="46.06"
        />
      </defs>
      <clipPath id="SVGID_8_">
        <use xlink:href="#SVGID_7_" />
      </clipPath>
      <path
        clip-path="url(#SVGID_8_)"
        d="M101.97,53.38c-0.14,0-0.26-0.08-0.32-0.22c-0.07-0.17,0.02-0.37,0.19-0.44
      c0.03-0.01,2.86-1.18,3.52-4.13c0.66-2.95-1.44-4.9-1.46-4.92c-0.14-0.13-0.15-0.34-0.02-0.48c0.13-0.14,0.34-0.15,0.48-0.02
      c0.1,0.09,2.41,2.24,1.66,5.57c-0.75,3.31-3.8,4.56-3.93,4.61C102.06,53.37,102.01,53.38,101.97,53.38z"
        fill="#FFFFFF"
      />
      <path
        clip-path="url(#SVGID_8_)"
        d="M104.39,54.56c-0.9,0-1.59-0.23-1.63-0.24c-0.16-0.06-0.26-0.22-0.22-0.39
      c0.02-0.08,0.4-1.85,1.96-2.84c1.02-0.66,2.29-0.82,3.77-0.48c0.18,0.04,0.3,0.22,0.26,0.41c-0.33,1.45-1.01,2.47-2.03,3.05
      C105.79,54.44,105.03,54.56,104.39,54.56z M102.87,53.99L102.87,53.99L102.87,53.99z M103.29,53.75c0.57,0.13,1.81,0.29,2.87-0.3
      c0.76-0.43,1.31-1.19,1.61-2.26c-1.15-0.2-2.13-0.04-2.91,0.46C103.9,52.26,103.46,53.25,103.29,53.75z"
        fill="#FFFFFF"
      />
      <path
        clip-path="url(#SVGID_8_)"
        d="M106.77,51.11c-0.13,0-0.25-0.07-0.31-0.19c-0.08-0.17-0.01-0.37,0.16-0.45
      c0.64-0.3,1.01-1.07,1.1-2.27h0c-1.13,0-1.75,0.74-1.78,0.77c-0.12,0.15-0.33,0.17-0.48,0.05c-0.15-0.12-0.17-0.33-0.05-0.48
      c0.04-0.05,0.99-1.21,2.71-1.01c0.18,0.02,0.31,0.17,0.3,0.35c-0.06,1.67-0.57,2.74-1.51,3.19
      C106.87,51.1,106.82,51.11,106.77,51.11z"
        fill="#FFFFFF"
      />
      <path
        clip-path="url(#SVGID_8_)"
        d="M106.64,48.41c-0.05,0-0.11-0.01-0.16-0.04c-0.17-0.09-0.23-0.29-0.14-0.46
      c0.01-0.01,0.58-1.11,0.51-2c-0.54,0.07-0.95,0.43-0.95,0.43c-0.14,0.13-0.36,0.12-0.48-0.02s-0.12-0.35,0.02-0.48
      c0.03-0.03,0.75-0.68,1.72-0.62c0.15,0.01,0.27,0.11,0.31,0.26c0.3,1.17-0.49,2.68-0.52,2.75
      C106.88,48.34,106.76,48.41,106.64,48.41z"
        fill="#FFFFFF"
      />
      <path
        clip-path="url(#SVGID_8_)"
        d="M105.76,46.03c-0.07,0-0.13-0.02-0.19-0.06c-0.16-0.11-0.2-0.32-0.09-0.47
      c0-0.01,0.38-0.58,0.28-1.27c-0.11-0.78-0.72-2.27-0.72-2.28c-0.07-0.17,0.01-0.37,0.19-0.45c0.17-0.07,0.37,0.01,0.45,0.19
      c0.03,0.06,0.65,1.59,0.77,2.45c0.13,0.95-0.37,1.71-0.39,1.74C105.97,45.97,105.87,46.03,105.76,46.03z"
        fill="#FFFFFF"
      />
    </g>
    <g>
      <defs>
        <rect
          height="4.8"
          id="SVGID_9_"
          transform="matrix(0.2198 -0.9755 0.9755 0.2198 33.2361 136.375)"
          width="12.97"
          x="95.39"
          y="45.01"
        />
      </defs>
      <clipPath id="SVGID_10_">
        <use xlink:href="#SVGID_9_" />
      </clipPath>
      <path
        clip-path="url(#SVGID_10_)"
        d="M104.06,53.85c-0.09,0-0.18-0.04-0.25-0.11c-0.1-0.1-2.32-2.54-1.58-5.85
      c0.75-3.33,3.76-4.28,3.89-4.32c0.18-0.05,0.37,0.05,0.43,0.23c0.05,0.18-0.05,0.37-0.23,0.43c-0.11,0.03-2.77,0.88-3.43,3.82
      c-0.67,2.96,1.39,5.22,1.41,5.24c0.13,0.14,0.12,0.35-0.02,0.48C104.23,53.82,104.14,53.85,104.06,53.85z"
        fill="#FFFFFF"
      />
      <path
        clip-path="url(#SVGID_10_)"
        d="M102.84,54.33c-0.01,0-0.03,0-0.04,0c-0.09-0.01-2.14-0.27-3.26-1.84
      c-0.68-0.96-0.85-2.17-0.53-3.62c0.02-0.09,0.07-0.17,0.15-0.21c0.08-0.05,0.17-0.06,0.26-0.04c1.48,0.33,2.56,1.03,3.2,2.06
      c0.98,1.57,0.57,3.34,0.55,3.41C103.14,54.22,103,54.33,102.84,54.33z M99.62,49.35c-0.18,1.1-0.02,2.01,0.48,2.73
      c0.7,0.99,1.89,1.37,2.46,1.5c0.06-0.53,0.08-1.61-0.52-2.58C101.55,50.22,100.73,49.67,99.62,49.35z"
        fill="#FFFFFF"
      />
      <path
        clip-path="url(#SVGID_10_)"
        d="M100.7,49.74c-0.1,0-0.2-0.04-0.26-0.13c-0.66-0.81-0.66-2,0-3.53
      c0.07-0.16,0.25-0.24,0.42-0.19c1.65,0.55,2.01,2.01,2.02,2.07c0.04,0.18-0.07,0.37-0.25,0.41c-0.18,0.04-0.37-0.07-0.41-0.25
      c-0.01-0.05-0.25-0.98-1.28-1.46c-0.43,1.13-0.42,1.97,0.02,2.52c0.12,0.15,0.1,0.36-0.05,0.48
      C100.85,49.72,100.78,49.74,100.7,49.74z"
        fill="#FFFFFF"
      />
      <path
        clip-path="url(#SVGID_10_)"
        d="M101.97,47.36c-0.18,0-0.33-0.14-0.34-0.33c0-0.07-0.07-1.78,0.7-2.71
      c0.09-0.11,0.25-0.15,0.39-0.1c0.9,0.36,1.28,1.26,1.29,1.3c0.07,0.17-0.01,0.37-0.19,0.44c-0.17,0.07-0.37-0.01-0.44-0.19
      c-0.01-0.01-0.22-0.51-0.68-0.8c-0.44,0.77-0.4,2.01-0.4,2.02c0.01,0.19-0.14,0.35-0.33,0.35L101.97,47.36z"
        fill="#FFFFFF"
      />
      <path
        clip-path="url(#SVGID_10_)"
        d="M103.79,45.58c-0.17,0-0.31-0.12-0.34-0.29c-0.01-0.04-0.13-0.94,0.39-1.74
      c0.48-0.73,1.69-1.84,1.74-1.88c0.14-0.13,0.36-0.12,0.48,0.02c0.13,0.14,0.12,0.36-0.02,0.48c-0.01,0.01-1.2,1.1-1.63,1.75
      c-0.38,0.58-0.29,1.26-0.29,1.27c0.03,0.19-0.1,0.36-0.29,0.39C103.83,45.58,103.81,45.58,103.79,45.58z"
        fill="#FFFFFF"
      />
    </g>
    <path
      d="M115.34,56.11c-0.73,0-1.33-0.6-1.33-1.33c0-0.73,0.6-1.33,1.33-1.33c0.73,0,1.33,0.6,1.33,1.33
    C116.67,55.52,116.07,56.11,115.34,56.11z M115.34,54.14c-0.36,0-0.65,0.29-0.65,0.65c0,0.36,0.29,0.64,0.65,0.64
    c0.36,0,0.65-0.29,0.65-0.64C115.99,54.43,115.7,54.14,115.34,54.14z"
      fill="#FFFFFF"
    />
    <path
      d="M81.09,29.25c-0.73,0-1.33-0.6-1.33-1.33c0-0.73,0.6-1.33,1.33-1.33c0.73,0,1.33,0.6,1.33,1.33
    C82.42,28.66,81.82,29.25,81.09,29.25z M81.09,27.28c-0.36,0-0.65,0.29-0.65,0.65c0,0.36,0.29,0.64,0.65,0.64
    c0.36,0,0.65-0.29,0.65-0.64C81.74,27.57,81.45,27.28,81.09,27.28z"
      fill="#FFFFFF"
    />
    <path
      d="M94.7,49.79c-0.73,0-1.33-0.6-1.33-1.33c0-0.73,0.6-1.33,1.33-1.33c0.73,0,1.33,0.6,1.33,1.33
    C96.03,49.19,95.43,49.79,94.7,49.79z M94.7,47.82c-0.36,0-0.65,0.29-0.65,0.64c0,0.36,0.29,0.65,0.65,0.65
    c0.36,0,0.65-0.29,0.65-0.65C95.34,48.11,95.05,47.82,94.7,47.82z"
      fill="#FFFFFF"
    />
    <path
      d="M87.3,51.19c-0.73,0-1.33-0.6-1.33-1.33c0-0.73,0.6-1.33,1.33-1.33c0.73,0,1.33,0.6,1.33,1.33
    C88.63,50.59,88.04,51.19,87.3,51.19z M87.3,49.22c-0.36,0-0.65,0.29-0.65,0.65c0,0.36,0.29,0.65,0.65,0.65
    c0.36,0,0.65-0.29,0.65-0.65C87.95,49.51,87.66,49.22,87.3,49.22z"
      fill="#FFFFFF"
    />
    <path
      d="M20.54,88.52c-1.07,0-2.04-0.42-2.73-1.17c-0.67-0.73-1.04-1.71-1.04-2.76c0-1.56,1.22-2.65,2.97-2.65
    c1.06,0,2.03,0.45,2.52,1.17c0.36,0.53,0.43,1.15,0.2,1.76c-0.19,0.5-0.51,0.7-0.75,0.78c-0.62,0.21-1.27-0.15-1.34-0.19
    c-0.16-0.1-0.22-0.31-0.12-0.47c0.1-0.16,0.31-0.22,0.47-0.12c0.12,0.07,0.49,0.23,0.77,0.14c0.15-0.05,0.25-0.18,0.33-0.38
    c0.15-0.4,0.11-0.79-0.12-1.13c-0.36-0.53-1.13-0.87-1.96-0.87c-1.37,0-2.29,0.79-2.29,1.97c0,0.88,0.3,1.7,0.86,2.3
    c0.56,0.61,1.36,0.95,2.23,0.95c1.13,0,4.99-0.57,7.24-7.88c0.4-1.31,1-2.12,1.78-2.41c0.83-0.31,1.53,0.12,1.56,0.14
    c0.16,0.1,0.21,0.31,0.11,0.47c-0.1,0.16-0.31,0.21-0.47,0.11c-0.02-0.01-0.46-0.27-0.97-0.08c-0.55,0.21-1.02,0.89-1.35,1.97
    C27.82,82.12,25.47,88.52,20.54,88.52z"
      fill="#FFFFFF"
    />
    <path
      d="M57.85,125.99c-1.89,0-3.75-0.72-5.12-1.97c-1.46-1.34-2.27-3.18-2.27-5.2c0-2.02,0.63-3.77,1.82-5.06
    c1.12-1.21,2.61-1.87,4.2-1.87c3,0,5.34,2.05,5.34,4.66c0,1.47-0.44,2.68-1.23,3.43c-0.61,0.57-1.41,0.84-2.32,0.78
    c-1.76-0.11-2.82-1.53-2.82-2.84v-0.01c0-0.56,0.25-1.15,0.62-1.51c0.3-0.29,0.68-0.44,1.1-0.42c1.16,0.05,1.69,0.92,1.69,1.71
    c0,0.85-0.59,1.71-1.71,1.71c-0.16,0-0.3-0.02-0.44-0.05c0.37,0.4,0.92,0.69,1.6,0.73c0.73,0.05,1.33-0.15,1.81-0.6
    c0.65-0.61,1.01-1.65,1.01-2.93c0-1.96-1.74-3.98-4.66-3.98c-2.59,0-5.34,2.19-5.34,6.25c0,3.19,2.51,6.48,6.71,6.48
    c3.52,0,9.44-1.46,9.44-11.26c0-4.53-2.41-8.71-6.61-11.46c-4.95-3.24-11.39-3.78-16.79-1.42c-0.14,0.06-0.31,0.02-0.41-0.11
    c-3.62-4.78-6.76-6.11-8.75-6.38c-1.81-0.25-3.55,0.25-5.17,1.49c-1.11,0.96-1.69,2.33-1.57,3.76c0.12,1.42,0.89,2.66,2.13,3.41
    c1.03,0.62,2.18,0.7,3.15,0.19c0.99-0.51,1.64-1.52,1.73-2.7c0.07-0.97-0.78-1.8-1.65-2.05c-0.38-0.11-1.33-0.26-1.83,0.71
    c-0.09,0.17-0.29,0.23-0.46,0.14c-0.17-0.09-0.23-0.29-0.14-0.46c0.5-0.96,1.51-1.36,2.62-1.05c1.12,0.32,2.25,1.39,2.14,2.75
    c-0.11,1.42-0.89,2.64-2.1,3.26c-1.19,0.61-2.58,0.53-3.82-0.22c-1.43-0.87-2.32-2.3-2.46-3.94c-0.14-1.65,0.53-3.23,1.82-4.34
    c1.79-1.36,3.7-1.91,5.7-1.63c3.75,0.51,6.96,3.73,9.04,6.43c5.56-2.33,12.12-1.74,17.19,1.58c4.4,2.88,6.92,7.26,6.92,12.03
    c0,4.71-1.35,8.11-4,10.08C61.9,125.67,59.5,125.99,57.85,125.99z M56.15,117.88c0.04,0.17,0.26,0.83,1.02,0.83
    c0.71,0,1.02-0.51,1.02-1.02c0-0.45-0.27-0.99-1.04-1.02c-0.23-0.01-0.43,0.07-0.6,0.23C56.29,117.16,56.16,117.57,56.15,117.88z"
      fill="#FFFFFF"
    />
    <path
      d="M59.77,109.27c-0.28,0-0.57-0.06-0.86-0.18c-1.37-0.56-1.21-2.11-0.94-2.85c0.07-0.18,0.26-0.27,0.44-0.2
    c0.18,0.07,0.27,0.26,0.2,0.44c-0.02,0.06-0.55,1.54,0.55,1.99c0.5,0.21,0.98,0.16,1.42-0.14c0.66-0.44,1.05-1.34,1.05-1.95
    c0-1.78-1.09-4.14-4.16-5.43c-0.17-0.07-0.26-0.27-0.18-0.45c0.07-0.17,0.27-0.26,0.45-0.18c3.38,1.41,4.58,4.05,4.58,6.06
    c0,0.8-0.46,1.92-1.35,2.51C60.6,109.14,60.19,109.27,59.77,109.27z"
      fill="#FFFFFF"
    />
    <path
      d="M51.02,120.9c-0.15,0-0.29-0.11-0.33-0.26c-0.77-3.2-0.01-6.13,2.44-9.48c1.62-2.21,1.54-5.01,0.78-6.05
    c-0.58-0.79-2.05-1.37-3.21-1.25c-0.55,0.05-1.29,0.29-1.67,1.09c-0.39,0.82-0.42,1.56-0.1,2.2c0.34,0.68,1.03,1.09,1.42,1.17
    c0.88,0.18,1.22-0.63,1.25-0.72c0.07-0.17,0.26-0.26,0.44-0.2c0.18,0.07,0.27,0.26,0.2,0.44c-0.19,0.5-0.85,1.39-2.03,1.15
    c-0.5-0.1-1.42-0.59-1.89-1.53c-0.29-0.57-0.51-1.52,0.09-2.79c0.4-0.85,1.19-1.38,2.22-1.48c1.41-0.14,3.09,0.53,3.82,1.52
    c0.87,1.18,1.09,4.3-0.77,6.86c-2.36,3.22-3.06,5.89-2.33,8.92c0.04,0.18-0.07,0.37-0.25,0.41C51.07,120.89,51.04,120.9,51.02,120.9
    z"
      fill="#FFFFFF"
    />
    <path
      d="M61.36,133.44c-0.07,0-0.15,0-0.23-0.01c-0.88-0.06-2.01-0.79-2.37-2c-0.46-1.52,0.08-4.17,5.23-8.18
    c0.15-0.12,0.36-0.09,0.48,0.06c0.12,0.15,0.09,0.36-0.06,0.48c-5.1,3.97-5.32,6.37-5,7.44c0.3,0.98,1.21,1.48,1.77,1.52
    c0.56,0.04,1.04-0.12,1.37-0.46c0.36-0.37,0.54-0.93,0.48-1.49c-0.09-0.82-1.18-0.89-1.23-0.89c-0.19-0.01-0.33-0.17-0.33-0.36
    c0.01-0.19,0.16-0.34,0.35-0.33c0.6,0.02,1.76,0.37,1.88,1.5c0.08,0.76-0.17,1.53-0.67,2.04C62.73,133.08,62.2,133.44,61.36,133.44z
    "
      fill="#FFFFFF"
    />
    <path
      d="M70.38,107.72c-0.11,0-0.22-0.05-0.28-0.15c-9.52-13.7-22-10.61-25.6-9.33c-0.18,0.06-0.37-0.03-0.44-0.21
    c-1.85-5.17-7.99-7.25-16.43-5.55c-0.09,0.02-0.19,0-0.27-0.06c-0.08-0.05-0.13-0.14-0.14-0.23c-0.34-2.53,0.13-5.72,1.4-9.48
    c0.94-2.79,1.99-4.87,2-4.89c0.09-0.17,0.29-0.24,0.46-0.15c0.17,0.08,0.24,0.29,0.15,0.46c-0.04,0.08-3.93,7.85-3.37,13.61
    c8.45-1.6,14.65,0.53,16.73,5.74c4.04-1.35,16.26-3.97,25.75,9.25c2.42-4.43,1.74-6.27,1.73-6.29c-0.07-0.17,0.01-0.37,0.19-0.44
    c0.17-0.07,0.37,0.01,0.44,0.18c0.04,0.09,0.9,2.23-2.03,7.36c-0.06,0.1-0.16,0.17-0.28,0.17L70.38,107.72z"
      fill="#FFFFFF"
    />
    <path
      d="M69.16,138.21c-0.19,0-0.34-0.15-0.34-0.34V120.5c0.01-2.22,0.94-6.42,4.37-6.42c0.19,0,0.34,0.15,0.34,0.34
    c0,0.19-0.15,0.34-0.34,0.34c-3.61,0-3.69,5.68-3.69,5.74v17.36C69.5,138.06,69.34,138.21,69.16,138.21z"
      fill="#FFFFFF"
    />
    <path
      d="M69.23,134.12c-0.08,0-0.16-0.03-0.23-0.09c-0.14-0.13-0.15-0.34-0.02-0.48l3.69-4.04
    c0.13-0.14,0.34-0.15,0.48-0.02c0.14,0.13,0.15,0.34,0.02,0.48l-3.69,4.04C69.41,134.08,69.32,134.12,69.23,134.12z"
      fill="#FFFFFF"
    />
    <path
      d="M69.23,127.59c-0.08,0-0.16-0.03-0.23-0.09c-0.14-0.13-0.15-0.34-0.02-0.48l3.69-4.04
    c0.13-0.14,0.34-0.15,0.48-0.02c0.14,0.13,0.15,0.34,0.02,0.48l-3.69,4.04C69.41,127.56,69.32,127.59,69.23,127.59z"
      fill="#FFFFFF"
    />
    <path
      d="M69.23,121.07c-0.08,0-0.16-0.03-0.23-0.09c-0.14-0.13-0.15-0.34-0.02-0.48l3.69-4.04
    c0.13-0.14,0.34-0.15,0.48-0.02c0.14,0.13,0.15,0.34,0.02,0.48l-3.69,4.04C69.41,121.03,69.32,121.07,69.23,121.07z"
      fill="#FFFFFF"
    />
    <path
      d="M69.23,124.33c-0.08,0-0.16-0.03-0.23-0.09c-0.14-0.13-0.15-0.34-0.02-0.48l3.69-4.04
    c0.13-0.14,0.34-0.15,0.48-0.02c0.14,0.13,0.15,0.34,0.02,0.48l-3.69,4.04C69.41,124.29,69.32,124.33,69.23,124.33z"
      fill="#FFFFFF"
    />
    <path
      d="M73.14,142.27c-0.19,0-0.34-0.15-0.34-0.34V99.34c0-0.19,0.15-0.34,0.34-0.34c0.19,0,0.34,0.15,0.34,0.34
    v42.59C73.48,142.12,73.32,142.27,73.14,142.27z"
      fill="#FFFFFF"
    />
    <path
      d="M69.23,130.86c-0.08,0-0.16-0.03-0.23-0.09c-0.14-0.13-0.15-0.34-0.02-0.48l3.69-4.04
    c0.13-0.14,0.34-0.15,0.48-0.02c0.14,0.13,0.15,0.34,0.02,0.48l-3.69,4.04C69.41,130.82,69.32,130.86,69.23,130.86z"
      fill="#FFFFFF"
    />
    <path
      d="M0.03,81.24c-0.19,0-0.34-0.15-0.34-0.34c0-0.19,0.15-0.34,0.34-0.34h17.98c0.04,0,4.67-0.06,4.67-2.98
    c0-0.19,0.15-0.34,0.34-0.34c0.19,0,0.34,0.15,0.34,0.34c0,2.87-3.5,3.65-5.35,3.66H0.03z"
      fill="#FFFFFF"
    />
    <path
      d="M7.08,81.18c-0.09,0-0.18-0.04-0.25-0.11c-0.13-0.14-0.12-0.35,0.02-0.48l3.33-3.04
    c0.14-0.13,0.35-0.12,0.48,0.02c0.13,0.14,0.12,0.35-0.02,0.48l-3.33,3.04C7.24,81.15,7.16,81.18,7.08,81.18z"
      fill="#FFFFFF"
    />
    <path
      d="M12.45,81.18c-0.09,0-0.18-0.04-0.25-0.11c-0.13-0.14-0.12-0.35,0.02-0.48l3.33-3.04
    c0.14-0.13,0.36-0.12,0.48,0.02c0.13,0.14,0.12,0.35-0.02,0.48l-3.33,3.04C12.62,81.15,12.53,81.18,12.45,81.18z"
      fill="#FFFFFF"
    />
    <path
      d="M17.82,81.18c-0.09,0-0.18-0.04-0.25-0.11c-0.13-0.14-0.12-0.35,0.02-0.48l3.33-3.04
    c0.14-0.13,0.36-0.12,0.48,0.02c0.13,0.14,0.12,0.35-0.02,0.48l-3.33,3.04C17.99,81.15,17.91,81.18,17.82,81.18z"
      fill="#FFFFFF"
    />
    <path
      d="M15.14,81.18c-0.09,0-0.18-0.04-0.25-0.11c-0.13-0.14-0.12-0.35,0.02-0.48l3.33-3.04
    c0.14-0.13,0.35-0.12,0.48,0.02c0.13,0.14,0.12,0.35-0.02,0.48l-3.33,3.04C15.3,81.15,15.22,81.18,15.14,81.18z"
      fill="#FFFFFF"
    />
    <path
      d="M35.51,77.96H4.78c-0.19,0-0.34-0.15-0.34-0.34s0.15-0.34,0.34-0.34h30.73c0.19,0,0.34,0.15,0.34,0.34
    S35.7,77.96,35.51,77.96z"
      fill="#FFFFFF"
    />
    <path
      d="M9.77,81.18c-0.09,0-0.18-0.04-0.25-0.11c-0.13-0.14-0.12-0.35,0.02-0.48l3.33-3.04
    c0.14-0.13,0.36-0.12,0.48,0.02c0.13,0.14,0.12,0.35-0.02,0.48L10,81.09C9.93,81.15,9.85,81.18,9.77,81.18z"
      fill="#FFFFFF"
    />
    <path
      d="M73.14,100.65c-1.46,0-2.65-1.19-2.65-2.65c0-1.46,1.19-2.65,2.65-2.65c1.46,0,2.65,1.19,2.65,2.65
    C75.78,99.47,74.6,100.65,73.14,100.65z M73.14,96.04c-1.08,0-1.96,0.88-1.96,1.96c0,1.08,0.88,1.96,1.96,1.96
    c1.08,0,1.96-0.88,1.96-1.96C75.1,96.92,74.22,96.04,73.14,96.04z"
      fill="#FFFFFF"
    />
    <path
      d="M71.17,139.58c-0.19,0-0.34-0.15-0.34-0.34v-7.21c0-0.19,0.15-0.34,0.34-0.34c0.19,0,0.34,0.15,0.34,0.34
    v7.21C71.51,139.43,71.36,139.58,71.17,139.58z"
      fill="#FFFFFF"
    />
    <g>
      <defs>
        <rect
          height="4.8"
          id="SVGID_11_"
          transform="matrix(0.2198 -0.9755 0.9755 0.2198 -73.1216 122.0028)"
          width="12.97"
          x="33.23"
          y="104.32"
        />
      </defs>
      <clipPath id="SVGID_12_">
        <use xlink:href="#SVGID_11_" />
      </clipPath>
      <path
        clip-path="url(#SVGID_12_)"
        d="M42.13,112.1c-0.08,0-0.16-0.03-0.23-0.09c-0.1-0.09-2.41-2.24-1.66-5.57
      c0.75-3.31,3.8-4.56,3.93-4.61c0.17-0.07,0.37,0.02,0.44,0.19c0.07,0.18-0.02,0.37-0.19,0.44c-0.03,0.01-2.86,1.18-3.52,4.13
      c-0.66,2.93,1.37,4.84,1.46,4.92c0.14,0.13,0.15,0.34,0.02,0.48C42.32,112.06,42.23,112.1,42.13,112.1z"
        fill="#FFFFFF"
      />
      <path
        clip-path="url(#SVGID_12_)"
        d="M39.43,104.76c-0.45,0-0.92-0.06-1.42-0.17c-0.18-0.04-0.3-0.22-0.26-0.41
      c0.33-1.45,1.01-2.47,2.03-3.05c1.68-0.94,3.65-0.3,3.73-0.27c0.16,0.06,0.26,0.22,0.22,0.39c-0.02,0.07-0.4,1.85-1.96,2.84
      C41.11,104.54,40.32,104.76,39.43,104.76z M38.5,103.99c1.15,0.2,2.12,0.04,2.9-0.46c0.96-0.61,1.4-1.6,1.57-2.1
      c-0.57-0.13-1.81-0.29-2.87,0.3C39.35,102.16,38.81,102.92,38.5,103.99z"
        fill="#FFFFFF"
      />
      <path
        clip-path="url(#SVGID_12_)"
        d="M38.55,107.67c-0.13,0-0.26-0.01-0.4-0.02c-0.18-0.02-0.31-0.17-0.3-0.35
      c0.06-1.67,0.57-2.74,1.51-3.19c0.17-0.08,0.37-0.01,0.45,0.16c0.08,0.17,0.01,0.37-0.16,0.45c-0.64,0.3-1.01,1.07-1.1,2.27h0
      c1.13,0,1.75-0.74,1.78-0.77c0.12-0.15,0.33-0.17,0.48-0.05c0.15,0.12,0.17,0.33,0.05,0.48C40.82,106.69,40.02,107.67,38.55,107.67
      z"
        fill="#FFFFFF"
      />
      <path
        clip-path="url(#SVGID_12_)"
        d="M39.25,109.96c-0.04,0-0.09,0-0.14,0c-0.15-0.01-0.27-0.11-0.31-0.26
      c-0.3-1.17,0.49-2.68,0.52-2.75c0.09-0.17,0.29-0.23,0.46-0.14c0.17,0.09,0.23,0.29,0.14,0.46c-0.01,0.01-0.58,1.11-0.51,2
      c0.54-0.07,0.95-0.43,0.95-0.43c0.14-0.13,0.35-0.12,0.48,0.02c0.13,0.14,0.12,0.35-0.02,0.48
      C40.81,109.37,40.15,109.96,39.25,109.96z"
        fill="#FFFFFF"
      />
      <path
        clip-path="url(#SVGID_12_)"
        d="M40.92,113.7c-0.13,0-0.26-0.08-0.32-0.21c-0.03-0.06-0.65-1.59-0.77-2.45
      c-0.13-0.95,0.37-1.71,0.39-1.74c0.11-0.16,0.32-0.2,0.47-0.09c0.16,0.1,0.2,0.32,0.09,0.47c-0.01,0.01-0.38,0.59-0.28,1.27
      c0.11,0.78,0.72,2.27,0.72,2.28c0.07,0.17-0.01,0.37-0.19,0.45C41.01,113.7,40.96,113.7,40.92,113.7z"
        fill="#FFFFFF"
      />
    </g>
    <g>
      <defs>
        <rect
          height="4.8"
          id="SVGID_13_"
          transform="matrix(0.2198 -0.9755 0.9755 0.2198 -70.5002 127.3888)"
          width="12.97"
          x="37.91"
          y="105.37"
        />
      </defs>
      <clipPath id="SVGID_14_">
        <use xlink:href="#SVGID_13_" />
      </clipPath>
      <path
        clip-path="url(#SVGID_14_)"
        d="M40.04,111.63c-0.15,0-0.28-0.09-0.33-0.24c-0.05-0.18,0.05-0.37,0.23-0.43
      c0.11-0.03,2.77-0.88,3.43-3.82c0.67-2.95-1.39-5.22-1.41-5.24c-0.13-0.14-0.12-0.35,0.02-0.48c0.14-0.13,0.35-0.12,0.48,0.02
      c0.1,0.1,2.32,2.54,1.58,5.85c-0.75,3.33-3.76,4.28-3.89,4.32C40.11,111.63,40.08,111.63,40.04,111.63z"
        fill="#FFFFFF"
      />
      <path
        clip-path="url(#SVGID_14_)"
        d="M46.92,106.59c-0.02,0-0.05,0-0.08-0.01c-1.48-0.33-2.56-1.03-3.2-2.06
      c-0.98-1.57-0.57-3.34-0.55-3.41c0.04-0.17,0.2-0.28,0.37-0.26c0.09,0.01,2.14,0.27,3.26,1.84c0.68,0.96,0.85,2.17,0.53,3.62
      C47.22,106.48,47.08,106.59,46.92,106.59z M43.71,101.59c-0.06,0.53-0.08,1.61,0.52,2.58c0.49,0.79,1.31,1.34,2.43,1.66
      c0.18-1.1,0.02-2.01-0.48-2.73C45.47,102.11,44.28,101.72,43.71,101.59z"
        fill="#FFFFFF"
      />
      <path
        clip-path="url(#SVGID_14_)"
        d="M45.52,109.3c-0.04,0-0.07-0.01-0.11-0.02c-1.65-0.55-2.01-2.01-2.02-2.07
      c-0.04-0.18,0.07-0.37,0.25-0.41c0.18-0.04,0.37,0.07,0.41,0.25c0.01,0.05,0.25,0.98,1.28,1.46c0.43-1.13,0.42-1.97-0.02-2.52
      c-0.12-0.15-0.1-0.36,0.05-0.48c0.15-0.12,0.36-0.1,0.48,0.05c0.66,0.81,0.66,2,0,3.53C45.78,109.22,45.65,109.3,45.52,109.3z"
        fill="#FFFFFF"
      />
      <path
        clip-path="url(#SVGID_14_)"
        d="M43.67,110.98c-0.04,0-0.08-0.01-0.13-0.02c-0.9-0.36-1.28-1.26-1.29-1.3
      c-0.07-0.17,0.01-0.37,0.19-0.44c0.17-0.07,0.37,0.01,0.44,0.19c0.01,0.01,0.22,0.51,0.68,0.8c0.44-0.77,0.4-2.01,0.4-2.02
      c-0.01-0.19,0.14-0.35,0.33-0.35c0.19,0,0.35,0.14,0.36,0.33c0,0.07,0.07,1.78-0.7,2.71C43.87,110.94,43.77,110.98,43.67,110.98z"
        fill="#FFFFFF"
      />
      <path
        clip-path="url(#SVGID_14_)"
        d="M40.45,113.6c-0.09,0-0.19-0.04-0.25-0.11c-0.13-0.14-0.12-0.36,0.02-0.48
      c0.01-0.01,1.2-1.1,1.63-1.75c0.38-0.58,0.29-1.26,0.29-1.27c-0.03-0.19,0.1-0.36,0.29-0.39c0.19-0.03,0.36,0.1,0.39,0.29
      c0.01,0.04,0.13,0.94-0.39,1.74c-0.48,0.73-1.69,1.84-1.74,1.88C40.62,113.57,40.54,113.6,40.45,113.6z"
        fill="#FFFFFF"
      />
    </g>
    <path
      d="M30.93,101.72c-0.73,0-1.33-0.6-1.33-1.33s0.6-1.33,1.33-1.33c0.73,0,1.33,0.6,1.33,1.33
    S31.66,101.72,30.93,101.72z M30.93,99.75c-0.36,0-0.65,0.29-0.65,0.65s0.29,0.65,0.65,0.65c0.36,0,0.65-0.29,0.65-0.65
    S31.29,99.75,30.93,99.75z"
      fill="#FFFFFF"
    />
    <path
      d="M65.18,128.58c-0.73,0-1.33-0.6-1.33-1.33s0.6-1.33,1.33-1.33c0.73,0,1.33,0.6,1.33,1.33
    S65.91,128.58,65.18,128.58z M65.18,126.61c-0.36,0-0.65,0.29-0.65,0.65c0,0.36,0.29,0.65,0.65,0.65c0.36,0,0.65-0.29,0.65-0.65
    C65.83,126.9,65.54,126.61,65.18,126.61z"
      fill="#FFFFFF"
    />
    <path
      d="M51.57,108.04c-0.73,0-1.33-0.6-1.33-1.33c0-0.73,0.6-1.33,1.33-1.33c0.73,0,1.33,0.6,1.33,1.33
    C52.9,107.45,52.3,108.04,51.57,108.04z M51.57,106.07c-0.36,0-0.65,0.29-0.65,0.64c0,0.36,0.29,0.65,0.65,0.65
    c0.36,0,0.65-0.29,0.65-0.65C52.22,106.36,51.93,106.07,51.57,106.07z"
      fill="#FFFFFF"
    />
    <path
      d="M58.97,106.65c-0.73,0-1.33-0.6-1.33-1.33c0-0.73,0.6-1.33,1.33-1.33s1.33,0.6,1.33,1.33
    C60.3,106.05,59.7,106.65,58.97,106.65z M58.97,104.67c-0.36,0-0.65,0.29-0.65,0.65c0,0.36,0.29,0.64,0.65,0.64
    c0.36,0,0.65-0.29,0.65-0.64C59.61,104.96,59.32,104.67,58.97,104.67z"
      fill="#FFFFFF"
    />
    <path
      d="M19.74,85.91c-0.73,0-1.33-0.6-1.33-1.33s0.6-1.33,1.33-1.33c0.73,0,1.33,0.6,1.33,1.33
    S20.47,85.91,19.74,85.91z M19.74,83.94c-0.36,0-0.65,0.29-0.65,0.65s0.29,0.65,0.65,0.65c0.36,0,0.65-0.29,0.65-0.65
    S20.1,83.94,19.74,83.94z"
      fill="#FFFFFF"
    />
    <path
      d="M125.74,88.52c-1.87,0-3.57-0.93-5.06-2.75c-1.48-1.82-2.36-4.05-2.84-5.6c-0.33-1.08-0.8-1.76-1.35-1.97
    c-0.51-0.19-0.95,0.06-0.97,0.08c-0.16,0.1-0.37,0.05-0.47-0.11c-0.1-0.16-0.05-0.37,0.11-0.47c0.03-0.02,0.73-0.45,1.56-0.14
    c0.77,0.29,1.37,1.1,1.78,2.41c2.25,7.31,6.11,7.88,7.24,7.88c0.87,0,1.67-0.34,2.23-0.95c0.55-0.6,0.86-1.42,0.86-2.3
    c0-1.18-0.92-1.97-2.29-1.97c-0.83,0-1.6,0.34-1.96,0.87c-0.23,0.34-0.28,0.73-0.12,1.13c0.08,0.21,0.19,0.33,0.33,0.38
    c0.27,0.1,0.65-0.07,0.77-0.14c0.16-0.09,0.37-0.04,0.47,0.12c0.1,0.16,0.04,0.37-0.12,0.47c-0.07,0.04-0.72,0.41-1.34,0.19
    c-0.24-0.08-0.56-0.28-0.75-0.78c-0.23-0.61-0.16-1.23,0.2-1.76c0.5-0.72,1.46-1.17,2.52-1.17c1.75,0,2.97,1.09,2.97,2.65
    c0,1.05-0.37,2.04-1.04,2.76C127.77,88.1,126.8,88.52,125.74,88.52z"
      fill="#FFFFFF"
    />
    <path
      d="M88.42,125.99c-3.04,0-10.12-1.16-10.12-11.94c0-4.77,2.52-9.15,6.92-12.03c5.07-3.32,11.63-3.91,17.19-1.58
    c2.08-2.7,5.3-5.92,9.04-6.43c2-0.27,3.91,0.27,5.68,1.62c1.31,1.13,1.97,2.71,1.84,4.36c-0.14,1.64-1.03,3.07-2.46,3.94
    c-1.24,0.75-2.63,0.83-3.82,0.22c-1.21-0.62-1.99-1.84-2.1-3.26c-0.1-1.36,1.03-2.44,2.14-2.75c1.12-0.32,2.12,0.08,2.62,1.05
    c0.09,0.17,0.02,0.37-0.14,0.46c-0.17,0.09-0.37,0.02-0.46-0.14c-0.5-0.97-1.45-0.82-1.83-0.71c-0.87,0.25-1.72,1.08-1.65,2.05
    c0.09,1.18,0.73,2.19,1.73,2.7c0.97,0.5,2.12,0.43,3.15-0.19c1.24-0.75,2.02-2,2.13-3.41c0.12-1.43-0.46-2.8-1.59-3.77
    c-1.6-1.22-3.34-1.72-5.16-1.47c-1.99,0.27-5.13,1.6-8.75,6.38c-0.1,0.13-0.26,0.17-0.41,0.11c-5.4-2.36-11.84-1.82-16.79,1.42
    c-4.2,2.75-6.61,6.92-6.61,11.46c0,9.79,5.91,11.26,9.44,11.26c4.2,0,6.71-3.3,6.71-6.48c0-3.03-1.87-6.25-5.34-6.25
    c-2.92,0-4.66,2.02-4.66,3.98c0,1.28,0.36,2.32,1.01,2.93c0.47,0.44,1.08,0.64,1.81,0.6c0.68-0.04,1.22-0.33,1.6-0.73
    c-0.13,0.03-0.28,0.05-0.44,0.05c-1.12,0-1.71-0.86-1.71-1.71c0-0.79,0.53-1.66,1.69-1.71c0.42-0.02,0.8,0.13,1.1,0.42
    c0.37,0.36,0.62,0.95,0.62,1.51v0.01c0,1.31-1.06,2.72-2.82,2.84c-0.91,0.06-1.71-0.21-2.32-0.78c-0.79-0.74-1.23-1.96-1.23-3.43
    c0-2.61,2.35-4.66,5.34-4.66c2.92,0,6.03,2.43,6.03,6.94c0,2.02-0.81,3.86-2.27,5.2C92.17,125.28,90.31,125.99,88.42,125.99z
     M89.15,116.67l-0.04,0c-0.77,0.03-1.04,0.57-1.04,1.02c0,0.51,0.32,1.02,1.02,1.02c0.77,0,0.98-0.68,1.02-0.83
    c-0.01-0.31-0.14-0.72-0.41-0.99C89.55,116.74,89.37,116.67,89.15,116.67z"
      fill="#FFFFFF"
    />
    <path
      d="M86.5,109.27c-0.42,0-0.82-0.13-1.2-0.38c-0.89-0.6-1.35-1.71-1.35-2.51c0-2,1.2-4.64,4.58-6.06
    c0.17-0.07,0.37,0.01,0.45,0.18c0.07,0.17-0.01,0.37-0.18,0.45c-3.07,1.28-4.16,3.64-4.16,5.43c0,0.61,0.39,1.5,1.05,1.95
    c0.44,0.3,0.92,0.34,1.42,0.14c1.1-0.45,0.58-1.92,0.55-1.99c-0.06-0.18,0.03-0.37,0.2-0.44c0.18-0.06,0.37,0.02,0.44,0.2
    c0.28,0.74,0.43,2.3-0.94,2.85C87.07,109.21,86.78,109.27,86.5,109.27z"
      fill="#FFFFFF"
    />
    <path
      d="M95.25,120.9c-0.03,0-0.05,0-0.08-0.01c-0.18-0.04-0.3-0.23-0.25-0.41c0.72-3.03,0.03-5.7-2.33-8.92
    c-0.82-1.12-1.33-2.51-1.42-3.89c-0.08-1.21,0.16-2.32,0.64-2.97c0.73-0.99,2.41-1.66,3.82-1.52c1.03,0.1,1.82,0.63,2.22,1.48
    c0.6,1.27,0.38,2.22,0.09,2.79c-0.47,0.94-1.39,1.43-1.89,1.53c-1.18,0.24-1.84-0.65-2.03-1.15c-0.07-0.18,0.02-0.37,0.2-0.44
    c0.18-0.07,0.37,0.02,0.44,0.2c0.04,0.09,0.37,0.9,1.25,0.72c0.38-0.08,1.07-0.49,1.42-1.17c0.32-0.64,0.29-1.38-0.1-2.2
    c-0.38-0.8-1.12-1.04-1.67-1.09c-1.15-0.11-2.62,0.46-3.21,1.25c-0.77,1.04-0.84,3.84,0.78,6.05c2.46,3.35,3.21,6.28,2.44,9.48
    C95.55,120.79,95.41,120.9,95.25,120.9z"
      fill="#FFFFFF"
    />
    <path
      d="M84.91,133.44c-0.84,0-1.37-0.36-1.68-0.67c-0.5-0.51-0.75-1.28-0.67-2.04c0.12-1.13,1.28-1.47,1.88-1.5
    c0.19-0.01,0.35,0.14,0.36,0.33c0.01,0.19-0.14,0.35-0.33,0.36c-0.05,0-1.14,0.07-1.23,0.89c-0.06,0.57,0.12,1.12,0.48,1.49
    c0.33,0.34,0.81,0.5,1.37,0.46c0.56-0.04,1.48-0.54,1.77-1.52c0.33-1.08,0.11-3.47-5-7.44c-0.15-0.12-0.18-0.33-0.06-0.48
    c0.12-0.15,0.33-0.18,0.48-0.06c5.15,4.01,5.69,6.66,5.23,8.18c-0.37,1.22-1.5,1.94-2.37,2C85.06,133.44,84.99,133.44,84.91,133.44z
    "
      fill="#FFFFFF"
    />
    <path
      d="M75.89,107.72l-0.01,0c-0.12-0.01-0.22-0.07-0.28-0.17c-2.93-5.13-2.07-7.27-2.03-7.36
    c0.07-0.17,0.28-0.25,0.45-0.18c0.17,0.07,0.25,0.27,0.18,0.45c-0.01,0.01-0.68,1.85,1.74,6.28c9.49-13.22,21.72-10.6,25.75-9.25
    c2.07-5.21,8.28-7.35,16.73-5.74c0.56-5.77-3.33-13.54-3.37-13.61c-0.09-0.17-0.02-0.37,0.15-0.46c0.17-0.09,0.37-0.02,0.46,0.15
    c0.01,0.02,1.06,2.1,2,4.89c1.26,3.76,1.73,6.95,1.4,9.48c-0.01,0.09-0.06,0.18-0.14,0.23c-0.08,0.05-0.17,0.08-0.27,0.06
    c-8.44-1.7-14.58,0.38-16.43,5.55c-0.06,0.18-0.26,0.27-0.44,0.21c-3.6-1.28-16.08-4.37-25.6,9.33
    C76.11,107.66,76,107.72,75.89,107.72z"
      fill="#FFFFFF"
    />
    <path
      d="M77.12,138.21c-0.19,0-0.34-0.15-0.34-0.34V120.5c0-0.06-0.07-5.74-3.69-5.74c-0.19,0-0.34-0.15-0.34-0.34
    c0-0.19,0.15-0.34,0.34-0.34c3.43,0,4.36,4.2,4.37,6.42v17.37C77.46,138.06,77.3,138.21,77.12,138.21z"
      fill="#FFFFFF"
    />
    <path
      d="M77.04,134.12c-0.09,0-0.18-0.04-0.25-0.11l-3.69-4.04c-0.13-0.14-0.12-0.35,0.02-0.48
    c0.14-0.13,0.35-0.12,0.48,0.02l3.69,4.04c0.13,0.14,0.12,0.35-0.02,0.48C77.21,134.09,77.12,134.12,77.04,134.12z"
      fill="#FFFFFF"
    />
    <path
      d="M77.04,127.59c-0.09,0-0.18-0.04-0.25-0.11l-3.69-4.04c-0.13-0.14-0.12-0.35,0.02-0.48
    c0.14-0.13,0.35-0.12,0.48,0.02l3.69,4.04c0.13,0.14,0.12,0.35-0.02,0.48C77.21,127.56,77.12,127.59,77.04,127.59z"
      fill="#FFFFFF"
    />
    <path
      d="M77.04,121.07c-0.09,0-0.18-0.04-0.25-0.11l-3.69-4.04c-0.13-0.14-0.12-0.35,0.02-0.48
    c0.14-0.13,0.35-0.12,0.48,0.02l3.69,4.04c0.13,0.14,0.12,0.35-0.02,0.48C77.21,121.04,77.12,121.07,77.04,121.07z"
      fill="#FFFFFF"
    />
    <path
      d="M77.04,124.33c-0.09,0-0.18-0.04-0.25-0.11l-3.69-4.04c-0.13-0.14-0.12-0.35,0.02-0.48
    c0.14-0.13,0.35-0.12,0.48,0.02l3.69,4.04c0.13,0.14,0.12,0.35-0.02,0.48C77.21,124.3,77.12,124.33,77.04,124.33z"
      fill="#FFFFFF"
    />
    <path
      d="M73.14,142.27c-0.19,0-0.34-0.15-0.34-0.34V99.34c0-0.19,0.15-0.34,0.34-0.34c0.19,0,0.34,0.15,0.34,0.34
    v42.59C73.48,142.12,73.32,142.27,73.14,142.27z"
      fill="#FFFFFF"
    />
    <path
      d="M77.04,130.86c-0.09,0-0.18-0.04-0.25-0.11l-3.69-4.04c-0.13-0.14-0.12-0.35,0.02-0.48
    c0.14-0.13,0.35-0.12,0.48,0.02l3.69,4.04c0.13,0.14,0.12,0.35-0.02,0.48C77.21,130.83,77.12,130.86,77.04,130.86z"
      fill="#FFFFFF"
    />
    <path
      d="M146.24,81.24h-17.98c-1.85-0.01-5.35-0.78-5.35-3.66c0-0.19,0.15-0.34,0.34-0.34
    c0.19,0,0.34,0.15,0.34,0.34c0,2.92,4.62,2.98,4.67,2.98h17.97c0.19,0,0.34,0.15,0.34,0.34C146.58,81.09,146.43,81.24,146.24,81.24z
    "
      fill="#FFFFFF"
    />
    <path
      d="M139.19,81.18c-0.08,0-0.16-0.03-0.23-0.09l-3.33-3.04c-0.14-0.13-0.15-0.34-0.02-0.48
    c0.13-0.14,0.34-0.15,0.48-0.02l3.33,3.04c0.14,0.13,0.15,0.34,0.02,0.48C139.38,81.14,139.28,81.18,139.19,81.18z"
      fill="#FFFFFF"
    />
    <path
      d="M133.82,81.18c-0.08,0-0.16-0.03-0.23-0.09l-3.33-3.04c-0.14-0.13-0.15-0.34-0.02-0.48
    c0.13-0.14,0.34-0.15,0.48-0.02l3.33,3.04c0.14,0.13,0.15,0.34,0.02,0.48C134.01,81.14,133.91,81.18,133.82,81.18z"
      fill="#FFFFFF"
    />
    <path
      d="M128.45,81.18c-0.08,0-0.16-0.03-0.23-0.09l-3.33-3.04c-0.14-0.13-0.15-0.34-0.02-0.48
    c0.13-0.14,0.34-0.15,0.48-0.02l3.33,3.04c0.14,0.13,0.15,0.34,0.02,0.48C128.63,81.14,128.54,81.18,128.45,81.18z"
      fill="#FFFFFF"
    />
    <path
      d="M131.13,81.18c-0.08,0-0.16-0.03-0.23-0.09l-3.33-3.04c-0.14-0.13-0.15-0.34-0.02-0.48
    c0.13-0.14,0.34-0.15,0.48-0.02l3.33,3.04c0.14,0.13,0.15,0.34,0.02,0.48C131.32,81.14,131.23,81.18,131.13,81.18z"
      fill="#FFFFFF"
    />
    <path
      d="M141.49,77.96h-30.73c-0.19,0-0.34-0.15-0.34-0.34s0.15-0.34,0.34-0.34h30.73c0.19,0,0.34,0.15,0.34,0.34
    S141.68,77.96,141.49,77.96z"
      fill="#FFFFFF"
    />
    <path
      d="M136.51,81.18c-0.08,0-0.16-0.03-0.23-0.09l-3.33-3.04c-0.14-0.13-0.15-0.34-0.02-0.48
    c0.13-0.14,0.34-0.15,0.48-0.02l3.33,3.04c0.14,0.13,0.15,0.34,0.02,0.48C136.69,81.14,136.6,81.18,136.51,81.18z"
      fill="#FFFFFF"
    />
    <path
      d="M73.14,100.65c-1.46,0-2.65-1.19-2.65-2.65c0-1.46,1.19-2.65,2.65-2.65c1.46,0,2.65,1.19,2.65,2.65
    C75.78,99.47,74.6,100.65,73.14,100.65z M73.14,96.04c-1.08,0-1.96,0.88-1.96,1.96c0,1.08,0.88,1.96,1.96,1.96
    c1.08,0,1.96-0.88,1.96-1.96C75.1,96.92,74.22,96.04,73.14,96.04z"
      fill="#FFFFFF"
    />
    <path
      d="M75.1,139.58c-0.19,0-0.34-0.15-0.34-0.34v-7.21c0-0.19,0.15-0.34,0.34-0.34c0.19,0,0.34,0.15,0.34,0.34
    v7.21C75.44,139.43,75.29,139.58,75.1,139.58z"
      fill="#FFFFFF"
    />
    <g>
      <defs>
        <rect
          height="12.97"
          id="SVGID_15_"
          transform="matrix(0.9755 -0.2198 0.2198 0.9755 -20.8513 26.0323)"
          width="4.8"
          x="104.16"
          y="100.23"
        />
      </defs>
      <clipPath id="SVGID_16_">
        <use xlink:href="#SVGID_15_" />
      </clipPath>
      <path
        clip-path="url(#SVGID_16_)"
        d="M104.14,112.1c-0.09,0-0.19-0.04-0.25-0.11c-0.13-0.14-0.12-0.35,0.02-0.48
      c0.09-0.08,2.12-1.98,1.46-4.92c-0.67-2.95-3.49-4.12-3.52-4.13c-0.17-0.07-0.26-0.27-0.19-0.44c0.07-0.17,0.27-0.26,0.44-0.19
      c0.13,0.05,3.19,1.3,3.93,4.61c0.75,3.33-1.57,5.48-1.66,5.57C104.3,112.07,104.22,112.1,104.14,112.1z"
        fill="#FFFFFF"
      />
      <path
        clip-path="url(#SVGID_16_)"
        d="M106.84,104.76c-0.89,0-1.67-0.22-2.35-0.65c-1.55-1-1.94-2.77-1.96-2.84
      c-0.04-0.17,0.06-0.34,0.22-0.39c0.08-0.03,2.05-0.68,3.73,0.27c1.02,0.57,1.7,1.6,2.03,3.05c0.04,0.18-0.07,0.37-0.26,0.41
      C107.76,104.7,107.29,104.76,106.84,104.76z M103.29,101.43c0.17,0.5,0.62,1.49,1.57,2.1c0.78,0.5,1.76,0.65,2.9,0.46
      c-0.3-1.07-0.84-1.83-1.61-2.26C105.1,101.14,103.86,101.3,103.29,101.43z M102.87,101.19L102.87,101.19L102.87,101.19z"
        fill="#FFFFFF"
      />
      <path
        clip-path="url(#SVGID_16_)"
        d="M107.72,107.67c-1.47,0-2.27-0.98-2.31-1.03c-0.12-0.15-0.09-0.36,0.05-0.48
      c0.15-0.12,0.36-0.09,0.48,0.05c0.03,0.04,0.65,0.77,1.78,0.77h0c-0.09-1.2-0.47-1.96-1.1-2.27c-0.17-0.08-0.24-0.28-0.16-0.45
      c0.08-0.17,0.28-0.24,0.45-0.16c0.94,0.45,1.45,1.52,1.51,3.19c0.01,0.18-0.12,0.33-0.3,0.35
      C107.98,107.66,107.85,107.67,107.72,107.67z"
        fill="#FFFFFF"
      />
      <path
        clip-path="url(#SVGID_16_)"
        d="M107.02,109.96c-0.9,0-1.56-0.59-1.59-0.62c-0.14-0.13-0.15-0.34-0.02-0.48
      c0.13-0.14,0.34-0.15,0.48-0.02c0.01,0.01,0.41,0.36,0.95,0.43c0.07-0.88-0.5-1.98-0.51-2c-0.09-0.17-0.02-0.37,0.14-0.46
      c0.17-0.09,0.37-0.02,0.46,0.14c0.03,0.06,0.82,1.58,0.52,2.75c-0.04,0.14-0.16,0.25-0.31,0.26
      C107.11,109.96,107.07,109.96,107.02,109.96z"
        fill="#FFFFFF"
      />
      <path
        clip-path="url(#SVGID_16_)"
        d="M105.35,113.7c-0.04,0-0.09-0.01-0.13-0.03c-0.17-0.07-0.26-0.27-0.19-0.45
      c0.01-0.02,0.62-1.51,0.72-2.28c0.09-0.68-0.28-1.26-0.28-1.27c-0.1-0.16-0.06-0.37,0.1-0.47c0.16-0.1,0.37-0.06,0.47,0.09
      c0.02,0.03,0.52,0.79,0.39,1.74c-0.12,0.86-0.74,2.38-0.77,2.45C105.61,113.62,105.49,113.7,105.35,113.7z"
        fill="#FFFFFF"
      />
    </g>
    <g>
      <defs>
        <rect
          height="12.97"
          id="SVGID_17_"
          transform="matrix(0.9755 -0.2198 0.2198 0.9755 -21.1974 25.0298)"
          width="4.8"
          x="99.48"
          y="101.29"
        />
      </defs>
      <clipPath id="SVGID_18_">
        <use xlink:href="#SVGID_17_" />
      </clipPath>
      <path
        clip-path="url(#SVGID_18_)"
        d="M106.23,111.63c-0.03,0-0.07,0-0.1-0.01c-0.13-0.04-3.14-0.99-3.89-4.32
      c-0.75-3.31,1.48-5.75,1.58-5.85c0.13-0.14,0.34-0.15,0.48-0.02c0.14,0.13,0.15,0.34,0.02,0.48c-0.02,0.02-2.07,2.29-1.41,5.24
      c0.66,2.93,3.31,3.78,3.43,3.82c0.18,0.06,0.28,0.25,0.23,0.43C106.51,111.54,106.37,111.63,106.23,111.63z"
        fill="#FFFFFF"
      />
      <path
        clip-path="url(#SVGID_18_)"
        d="M99.35,106.59c-0.16,0-0.3-0.11-0.33-0.27c-0.33-1.45-0.15-2.67,0.53-3.62
      c1.12-1.58,3.17-1.83,3.26-1.84c0.17-0.02,0.33,0.09,0.37,0.26c0.02,0.07,0.43,1.84-0.55,3.41c-0.64,1.03-1.72,1.72-3.2,2.06
      C99.4,106.58,99.37,106.59,99.35,106.59z M102.56,101.59c-0.57,0.13-1.76,0.51-2.46,1.5c-0.51,0.72-0.67,1.63-0.48,2.73
      c1.12-0.31,1.94-0.87,2.43-1.66C102.65,103.2,102.62,102.12,102.56,101.59z"
        fill="#FFFFFF"
      />
      <path
        clip-path="url(#SVGID_18_)"
        d="M100.75,109.3c-0.13,0-0.26-0.08-0.31-0.21c-0.66-1.53-0.66-2.72,0-3.53
      c0.12-0.15,0.33-0.17,0.48-0.05c0.15,0.12,0.17,0.33,0.05,0.48c-0.45,0.55-0.45,1.39-0.02,2.52c1.04-0.49,1.27-1.45,1.28-1.46
      c0.04-0.18,0.23-0.3,0.41-0.25c0.18,0.04,0.3,0.23,0.25,0.41c-0.01,0.06-0.37,1.52-2.02,2.07
      C100.83,109.29,100.79,109.3,100.75,109.3z"
        fill="#FFFFFF"
      />
      <path
        clip-path="url(#SVGID_18_)"
        d="M102.6,110.98c-0.1,0-0.2-0.04-0.26-0.12c-0.77-0.93-0.71-2.63-0.7-2.71
      c0.01-0.19,0.16-0.33,0.36-0.33c0.19,0.01,0.33,0.17,0.33,0.35c0,0.01-0.05,1.25,0.4,2.02c0.46-0.3,0.67-0.79,0.68-0.8
      c0.07-0.17,0.27-0.26,0.44-0.19c0.17,0.07,0.26,0.27,0.19,0.44c-0.02,0.04-0.39,0.94-1.29,1.3
      C102.68,110.97,102.64,110.98,102.6,110.98z"
        fill="#FFFFFF"
      />
      <path
        clip-path="url(#SVGID_18_)"
        d="M105.82,113.6c-0.08,0-0.16-0.03-0.23-0.09c-0.05-0.05-1.27-1.15-1.74-1.88
      c-0.52-0.8-0.4-1.7-0.39-1.74c0.03-0.19,0.2-0.32,0.39-0.29c0.19,0.03,0.31,0.2,0.29,0.39c0,0.01-0.09,0.69,0.29,1.27
      c0.43,0.66,1.62,1.74,1.63,1.75c0.14,0.13,0.15,0.34,0.02,0.48C106,113.56,105.91,113.6,105.82,113.6z"
        fill="#FFFFFF"
      />
    </g>
    <path
      d="M115.34,101.72c-0.73,0-1.33-0.6-1.33-1.33s0.6-1.33,1.33-1.33c0.73,0,1.33,0.6,1.33,1.33
    S116.07,101.72,115.34,101.72z M115.34,99.75c-0.36,0-0.65,0.29-0.65,0.65s0.29,0.65,0.65,0.65c0.36,0,0.65-0.29,0.65-0.65
    S115.7,99.75,115.34,99.75z"
      fill="#FFFFFF"
    />
    <path
      d="M81.09,128.58c-0.73,0-1.33-0.6-1.33-1.33s0.6-1.33,1.33-1.33c0.73,0,1.33,0.6,1.33,1.33
    S81.82,128.58,81.09,128.58z M81.09,126.61c-0.36,0-0.65,0.29-0.65,0.65c0,0.36,0.29,0.65,0.65,0.65c0.36,0,0.65-0.29,0.65-0.65
    C81.74,126.9,81.45,126.61,81.09,126.61z"
      fill="#FFFFFF"
    />
    <path
      d="M94.7,108.04c-0.73,0-1.33-0.6-1.33-1.33c0-0.73,0.6-1.33,1.33-1.33c0.73,0,1.33,0.6,1.33,1.33
    C96.03,107.45,95.43,108.04,94.7,108.04z M94.7,106.07c-0.36,0-0.65,0.29-0.65,0.64c0,0.36,0.29,0.65,0.65,0.65
    c0.36,0,0.65-0.29,0.65-0.65C95.34,106.36,95.05,106.07,94.7,106.07z"
      fill="#FFFFFF"
    />
    <path
      d="M87.3,106.65c-0.73,0-1.33-0.6-1.33-1.33c0-0.73,0.6-1.33,1.33-1.33c0.73,0,1.33,0.6,1.33,1.33
    C88.63,106.05,88.04,106.65,87.3,106.65z M87.3,104.67c-0.36,0-0.65,0.29-0.65,0.65c0,0.36,0.29,0.64,0.65,0.64
    c0.36,0,0.65-0.29,0.65-0.64C87.95,104.96,87.66,104.67,87.3,104.67z"
      fill="#FFFFFF"
    />
    <path
      d="M126.53,85.91c-0.73,0-1.33-0.6-1.33-1.33s0.6-1.33,1.33-1.33c0.73,0,1.33,0.6,1.33,1.33
    S127.26,85.91,126.53,85.91z M126.53,83.94c-0.36,0-0.65,0.29-0.65,0.65s0.29,0.65,0.65,0.65c0.36,0,0.65-0.29,0.65-0.65
    S126.89,83.94,126.53,83.94z"
      fill="#FFFFFF"
    />
    <path
      d="M145.74,207.01H5.29c-2.13,0-3.86-1.73-3.86-3.86v-35.23c0-2.13,1.73-3.86,3.86-3.86h140.45
    c2.13,0,3.86,1.73,3.86,3.86v35.23C149.59,205.29,147.87,207.01,145.74,207.01z"
      fill="#FFFFFF"
    />
    <g>
      <g>
        <path
          d="M87.8,158.24c-0.02,0-0.07,0-0.15-0.01c-0.08-0.01-0.17-0.02-0.28-0.04c-0.11-0.02-0.22-0.03-0.34-0.05
        c-0.12-0.02-0.23-0.03-0.33-0.06c-0.1-0.02-0.18-0.04-0.25-0.06c0-0.04,0.01-0.07,0.02-0.1c0.02-0.03,0.03-0.06,0.05-0.07h0.06
        c0.05,0,0.11-0.02,0.16-0.05c0.06-0.03,0.11-0.09,0.17-0.18c0.06-0.08,0.12-0.2,0.18-0.34c0.06-0.15,0.12-0.33,0.19-0.56l0.7-2.44
        c0.04-0.04,0.09-0.08,0.17-0.13c0.08-0.05,0.16-0.1,0.25-0.15c0.09-0.05,0.18-0.09,0.26-0.13c0.08-0.03,0.15-0.05,0.19-0.05
        c0.05,0,0.13,0.02,0.22,0.06c0.09,0.04,0.19,0.08,0.28,0.14s0.17,0.11,0.23,0.17l0.83,3.51c0.01,0.04,0.03,0.08,0.05,0.1
        c0.02,0.03,0.06,0.05,0.12,0.05l0.39,0.02c0.01,0.02,0.02,0.03,0.04,0.04c0.01,0.01,0.02,0.05,0.02,0.12
        c-0.03,0.03-0.08,0.06-0.13,0.09c-0.06,0.03-0.11,0.06-0.18,0.08c-0.06,0.02-0.12,0.03-0.18,0.03c-0.04,0-0.1-0.01-0.2-0.02
        c-0.09-0.01-0.2-0.03-0.32-0.05c-0.12-0.02-0.24-0.04-0.36-0.06c-0.12-0.02-0.22-0.05-0.32-0.07c-0.09-0.02-0.16-0.04-0.2-0.05
        c0-0.04,0.01-0.08,0.03-0.1c0.02-0.03,0.03-0.05,0.04-0.05h0.21c0.07,0,0.12,0,0.17-0.01c0.05-0.01,0.07-0.04,0.09-0.09
        c0.01-0.05,0.01-0.13-0.02-0.25l-0.23-1h-1.57l-0.25,0.87c-0.02,0.09-0.05,0.16-0.09,0.22c-0.03,0.06-0.07,0.11-0.11,0.15
        c-0.04,0.04-0.08,0.06-0.13,0.07l0.93,0.07c0,0.01,0.01,0.03,0.02,0.06c0.01,0.03,0.02,0.06,0.02,0.1
        c-0.03,0.03-0.08,0.07-0.13,0.1c-0.06,0.03-0.11,0.06-0.18,0.08C87.9,158.23,87.85,158.24,87.8,158.24z M87.97,156.13
        c0.21,0,0.4,0,0.59-0.01c0.18-0.01,0.34-0.02,0.48-0.03c0.14-0.01,0.24-0.02,0.31-0.03l-0.36-1.58c-0.09-0.06-0.18-0.11-0.27-0.14
        c-0.09-0.03-0.17-0.06-0.25-0.08L87.97,156.13z"
          fill="#FFFFFF"
        />
        <path
          d="M94.1,158.24c-0.13,0-0.28,0-0.47-0.01c-0.18-0.01-0.38-0.02-0.6-0.03c-0.21-0.01-0.42-0.03-0.63-0.05
        c-0.21-0.02-0.39-0.04-0.56-0.06c-0.17-0.02-0.3-0.04-0.41-0.06c0-0.03,0.01-0.06,0.02-0.1c0.01-0.03,0.03-0.05,0.04-0.07
        l0.18-0.02c0.08-0.01,0.14-0.03,0.19-0.07c0.04-0.03,0.07-0.09,0.09-0.18c0.02-0.09,0.03-0.21,0.03-0.37v-2.47
        c0-0.11,0.01-0.21,0.02-0.28c0.01-0.07,0.03-0.12,0.05-0.16c0.02-0.03,0.03-0.05,0.04-0.06h-0.6c-0.02-0.02-0.03-0.04-0.05-0.06
        c-0.01-0.02-0.02-0.05-0.03-0.08c0.02-0.03,0.06-0.06,0.11-0.09c0.05-0.03,0.1-0.06,0.16-0.08c0.05-0.02,0.09-0.03,0.12-0.03h1.35
        c0.02,0.02,0.04,0.05,0.06,0.1c0.02,0.05,0.02,0.09,0.02,0.14l-0.27,0.08c-0.09,0.02-0.15,0.06-0.2,0.1
        c-0.05,0.04-0.08,0.1-0.09,0.16c-0.02,0.07-0.02,0.16-0.02,0.27v2.62c0,0.1-0.01,0.18-0.04,0.26c-0.02,0.07-0.05,0.13-0.07,0.16
        h1.42l0.36-1.06c0.09,0,0.17,0.01,0.23,0.04c0.06,0.02,0.11,0.05,0.13,0.07c0,0.14-0.01,0.27-0.02,0.42
        c-0.01,0.14-0.02,0.28-0.04,0.4c-0.01,0.12-0.03,0.22-0.04,0.3c-0.03,0.03-0.08,0.07-0.15,0.12c-0.07,0.05-0.13,0.08-0.2,0.12
        C94.18,158.22,94.13,158.24,94.1,158.24z"
          fill="#FFFFFF"
        />
        <path
          d="M96.46,158.24c-0.03,0-0.09,0-0.18-0.02s-0.19-0.02-0.3-0.04c-0.11-0.02-0.23-0.03-0.34-0.05
        c-0.11-0.02-0.21-0.04-0.3-0.06c-0.09-0.02-0.15-0.03-0.19-0.05c0-0.03,0.01-0.06,0.02-0.1c0.01-0.03,0.03-0.05,0.04-0.07
        l0.18-0.02c0.07-0.01,0.13-0.03,0.18-0.06c0.05-0.03,0.08-0.08,0.1-0.17c0.02-0.09,0.03-0.22,0.03-0.4v-2.46
        c0-0.16,0.02-0.28,0.05-0.36c0.03-0.08,0.05-0.12,0.07-0.13h-0.6c-0.02-0.02-0.03-0.04-0.05-0.06c-0.01-0.02-0.02-0.05-0.03-0.08
        c0.02-0.03,0.06-0.06,0.11-0.09c0.05-0.03,0.1-0.06,0.16-0.08c0.05-0.02,0.09-0.03,0.12-0.03h1.35c0.02,0.02,0.04,0.05,0.06,0.1
        c0.02,0.05,0.02,0.09,0.02,0.14l-0.27,0.08c-0.09,0.02-0.15,0.06-0.2,0.1c-0.05,0.04-0.08,0.1-0.09,0.16
        c-0.02,0.07-0.02,0.16-0.02,0.27v1.06l0.66-0.01c0.03-0.07,0.07-0.16,0.12-0.28c0.05-0.12,0.11-0.26,0.18-0.42
        c0.07-0.16,0.14-0.31,0.22-0.47c0.08-0.16,0.17-0.3,0.25-0.43c0.09-0.13,0.18-0.23,0.27-0.3h1.04c0.02,0.02,0.04,0.05,0.06,0.09
        c0.02,0.04,0.03,0.09,0.03,0.14l-0.29,0.09c-0.13,0.04-0.26,0.11-0.37,0.21c-0.12,0.1-0.23,0.21-0.33,0.34
        c-0.1,0.13-0.2,0.27-0.28,0.41c-0.09,0.14-0.16,0.28-0.22,0.41c-0.06,0.13-0.11,0.24-0.15,0.33l0.65,1.08
        c0.1,0.16,0.19,0.3,0.27,0.42c0.08,0.12,0.16,0.22,0.25,0.29s0.19,0.11,0.32,0.12l0.12,0.01c0.01,0.01,0.03,0.03,0.04,0.07
        c0.02,0.03,0.02,0.07,0.02,0.12c-0.05,0.05-0.12,0.09-0.2,0.12c-0.08,0.03-0.16,0.06-0.25,0.08c-0.08,0.02-0.16,0.02-0.24,0.02
        c-0.07,0-0.14-0.01-0.21-0.03c-0.07-0.02-0.14-0.06-0.21-0.11c-0.07-0.05-0.14-0.12-0.21-0.22c-0.07-0.09-0.15-0.22-0.22-0.36
        l-0.53-1.03c-0.05-0.09-0.1-0.17-0.15-0.22c-0.05-0.05-0.14-0.08-0.27-0.08h-0.36v1.19c0,0.1-0.01,0.19-0.04,0.26
        c-0.02,0.07-0.05,0.13-0.07,0.15l0.64,0.07c0.01,0.01,0.02,0.03,0.03,0.06c0.01,0.03,0.02,0.06,0.02,0.09
        c-0.06,0.05-0.14,0.1-0.24,0.14C96.62,158.22,96.53,158.24,96.46,158.24z"
          fill="#FFFFFF"
        />
        <path
          d="M99.9,158.28c-0.03,0-0.07-0.02-0.12-0.06c-0.05-0.04-0.1-0.09-0.15-0.15c-0.05-0.06-0.09-0.12-0.12-0.18
        c-0.03-0.06-0.05-0.11-0.05-0.14c0-0.04,0.02-0.08,0.06-0.12c0.04-0.05,0.1-0.09,0.16-0.14c0.07-0.05,0.13-0.08,0.19-0.11
        c0.06-0.03,0.11-0.04,0.15-0.04c0.04,0,0.08,0.02,0.13,0.06c0.05,0.04,0.09,0.09,0.14,0.15c0.04,0.06,0.08,0.12,0.11,0.18
        c0.03,0.06,0.04,0.11,0.04,0.14c0,0.03-0.02,0.07-0.06,0.12c-0.04,0.05-0.09,0.09-0.15,0.13c-0.06,0.04-0.12,0.08-0.18,0.11
        C100,158.27,99.95,158.28,99.9,158.28z"
          fill="#FFFFFF"
        />
        <path
          d="M103.8,158.89c-0.01,0-0.03-0.01-0.05-0.02c-0.03-0.01-0.06-0.03-0.09-0.05c0.02-0.07,0.05-0.16,0.07-0.28
        c0.02-0.12,0.03-0.24,0.03-0.36v-0.31h-1.49c-0.05-0.03-0.11-0.09-0.17-0.16c-0.06-0.08-0.1-0.17-0.12-0.29l1.68-2.54
        c0.04-0.01,0.09-0.03,0.16-0.04c0.06-0.02,0.13-0.03,0.21-0.05c0.07-0.02,0.15-0.03,0.21-0.03l0.14,0.09
        c0,0.04-0.01,0.09-0.02,0.17c-0.01,0.08-0.01,0.16-0.02,0.26s-0.01,0.2-0.01,0.31c0,0.11,0,0.21,0,0.32v1.53l0.6-0.03l0.06,0.1
        c0,0.04-0.01,0.08-0.03,0.13c-0.01,0.05-0.03,0.1-0.04,0.14c-0.01,0.04-0.03,0.07-0.04,0.09h-0.55v0.91
        c-0.03,0.02-0.08,0.03-0.14,0.05c-0.06,0.02-0.13,0.03-0.19,0.05C103.93,158.88,103.86,158.89,103.8,158.89z M102.51,157.45h1.24
        v-1.44c0-0.11,0.01-0.22,0.02-0.32c0.02-0.1,0.03-0.18,0.05-0.22l-0.02-0.01L102.51,157.45z"
          fill="#FFFFFF"
        />
        <path
          d="M105.53,159.04c-0.04,0-0.08-0.01-0.12-0.04c-0.04-0.02-0.08-0.05-0.1-0.09
        c-0.03-0.03-0.05-0.06-0.05-0.09c0.06-0.03,0.12-0.09,0.2-0.16c0.08-0.08,0.15-0.16,0.21-0.24c0.06-0.09,0.11-0.16,0.13-0.22
        c-0.06-0.03-0.12-0.07-0.18-0.13c-0.06-0.06-0.11-0.12-0.14-0.19c-0.04-0.07-0.06-0.12-0.06-0.15c0-0.03,0.02-0.07,0.06-0.11
        c0.04-0.04,0.08-0.08,0.14-0.12c0.06-0.04,0.11-0.07,0.17-0.1c0.06-0.02,0.1-0.04,0.13-0.04c0.03,0,0.07,0.02,0.11,0.06
        c0.04,0.04,0.08,0.09,0.12,0.14c0.04,0.06,0.07,0.11,0.1,0.17c0.03,0.06,0.04,0.1,0.04,0.14c0,0.05-0.02,0.12-0.06,0.21
        c-0.04,0.09-0.09,0.19-0.14,0.3c-0.06,0.11-0.12,0.21-0.19,0.31s-0.14,0.18-0.2,0.24S105.57,159.04,105.53,159.04z"
          fill="#FFFFFF"
        />
        <path
          d="M107.8,158.89c-0.05-0.01-0.1-0.02-0.16-0.05c-0.06-0.02-0.12-0.05-0.18-0.09
        c-0.06-0.03-0.11-0.07-0.15-0.1c-0.04-0.03-0.07-0.07-0.1-0.1c0-0.09,0.03-0.21,0.08-0.36c0.05-0.15,0.13-0.31,0.22-0.5
        c0.09-0.18,0.19-0.38,0.3-0.57c0.11-0.2,0.22-0.4,0.33-0.59s0.22-0.37,0.33-0.54c0.1-0.17,0.19-0.31,0.27-0.43
        c0.08-0.12,0.13-0.2,0.16-0.25h-1.46l-0.15,0.53c-0.01,0.01-0.03,0.02-0.07,0.03c-0.04,0.01-0.09,0.02-0.16,0.02
        c-0.04-0.06-0.08-0.12-0.12-0.2c-0.04-0.08-0.08-0.15-0.1-0.23c-0.03-0.08-0.05-0.15-0.05-0.21c0.03-0.05,0.08-0.11,0.14-0.18
        c0.06-0.07,0.13-0.13,0.2-0.19c0.07-0.06,0.14-0.1,0.19-0.13c0.02,0,0.08,0,0.18,0.01c0.1,0.01,0.22,0.01,0.35,0.02
        c0.13,0.01,0.27,0.02,0.41,0.03c0.14,0.01,0.27,0.02,0.38,0.03c0.11,0.01,0.19,0.01,0.24,0.01c0.02,0,0.06-0.01,0.13-0.03
        c0.07-0.02,0.13-0.04,0.2-0.05c0.07-0.02,0.1-0.02,0.11-0.02c0.05,0.02,0.09,0.05,0.13,0.11c0.04,0.06,0.06,0.12,0.06,0.18
        l-0.73,1.25c-0.15,0.25-0.27,0.48-0.38,0.68c-0.11,0.2-0.19,0.39-0.26,0.57c-0.07,0.17-0.12,0.33-0.16,0.47
        c-0.03,0.14-0.05,0.27-0.05,0.39c0,0.05,0,0.1,0.01,0.15c0.01,0.05,0.02,0.09,0.03,0.13c-0.02,0.04-0.05,0.08-0.1,0.11
        C107.84,158.86,107.81,158.88,107.8,158.89z"
          fill="#FFFFFF"
        />
        <path
          d="M112.18,156.49c-0.13,0-0.25-0.02-0.38-0.06c-0.13-0.04-0.25-0.09-0.35-0.14c-0.1-0.06-0.18-0.1-0.23-0.14
        c-0.04-0.09-0.07-0.22-0.09-0.38c-0.02-0.16-0.03-0.33-0.03-0.52c0-0.14,0-0.27,0.01-0.4c0.01-0.13,0.02-0.24,0.04-0.35
        c0.02-0.1,0.04-0.18,0.06-0.23c0.03-0.03,0.09-0.06,0.16-0.1c0.07-0.04,0.16-0.08,0.26-0.12c0.1-0.04,0.21-0.08,0.33-0.1
        c0.12-0.03,0.23-0.04,0.35-0.04c0.09,0,0.2,0.02,0.32,0.05s0.23,0.08,0.33,0.13c0.1,0.05,0.17,0.09,0.22,0.13
        c0.03,0.07,0.06,0.18,0.07,0.32c0.02,0.14,0.02,0.3,0.02,0.49c0,0.11,0,0.23-0.01,0.37c-0.01,0.14-0.02,0.27-0.04,0.4
        c-0.02,0.13-0.04,0.24-0.06,0.32c-0.03,0.04-0.08,0.08-0.15,0.12c-0.07,0.04-0.15,0.09-0.24,0.13c-0.09,0.04-0.19,0.08-0.29,0.1
        C112.38,156.48,112.28,156.49,112.18,156.49z M112.26,156.14c0.03,0,0.07-0.01,0.13-0.02c0.06-0.01,0.11-0.03,0.18-0.05
        c0.06-0.02,0.11-0.05,0.14-0.07c0.02-0.08,0.04-0.19,0.05-0.32c0.01-0.13,0.02-0.27,0.02-0.42c0-0.18-0.01-0.35-0.02-0.51
        c-0.01-0.16-0.03-0.27-0.05-0.32c-0.03-0.04-0.08-0.07-0.15-0.1c-0.07-0.03-0.14-0.05-0.21-0.07c-0.07-0.02-0.13-0.02-0.18-0.02
        c-0.04,0-0.08,0.01-0.14,0.02c-0.06,0.01-0.12,0.03-0.18,0.05c-0.06,0.02-0.11,0.04-0.15,0.05c-0.02,0.06-0.04,0.15-0.06,0.27
        c-0.01,0.12-0.02,0.26-0.02,0.41c0,0.11,0,0.22,0.01,0.35c0.01,0.13,0.02,0.24,0.03,0.34c0.01,0.1,0.03,0.17,0.05,0.21
        c0.03,0.03,0.09,0.06,0.17,0.09c0.08,0.03,0.16,0.06,0.23,0.07C112.17,156.13,112.22,156.14,112.26,156.14z M113.06,158.84
        c-0.09,0-0.17-0.01-0.24-0.04c-0.07-0.03-0.12-0.06-0.16-0.09l1.41-3.62c0.09-0.24,0.18-0.46,0.25-0.65
        c0.07-0.19,0.13-0.36,0.18-0.51c0.05-0.14,0.08-0.25,0.1-0.33c0.11,0.02,0.2,0.05,0.27,0.09c0.07,0.04,0.12,0.08,0.15,0.12
        L113.06,158.84z M115.61,158.27c-0.13,0-0.25-0.02-0.38-0.06c-0.13-0.04-0.25-0.09-0.35-0.14c-0.1-0.06-0.18-0.1-0.23-0.14
        c-0.04-0.09-0.07-0.22-0.09-0.38s-0.03-0.33-0.03-0.52c0-0.14,0-0.27,0.01-0.4c0.01-0.13,0.02-0.24,0.04-0.35
        c0.02-0.1,0.04-0.18,0.06-0.23c0.03-0.03,0.09-0.06,0.16-0.1c0.07-0.04,0.16-0.08,0.26-0.12c0.1-0.04,0.21-0.08,0.33-0.1
        c0.12-0.03,0.23-0.04,0.35-0.04c0.09,0,0.2,0.02,0.32,0.05s0.23,0.08,0.33,0.13c0.1,0.05,0.17,0.09,0.22,0.13
        c0.03,0.07,0.06,0.18,0.07,0.32s0.02,0.3,0.02,0.49c0,0.11,0,0.23-0.01,0.37c-0.01,0.14-0.02,0.27-0.04,0.4
        c-0.02,0.13-0.04,0.24-0.06,0.32c-0.03,0.04-0.08,0.08-0.15,0.12c-0.07,0.04-0.15,0.09-0.24,0.13c-0.09,0.04-0.19,0.08-0.29,0.1
        C115.81,158.26,115.71,158.27,115.61,158.27z M115.68,157.92c0.03,0,0.07-0.01,0.13-0.02c0.06-0.01,0.11-0.03,0.18-0.05
        c0.06-0.02,0.11-0.05,0.14-0.07c0.02-0.08,0.04-0.19,0.05-0.32c0.01-0.13,0.02-0.27,0.02-0.42c0-0.18-0.01-0.35-0.02-0.51
        c-0.01-0.16-0.03-0.27-0.05-0.32c-0.03-0.04-0.08-0.07-0.15-0.1c-0.07-0.03-0.14-0.05-0.21-0.07s-0.13-0.02-0.18-0.02
        c-0.04,0-0.08,0.01-0.14,0.02c-0.06,0.01-0.12,0.03-0.18,0.05c-0.06,0.02-0.11,0.04-0.15,0.05c-0.02,0.06-0.04,0.15-0.06,0.27
        c-0.01,0.13-0.02,0.26-0.02,0.41c0,0.11,0,0.22,0.01,0.35c0.01,0.13,0.02,0.24,0.03,0.34c0.01,0.1,0.03,0.17,0.05,0.21
        c0.03,0.03,0.09,0.06,0.17,0.09c0.08,0.03,0.16,0.06,0.23,0.07C115.59,157.91,115.65,157.92,115.68,157.92z"
          fill="#FFFFFF"
        />
        <path
          d="M120.61,158.24c-0.03,0-0.09,0-0.18-0.02c-0.09-0.01-0.19-0.02-0.31-0.04c-0.12-0.02-0.23-0.03-0.35-0.05
        c-0.12-0.02-0.22-0.04-0.31-0.06c-0.09-0.02-0.15-0.03-0.18-0.05c0-0.03,0.01-0.07,0.02-0.1c0.01-0.03,0.03-0.06,0.03-0.06
        l0.2-0.02c0.09-0.01,0.16-0.04,0.21-0.08c0.04-0.04,0.07-0.1,0.09-0.19c0.01-0.09,0.02-0.2,0.02-0.35v-2.61
        c0-0.09,0.01-0.16,0.03-0.21s0.04-0.09,0.05-0.12h-1.01l-0.13,1c-0.03,0.02-0.08,0.03-0.15,0.05c-0.07,0.01-0.15,0.02-0.23,0.02
        c-0.02-0.05-0.04-0.14-0.06-0.25s-0.05-0.24-0.07-0.39c-0.02-0.15-0.04-0.29-0.05-0.45c0.06-0.06,0.14-0.13,0.25-0.2
        c0.11-0.07,0.22-0.12,0.32-0.14h2.82c0.1,0.03,0.21,0.08,0.32,0.15c0.11,0.07,0.19,0.14,0.24,0.2c-0.02,0.25-0.04,0.46-0.08,0.65
        c-0.04,0.19-0.07,0.33-0.1,0.43c-0.08,0-0.15-0.01-0.22-0.02c-0.07-0.01-0.12-0.03-0.15-0.05l-0.14-0.95
        c-0.07-0.01-0.16-0.03-0.27-0.04c-0.11-0.01-0.24-0.02-0.37-0.02c-0.1,0-0.17,0.02-0.21,0.06c-0.04,0.04-0.07,0.08-0.08,0.14
        c-0.01,0.06-0.01,0.11-0.01,0.17v2.76c0,0.1-0.01,0.19-0.04,0.26c-0.02,0.07-0.05,0.12-0.07,0.16l0.66,0.07
        c0.01,0,0.02,0.02,0.03,0.05c0.01,0.03,0.02,0.06,0.02,0.1c-0.06,0.05-0.14,0.1-0.25,0.14
        C120.78,158.22,120.69,158.24,120.61,158.24z"
          fill="#FFFFFF"
        />
        <path
          d="M123.86,158.24c-0.03,0-0.09,0-0.18-0.02s-0.19-0.02-0.3-0.04c-0.11-0.02-0.23-0.03-0.34-0.05
        c-0.11-0.02-0.21-0.04-0.3-0.06c-0.09-0.02-0.15-0.03-0.19-0.05c0-0.03,0.01-0.06,0.02-0.1c0.01-0.03,0.03-0.05,0.04-0.07
        l0.18-0.02c0.08-0.01,0.14-0.03,0.19-0.07c0.04-0.03,0.07-0.09,0.09-0.18c0.02-0.09,0.03-0.21,0.03-0.37v-2.48
        c0-0.19,0.02-0.32,0.05-0.38s0.06-0.09,0.06-0.1h-0.6c-0.01-0.02-0.02-0.03-0.04-0.05c-0.02-0.02-0.03-0.05-0.04-0.09
        c0.02-0.03,0.06-0.06,0.11-0.09c0.05-0.03,0.1-0.06,0.16-0.08c0.05-0.02,0.09-0.03,0.12-0.03h1.35c0.02,0.02,0.04,0.05,0.06,0.1
        c0.02,0.04,0.02,0.09,0.02,0.14l-0.27,0.08c-0.09,0.02-0.15,0.06-0.2,0.1c-0.05,0.04-0.08,0.1-0.09,0.16
        c-0.02,0.07-0.02,0.16-0.02,0.27v2.63c0,0.1-0.01,0.19-0.04,0.26c-0.02,0.07-0.05,0.13-0.07,0.15l0.64,0.07
        c0.01,0.01,0.02,0.03,0.03,0.06c0.01,0.03,0.02,0.06,0.02,0.09c-0.06,0.05-0.14,0.1-0.24,0.14
        C124.02,158.22,123.93,158.24,123.86,158.24z"
          fill="#FFFFFF"
        />
        <path
          d="M127.63,158.24c-0.13,0-0.28,0-0.47-0.01c-0.18-0.01-0.38-0.02-0.6-0.03c-0.21-0.01-0.42-0.03-0.63-0.05
        c-0.21-0.02-0.39-0.04-0.56-0.06c-0.17-0.02-0.3-0.04-0.41-0.06c0-0.03,0.01-0.06,0.02-0.1c0.01-0.03,0.03-0.05,0.04-0.07
        l0.18-0.02c0.08-0.01,0.14-0.03,0.19-0.07c0.04-0.03,0.07-0.09,0.09-0.18c0.02-0.09,0.03-0.21,0.03-0.37v-2.47
        c0-0.11,0.01-0.21,0.02-0.28c0.01-0.07,0.03-0.12,0.05-0.16c0.02-0.03,0.03-0.05,0.04-0.06h-0.6c-0.02-0.02-0.03-0.04-0.05-0.06
        c-0.01-0.02-0.02-0.05-0.03-0.08c0.02-0.03,0.06-0.06,0.11-0.09c0.05-0.03,0.1-0.06,0.16-0.08c0.05-0.02,0.09-0.03,0.12-0.03h1.35
        c0.02,0.02,0.04,0.05,0.06,0.1c0.02,0.05,0.02,0.09,0.02,0.14l-0.27,0.08c-0.09,0.02-0.15,0.06-0.2,0.1
        c-0.05,0.04-0.08,0.1-0.09,0.16c-0.02,0.07-0.02,0.16-0.02,0.27v2.62c0,0.1-0.01,0.18-0.04,0.26c-0.02,0.07-0.05,0.13-0.07,0.16
        h1.42l0.36-1.06c0.09,0,0.17,0.01,0.23,0.04s0.11,0.05,0.13,0.07c0,0.14-0.01,0.27-0.02,0.42c-0.01,0.14-0.02,0.28-0.04,0.4
        c-0.01,0.12-0.03,0.22-0.04,0.3c-0.03,0.03-0.08,0.07-0.15,0.12c-0.07,0.05-0.13,0.08-0.2,0.12
        C127.71,158.22,127.66,158.24,127.63,158.24z"
          fill="#FFFFFF"
        />
        <path
          d="M129.02,158.28c-0.03,0-0.07-0.02-0.12-0.06c-0.05-0.04-0.1-0.09-0.15-0.15
        c-0.05-0.06-0.09-0.12-0.12-0.18c-0.03-0.06-0.05-0.11-0.05-0.14c0-0.04,0.02-0.08,0.06-0.12c0.04-0.05,0.1-0.09,0.16-0.14
        c0.07-0.05,0.13-0.08,0.19-0.11c0.06-0.03,0.11-0.04,0.15-0.04c0.04,0,0.08,0.02,0.13,0.06c0.05,0.04,0.09,0.09,0.14,0.15
        c0.04,0.06,0.08,0.12,0.11,0.18c0.03,0.06,0.04,0.11,0.04,0.14c0,0.03-0.02,0.07-0.06,0.12c-0.04,0.05-0.09,0.09-0.15,0.13
        c-0.06,0.04-0.12,0.08-0.18,0.11C129.12,158.27,129.06,158.28,129.02,158.28z"
          fill="#FFFFFF"
        />
        <path
          d="M131.59,158.8c-0.09,0-0.17-0.01-0.25-0.03c-0.08-0.02-0.13-0.04-0.16-0.06l1.41-3.62
        c0.14-0.36,0.25-0.67,0.34-0.92c0.09-0.25,0.15-0.43,0.18-0.54c0.11,0.01,0.21,0.04,0.29,0.07c0.08,0.03,0.13,0.07,0.15,0.1
        L131.59,158.8z"
          fill="#FFFFFF"
        />
        <path
          d="M137.47,158.24c-0.05,0-0.12-0.01-0.2-0.04c-0.08-0.02-0.16-0.06-0.25-0.1c-0.09-0.04-0.17-0.09-0.24-0.13
        c-0.07-0.05-0.13-0.1-0.16-0.15l-0.92-3.37c-0.02-0.06-0.04-0.11-0.08-0.14c-0.03-0.03-0.09-0.05-0.16-0.05h-0.31
        c-0.02-0.02-0.03-0.04-0.05-0.06c-0.01-0.02-0.02-0.05-0.03-0.08c0.02-0.02,0.06-0.05,0.1-0.09c0.05-0.03,0.1-0.06,0.15-0.08
        c0.05-0.02,0.09-0.03,0.12-0.03H137c0.02,0.02,0.04,0.05,0.06,0.1c0.02,0.05,0.02,0.09,0.02,0.14l-0.32,0.07
        c-0.05,0.01-0.11,0.03-0.17,0.06c-0.06,0.03-0.1,0.07-0.13,0.14c-0.03,0.06-0.02,0.16,0.01,0.29l0.74,2.84
        c0.04,0.04,0.09,0.07,0.15,0.11c0.06,0.03,0.13,0.06,0.2,0.08c0.07,0.02,0.13,0.03,0.19,0.03l0.73-2.78
        c0.07-0.24,0.13-0.42,0.2-0.53c0.07-0.11,0.12-0.18,0.16-0.2h-0.77c-0.02-0.02-0.03-0.03-0.05-0.06
        c-0.01-0.02-0.02-0.05-0.03-0.09c0.03-0.03,0.07-0.06,0.13-0.1c0.06-0.03,0.11-0.06,0.17-0.08c0.06-0.02,0.1-0.03,0.12-0.03h1.32
        c0.02,0.02,0.04,0.05,0.06,0.09c0.02,0.05,0.02,0.09,0.02,0.14l-0.17,0.06c-0.09,0.03-0.18,0.06-0.25,0.11
        c-0.07,0.04-0.13,0.11-0.19,0.2c-0.06,0.09-0.12,0.21-0.18,0.38c-0.06,0.17-0.13,0.39-0.2,0.66l-0.6,2.24
        c-0.04,0.05-0.09,0.1-0.16,0.15c-0.07,0.05-0.13,0.1-0.2,0.15s-0.14,0.08-0.21,0.11C137.59,158.23,137.53,158.24,137.47,158.24z"
          fill="#FFFFFF"
        />
        <path
          d="M141.86,158.24c-0.13,0-0.27-0.02-0.42-0.06c-0.15-0.04-0.3-0.1-0.45-0.16c-0.15-0.07-0.28-0.14-0.4-0.21
        c-0.12-0.07-0.2-0.14-0.25-0.2c-0.1-0.12-0.17-0.3-0.22-0.53c-0.05-0.24-0.08-0.5-0.08-0.79c0-0.24,0.01-0.48,0.04-0.71
        c0.03-0.23,0.06-0.45,0.1-0.64c0.04-0.19,0.08-0.34,0.13-0.44c0.09-0.07,0.2-0.15,0.34-0.23c0.14-0.08,0.29-0.15,0.46-0.22
        c0.17-0.07,0.34-0.12,0.52-0.17c0.18-0.04,0.36-0.06,0.54-0.06c0.11,0,0.23,0.02,0.37,0.05c0.14,0.03,0.28,0.08,0.43,0.14
        c0.15,0.06,0.28,0.12,0.39,0.18c0.12,0.07,0.21,0.14,0.27,0.21c0.06,0.09,0.11,0.22,0.15,0.36c0.04,0.15,0.07,0.31,0.09,0.49
        c0.02,0.18,0.03,0.36,0.03,0.55c0,0.23-0.01,0.47-0.04,0.7c-0.03,0.23-0.06,0.44-0.1,0.63c-0.04,0.19-0.09,0.34-0.14,0.46
        c-0.09,0.08-0.23,0.18-0.41,0.28s-0.39,0.19-0.62,0.27C142.35,158.2,142.11,158.24,141.86,158.24z M142,157.78
        c0.11,0,0.23-0.02,0.36-0.05c0.13-0.03,0.24-0.07,0.35-0.11c0.11-0.05,0.19-0.08,0.23-0.12c0.05-0.11,0.09-0.25,0.13-0.41
        c0.03-0.16,0.06-0.33,0.08-0.53c0.02-0.19,0.03-0.39,0.03-0.59c0-0.26-0.01-0.5-0.04-0.73c-0.03-0.22-0.07-0.41-0.11-0.55
        c-0.02-0.03-0.07-0.07-0.15-0.11c-0.08-0.05-0.17-0.09-0.27-0.14c-0.1-0.05-0.21-0.08-0.32-0.11c-0.11-0.03-0.2-0.05-0.29-0.05
        c-0.1,0-0.21,0.01-0.34,0.03c-0.13,0.02-0.25,0.05-0.37,0.09c-0.12,0.03-0.22,0.07-0.3,0.11c-0.05,0.12-0.08,0.25-0.12,0.41
        c-0.03,0.16-0.06,0.34-0.08,0.52c-0.02,0.19-0.02,0.38-0.02,0.57c0,0.25,0.02,0.49,0.05,0.73c0.03,0.24,0.09,0.44,0.17,0.6
        c0.04,0.04,0.1,0.09,0.18,0.13c0.09,0.05,0.18,0.1,0.29,0.14c0.1,0.05,0.21,0.08,0.31,0.11
        C141.85,157.77,141.94,157.78,142,157.78z"
          fill="#FFFFFF"
        />
        <path
          d="M147.25,158.24c-0.13,0-0.28,0-0.47-0.01c-0.18-0.01-0.38-0.02-0.6-0.03c-0.21-0.01-0.42-0.03-0.63-0.05
        c-0.21-0.02-0.39-0.04-0.56-0.06c-0.17-0.02-0.3-0.04-0.41-0.06c0-0.03,0.01-0.06,0.02-0.1c0.01-0.03,0.03-0.05,0.04-0.07
        l0.18-0.02c0.08-0.01,0.14-0.03,0.19-0.07c0.04-0.03,0.07-0.09,0.09-0.18c0.02-0.09,0.03-0.21,0.03-0.37v-2.47
        c0-0.11,0.01-0.21,0.02-0.28c0.01-0.07,0.03-0.12,0.05-0.16c0.02-0.03,0.03-0.05,0.04-0.06h-0.6c-0.02-0.02-0.03-0.04-0.05-0.06
        c-0.01-0.02-0.02-0.05-0.03-0.08c0.02-0.03,0.06-0.06,0.11-0.09c0.05-0.03,0.1-0.06,0.16-0.08c0.05-0.02,0.09-0.03,0.12-0.03h1.35
        c0.02,0.02,0.04,0.05,0.06,0.1c0.02,0.05,0.02,0.09,0.02,0.14l-0.27,0.08c-0.09,0.02-0.15,0.06-0.2,0.1
        c-0.05,0.04-0.08,0.1-0.09,0.16c-0.02,0.07-0.02,0.16-0.02,0.27v2.62c0,0.1-0.01,0.18-0.04,0.26c-0.02,0.07-0.05,0.13-0.07,0.16
        h1.42l0.36-1.06c0.09,0,0.17,0.01,0.23,0.04s0.11,0.05,0.13,0.07c0,0.14-0.01,0.27-0.02,0.42c-0.01,0.14-0.02,0.28-0.04,0.4
        c-0.01,0.12-0.03,0.22-0.04,0.3c-0.03,0.03-0.08,0.07-0.15,0.12c-0.07,0.05-0.13,0.08-0.2,0.12
        C147.34,158.22,147.29,158.24,147.25,158.24z"
          fill="#FFFFFF"
        />
      </g>
    </g>
    <g>
      <g>
        <g>
          <path
            d="M5.94,158.24c-0.13,0-0.27-0.02-0.42-0.06c-0.15-0.04-0.3-0.1-0.45-0.16c-0.15-0.07-0.28-0.14-0.4-0.21
          c-0.12-0.07-0.2-0.14-0.25-0.2c-0.1-0.12-0.17-0.3-0.22-0.53c-0.05-0.24-0.08-0.5-0.08-0.79c0-0.24,0.01-0.48,0.04-0.71
          c0.03-0.23,0.06-0.45,0.1-0.64c0.04-0.19,0.08-0.34,0.13-0.44c0.09-0.07,0.2-0.15,0.34-0.23c0.14-0.08,0.29-0.15,0.46-0.22
          c0.17-0.07,0.34-0.12,0.52-0.17c0.18-0.04,0.36-0.06,0.54-0.06c0.11,0,0.23,0.02,0.37,0.05c0.14,0.03,0.28,0.08,0.43,0.14
          c0.15,0.06,0.28,0.12,0.39,0.18c0.12,0.07,0.21,0.14,0.27,0.21c0.06,0.09,0.11,0.22,0.15,0.36c0.04,0.15,0.07,0.31,0.09,0.49
          c0.02,0.18,0.03,0.36,0.03,0.55c0,0.23-0.01,0.47-0.04,0.7c-0.03,0.23-0.06,0.44-0.1,0.63c-0.04,0.19-0.09,0.34-0.14,0.46
          c-0.09,0.08-0.23,0.18-0.41,0.28c-0.18,0.1-0.39,0.19-0.62,0.27C6.43,158.2,6.19,158.24,5.94,158.24z M6.09,157.78
          c0.11,0,0.23-0.02,0.36-0.05c0.13-0.03,0.24-0.07,0.35-0.11c0.11-0.05,0.19-0.08,0.23-0.12c0.05-0.11,0.09-0.25,0.13-0.41
          s0.06-0.33,0.08-0.53c0.02-0.19,0.03-0.39,0.03-0.59c0-0.26-0.01-0.5-0.04-0.73c-0.03-0.22-0.07-0.41-0.11-0.55
          c-0.02-0.03-0.07-0.07-0.15-0.11c-0.08-0.05-0.17-0.09-0.27-0.14c-0.1-0.05-0.21-0.08-0.32-0.11c-0.11-0.03-0.2-0.05-0.29-0.05
          c-0.1,0-0.21,0.01-0.34,0.03c-0.13,0.02-0.25,0.05-0.37,0.09c-0.12,0.03-0.22,0.07-0.3,0.11c-0.05,0.12-0.08,0.25-0.12,0.41
          c-0.03,0.16-0.06,0.34-0.08,0.52c-0.02,0.19-0.02,0.38-0.02,0.57c0,0.25,0.02,0.49,0.05,0.73c0.03,0.24,0.09,0.44,0.17,0.6
          c0.04,0.04,0.1,0.09,0.18,0.13c0.09,0.05,0.18,0.1,0.29,0.14c0.1,0.05,0.21,0.08,0.31,0.11C5.94,157.76,6.02,157.78,6.09,157.78z
          "
            fill="#FFFFFF"
          />
          <path
            d="M9.63,158.24c-0.03,0-0.1-0.01-0.19-0.02c-0.1-0.01-0.2-0.03-0.33-0.06c-0.12-0.02-0.23-0.05-0.34-0.07
          c-0.1-0.02-0.18-0.05-0.23-0.06c0-0.03,0.01-0.06,0.02-0.09c0.01-0.03,0.03-0.05,0.04-0.07l0.17-0.03
          c0.07-0.01,0.12-0.05,0.16-0.11c0.04-0.06,0.06-0.19,0.06-0.38v-3.37c0-0.03-0.01-0.06-0.02-0.09c-0.01-0.03-0.04-0.05-0.07-0.06
          l-0.48-0.16c0.01-0.04,0.02-0.08,0.03-0.11c0.01-0.03,0.02-0.05,0.03-0.06c0.04-0.02,0.11-0.05,0.2-0.08
          c0.09-0.03,0.18-0.05,0.27-0.07c0.09-0.02,0.16-0.03,0.21-0.03c0.12,0,0.22,0.05,0.31,0.14c0.09,0.09,0.13,0.2,0.13,0.33v3.69
          c0,0.09-0.01,0.17-0.02,0.22c-0.02,0.06-0.03,0.1-0.04,0.13l0.53,0.06c0.01,0.01,0.02,0.03,0.03,0.05
          c0.01,0.02,0.02,0.05,0.02,0.1c-0.02,0.03-0.06,0.07-0.12,0.1c-0.06,0.03-0.12,0.06-0.19,0.08
          C9.72,158.23,9.67,158.24,9.63,158.24z"
            fill="#FFFFFF"
          />
          <path
            d="M11.63,158.26c-0.1,0-0.2-0.02-0.31-0.07c-0.11-0.05-0.21-0.11-0.3-0.18c-0.09-0.07-0.17-0.14-0.23-0.2
          c-0.06-0.07-0.09-0.12-0.09-0.16v-1.84c0-0.03-0.01-0.06-0.02-0.09c-0.01-0.03-0.04-0.05-0.07-0.06l-0.38-0.14
          c0.01-0.06,0.02-0.1,0.03-0.13s0.03-0.05,0.04-0.06c0.07-0.04,0.16-0.08,0.28-0.12c0.12-0.04,0.21-0.06,0.28-0.06
          c0.12,0,0.22,0.05,0.31,0.14s0.13,0.2,0.13,0.33v1.86c0.05,0.04,0.1,0.08,0.16,0.13c0.06,0.05,0.12,0.09,0.18,0.13
          c0.06,0.04,0.12,0.06,0.16,0.06c0.05,0,0.12-0.01,0.2-0.03c0.09-0.02,0.18-0.05,0.27-0.09c0.09-0.04,0.18-0.07,0.26-0.1v-1.75
          c0-0.03-0.01-0.06-0.02-0.09c-0.01-0.03-0.04-0.05-0.08-0.06L12,155.51c0-0.05,0.01-0.08,0.02-0.11
          c0.01-0.03,0.02-0.05,0.04-0.06c0.05-0.02,0.11-0.05,0.19-0.08c0.08-0.03,0.17-0.05,0.25-0.07c0.09-0.02,0.16-0.03,0.21-0.03
          c0.12,0,0.22,0.05,0.31,0.14s0.13,0.2,0.13,0.33v2.18l0.46,0.06c0.01,0.01,0.02,0.03,0.03,0.06c0.01,0.03,0.02,0.06,0.02,0.1
          c-0.02,0.04-0.07,0.07-0.13,0.1c-0.06,0.03-0.13,0.06-0.2,0.08c-0.07,0.02-0.13,0.03-0.18,0.03c-0.11,0-0.22-0.03-0.33-0.09
          c-0.11-0.06-0.18-0.13-0.23-0.22c-0.09,0.05-0.19,0.11-0.31,0.16c-0.12,0.05-0.24,0.09-0.36,0.13
          C11.83,158.25,11.72,158.26,11.63,158.26z"
            fill="#FFFFFF"
          />
          <path
            d="M15.21,158.24c-0.05,0-0.12-0.01-0.22-0.04c-0.1-0.03-0.2-0.07-0.3-0.11c-0.1-0.04-0.19-0.09-0.27-0.13
          c-0.07-0.05-0.11-0.08-0.11-0.11v-2.32l-0.48-0.04c0-0.07,0.01-0.13,0.02-0.18c0.02-0.05,0.03-0.08,0.04-0.1l0.32-0.04
          c0.06-0.01,0.1-0.03,0.12-0.07c0.02-0.04,0.03-0.1,0.03-0.18v-0.63c0.05-0.04,0.12-0.08,0.2-0.12c0.08-0.04,0.15-0.06,0.22-0.06
          l0.12,0.08v0.98l0.69,0.01l0.06,0.08c0,0.07-0.02,0.13-0.04,0.18s-0.04,0.09-0.06,0.12l-0.65-0.02v2.21l0.72,0.12
          c0.01,0.01,0.02,0.03,0.03,0.06c0.01,0.02,0.02,0.06,0.02,0.1c-0.03,0.04-0.07,0.07-0.13,0.11c-0.06,0.03-0.12,0.06-0.18,0.08
          C15.31,158.23,15.25,158.24,15.21,158.24z"
            fill="#FFFFFF"
          />
          <path
            d="M16.43,158.8c-0.09,0-0.17-0.01-0.25-0.03c-0.08-0.02-0.13-0.04-0.16-0.06l1.41-3.62
          c0.14-0.36,0.25-0.67,0.34-0.92c0.09-0.25,0.15-0.43,0.18-0.54c0.11,0.01,0.21,0.04,0.29,0.07c0.08,0.03,0.13,0.07,0.15,0.1
          L16.43,158.8z"
            fill="#FFFFFF"
          />
          <path
            d="M20.79,158.24c-0.13,0-0.27-0.02-0.42-0.06c-0.15-0.04-0.3-0.1-0.45-0.16c-0.15-0.07-0.28-0.14-0.4-0.21
          c-0.12-0.07-0.2-0.14-0.25-0.2c-0.1-0.12-0.17-0.3-0.22-0.53c-0.05-0.24-0.08-0.5-0.08-0.79c0-0.24,0.01-0.48,0.04-0.71
          c0.03-0.23,0.06-0.45,0.1-0.64c0.04-0.19,0.08-0.34,0.13-0.44c0.09-0.07,0.2-0.15,0.34-0.23c0.14-0.08,0.29-0.15,0.46-0.22
          c0.17-0.07,0.34-0.12,0.52-0.17c0.18-0.04,0.36-0.06,0.54-0.06c0.11,0,0.23,0.02,0.37,0.05c0.14,0.03,0.28,0.08,0.43,0.14
          c0.15,0.06,0.28,0.12,0.39,0.18c0.12,0.07,0.21,0.14,0.27,0.21c0.06,0.09,0.11,0.22,0.15,0.36c0.04,0.15,0.07,0.31,0.09,0.49
          c0.02,0.18,0.03,0.36,0.03,0.55c0,0.23-0.01,0.47-0.04,0.7c-0.03,0.23-0.06,0.44-0.1,0.63c-0.04,0.19-0.09,0.34-0.14,0.46
          c-0.09,0.08-0.23,0.18-0.41,0.28c-0.18,0.1-0.39,0.19-0.62,0.27C21.28,158.2,21.04,158.24,20.79,158.24z M20.93,157.78
          c0.11,0,0.23-0.02,0.36-0.05c0.13-0.03,0.24-0.07,0.35-0.11c0.11-0.05,0.19-0.08,0.23-0.12c0.05-0.11,0.09-0.25,0.13-0.41
          c0.03-0.16,0.06-0.33,0.08-0.53c0.02-0.19,0.03-0.39,0.03-0.59c0-0.26-0.01-0.5-0.04-0.73s-0.07-0.41-0.11-0.55
          c-0.02-0.03-0.07-0.07-0.15-0.11s-0.17-0.09-0.27-0.14c-0.1-0.05-0.21-0.08-0.32-0.11c-0.11-0.03-0.2-0.05-0.29-0.05
          c-0.1,0-0.21,0.01-0.34,0.03c-0.13,0.02-0.25,0.05-0.37,0.09c-0.12,0.03-0.22,0.07-0.3,0.11c-0.05,0.12-0.08,0.25-0.12,0.41
          c-0.03,0.16-0.06,0.34-0.08,0.52c-0.02,0.19-0.02,0.38-0.02,0.57c0,0.25,0.02,0.49,0.05,0.73c0.03,0.24,0.09,0.44,0.17,0.6
          c0.04,0.04,0.1,0.09,0.18,0.13c0.09,0.05,0.18,0.1,0.29,0.14c0.1,0.05,0.21,0.08,0.31,0.11
          C20.78,157.76,20.86,157.78,20.93,157.78z M20.21,153.45c-0.03,0-0.07-0.02-0.11-0.05c-0.05-0.03-0.09-0.08-0.13-0.13
          c-0.04-0.05-0.08-0.11-0.11-0.16c-0.03-0.05-0.05-0.09-0.05-0.13c0-0.03,0.02-0.07,0.06-0.11c0.04-0.04,0.09-0.08,0.15-0.12
          c0.06-0.04,0.11-0.07,0.17-0.1c0.06-0.02,0.1-0.04,0.14-0.04c0.03,0,0.07,0.02,0.11,0.05c0.04,0.03,0.08,0.08,0.12,0.13
          c0.04,0.05,0.07,0.11,0.1,0.16s0.04,0.1,0.04,0.13c0,0.03-0.02,0.06-0.05,0.1c-0.03,0.04-0.08,0.08-0.13,0.12
          c-0.05,0.04-0.11,0.07-0.17,0.1C20.3,153.44,20.25,153.45,20.21,153.45z M21.51,153.45c-0.03,0-0.07-0.02-0.11-0.05
          s-0.09-0.08-0.14-0.13c-0.05-0.05-0.08-0.11-0.11-0.16c-0.03-0.05-0.04-0.09-0.04-0.13c0-0.03,0.02-0.07,0.06-0.11
          c0.04-0.04,0.09-0.08,0.15-0.12c0.06-0.04,0.12-0.07,0.17-0.1c0.06-0.02,0.1-0.04,0.13-0.04c0.03,0,0.07,0.02,0.11,0.06
          c0.04,0.04,0.08,0.08,0.13,0.14c0.04,0.05,0.07,0.11,0.1,0.16c0.03,0.05,0.04,0.1,0.04,0.13c0,0.03-0.02,0.06-0.06,0.1
          c-0.04,0.04-0.08,0.08-0.14,0.12c-0.05,0.04-0.11,0.07-0.16,0.1C21.6,153.44,21.55,153.45,21.51,153.45z"
            fill="#FFFFFF"
          />
          <path
            d="M24.48,158.24c-0.03,0-0.1-0.01-0.19-0.02c-0.1-0.01-0.2-0.03-0.33-0.06c-0.12-0.02-0.23-0.05-0.34-0.07
          c-0.1-0.02-0.18-0.05-0.23-0.06c0-0.03,0.01-0.06,0.02-0.09c0.01-0.03,0.03-0.05,0.04-0.07l0.17-0.03
          c0.07-0.01,0.12-0.05,0.16-0.11c0.04-0.06,0.06-0.19,0.06-0.38v-3.37c0-0.03-0.01-0.06-0.02-0.09c-0.01-0.03-0.04-0.05-0.07-0.06
          l-0.48-0.16c0.01-0.04,0.02-0.08,0.03-0.11c0.01-0.03,0.02-0.05,0.03-0.06c0.04-0.02,0.11-0.05,0.2-0.08
          c0.09-0.03,0.18-0.05,0.27-0.07c0.09-0.02,0.16-0.03,0.21-0.03c0.12,0,0.22,0.05,0.31,0.14c0.09,0.09,0.13,0.2,0.13,0.33v3.69
          c0,0.09-0.01,0.17-0.02,0.22s-0.03,0.1-0.04,0.13l0.53,0.06c0.01,0.01,0.02,0.03,0.03,0.05c0.01,0.02,0.02,0.05,0.02,0.1
          c-0.02,0.03-0.06,0.07-0.12,0.1c-0.06,0.03-0.12,0.06-0.19,0.08C24.57,158.23,24.52,158.24,24.48,158.24z"
            fill="#FFFFFF"
          />
          <path
            d="M25.62,158.8c-0.09,0-0.17-0.01-0.25-0.03c-0.08-0.02-0.13-0.04-0.16-0.06l1.41-3.62
          c0.14-0.36,0.25-0.67,0.34-0.92c0.09-0.25,0.15-0.43,0.18-0.54c0.11,0.01,0.21,0.04,0.29,0.07c0.08,0.03,0.13,0.07,0.15,0.1
          L25.62,158.8z"
            fill="#FFFFFF"
          />
          <path
            d="M30.6,158.24c-0.11,0-0.25,0-0.42-0.01c-0.17-0.01-0.35-0.02-0.55-0.03c-0.2-0.01-0.4-0.03-0.59-0.05
          c-0.2-0.02-0.38-0.04-0.55-0.06c-0.17-0.02-0.31-0.04-0.41-0.06c0-0.03,0.01-0.06,0.02-0.1c0.01-0.03,0.03-0.05,0.04-0.07
          l0.18-0.02c0.08-0.01,0.14-0.03,0.19-0.07c0.04-0.03,0.07-0.09,0.09-0.18c0.02-0.09,0.03-0.21,0.03-0.37v-2.47
          c0-0.11,0.01-0.21,0.03-0.31c0.02-0.1,0.05-0.16,0.09-0.18h-0.6c-0.01-0.02-0.02-0.03-0.04-0.05s-0.03-0.05-0.04-0.09
          c0.02-0.03,0.06-0.06,0.11-0.09c0.05-0.03,0.1-0.06,0.16-0.08c0.05-0.02,0.09-0.03,0.12-0.03h2.12c0.11,0.03,0.21,0.07,0.32,0.12
          c0.11,0.05,0.21,0.11,0.3,0.18c0.09,0.07,0.17,0.13,0.23,0.2c0.02,0.05,0.04,0.12,0.06,0.19c0.01,0.08,0.02,0.15,0.02,0.24
          c0,0.13-0.01,0.27-0.03,0.4c-0.02,0.14-0.05,0.26-0.08,0.36c-0.03,0.1-0.08,0.18-0.13,0.22c-0.06,0.03-0.14,0.06-0.24,0.1
          c-0.1,0.04-0.21,0.06-0.31,0.08l-0.01,0.02c0.19,0.02,0.37,0.06,0.52,0.12c0.16,0.06,0.27,0.11,0.34,0.16
          c0.05,0.09,0.08,0.18,0.09,0.29c0.02,0.11,0.02,0.21,0.02,0.32c0,0.09-0.01,0.18-0.02,0.27c-0.01,0.09-0.03,0.18-0.05,0.26
          c-0.02,0.08-0.04,0.16-0.07,0.23c-0.04,0.06-0.12,0.13-0.23,0.22c-0.11,0.08-0.23,0.16-0.36,0.22
          C30.83,158.19,30.71,158.23,30.6,158.24z M29.19,157.8h1.55c0.05-0.05,0.1-0.12,0.13-0.2c0.03-0.09,0.06-0.18,0.07-0.29
          s0.02-0.21,0.02-0.3c0-0.08-0.01-0.17-0.02-0.25c-0.01-0.09-0.03-0.17-0.05-0.24c-0.05-0.04-0.12-0.09-0.21-0.13
          c-0.09-0.05-0.2-0.09-0.34-0.13c-0.14-0.04-0.3-0.06-0.49-0.06h-0.56v1.19c0,0.1-0.01,0.18-0.04,0.26
          C29.24,157.71,29.22,157.76,29.19,157.8z M29.31,155.84c0.17,0,0.33-0.01,0.48-0.02c0.15-0.01,0.3-0.01,0.44-0.02
          c0.14-0.01,0.28-0.02,0.41-0.04c0.07-0.09,0.12-0.21,0.16-0.34c0.03-0.13,0.05-0.27,0.05-0.41c0-0.13-0.01-0.25-0.04-0.37
          c-0.03-0.11-0.08-0.2-0.15-0.27c-0.07-0.03-0.16-0.05-0.26-0.07c-0.11-0.02-0.22-0.03-0.33-0.04c-0.11-0.01-0.21-0.01-0.3-0.01
          c-0.15,0-0.26,0.05-0.34,0.13c-0.07,0.09-0.11,0.21-0.11,0.35V155.84z"
            fill="#FFFFFF"
          />
          <path
            d="M33.59,158.24c-0.11,0-0.23-0.02-0.34-0.06c-0.12-0.04-0.23-0.1-0.34-0.16c-0.11-0.06-0.2-0.12-0.28-0.18
          c-0.08-0.06-0.12-0.1-0.14-0.13c-0.05-0.06-0.09-0.16-0.12-0.32s-0.05-0.32-0.05-0.5c0-0.17,0.01-0.33,0.02-0.48
          c0.01-0.15,0.03-0.29,0.06-0.42c0.02-0.13,0.05-0.23,0.07-0.3c0.06-0.04,0.14-0.08,0.24-0.14c0.1-0.06,0.22-0.12,0.34-0.18
          c0.13-0.06,0.25-0.11,0.39-0.15c0.13-0.04,0.26-0.06,0.39-0.06c0.07,0,0.15,0.02,0.25,0.07s0.2,0.1,0.3,0.16
          c0.09,0.06,0.16,0.11,0.19,0.16c0.02,0.03,0.05,0.09,0.06,0.17c0.02,0.09,0.03,0.18,0.05,0.29c0.01,0.1,0.02,0.2,0.02,0.29
          c-0.05,0.07-0.1,0.13-0.16,0.18c-0.06,0.06-0.12,0.11-0.18,0.15c-0.06,0.04-0.11,0.08-0.16,0.1c-0.04,0.01-0.12,0.03-0.24,0.05
          s-0.27,0.03-0.45,0.05c-0.18,0.01-0.37,0.02-0.58,0.02c0.01,0.13,0.02,0.25,0.04,0.36c0.02,0.11,0.04,0.18,0.06,0.2
          c0.01,0.01,0.05,0.04,0.1,0.07c0.05,0.04,0.11,0.08,0.18,0.12c0.07,0.04,0.14,0.08,0.2,0.11c0.07,0.03,0.12,0.05,0.16,0.05
          c0.05,0,0.12-0.01,0.2-0.02c0.09-0.01,0.18-0.03,0.27-0.04c0.1-0.02,0.18-0.03,0.26-0.05c0.08-0.02,0.14-0.03,0.18-0.04
          c0.02,0.02,0.03,0.04,0.05,0.06c0.02,0.03,0.03,0.06,0.03,0.11c-0.08,0.07-0.17,0.13-0.28,0.21c-0.11,0.07-0.23,0.13-0.37,0.18
          C33.87,158.22,33.73,158.24,33.59,158.24z M33.35,156.49c0.09,0,0.19,0,0.29-0.02c0.1-0.01,0.2-0.02,0.28-0.04
          c0.09-0.02,0.15-0.04,0.19-0.06c0-0.05,0-0.1-0.01-0.17c-0.01-0.07-0.02-0.14-0.02-0.2c-0.01-0.06-0.02-0.1-0.02-0.12
          c0-0.02-0.03-0.04-0.08-0.08s-0.11-0.07-0.18-0.1c-0.07-0.03-0.12-0.04-0.17-0.04c-0.04,0-0.1,0.01-0.19,0.03
          c-0.09,0.02-0.18,0.04-0.26,0.07c-0.09,0.03-0.15,0.05-0.19,0.08c-0.02,0.05-0.03,0.1-0.04,0.17c-0.01,0.07-0.02,0.14-0.03,0.21
          s-0.01,0.14-0.01,0.21c0.04,0.02,0.11,0.04,0.19,0.05C33.19,156.48,33.27,156.49,33.35,156.49z"
            fill="#FFFFFF"
          />
          <path
            d="M36.41,158.24c-0.11,0-0.23-0.02-0.34-0.06c-0.12-0.04-0.23-0.1-0.34-0.16c-0.11-0.06-0.2-0.12-0.28-0.18
          c-0.08-0.06-0.12-0.1-0.14-0.13c-0.05-0.06-0.09-0.16-0.12-0.32s-0.05-0.32-0.05-0.5c0-0.17,0.01-0.33,0.02-0.48
          c0.01-0.15,0.03-0.29,0.06-0.42c0.02-0.13,0.05-0.23,0.07-0.3c0.06-0.04,0.14-0.08,0.24-0.14c0.1-0.06,0.22-0.12,0.34-0.18
          c0.13-0.06,0.25-0.11,0.39-0.15c0.13-0.04,0.26-0.06,0.39-0.06c0.07,0,0.15,0.02,0.25,0.07s0.2,0.1,0.3,0.16
          c0.09,0.06,0.16,0.11,0.19,0.16c0.02,0.03,0.05,0.09,0.06,0.17c0.02,0.09,0.03,0.18,0.05,0.29c0.01,0.1,0.02,0.2,0.02,0.29
          c-0.05,0.07-0.1,0.13-0.16,0.18c-0.06,0.06-0.12,0.11-0.18,0.15s-0.11,0.08-0.16,0.1c-0.04,0.01-0.12,0.03-0.24,0.05
          s-0.27,0.03-0.45,0.05c-0.18,0.01-0.37,0.02-0.58,0.02c0.01,0.13,0.02,0.25,0.04,0.36c0.02,0.11,0.04,0.18,0.06,0.2
          c0.01,0.01,0.05,0.04,0.1,0.07c0.05,0.04,0.11,0.08,0.18,0.12c0.07,0.04,0.14,0.08,0.2,0.11c0.07,0.03,0.12,0.05,0.16,0.05
          c0.05,0,0.12-0.01,0.2-0.02c0.09-0.01,0.18-0.03,0.27-0.04c0.1-0.02,0.18-0.03,0.26-0.05c0.08-0.02,0.14-0.03,0.18-0.04
          c0.02,0.02,0.03,0.04,0.05,0.06c0.02,0.03,0.03,0.06,0.03,0.11c-0.08,0.07-0.17,0.13-0.28,0.21c-0.11,0.07-0.23,0.13-0.37,0.18
          C36.69,158.22,36.55,158.24,36.41,158.24z M36.17,156.49c0.09,0,0.19,0,0.29-0.02c0.1-0.01,0.2-0.02,0.28-0.04
          c0.09-0.02,0.15-0.04,0.19-0.06c0-0.05,0-0.1-0.01-0.17c-0.01-0.07-0.02-0.14-0.02-0.2c-0.01-0.06-0.02-0.1-0.02-0.12
          c0-0.02-0.03-0.04-0.08-0.08s-0.11-0.07-0.18-0.1c-0.07-0.03-0.12-0.04-0.17-0.04c-0.04,0-0.1,0.01-0.19,0.03
          c-0.09,0.02-0.18,0.04-0.26,0.07c-0.09,0.03-0.15,0.05-0.19,0.08c-0.02,0.05-0.03,0.1-0.04,0.17c-0.01,0.07-0.02,0.14-0.03,0.21
          s-0.01,0.14-0.01,0.21c0.04,0.02,0.11,0.04,0.19,0.05C36.01,156.48,36.09,156.49,36.17,156.49z"
            fill="#FFFFFF"
          />
          <path
            d="M38.96,158.24c-0.03,0-0.09-0.01-0.19-0.02c-0.1-0.01-0.21-0.03-0.33-0.06
          c-0.12-0.02-0.24-0.05-0.35-0.07c-0.11-0.02-0.19-0.05-0.24-0.06c0-0.03,0.01-0.06,0.02-0.09c0.01-0.03,0.03-0.05,0.04-0.07
          l0.17-0.03c0.04-0.01,0.08-0.03,0.12-0.05c0.03-0.03,0.06-0.07,0.08-0.14c0.02-0.07,0.03-0.17,0.03-0.3v-1.53
          c0-0.03-0.01-0.06-0.02-0.09c-0.01-0.03-0.04-0.05-0.07-0.06l-0.37-0.14c0.01-0.06,0.02-0.1,0.03-0.13
          c0.01-0.03,0.03-0.05,0.04-0.06c0.07-0.04,0.16-0.08,0.28-0.12c0.12-0.04,0.21-0.06,0.28-0.06c0.12,0,0.22,0.04,0.3,0.13
          c0.08,0.08,0.13,0.19,0.13,0.32v0.04c0.04-0.04,0.09-0.08,0.14-0.14c0.06-0.06,0.11-0.11,0.18-0.16c0.06-0.05,0.12-0.1,0.18-0.13
          c0.06-0.03,0.12-0.05,0.17-0.05c0.05,0,0.11,0.02,0.16,0.06c0.06,0.04,0.11,0.09,0.16,0.15c0.05,0.06,0.09,0.12,0.12,0.17
          c0.03,0.05,0.05,0.09,0.05,0.12c0,0.02-0.01,0.06-0.04,0.13c-0.02,0.07-0.05,0.13-0.09,0.2c-0.04,0.07-0.07,0.12-0.1,0.16
          c-0.05,0-0.1-0.01-0.14-0.02c-0.05-0.01-0.08-0.03-0.11-0.03c-0.02-0.06-0.05-0.11-0.08-0.17c-0.03-0.06-0.06-0.1-0.08-0.14
          c-0.02-0.04-0.05-0.06-0.06-0.06c-0.02,0-0.05,0.01-0.09,0.04c-0.04,0.03-0.09,0.06-0.14,0.1c-0.05,0.04-0.09,0.07-0.13,0.1
          c-0.04,0.03-0.06,0.06-0.08,0.07v1.43c0,0.08-0.01,0.15-0.02,0.22c-0.02,0.06-0.03,0.11-0.05,0.13l0.54,0.06
          c0.01,0.01,0.02,0.03,0.03,0.05c0.01,0.03,0.02,0.06,0.02,0.1c-0.02,0.03-0.07,0.07-0.13,0.1c-0.06,0.03-0.12,0.06-0.19,0.08
          C39.06,158.23,39,158.24,38.96,158.24z"
            fill="#FFFFFF"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            d="M48.23,158.24c-0.13,0-0.25-0.02-0.37-0.07c-0.12-0.05-0.24-0.11-0.34-0.18c-0.1-0.07-0.19-0.14-0.26-0.2
          c-0.07-0.06-0.12-0.11-0.14-0.14c-0.07-0.11-0.11-0.26-0.14-0.43c-0.03-0.17-0.04-0.36-0.04-0.56c0-0.17,0.01-0.34,0.02-0.5
          c0.02-0.16,0.04-0.31,0.06-0.44c0.02-0.13,0.05-0.24,0.07-0.32c0.05-0.05,0.13-0.11,0.23-0.18c0.1-0.07,0.21-0.14,0.34-0.21
          c0.13-0.07,0.26-0.12,0.4-0.17s0.28-0.07,0.43-0.07c0.08,0,0.17,0.02,0.27,0.05c0.1,0.03,0.21,0.08,0.32,0.13
          c0.11,0.06,0.2,0.11,0.29,0.17c0.08,0.06,0.15,0.11,0.19,0.16c0.05,0.12,0.09,0.27,0.11,0.45c0.02,0.18,0.03,0.35,0.03,0.52
          c0,0.16-0.01,0.33-0.02,0.49c-0.02,0.17-0.04,0.32-0.06,0.47c-0.02,0.15-0.05,0.27-0.08,0.37c-0.06,0.08-0.16,0.17-0.29,0.27
          c-0.13,0.1-0.28,0.19-0.45,0.27C48.6,158.2,48.42,158.24,48.23,158.24z M48.48,157.77c0.09,0,0.18-0.02,0.27-0.06
          c0.09-0.04,0.17-0.08,0.22-0.13c0.04-0.1,0.06-0.24,0.08-0.4c0.02-0.16,0.02-0.32,0.02-0.47c0-0.16-0.01-0.31-0.02-0.46
          c-0.01-0.15-0.02-0.29-0.04-0.41c-0.02-0.12-0.03-0.2-0.06-0.26c-0.08-0.1-0.19-0.19-0.35-0.25c-0.15-0.06-0.31-0.1-0.48-0.1
          c-0.09,0-0.19,0.01-0.29,0.04c-0.1,0.03-0.19,0.06-0.25,0.1c-0.02,0.06-0.04,0.14-0.06,0.24c-0.01,0.1-0.03,0.22-0.03,0.34
          c-0.01,0.12-0.01,0.25-0.01,0.37c0,0.16,0.01,0.31,0.02,0.46c0.01,0.15,0.02,0.27,0.04,0.37c0.02,0.1,0.04,0.17,0.06,0.22
          c0.09,0.11,0.22,0.21,0.38,0.28C48.17,157.74,48.32,157.77,48.48,157.77z"
            fill="#FFFFFF"
          />
          <path
            d="M50.39,159.04c-0.04,0-0.08-0.01-0.12-0.04c-0.04-0.02-0.08-0.05-0.1-0.09
          c-0.03-0.03-0.05-0.06-0.05-0.09c0.06-0.03,0.12-0.09,0.2-0.16c0.08-0.08,0.15-0.16,0.21-0.24c0.06-0.09,0.11-0.16,0.13-0.22
          c-0.06-0.03-0.12-0.07-0.18-0.13c-0.06-0.06-0.11-0.12-0.14-0.19c-0.04-0.07-0.06-0.12-0.06-0.15c0-0.03,0.02-0.07,0.06-0.11
          c0.04-0.04,0.08-0.08,0.14-0.12c0.06-0.04,0.11-0.07,0.17-0.1c0.06-0.02,0.1-0.04,0.13-0.04s0.07,0.02,0.11,0.06
          c0.04,0.04,0.08,0.09,0.12,0.14c0.04,0.06,0.07,0.11,0.1,0.17c0.03,0.06,0.04,0.1,0.04,0.14c0,0.05-0.02,0.12-0.06,0.21
          c-0.04,0.09-0.09,0.19-0.14,0.3c-0.06,0.11-0.12,0.21-0.19,0.31c-0.07,0.1-0.14,0.18-0.2,0.24
          C50.49,159.01,50.44,159.04,50.39,159.04z"
            fill="#FFFFFF"
          />
          <path
            d="M52.27,158.88c-0.06,0-0.14-0.02-0.25-0.06c-0.11-0.04-0.21-0.08-0.3-0.14s-0.16-0.1-0.2-0.14
          c0.02-0.07,0.04-0.11,0.06-0.14c0.02-0.03,0.04-0.05,0.06-0.06c0.11,0.02,0.22,0.03,0.33,0.04c0.12,0.01,0.23,0.02,0.33,0.02
          c0.14,0,0.28-0.01,0.42-0.03c0.14-0.02,0.26-0.04,0.37-0.08c0.03-0.03,0.06-0.09,0.1-0.17c0.03-0.08,0.06-0.18,0.08-0.27
          s0.03-0.18,0.03-0.25c0-0.15-0.05-0.27-0.16-0.37c-0.11-0.09-0.24-0.17-0.41-0.22c-0.16-0.06-0.33-0.1-0.49-0.14
          c0-0.02,0-0.06,0.01-0.1c0.01-0.04,0.02-0.08,0.03-0.12c0.01-0.04,0.03-0.07,0.05-0.1c0.02,0,0.07,0,0.13,0
          c0.06,0,0.12-0.02,0.18-0.05c0.15-0.09,0.28-0.19,0.37-0.31c0.1-0.12,0.17-0.25,0.22-0.39c0.05-0.14,0.07-0.28,0.07-0.41
          c-0.05-0.04-0.14-0.07-0.25-0.09c-0.11-0.02-0.24-0.04-0.39-0.06c-0.15-0.01-0.31-0.02-0.49-0.02l-0.18,0.61
          c-0.01,0.01-0.04,0.02-0.08,0.04c-0.04,0.01-0.09,0.02-0.15,0.02c-0.04-0.05-0.09-0.14-0.14-0.25c-0.06-0.11-0.1-0.24-0.12-0.38
          c0.1-0.11,0.23-0.2,0.38-0.27c0.15-0.07,0.31-0.13,0.48-0.17c0.17-0.04,0.32-0.06,0.47-0.06c0.05,0,0.12,0.01,0.21,0.04
          c0.09,0.03,0.18,0.06,0.29,0.11c0.1,0.05,0.2,0.09,0.29,0.14c0.09,0.05,0.17,0.1,0.22,0.15c0.06,0.05,0.09,0.09,0.09,0.13
          c0,0.18-0.04,0.36-0.13,0.54c-0.09,0.19-0.2,0.35-0.33,0.5c-0.13,0.15-0.27,0.25-0.42,0.32v0.02c0.16,0.05,0.31,0.1,0.45,0.17
          c0.13,0.07,0.24,0.15,0.33,0.25c0.08,0.1,0.12,0.22,0.12,0.37c0,0.07-0.02,0.16-0.05,0.27c-0.03,0.1-0.07,0.21-0.12,0.32
          c-0.05,0.11-0.1,0.2-0.15,0.28c-0.05,0.08-0.1,0.14-0.14,0.17c-0.06,0.05-0.16,0.1-0.3,0.15c-0.14,0.05-0.29,0.09-0.45,0.13
          C52.58,158.87,52.42,158.88,52.27,158.88z"
            fill="#FFFFFF"
          />
          <path
            d="M55.13,158.88c-0.06,0-0.14-0.02-0.25-0.06c-0.11-0.04-0.21-0.08-0.3-0.14s-0.16-0.1-0.2-0.14
          c0.02-0.07,0.04-0.11,0.06-0.14c0.02-0.03,0.04-0.05,0.06-0.06c0.11,0.02,0.22,0.03,0.33,0.04c0.12,0.01,0.23,0.02,0.33,0.02
          c0.14,0,0.28-0.01,0.42-0.03c0.14-0.02,0.26-0.04,0.37-0.08c0.03-0.03,0.06-0.09,0.1-0.17c0.03-0.08,0.06-0.18,0.08-0.27
          c0.02-0.1,0.03-0.18,0.03-0.25c0-0.15-0.05-0.27-0.16-0.37c-0.11-0.09-0.24-0.17-0.41-0.22c-0.16-0.06-0.33-0.1-0.49-0.14
          c0-0.02,0-0.06,0.01-0.1c0.01-0.04,0.02-0.08,0.03-0.12c0.01-0.04,0.03-0.07,0.05-0.1c0.02,0,0.07,0,0.13,0
          c0.06,0,0.12-0.02,0.18-0.05c0.15-0.09,0.28-0.19,0.37-0.31c0.1-0.12,0.17-0.25,0.22-0.39c0.05-0.14,0.07-0.28,0.07-0.41
          c-0.05-0.04-0.14-0.07-0.25-0.09c-0.11-0.02-0.24-0.04-0.39-0.06c-0.15-0.01-0.31-0.02-0.49-0.02l-0.18,0.61
          c-0.01,0.01-0.04,0.02-0.08,0.04c-0.04,0.01-0.09,0.02-0.15,0.02c-0.04-0.05-0.09-0.14-0.14-0.25c-0.06-0.11-0.1-0.24-0.12-0.38
          c0.1-0.11,0.23-0.2,0.38-0.27c0.15-0.07,0.31-0.13,0.48-0.17c0.17-0.04,0.32-0.06,0.47-0.06c0.05,0,0.12,0.01,0.21,0.04
          c0.09,0.03,0.18,0.06,0.29,0.11s0.2,0.09,0.29,0.14c0.09,0.05,0.17,0.1,0.22,0.15c0.06,0.05,0.09,0.09,0.09,0.13
          c0,0.18-0.04,0.36-0.13,0.54c-0.09,0.19-0.2,0.35-0.33,0.5c-0.13,0.15-0.27,0.25-0.42,0.32v0.02c0.16,0.05,0.31,0.1,0.45,0.17
          c0.13,0.07,0.24,0.15,0.33,0.25c0.08,0.1,0.12,0.22,0.12,0.37c0,0.07-0.02,0.16-0.05,0.27c-0.03,0.1-0.07,0.21-0.12,0.32
          c-0.05,0.11-0.1,0.2-0.15,0.28c-0.05,0.08-0.1,0.14-0.14,0.17c-0.06,0.05-0.16,0.1-0.3,0.15c-0.14,0.05-0.29,0.09-0.45,0.13
          C55.43,158.87,55.28,158.88,55.13,158.88z"
            fill="#FFFFFF"
          />
          <path
            d="M60.05,158.24c-0.13,0-0.28,0-0.47-0.01c-0.18-0.01-0.38-0.02-0.6-0.03c-0.21-0.01-0.42-0.03-0.63-0.05
          c-0.21-0.02-0.39-0.04-0.56-0.06c-0.17-0.02-0.3-0.04-0.41-0.06c0-0.03,0.01-0.06,0.02-0.1c0.01-0.03,0.03-0.05,0.04-0.07
          l0.18-0.02c0.08-0.01,0.14-0.03,0.19-0.07c0.04-0.03,0.07-0.09,0.09-0.18c0.02-0.09,0.03-0.21,0.03-0.37v-2.47
          c0-0.11,0.01-0.21,0.02-0.28c0.01-0.07,0.03-0.12,0.05-0.16c0.02-0.03,0.03-0.05,0.04-0.06h-0.6c-0.02-0.02-0.03-0.04-0.05-0.06
          c-0.01-0.02-0.02-0.05-0.03-0.08c0.02-0.03,0.06-0.06,0.11-0.09c0.05-0.03,0.1-0.06,0.16-0.08c0.05-0.02,0.09-0.03,0.12-0.03
          h1.35c0.02,0.02,0.04,0.05,0.06,0.1c0.02,0.05,0.02,0.09,0.02,0.14l-0.27,0.08c-0.09,0.02-0.15,0.06-0.2,0.1
          c-0.05,0.04-0.08,0.1-0.09,0.16c-0.02,0.07-0.02,0.16-0.02,0.27v2.62c0,0.1-0.01,0.18-0.04,0.26c-0.02,0.07-0.05,0.13-0.07,0.16
          h1.42l0.36-1.06c0.09,0,0.17,0.01,0.23,0.04s0.11,0.05,0.13,0.07c0,0.14-0.01,0.27-0.02,0.42c-0.01,0.14-0.02,0.28-0.04,0.4
          c-0.01,0.12-0.03,0.22-0.04,0.3c-0.03,0.03-0.08,0.07-0.15,0.12c-0.07,0.05-0.13,0.08-0.2,0.12
          C60.14,158.22,60.08,158.24,60.05,158.24z"
            fill="#FFFFFF"
          />
        </g>
      </g>
    </g>
    <g>
      <defs>
        <rect height="36.96" id="SVGID_19_" width="36.96" x="106.61" y="167.06" />
      </defs>
      <clipPath id="SVGID_20_">
        <use xlink:href="#SVGID_19_" />
      </clipPath>
      <g clip-path="url(#SVGID_20_)">
        <image
          :href="
            logo ||
            'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAABAAAAAQACAYAAAB/HSuDAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJ\
  bWFnZVJlYWR5ccllPAAAA3VpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdp\
  bj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6\
  eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDYuMC1jMDA2IDc5LjE2\
  NDY0OCwgMjAyMS8wMS8xMi0xNTo1MjoyOSAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJo\
  dHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlw\
  dGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wTU09Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEu\
  MC9tbS8iIHhtbG5zOnN0UmVmPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvc1R5cGUvUmVz\
  b3VyY2VSZWYjIiB4bWxuczp4bXA9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC8iIHhtcE1N\
  Ok9yaWdpbmFsRG9jdW1lbnRJRD0ieG1wLmRpZDpjYzMwOWQ2NC1hZTFlLTRkMTQtYmJjYy1lZGJl\
  NjRlY2U2MzIiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6MTI0RUI4REM2NzkxMTFFQjkwQTg4\
  MkFGRDMyRkIwN0EiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6MTI0RUI4REI2NzkxMTFFQjkw\
  QTg4MkFGRDMyRkIwN0EiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIDIyLjIgKE1h\
  Y2ludG9zaCkiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDpj\
  YzMwOWQ2NC1hZTFlLTRkMTQtYmJjYy1lZGJlNjRlY2U2MzIiIHN0UmVmOmRvY3VtZW50SUQ9Inht\
  cC5kaWQ6Y2MzMDlkNjQtYWUxZS00ZDE0LWJiY2MtZWRiZTY0ZWNlNjMyIi8+IDwvcmRmOkRlc2Ny\
  aXB0aW9uPiA8L3JkZjpSREY+IDwveDp4bXBtZXRhPiA8P3hwYWNrZXQgZW5kPSJyIj8+LYgF7wAA\
  rOpJREFUeNrs3Qd0VNXWwPF904CQEFroHaQEASO9dxSUIqAgIE2wV9AnPhXhWQCfog8fqBRpgqhI\
  VUCQ3lRAmlIEnnRpSpEekvn2mUz8LmMSkpAy5f9ba68zcyYJZGcyuXvPuedaDodDAAAAAACAbwsg\
  BQAAAAAA0AAAAAAAAAA0AAAAAAAAAA0AAAAAAABAAwAAAAAAANAAAAAAAAAANAAAAAAAAAANAAAA\
  AAAAQAMAAAAAAAAaAAAAAAAAgAYAAAAAAACgAQAAAAAAAGgAAAAAAAAAGgAAAAAAAIAGAAAAAAAA\
  oAEAAAAAAABoAAAAAAAAQAMAAAAAAADQAAAAAAAAADQAAAAAAAAADQAAAAAAAEADAAAAAAAA0AAA\
  AAAAAAA3I4gUAACQ+SzLMk34PBq5XRGukdMV7vdHORyOI8l8rfE6NHXdDXf7+x6qke0G/50LGlfd\
  5uI0zrpuX9G46Lr9p8ZW/f88k8z/J0SHe12ff84V5vYZM+rnxvEMAACABgAAAL5W6EfoMMFV7Nsj\
  IhVfZqbGkWQez6tR5ib+mwmNBnf50nj8EKnxSTI5+dPVEPjdFSdd4x+2ufkOh+MMzyAAAGgAAACQ\
  GcW7eSe7qCsKaxRJZDymhWrzZL7MZY1ON/P/mDhx4tM6nHAV6Qnv6OfSCDaNhLZt25aeP39+puWl\
  du3at+gwVeJXBSSsHjAF/TUzfvHFF3nuvffe5L5EuCuKJfMx5t84k8zPppfEN1IOaxzVOOj6WVzj\
  mQsAAA0AAAASKyQLmZpWo6RGCY3irjD3zWM32i8nj2s0hXkB1+eYd8ALmttakEaGhobGXrp0KTCt\
  /8fIyMjuyVbT4eGZmrMCBQqY761HUo+XLl36pv+NDRs2lHY1FE7I/59+YPeo6+dmF6c/z2M6HnI1\
  BkxTYL/Gr65xv/48/uRZDwDw2+Me/UNIFgAAvlrcWzrk0L91F5P5mG46TEvrvxEYGOiIiYm5oF8n\
  LKmPKVOmjPz666+J/duSJ08eyZ07t0RERDgL+bCwMGeYudDQUGf06tVLypcvn+T/4eDBg3Ly5Mn/\
  70jkyfO3j8mWLZvzayUmNjZWzp0797f506dPO0f9/uT8+fPO2xcvXpSCBQuaVQBJ/n927dolAwcO\
  dH7Ns2fPOkcTCV/vRsz/9fLly/Yp848fczUDTBzTfHXRr5knDT+y313NgOH6vJjJbwkAgAYAAADe\
  VeibTfNMhVzBFeVtMUH/1j2ZxKeGdO7cucOXX3752c38+8ePHzfviif5uH59MX9vTWFuD1Pk+5uE\
  hoBpBvz+++9y6tQpZ5jbf/zxh3M0Jk+enOTXMLnMnj27XL169Wb+Kz3060xL5jlVx/Vc2qHxi37s\
  WX7TAAA0AAAAyLxC37zje6tGZVdEucaCSX1OSEjI4itXrgzUm+VsUcY1ljh69Ghg0aJFk/w3zbvm\
  JUqUkCJFioj5ODOaKFSokBQuXNhZ+N9yyy0SEMCVdTOL/jxl8ODBoj875+oHMx4+fNh91UCyZs6c\
  OadTp05r9eZeW1y2PddG6WBvHJlNGHfaYrfGNj2OOsVPBABAAwAAgPQp+u/Tob+r0C+c2s8vV66c\
  7NmzJ8nHzd/BJk2aOIv54sWLO4v9kiVLOsdixYpJ/vz5+SF4CbOSwDQDDh065Iz9+/dfF2alRoJj\
  x445T2VwcyShGVCpUqWGu3btKp+Cf/Y3je2mGeAaf9L4WZ9XV/iJAABoAAAAcH2BH5DcdeH18Sd0\
  eD+tX9+8g2+WnAcGBpJsP3fp0iXnXgymGdCmTZtkP7ZUqVJy4MCBtP5TZvNCs8fAK2QdAOBJuAoA\
  ACAzi33zdnoNjeq2+EjjzUQ+3OwCf9u4ceNq9u/f/4Zf27xbbzbKS4iKFSs6R1PIUfzDyJEjh0RF\
  RTnjRtq2bSs7d+50bmh45MiRtBxf/XaD3wVzpYg4h8PxOz8ZAECmHYuxAgAAkEHFfg4dbtcwm6nV\
  dEWZv1VKQUFzYmJiXtab0abgd4X5POcO72ZjuHz58v318aagNwVc5cqV/wpT7Jud84GMYDYuNI2A\
  n3/+WXbv3u28/dNPPzlXEyR1HLVgwYKNrVu3Xqk3t7jC7BsQa/v9GKzDUIk/5eAHW2zWr3mZrAMA\
  aAAAADy54C+lQ11X1HIV8cE3+jxzibx9+/Yl+zHTpk1zbrRXqVKlTL/mPZCUP//809kU2LZtm2zf\
  vv2vOHPmjDPcnqvmUpTbXc2AzQULFux+4sSJhol82RiNra5mwHqNdXqs9j+yDQCgAQAA8ITCv6cO\
  IzQKpfVrmHdYc+XKRTLhE3777TfnppLJMVeSMB+XQkc1zBUL1rmaAj/q8VsMmQYApBZ7AAAAbtbp\
  1BT/Zof9GjVqXBcU//AlNyr+zaaUZnPKVCiica8rjAuWZT3rcDjGkW0AAA0AAMBNcZ2/b5byN9M4\
  poXGfxP5sGIajffv39+6dOnSiZ4LHRkZKbVq1ZI6der8VexzWT34O9Pw2rt3r/OyhT/88MN18fvv\
  KdoTMKfGITIJAEj1MR6nAAAAtOA35+rX1mjqKvrNxn3ZXQ9v1b8VZmO+shrmnOUmGo0kfpd+J7MJ\
  nzmPv0qVKlK3bl1nwV+7dm3nLvwAUs40BjZs2CDr16+XtWvXytatWyU2Nva6jzFXtfjtt98+j4yM\
  XKR3V2nsS+R3+mkdcmss1fhOf4evkV0AAA0AAPDfor+yDq1cYQr7nEl8aNypU6d+y5cvX9GkvtYv\
  v/wiRYsWlZw5c5JYIB2ZjQa///57WbdunaxZs0a+++4754aYmzZtsn+YuU6hueLAaldDYIf+fv+s\
  Y8L1Ds9rrND41oQe+/1MZgGABgAAwLcL/rw6tLQV/cVS+rlz5syR9u3bk0Qgi5nVACdOnEh2n4Gj\
  R4+eKFq0aIFkvozZffAbDbOCYIkeC/5BZgHAP7AHAAD4fuFvCv1ZGtU1AlL6eeZc/caNG0uzZs2k\
  evXqJBLwAGb5/402GVyxYkWBG3wZ8wV6uyJWXyPMJQcXuhoCmxwORxyZBgAfPS5kBQAA+HwDIFCH\
  kxp5kvs4szFZo0aNnAW/CXM+f0BAAAkEvMzBgwdl9uzZ8u2335pmgJw/fz41n25eKxZrPK3HiL+T\
  TQCgAQAA8KwCv5y+lu9N4mFzeb67taAfsnz58uvO4Q8KCnLu0H/nnXdKixYtpGbNms45AL7j2rVr\
  zn0DTDPAhNlPwMzdgDkloIC+rsSSQQCgAQAAyPqi3+zKf49GR41bNSrp6/ku85DG7abod4VZu29N\
  mjRJ+vTpI6VKlXIW/C1btpTmzZtLREQEyQT8iNlUcNmyZbJo0SJn7N+//28fU7t27e3fffddX71p\
  dhp0JPEaFEiDAABoAAAAMqbgN2vxa7kK/k4aZeyPt2jRYuqSJUuu6M27JP783uucOXPGec3xcuXK\
  kUwAf9m1a5csXLjQ2QxYtWqVXL58WaZNmybdunUzD5vNAr/W+EpjicZF1+uRubrAco05Gl+a23o8\
  GUM2AYAGAAAg7UW/WY/fyFX0m3f7iyT1sQ0aNJDVq1eTNABpdvHiReeeAfXq1ZPcuXO7P3xZ4i8l\
  OP8WtXfv3mdsj512NQpMM+AbPba8RDYBgAYAAODGRX82HVpI/Lv87TTypeBzpE6dOrJy5UoJDg4m\
  iQAylNk7ZMOGDUk9fEFjgcZMja/0OPMiGQMAGgAAgOuL+Lo6PK3RRiP8Rh8fEhIiTZs2lQ4dOki7\
  du2kSJEiJBFAhvvjjz+kWLFiculSit7kN82A+RqfayzUY87LZBAAaAAAAC/GlmXe8Z+Z3MeYy/S1\
  adNG2rdv7xzNfQDIbGYjwQULFsiXX37pHC9cuJCSTzunMVdjhsYS9gwAABoAAOC3fv7555q1a9f+\
  Tg+kA+zzefLkcb7Df++99zov1ZctWzaSBcBjmE0DFy9eLLNmzZJ58+bJ6dOnU/JpD+rx58dkDwBo\
  AACA773QWlYlHRrp6+1Hbg+V1Lhfw2y3XaVHjx7O3bdN0W+W9nfu3NlZ9Jvl/gDg6WJiYmT58uXy\
  +eefy+zZs52nCyTCXK2kkL4eniFjAEADAAB8pejPr0NXjd4a1TXiNEq5dse+z1X41zcfmvA5mzdv\
  luPHj0vz5s3ZzA+A1zcDlixZIp999pnMnTtXzp4965zv0KGDY/bs2Wv15qcSvzfAqUReP0PZPBAA\
  aAAAgKcX/WZ9fmtX0W8287uuin/uued2//vf/y7jPg8AvuzKlSuyaNEiZzOga9euztOaEvoEGotd\
  zQCzP8B5fR01m6AekvhLDk7UWMB+AQBAAwAAPKnwr6LDQxK/lD9vUh9XpUoV2bZtGwkDgL8zOwnO\
  6dix44nZs2c/a5s/oTHNNAP0mHU7aQKAmxNECgAgTUV/hMQv8e8v8Uv8k1WjRg3p1auXxMXFSUBA\
  AAkEgOvl1OieyCaCBTRMQ+BZfd3dpOMk0xBwOBynSRkApOEYlhUAAJDiot+cr99Qo6/En8efI7mP\
  N9fK7t69u/Tu3VsqVqxIAgEgGeZygpUrV5YDBw7c6EPNXirmsqlj9Th2DZkDABoAAJARDYBeEv/u\
  U5LCwsKkffv2zqK/WbNmvNsPAKlgVkktW7ZMJk2a5Ly04KVLl270KTs1xmlM1mPaP8ggANAAAID0\
  agCYzamOmjrf/bG6des6i/5u3bo5mwAAgJtjrhxgNg40zYD169ff6MPN5QXNqoDX9Nh2N9kDABoA\
  AHAzGmg81qdPn856MOrcxT9//vzywAMPiM45N/gDAGSMXbt2yccffyyTJ0+WEydOJPeht+qx7c9k\
  DABoAABA0i+GlhWlw+Mar+rrYsJ1qc1Gfz00HtWobCY2bNggr7zyijz44IPOS1lly5aN5AFAJrl6\
  9arMnTtXxo4dK0uXLhX7cWzJkiX/t3///tv15lkyBQA0AADAveg3J+jfrfGERgszpfFPfV1c5Cr6\
  75dElvsDALLevn37ZPz48TJx4kQ5fvy4TJ8+Xe6///7z+tAMjTEamxN53Q/U1/hYsgeABgAA+E/h\
  n1vid/I3hX9p+2OFCxeOOXToUHBgYCCJAgAvEBMTI1999ZXcddddEhISYn/oB40PXQ2BS/ra30jH\
  KRrva0zQY+AzZA8ADQAA8N3Cv4IOz2g8IPHXnE7UnDlznDv5AwB8wu8a4/LkyVPlzJkzd7nmzEqB\
  SRqj9Fh4DykCQAMAAHyn8G+qw7Ma5sAvyevy3XrrrfLUU09J9+7dJTQ0lMQBgI84ePCglC1bVq5d\
  u+b+UJzG1xr/0WPipWQKgK8LIgUAfLToNzv1d5H4d/yrJ/VxZqm/2czvySeflKZNm5I4APBBa9as\
  Seoh0xRua0L/bmzT8d8anzkcjhiyBsAnj5FZAQDAB4v/p3UYqFE8qY/Jmzev9OvXTx599FEpVaoU\
  SQMAH3fgwAEZPXq0jBs3Ts6cSfb0/0Ma72qM1+PkP8kcABoAAODZDYCxOvRP7LEKFSrIM888Iz17\
  9mSZPwD4oQsXLsikSZNk1KhR8ssvvyT3oaZLYDYRNKcHHCNzAHxBACkA4Gv++9//rg4IuP7lzSzv\
  N9eO3rFjhzzyyCMU/wDgp3LmzCmPP/647Ny503n1gBYtWiT1oeaKMYM09luW9RaZA+ALWAEAwGde\
  zzTu1nheo2GHDh1kwYIF0qVLF+c7/tWrVydDAIBEbdmyRd555x2ZMWNGYhsFGkP0mHkomQJAAwAA\
  MvNFy7Lq6dBT4zF9/TK7N5vNTO+X+HdpohI+bt++fc7rQRcvXpykAQBSxOwT8N5778mECRPkzz/j\
  T/8PCwuTtWvXPlO1atXRevcaWQJAAwAAMr7wN1v0v6Lh3Kq/cOHCPY4ePWqWZz6nUYoMAQDSyx9/\
  /CEffvihvP/++9K1a1d5912zJ6Ds13hb42ONS2QJAA0AAEj/wv8OHV7WaGCfr1GjhmzYsIEEAQAy\
  zJUrV+Ty5csSERFhnz4u8VcJGKPxp/6dMivRPtWYoMfVi8gaABoAAJD6wr+tq/CvldTHrFixQho3\
  bkyyAABZ4Q+N/9xyyy1/7t27d6Rr7nuJ3y+ARgAAj8RVAAB4WuHfWuMHvTkvqeLfbOhndvRv1KgR\
  CQMAZJW8165dG6p/s0ba5mprLNS57zTuJEUAaAAAQOKFfwuNtXpzgUbNxD6mXr16zp39N27cKO3a\
  tTOfQ+IAAFlm8eLFsmfPnsQeSmgErHedygYANAAAQA+MGmms1JtLTI2f2MeYZf7ffvut2YVZWrdu\
  TdIAAB6hTZs2smzZsuRWpNXRWKR/51ZpNCRjALL82Js9AABkYfFvLqn0WFKPN23aVIYOHSoNG3LM\
  BADwbMuXL5chQ4bIqlWrkvswszfAy3r8vYmMAcgKrAAAkJUWJzbZoEED5zsqJij+AQDewDStV65c\
  eaMVAWZfgA2WZc3UiCJrAGgAAPAXIbGxsUVr1qwZmzBRp04d5/mUq1evdh5IAQDgrY2ApUuXOveu\
  SYTZwKaTxnbLsl4jYwBoAADw9dedHho7AwICRr/xxhuBNWrUkK+++krWr18vLVu2JEMAAK/XrFkz\
  59418+fPl9tuuy2pv4fbyRSAzMQeAAAy5sXFsnLoMEDjE32dOeCabqvxhkYV+8ea1yF29AcA+Crz\
  d+6LL76QwYMHy+7du51zt912W8zGjRs7BwYGziNDADILKwAApHfhH6DRV2/+ovG6xr80Gmus05jn\
  Xvy7PofEAQB8+W+j3HffffLTTz/Jxx9/LCVLlpS33norWIv/ua6/j43JEoBMeT1iBQCAdDzAMdc6\
  HqYRnTAXEBAgP/74o1SrVo0EAQCgYmJiJDg42H3aXCFgoMYO19/UfDrU1GP1RWQMQHphBQCA9Cj8\
  K2ssdB28RNsfi4uLc14WCQAAxEuk+DfMFQK2aozVKKzxssZC/fu6QKMcWQNAAwBAVhf++TXG6c1t\
  rgOX6wQFBUm/fv1kzJgxJAsAgBsL0uj/i9LxMddca42f9e/t2xq5SREAGgAAMrvwD9EwyxT3aPRL\
  7LXkzjvvlK1bt8q4ceOkcOHCJA0AgBT65z//GaZDiG3K3Hb+3dW/v/3NfjtkCQANAACZUfy31+Fn\
  jbc1/vZORHR0tCxbtkwWLlwoUVFRJAwAgFR64oknnH9PE5Ff4k8R2Kh/j5uQKQA0AABkVOEf5TrP\
  f47G385FLFGihEyZMkU2btwoTZs2JWEAAKRRkyZNnH9PJ0yYkNQqOtMdWK5/l2dqlCBjAFJ8TM9V\
  AACkoPg3SxEPSSLv+IeFhcmgQYNkwIABkiNHDpIFAEA6On/+vAwfPlxGjhwply5dSuxDzORIjeF6\
  XH+ejAGgAQAgPZoAL+rwpu2+9OzZU4YNG8Y5/gAAZLCDBw86G+4zZsyQJI7ff9N4TuNTBwf4AJLA\
  KQAAUiL6woULbUqXLu28U6dOHVm3bp1MmjSJ4h8AgExgTrWbPn268+9vjRo1EvsQ8wd5msZay7Ky\
  kTEAiWEFAIDk5NV4TeNhjcBFixbJ0aNHpU+fPs4VAAAAIPPFxcU5m/AvvPCCnDp1yv3hyXp835ss\
  AaABACDxF4L4av4+jVn6mhAj8auDHnIV//nJEAAAnufMmTMyZMgQGT16tFy7dk1y584tK1eufLlq\
  1arDTJ+ADAGgAQDAvfivpMOHGo00XtDXhNU6jpb4HYYBAICH27Fjh/PSgffcc488+eSTZmqzxuMa\
  68kOABoAAEzhb7bsf1niNwwKMXPBwcFX9u7dG1KiRAnW9wMA4GXMqQEBAX9t8WUO8sdrvKBx2vb3\
  P9i12g+AH2ITQMA/i/87dNiu8c+E4t+IiYnJNnDgQIp/AAC88cA+4LpDe/P3vL/GTo3urr//LXTY\
  7ToOAOCPdQArAAC/KvwL6fAfiT/f/2/q168vH3zwgVSpUoVkAQDgQy5cuPBtWFhYKb1ZzjVlrhgw\
  QGuBE2QH8B+sAAD8o/A3+ujNHYkV/3ny5JGxY8fK6tWrKf4BAPBBw4cPb2Er/g2zKmCHHh/0tri0\
  D0ADAIDPFP9ldPhW42NT67s9Jg888IDs2rVL+vfvz6X9AADwUSdOnEjs73w+jYkaS/SxW8gS4Ae1\
  AacAAD5b+Afp8JTEX8ov1P3x8uXLy5gxY6R58+YkCwAAP7B06VJ55JFHZO/evYk9fEljiMZIrQ+u\
  kS3AN7ECAPDN4r+qxF/65x334j84OFhefvll2bZtG8U/AAB+xPzdN3//X3zxRQkKCnJ/2FwdaITG\
  Oj2OuJVsAT5aJ7ACAPDJBsAsHe5xn69Vq5aMHz+e8/wBAPBzphHw0EMPyffff5/Yw1c1XtcYziUD\
  Ad/CCgDANz0VEhJyKeFOzpw55Z133pF169ZR/AMAAKlatarzuOA///mPhIWFuT9sLhH8L40fLMuK\
  JlsADQAAnquIw+EYPWbMGLOUT1q2bCnbt2+XAQMGSGBgINkBAADxhUBAgDz11FPO1QAtWrRI7ENu\
  czUBBpAtwDdwCgDgW8yl/kZq5Da/2wsXLpTWrVuzuz8AAEiWOW4wpwk+//zzcvbsWfeHO+rjs8kS\
  QAMAQFb84sbv8F9Yf38PuaZKaIzVuIPsAACAtDp8+LA8/PDDsmDBAuf91q1b/6a36+jNg2QHoAEA\
  IPOL/wo6TNEwJ+xV19/hnjr+WyMX2QEAAOlhypQpMmLECFm9erXkzZv3nE49pzGOzAA0AABkTuFv\
  1vE/pvGWuC7t17t37wMTJ04sSXYAAEB6M3WC22mEX2n00zhOdgAaAAAyrvgvqsPHGq3s82ZTv+XL\
  l0vDhg1JEgAAyAwnNR7WmO06RumrwxLbaYkAPBhXAQA8v/jvosM29+LfaNKkiZQqVYokAQCAzBKp\
  MUtjYrVq1dpJ/GkBW/V45T5SA9AAAJD2wj+XxjS9OUMjr/2xHDlyyHvvvSdLliyR4sWLkywAAJCp\
  zp071/vs2bNzXfVEHo3P9Lhlkjl+ITsADQAAqSv+6+mwVaOb+2PVq1eXjRs3ytNPP83l/QAAQJZ4\
  99135cCBA+7TvTQ2u45jANAAAHCDwj9QY7DeXKlRyv5YUFCQvPzyy7J+/XqJiooiWQAAIMu89NJL\
  zuMSsx+RmzLmOEaPZ4a6LlsMwJPqDTYBBDym+De7+Zsl//XdHytbtqx88sknUqdOHRIFAAA8xtq1\
  a6VHjx6yf//+xB5er3G/1hsHyBTgGVgBAHhG8d9Vhy2JFf89e/aUzZs3U/wDAACPU79+fdm6dauz\
  CZCIuub4Ro9zOpEpgAYAgP9nds7NbZ+IiIiQTz/9VCZPnizh4eFkCAAAeKRcuXLJ1KlT5bPPPpM8\
  efK4P2yOb2ZalvWBRg6yBWQtTgEAPMDtt98eefjw4V9PnjyZ09yvV6+eTJs2jUv8AQAAr3Lo0CHp\
  3r27rF69OrGHf9LoqvXHz2QKyBqsAACyXqkff/xx7uzZs3OGhITIq6++KitXrqT4BwAAXsdcnnj5\
  8uUyePDgxDYIvFXjB8uyepEpIGuwAgDIWmbp/0fiWv5/9OhRKVKkCFkBAABeb8WKFc69AY4cOeL+\
  kFkF8BkZAjIfKwCATOJ2KZxQjXEan4nt3H+KfwAA4CuaNGkiW7ZskbZt2/41p7d3a/E/n+wAWVST\
  sAIAyJTiv7cOz2o01N85U+V/IfHL4AAAAHyaqTdGjRrl3NzYrArInj272QvgXo1dZAegAQD4UuGf\
  XYfRGn3N/XLlyq3bs2dPVb0ZRnYAAIA/iY2Nte8LcF7jEY1pZAbIPJwCAGRc8V9Gh/UJxb+xd+/e\
  eqNHj6b4BwAAfsdtU0BzPPSJxO+FlN12/MS1jwEaAIDXFf/tddikcZt9Pjg4mOQAAAD8v4c01mmU\
  1uOnRjoecB1HAciIOoVTAIB0LfxNa/sNjX+Yu/bHihYtKjNnzpQ6deqQKAAAAJsjR46cLlGiRExc\
  XFwBvWsKlGEag7VWiSU7QPphBQCQfsV/fh2+0XjBvfhv2bKlbN68meIfAADATUxMjNx///15XMW/\
  uI6j/qmxUI+v8pEhgAYA4GnFf7QOGzSaX/cLFhAgr7zyiixcuFAiIyNJFAAAgBuzN0Dz5s2dx01u\
  Wmps0uOs28kSkE51C6cAADdd/PfQYaxGDvt8vnz5ZMqUKdKmTRuSBAAAcAMLFiyQ7t27y5kzZ9wf\
  uqzxqNYtk8gSQAMAyKrCP0iHtzWedn8sOjpaZs2aJaVKlSJRAAAAKbRv3z7p1KmTbN26NbGH39cY\
  oPXLNTIFpA2nAABpK/7Nev5vEyv+Ted6zZo1FP8AAACpVLZsWVm3bp306NEjsYef1Fjs2ncJAA0A\
  INNc1ShqnwgKCpKRI0fKJ598IqGhoWQIAAAgDcxx1NSpU2XUqFGJXUK5qcb3lmVVJVMADQAgUzgc\
  jrPDhw9/JSIiwnnfbPC3ZMkSefbZZ0kOAABAOnjyySdl6dKlUqBAAfeHymistSyrE1kCUoc9AIC0\
  uU9j4vz580Nfe+01mTlzppQoUYKsAAAApLMDBw7IPffc47ykshtTyLymMcRBUQPQAAAygFk187rG\
  IIm/Rq3ExcUldtkaAAAApJOLFy/Kgw8+KDNmzHB/aKLWM33JEJDyYgZAyoRpzNZ4MaH4d/4SUfwD\
  AABkKLMvwPTp0+XNN9/869irWrVqZz///PPnyA6QcqwAAJL65bCs6jo8oPGs/p6Y9f3zNNhwBgAA\
  IAt9/fXX8uKLLzr3B4iMjNyuU201DpAZgAYAkNbi3/wh+VQjZ/Xq1Sdv3Lixtd4uQGYAAACyntsp\
  mCc0OmqsJTNA8li7DPy9+H9C4pf65zT3f/zxx17Tp0+n+AcAAPCUIub6UzDNcdpSjZ5kBqABAKS0\
  8A/QeEdvvq8RmDBvVsl89913JAgAAMBzZdOYLPGbNeshnRWpMUwjhNQAtpqHUwAA51+JHDp8IvHL\
  x/5iusvDhw+X559/niQBAAB4gUuXLs0IDQ0tpTfrSPzKgE5a85wlM4BIECkAxb9llo2ZDf5q2+dz\
  5swpU6dOdV53FgAAAJ7PvLnZv3//rrap5hpr9HjvLn3sIBmCv+MUAPh78V9Rh/XuxX+hQoVk+fLl\
  FP8AAABe5MiRI85jODe3muM9Pe67nQyBBgDgv8V/I4nfLbaMfb5KlSry/fffS82aNUkSAACAFylW\
  rJhz7yZzPOemiMZKPf5rQ5ZAAwDwv+K/mw6LNfLa5++8805Zu3atlChRgiQBAAB4oeLFi8vq1aul\
  RYsW7g+FaczT48D+ZAk0AAD/Kf5fkvgN/7LZ5/v27Svz58+X8PBwkgQAAODFIiIiZMGCBdK7d2/3\
  h8yVnsbq8eBQsgQaAIDvF/8fiOvyMLY5GTJkiEyYMEGCgtgXEwAAwBcEBwfLxIkTZejQoc7jPTeD\
  dc40AgLJFPyqHuIygPCzBkB3ib9GbGDCH4aPPvpI+vTpQ3IAAAB81JQpU8zVAeTq1avuD5krQXXV\
  mugSWYI/YAUA/Iq+uM8fOnToLnPbLPU3S/4p/gEAAHxbz549ncd9YWFh7g+10/jWsqy8ZAn+gBUA\
  8Cdm99eFGlVHjBghrVq1kujoaLICAADgJzZs2CBt27aV48ePuz+0Q+NOrY0OkSXQAAC8X0WNRRol\
  SQUAAID/2rt3r9xxxx3yv//9zz59UqOe1kZ7yRB8GacAwB/crrGK4h8AAADlypWTdevWSfXq1Z33\
  zWmhAwcO/CfFP/wBKwDge09qy2ouztP9Hct0bCzxm7vkIjMAAABI8Oeff0rXrl1N8S/NmjU7J/H7\
  AawkM6ABAHhP8d9eh880Lr/zzjuvDxgw4F96OweZAQAAwA2YKwF00ZhPKkADAPD84v8BHSaK6xJ/\
  kZGRsmbNGilfvjzJAQAAQErEaJhLRE0jFfBF7AEAXyn+H9NhckLxb5w8eVLmzp1LcgAAAJBSwRpT\
  NR63HWdWIy2gAQB4TvH/Dx1Gm5u2OTGX+nv++edJEAAAAFJ1eKnxX41X9JjyIR0368hBJXxCECmA\
  lxf/r+vwkn0uICBARo8eLY888ggJAgAAQJpMnz79X3pc6YiLizMNgbf0uDPU4XAMJTPw6vqJPQDg\
  xcX/v3V4zj4XFBQkkyZNku7du5MgAAAApMlvv/0mZcuWlUuXLrk/NELrp0FkCN6KUwDgjYW/Mcq9\
  +M+WLZvMnDmT4h8AAAA3pXDhwjJjxgwJCQlxf+gFcxxqDkbJEryylmIFALyt+NfhA42H7fM5c+aU\
  OXPmSIsWLUgSAAAA0sU333wjHTt2lIsXL7o/NE7jUa2lYskSaAAAGVP8mx3+P9J40D4fHh4uX3/9\
  tTRs2JAkAQAAIF2tWLFC2rZtK+fPn3d/aIpGX5oAoAEAZEzxP0mjh30+IiJCFi5cKHXr1iVJAAAA\
  yBDr16+X1q1by9mzZ90fMpcM7EMTAN6CPQDgLV50L/7z5MkjS5cupfgHAABAhjLHm8uWLZN8+fK5\
  P/SAxmTXm1WAx2MFALzjiWpZ4Tos1Khv7ufPn18WL14s0dHRJAcAAACZYtu2bc49p06ePOn+0DSN\
  XqwEgKdjBQC8gr6Yxp06dSqmXr16UrBgQVm+fDnFPwAAADJV1apVnStQIyMj3R8yl6GaykoAeDpW\
  AMAbhGp8o9HAnHd14sQJueWWW8gKAAAAssTPP/8sTZs2TWwlwAyNHqwEgKdiBQC8ofj/2hT/5o7Z\
  9I/iHwAAAFmpcuXKzhWpBQoUcH/ojEYcGQINACD1smvM0WhCKgAAAOCJTQBzeqrRtWvXI4cOHRrg\
  YIk1PBinAMBznoxKh74ak/R5Gegq/luTGQAAAHiqHTt2yPTp0+W1114zx7Nm0+oOGlfJDGgAAMk3\
  AEbp8KTGzIsXLwbnyJGjPVkBAACAl5mrcR9NAHgiTgGApxT/I1zFv9G5a9eu7a9e5TUTAAAAXse8\
  iTVdgysCgAYAkEjxP1iHf9jnVq5cKb/++ivJAQAAgDfqpDEtoQmgx7s5SAloAIDi37IG6jDUPhce\
  Hi7ffPONVKhQgQQBAADAW3XR+LhUqVKFdVyvx70vkBJktSBSgCws/h/T4d/2udDQUJk/f77Url2b\
  BAEAAMCrHTlypGdcXFxHvRmmUVWPf886HI4PyQyyrAZjE0BkUfHfXYep5mbCXEhIiMybN0/uuOMO\
  EgQAAACvdu3aNalatars3LnTPh2r0UtrsGlkCFmBUwCQFcV/Ox0m24v/4OBg+fzzzyn+AQAA4BOC\
  goJk+PDhzuNcG7MnwETX8TBAAwA+X/w30+FTse2KGhgYKFOnTpX27bnqHwAAAHxHu3btZOLEiRIQ\
  cF3ZZToCn7mOiwEaAPDZ4r+6DrM0Qm1z8sEHH0iXLl1IEAAAAHxO9+7d5b///a/zuNcmu8YcnWPj\
  K9AAgE8W/+V1WKgRYZ9/8803pX///iQIAAAAPuvRRx+VYcOGuU+Ha8zX42QufQUaAPCp4r+YDks1\
  Iu3zgwYNcgYAAADg61544YXEjn3N8fG3ruNlgAYAfEItjUL2iYceesj57j8AAADgL8wqALMawI0p\
  /hdYlhVBhkADAF7P4XDMuvvuu58NDY0/9b9z584yZswY9/OgAAAAAJ/3/vvvS6dOndynq2gs0uPj\
  UDKEjGRpcUYWkNFMV3P92rVri7377rsyffp0CQkJISsAAADwS1evXpXWrVvLsmXL3B+ap9FRa7RY\
  sgQaAPBGZinTaonvagIAAABQZ8+elcaNG8vWrVvdH/qv1mhPkiFkBE4BQEYyb/PPovgHAAAArhcR\
  ESFLliyRcuXK2aePaIwlO6ABAG9jTvD/WKMZqQAAAAD+LjIyUhYuXOgco6KiZNu2bescDsdPZAYZ\
  VqRxCgDS7clkWXfpcEqfU9/raC50yjX+AAAAgBvYuXOnFCxYUPLmzWvuDtd4kawgI7ACAOlV/JtL\
  /X2useyVV14ZS/EPAAAApEylSpUSin9xHUc/SlaQIXUbKwCQDsW/c5d/id/tXwIDA2XUqFHy2GOP\
  kRwAAAAg9cxVADpK/FUBgHTDCgDcbPFvdvlfkFD8O1+tYmPl3LlzJAcAAABIm0CNTzVq2467qd1w\
  8/UbKwBwE8W/2eV/obht9Pfggw/K+PHjSRAAAABwc05q1NPj7vY63qlxl9ZvV0kL0iqIFOAmjHUv\
  /ps1ayZjxowhMwAAAMBNio2NjezXr98mvZnLNWUOtPuRGaQVy0iQJpZlmc1JetnnqlSpIrNmzZKQ\
  kBASBAAAANykgQMHyqRJk3LZph50HYcDaavjOAUAaSj+O+nwhbmZMFesWDFZv369cwQAAABw8w4f\
  Pix16tSRI0eO2KdNAXeP1nFzyRBSixUASG3xX0OHKfbiPzQ0VObMmUPxDwAAAKQjc3z95ZdfOo+3\
  7YfkGtP1uLw2GQINAGRk8W8q/Nmm5k+YM5f8+/TTT6V69eokCAAAAEhntWvXlunTp5tjcfu0OR6f\
  6To+B2gAIN2Lf/MiM0tsl/sz3njjDWnXrh0JAgAAADJI+/bt5c0333SfNsfl813H6UDK6jr2AEAK\
  in/Tbvxco7N9vnfv3jJx4kQSBAAAAGSCfv36yYQJE9ynzd5cXRwUdqABgHRqADylw3/scw0bNpRv\
  v/2WHf8BAACATHL16lVp3bq1LFu2zP2hV7Wu+xcZAg0ApEcDIKcOkzXM7v9Srlw5WbdunURGRpIc\
  AAAAIBOdOnVK6tatK3v37rVPm6Kuk9Z2s8kQksMeALghfSG5EBsbu3vIkCGSO3dumTVrFsU/AAAA\
  kAXy58/vPB6PiIiwT5tTdj+xLKsKGUJyWAGAlOii8al5vvz++++SL18+MgIAAABkoXnz5sk999wj\
  cXFx9ukDGjW1xjtJhpAYVgDgRqI1Ppb4riLFPwAAAOABzJW4Xn/9dffpkuK2cTdAAwApZdb5z5X4\
  64wCAAAA8CCDBg2S+++/33k7e/bsUqlSpQEOh+MDMgMaAEitYI2ZGsVJBQAAAOB5zNW6x48fLx06\
  dJBVq1bJjh07Oug0l+lC0s8Z9gCA7QWkug4vaPR3XUbkKbICAAAAeJVRGk+TBtAAQHLFf34dNmqU\
  jIiIOLZu3bpCUVFRJAYAAADwPr0l/jLeAA0A/K34D9RhgUarhLnw8HDZvHmzlC1blgQBAAAA3uWS\
  RkONTaQCduwBAGOIvfg3zCVFKP4BAAAAr5RDY5aGWeVr3vALIyWgAQDzYtBOh5fsc9WqVZMPPmDz\
  UAAAAMCLldD4JCwsrLmOe/S4vwMpAQ0A/y7+b9FhirmZMJc7d2758ssvJTSUK/8BAAAA3sqc6v32\
  22/fcfny5SV6t5DGJD3+Z4mvv9eA7AHgt8W/qfC/06him5OvvvpK2rRpQ4IAAAAAL7Zp0yapVauW\
  xMXF2ac3a9TXGvASGfJPrADwX6Ptxb8xePBgin8AAADAB1SvXl1eeeUV9+lojffJjv9iBYA//tAt\
  q7cOE+1zd955p3z99dcSEEBPCAAAAPAFsbGxzjf4Fi9e7P5QX60DJ5IhGgDw/eI/SocNGn+d5F+i\
  RAn58ccfJV++fCQIAAAA8CEnT550rgY4dOiQffqiRj2tBbeSIf/C273+Vfzn1GGmvfgPCQmRGTNm\
  UPwDAAAAPigyMtJ5vG+O+21MPfC51gfhZIgGAHzXhxqV7BPDhg2TunXrkhkAAADAR9WrV09GjBjh\
  Pl1eg2t/+xlOAfCXH7Rl9ddhrH2uQ4cOMmvWLOfu/wAAAAB827333iszZ850n35Qa8KPyQ4NAPhW\
  A+B2HWZo3GLuly5d2nnef+7cuUkOAAAA4AfOnj0r0dHR8uuvv9qnzX4ANbQu3EmGfB+nAPgJ/YX+\
  cc+ePSO6devmPP/niy++oPgHAAAA/EhERIR89tlnie0H8IVlWaFkyPexAsB/lNX4USPXjh07JCoq\
  iowAAAAAfmjkyJEycOBA9+mxWhs+THZoAMD7mRbfeo3bSQUAAADg30wN2L59e5k/f777Q+30sflk\
  yHdxCoB/GEHxDwAAAMAwm4BPnDhRihUr9ldPQOM9jcVkhwYAvFsbjadJAwAAAIAE+fLlk08//VQK\
  FSokX3/9tVkV8K3GFTLj2zgFwBd/qJYVrT/XzXqzkMYWjYJkBQAAAIC7K1euSLZs2czNExrVNI6R\
  Fd/FCgDfK/4b6LBBx8+PHz8+heIfAAAAQFJcxb9RQGOSKSnICg0AeEfxn0uHqRqBGvfWqlWr5apV\
  q0gMAAAAgJS4Q2MAaaABAO8wSqNUwp2DBw/KiRMnyAoAAACAlHpToyppoAEAD2ZZVmcdetnnevXq\
  JZ07dyY5AAAAAFLKXEL8E41sWmMU1WhOSnxHECnwieK/qA4f2edKlSolo0aNIjkAAAAAUqvKsGHD\
  ZupYL77csKo6HI7DpMUHakeuAuD1xb/ZpGOJxl+ducDAQFmxYoU0aNCABAEAAABIlb59+8rEiRPt\
  U6beuMNB8ej1OAXA+z1tL/6NQYMGUfwDAAAASJNixYq5T7XUeJzMeD9WAHjzD8+yKuiwWSNHwlzN\
  mjVl7dq1EhwcTIIAAAAApFpMTIzUrVtXNm3aZJ++qBGt9eMvZIgGADK/+DeX+lurUTthLkeOHLJl\
  yxYpX748CQIAAACQZjt27JDq1avL5cuX7dM/aNTXGvIaGfJOnALgvQbZi39j+PDhFP8AAAAAblpU\
  VJQMGzbMfbqWxotkx3uxAsAbf2iWZa7LuUHiL9Hh1LRpU1m6dKnE7wkIAAAAADfH1IrNmzeX5cuX\
  26djTCNAH9tChmgAIOOLf1P0m6U31RLmwsPDZdu2bc5L/wEAAABAejlw4IBUq1ZNzp49a582xX9t\
  rSWvkiHvwikA3ucVe/FvjBw5kuIfAAAAQLorWbKkvPfee+7Tt2m8RHa8DysAvO0HZln1dfhYw3my\
  f5s2beSrr75i6T8AAACADGPqjoULF9qnOBXAC7ECwMvoL9jaY8eOvT1gwADJnz+/jBs3juIfAAAA\
  QIYaO3as5MmTxz5lrjs+UWsRrj/uRVgB4H2KaPykkefMmTOSO3duMgIAAAAgw02aNEn69OnjPj1E\
  a8qhZIcGADLGPI22pAEAAABAZkviVIDqWlduJzuej1MAvEsPin8AAAAAWcWcChAREZFw11wF4HWN\
  XWSGBgDSVwGNd0kDAAAAgKxSrFgxeffdd6VWrVqydevW8w6H40ONGDLjHTgFwJN/OJaVQ38+l1x3\
  v9DoTFYAAAAAZLXY2FgJDAw0N2dq3EtGvAMrADy3+Ddb+3+tw6c//PDDIxT/AAAAADyFq/gXV51C\
  reItdSYrADy2AdBbh4nmdt68eWXkyJHSq1cvEgMAAADA05zQqKTxB6mgAYDUF/+ROuzUyJcwV6dO\
  HVm7dq0EBLBoAwAAAIDHmaDRjzR4NqpJz/SuvfgPDg6Wjz76iOIfAAAAgKfqq9HQ3LAsKy/poAGA\
  FNBfljt06G6fe+6556Rq1aokBwAAAIDHljJxcXEfBQYGPq2392td05qUeOAPiVMAPKr4D9Vhu0aZ\
  hLly5crJ9u3bJXv27CQIAAAAgEfaunWr9OvXTzZu3Jgw9T+NW21XNYMHYAWAZ3nVXvybCwF8+OGH\
  FP8AAAAAPNrp06dl06ZN9ilT17xMZmgAIBFa7FfW4Vn73AMPPCDNmzcnOQAAAAA8WpMmTaRHjx7u\
  089pnVOJ7HhQ3ckpAB5R/Fs6LNdonDCXP39+2blzp3MEAAAAAE938uRJqVixovzxx3VXA1yp0dRB\
  4ekRWAHgGXrYi39jxIgRFP8AAAAAvEZkZKQMGzbMfdrUOT3JjmdgBUBW/wAsK7cOuzQKJszVr19f\
  Vq9eLfELAwAAAADAO5j6skGDBrJu3Tr79EmNSvrY72Qoa7ECIOu9YS/+g4KCZPTo0RT/AAAAALyO\
  qWM++OADZ11jE6nxGtmhAeDvvxzVdXjYPvfEE09ItWrVSA4AAAAAr1S1alV59tln3acf0vrnNrKT\
  xTUopwBkaQNgjg7tE+4XLVpUduzYIbly5SI5AAAAALzW+fPnnRsCHjlyxD79nUY9NgTMOqwAyFo9\
  ChUq9GFISIjzzttvv03xDwAAAMDrhYWFyVtvveU+XUfYEDBLsQIg6328a9euPlOnTpU33niDbAAA\
  AADwCabWbNasmaxYscI+fUyjoj52lgzRAPA3pgNmtsdkxz8AAAAAPuenn36S6OhouXbtmn16lNah\
  T5OdzMcpAFmb+/cp/gEAAAD4qltvvVUee+wx9+k+lmXlJTuZjxUAWaePxsekAQAAAIAvO3funFSo\
  UEGOHTsmZcuW3bBv3777tA7dT2ZoAPgLs9Pfbo1CpAIAAACAr5s3b55zY8BmzZqZPQAqmL4AWaEB\
  4LuJtqzsOgRpvs/r+LbGQLICAAAAwA+9o/Ecach87AGQeZ7S+KVq1aovxMXFPUU6AAAAAPhxbVSB\
  NGQ+VgBkRpItK1KHvRK/9N+5C+a0adOkUqVKJAcAAACAP5qr0YE0ZC5WAGSOoQnFv7F7924JDw8n\
  KwAAAAD8VXuNJqSBBoBPsSwrSof+9rnnn39eihUrRnIAAAAA+DOzN1qA1kxBrlXToAHg9cwGF0EJ\
  d4oUKeJsAAAAAACAn6s+ZMgQUy9t1fiEdGS8IFKQcSzLukOHO+1zr7/+uuTMmZPkAAAAAPBbp0+f\
  lm7dusmiRYuecU1Faf3UyuFwLCY7GYcVABlX/AdK/JKWv5jN/3r16kVyAAAAAPi13Llzy8mTJ92n\
  /611FDUqDQCv1FvjVvvEO++8IwEBpBwAAACAf9NC31kfuamq8QDZycC8cxnADHky59DhF42/dvpr\
  3769zJkzh+QAAAAAgEuHDh1k7ty59qlDGhW0Tr1EdtIfb0dnjKftxX9QUJAMGzaMrAAAAACAzfDh\
  w531kk1xjWfIDA0Ar2BZVl4dBtnnevfuLZUqVSI5AAAAAGBTsWJF6devn/v0IC4LmEH1KqcApHsD\
  wJzIMiDhfmhoqOzZs8d5+T8AAAAAwPWOHTsm5cqVkwsXLtin/6O1KisB0hkrANK3+C+pw+P2uWee\
  eYbiHwAAAACSUKhQIRk4cKD79COu+grpWbOyAiBdGwCTdeiZcD9//vyyd+9eiYiIIDkAAAAAkIRz\
  585J2bJl5dSpU/bpSVqv9iE76YcVAOlrqsaGhDsvvfQSxT8AAAAA3ECuXLnkxRdfdJ9+wLKsKLKT\
  flgBkM5iY2O7zZkzZ9r48eOdl/3Lli0bSQEAAACAG7h8+bKUL19eDh06ZJ+erTVrR7JDA8ATBWvs\
  1ChLKgAAAAAgdSZMmOB+VQBTsNbVuvV7skMDwNM8qjGGNAAAAABA6l27dk2qVKkiu3btsk8v07q1\
  Odm5eewBkH5CNV4hDQAAAACQNkFBQfLaa6/Zp7ZovEtmaAB4mqc0CpMGAAAAAEi7Tp06Sbdu3eTz\
  zz83e6x95nA4viIr6YNTANJHbo3/aeQhFQAAAACQbk5L/B5rp0nFzWMFwE2wLOsVjVJ68xmKfwAA\
  AABId3lc9RZoAGRp8d9ah39p7O7bt+8/3C5VAQAAAABIH09L/Kpr3GwdyykAaW4AfKdD7YT7LVu2\
  lMWLF5MYAAAAAEh/QzWGkAYaAFlR/N+pw0L73PLly6VJkyYkBwAAAADS3xmN0q4RacQpAGnzqv1O\
  48aNKf4BAAAAIOOYUwCesizrFo2hpIMGQKbQJ9sdOtS5rhvw6qskBgAAAAAyyO7du6Vr166D9OYO\
  jcGuVdlIbT3LKQCpbgCs1aFewv1GjRrJypUrSQwAAAAAZBCz59q3335rn1qrtWwDMpM6rABIXfHf\
  0l78G7z7DwAAAAAZa9CgQe5T9bU+a0pmUlnTsgIgVQ2AFTo0TrjfsGFDWbVqFYkBAAAAgAzWoEED\
  Wbt2rX1qmdazzclMyrECIOXFfz178W8MHjyYxAAAAABAJvjnP//pPtVM67T6ZCYVdS0rAFLcAPhK\
  h7sS7tesWVN++OEHEgMAAAAAmcTUYRs3brRPLdSatg2ZSRlWAKSs+K+mw3VPqkS6TwAAAACADPTS\
  Sy+5T7XWeq0GmUlhbcsKgBQ1AGbo0CXhfuXKlWX79u1mnuQAAAAAQCYx9ettt90m27Zts09/ofP3\
  kZ0bYwXAjYv/W3S41z5ndqCk+AcAAACATK/P5MUXX3Sf7qTz5chOCvLHCoAbPsEm6NA34X6ZMmVk\
  9+7dEhQURHIAAAAAIJNdu3ZNKlasKPv27bNPj9Pa9iGykzxWANzY8ICAgPHZsmVz3nn++ecp/gEA\
  AAAgi5h6bODAge7TD1iWVZjsJI8VACnT7rfffpv70UcfOZf/Z8+enYwAAAAAQBa5dOmSlCpVSk6c\
  OGGfHqH17SCyQwPgZq3SaEgaAAAAAMAzvPHGG/Lyyy/bp85olNIa9yzZoQGQVnU01pMGAAAAAPAc\
  Z86ckeLFi8v58+ft0//QGvffZCdx7AFwYy+QAgAAAADwLLlz55aHH37YeTs8PPySDq9qTCQzSWMF\
  QPLKa+wUGiUAAAAA4HGOHDki8+bNkz59+sRlz549Sqd2kxUaAGk1VqM/aQAAAAAAjzdOg0sB0gBI\
  RUIsq4YOuTQv23Q8qJGDrAAAAACAxzOnAZTQOEUqEsfS9r8z20guLVmy5M4pU6bkuHr1KhkBAAAA\
  AM9n3rx9hDQkjRUA9mRYVjmJP2fkr8bIN998I61atSI5AAAAAOD5ftMopcE7uYlgBcD1nrLnpEKF\
  CtKyZUuyAgAAAADeobBGV9JAAyBZlmXl1qGPfe7xxx838yQHAAAAALzHM1rH5dR4xFXnwSWIFPyl\
  n0ZYwh1zTck+ffqQFQAAAADwEgcPHpTRo0dHBwUF/Xbt2rVwV433NpmJxwoAcb77b/Lw2HXdgH79\
  JCwsjOQAAAAAgJcYM2aMvPXWW+Iq/o1HXfUehE0AExoA7XWYk3A/KChI9u3bJyVKlOAZAgAAAABe\
  wqwAKFu2rGkA2Kc7aN07l+ywAiDBU/Y7HTt2pPgHAAAAAC9j6ri2bdu6Tz9GZmgAOFmWVUmHpva5\
  Rx99lGcGAAAAAHihp556yn2qpdZ9UWSGBoCz3jd9gIQ7VapUkSZNmpAVAAAAAPBCpp6rXLmyfcrU\
  e4+QGT9vAFiWZXb5e8A+99hjrA4BAAAAAG/29NNPu0/10fovl7/nxa83AdQnwMM6fJhwP1euXHLk\
  yBF2/wcAAAAAL3b+/HkpXry4nDlzxj79uNa/Y/w5L/5+CsB1b/f36tWL4h8AAAAAvJyp6/r06eM+\
  /bC/58VvVwBYltVAh9W2+/LTTz9JVBR7QwAAAACAt9u9e7dUqlRJ3Gre+np/nb/mxJ9XABTUOJpw\
  x2wUQfEPAAAAAL6hQoUKiW3w7tebAfptA8DhcHypQ8lPPvnk8B133CFPPPEEvyEAAAAA4EMefvhv\
  q/47W5aV11/z4debAKqGGqv4tQAAAAAA33P16lXnZoAnTpywTw/QOvhdf8yHv28CyLUgAQAAAMBH\
  hYSESN++fd2nH7bMJnA0APxKpEZnfiUAAAAAwHf1799fAgL+Kn1jNH7SyOWPuQjy4+eBaQOF8OsA\
  AAAAAL6rTJky8tBDD0mpUqWkTZs2r1epUuVf/poLf94DYJdGBX4dAAAAAMBv/KJRUcMvC2F/PQWg\
  HsU/AAAAAPid8hoN/PWb96sGgGVZczWGbt++/Rme9wAAAADglx7012/cb04B0MK/mg5bzG2zAUSz\
  Zs1k/vz5kj17dp7+AAAAAOA/LmoU1jjnb9+4P60A6JNwIy4uTi5evEjxDwAAAAD+J1Tjfn/8xv2i\
  AWBZltntv5t9rlevXjztAQAAAMA/9aYB4Lvu0ohMuJMjRw7p0qULT3kAAAAA8EPnz5+vEx0d/Q/L\
  slZqdKQB4Fuue7v/nnvukYiICJ71AAAAAOBnxo8fL4ULF5YtW7aM0LuN3OtFGgBezLKsAjq0sc/1\
  7t2bZz0AAAAA+KHSpUubFQD2qdZaN0bSAPAN3TWCE+6UKFFCmjdvzrMeAAAAAPxQ06ZNpVixYvYp\
  Uy92pQHgG3rY7/Ts2dN5GUAAAAAAgP8x9WD37t3dpx/wh+/dcjgcvvvNWVaUDj/b53bt2iUVKlTg\
  WQ8AAAAAfmrHjh1SuXJl9+korY93+vL37etvhV936b9atWpR/AMAAACAn4uKipIaNWq4T/v8KgCf\
  bQBYSuLP////bkC3bjzTAQAAAADO08Pd9NAy0qffJPfZUwD0B9dAh9UJ94OCguTw4cNSsGBBnukA\
  AAAA4OdOnDjh3AwwJibGPt1Ya+RVvvo9+3J347q3+1u0aEHxDwAAAABwKlCggLRq1cp92qevBuCT\
  DQDLskJ06HJdN4Dl/wAAAAAAe7Xf9W/1/r1aTwb76vfrk6cA6A/MvNU/RqO1Ro6cOXPKsWPHJCws\
  jGc4AAAAAMDpzz//lEKFCsnFixft0621Tl7ki9+vT64A0B/WcY1OBw8efGPy5Mny6quvUvwDAAAA\
  AK4THh4ud911l/u0zy4f99lNAF22aVThaQ0AAAAASMzs2bOlY8eO9qlzGgW1Vr5MA8B7VHE1AAAA\
  AAAASNSVK1ecG8afPXvWPn2v1sozfe179ZurAAAAAAAA4C5btmxyzz33JNyN1ViicdoXv1dfXgHw\
  i8YtPJ0BAAAAAMnZsGGDbNq0STp06LCvUKFC5Xz1+/TVBkA1jS08jQEAAAAAqVRd40df/MZ89RSA\
  jjxnAQAAAABp0NlXvzGfaQBYlhWqMUejz/Hjx+/jOQsAAAAAoAHggw0AdadGe42PixcvXrFbN/YA\
  BAAAAACkmtlLLpoGgGfrkHAjJibGuZMjAAAAAABp0IkGgIeyLCtYh7b2OdtlHAAAAAAASA2f3FfO\
  V1YANNPInXAnLCxMWrVqxVMWAAAAAJBqp0+frhQdHf28ZVmzNVrSAPAs173d37p1a8mePTvPWgAA\
  AABAqrz55ptSoEAB2bJly1sSf6q5z2wK6PUNAMuyzPfQ3j7XsSNXAQQAAAAApF65cuXk2rVr9qm2\
  rrrT61kOh8PbGwD1dViTcN9s/nfixAnJlSsXz1wAAAAAQKqcPXtWChYsKFeuXLFP19Ha+Xtv/958\
  oYvRwX6nefPmFP8AAAAAgDSJiIiQJk2aJFt30gDIOnfb77D7PwAAAADgZrRv394nGwBefQqAZVll\
  ddhruy+HDx+WIkWK8IwFAAAAAKTJ0aNHpVixYuJWL1fQ+7948/fl7SsArmvL3H777RT/AAAAAICb\
  YurKmjVruk+38/bvy9sbANct/09kmQYAAAAAAKmWSH1JAyCrWJYVoUMD+9xdd93FsxQAAAAAcNPa\
  tm3rPlXHVYfSAMgCptoPTrhTtGhRiY6O5lkKAAAAALhpVapUkRIlStinTP3ZigZA1ojSiEu4065d\
  O+cmgAAAAAAApIdEVpl79bJzr20AOByOl3Uo+v777x/q1KmTdOzYkWcnAAAAACDdtGr1tzf8W1uW\
  5b2n0nvzZQCV2fL/sPk+eGoCAAAAANLT+fPnJX/+/HLlyhX7dB2to7/3xu/H268C0IbiHwAAAACQ\
  EcLCwqRx48bu0157GoAvNAAAAAAAAMgQbvsAmCX0ZWgAZL5AjWY8HQEAAAAAGeXuu++WLl26yIQJ\
  E2LKlStXwuFw9PDW78Wb9wCop7GWpyMAAAAAIJM08OY61JtXALTkuQcAAAAAyEQtvPk/71UNACve\
  3RphNAAAAAAAAJnMq+tQb1sBUFljvsbphg0b1h0xYgRPPwAAAABAZqmtEUEDIHMkLLcIWrNmTcDX\
  X3/N0w8AAAAAkFmCNJrQAMgcza+707w5Tz8AAAAAQGZqRQMgg1mWFaxDY/tcixYteOoBAAAAADJT\
  S7MvncZdGiE0ADJGLY3whDvh4eFSq1YtnnoAAAAAgEzxzjvvSMOGDW/Rm6c1vnLVqTQAMsB1b/c3\
  atRIgoODeQYCAAAAADLFypUrZc2aNeZmkGuqKQ2AjMH5/wAAAACALNOsWbO/TdEASGeWZYXqUIcG\
  AAAAAAAgqzRt+rc3/OtovZqdBkD6qq/x13r/AgUKSJUqVXj2AQAAAAAyjalD8+XLZ58yxX9dGgDp\
  q9F1dxo1MqsCePYBAAAAADKvgA4IkCZNmrhPe81pAN7SALju8n+NGzfmmQcAAAAAyHSJnAbQhAZA\
  OrEsK4e4XVqBBgAAAAAAICsksgKgtmvfOhoA6aC2RraEO+Z8i8qVK/OsAwAAAABkuqioKOe+dDbB\
  rrqVBkA6uO7t/oYNGzrPuwAAAAAAILOZ/egaNGjgPt2QBkAGNABY/g8AAAAAyErmjWkaABnjK41F\
  4eHhNAAAAAAAAFkukRUAdSzLCvL0/7flcDi8Ib+lY2Nj/7dt2zapVq0apwAAAAAAALKM1qeSO3du\
  OX/+vH26ltbXGzz5/+0tlXSDwMBAiY6OpvgHAAAAAGQpU5/WrVv3b3Wrp/+/vaYBwFMMAAAAAOAp\
  vHEfAG9pANTn6QUAAAAA8BQJ+wCEh4fH6bBIY6Wn/5+9YQ+ACI0/xHuaFQAAAAAAH3flyhXZs2eP\
  REVFxQUEBOTVqbOe/n/2hqK6FsU/AAAAAMCTZMuWTW699VazT52pV2t7w//ZGwprlv8DAAAAAKhb\
  fbUBYFnWqxoPrV279g5ziQUAAAAAADxUXW/4T3rkHgBa+OfQ4ZzG/7F3J3BWlvXiwJ8Xh31RQDQX\
  zB3NFJXS0kwlTUWtNFHMqzfLyqX6V1crK63kWpmZN7dKvS6p6AW33EUBN1xQRDYRRQxFVFZZHNaZ\
  83/egaEzh1FZZua855zv9/P53Xfehxu88zvPjOf3O8/7vFXpeefOncPkyZPDFltsYVoBAACQNen9\
  /+k+ALVZvsisrgDYq774T7Vr107xDwAAQFalm9fvnPWLzGoD4LP5J5/5zGdMJwAAALLscxoA62ef\
  /JN9993XVAIAACCTVqxYER5++OF+SZKcFuN7Wb3OqlJoAOyzzz5mFAAAAJnz3HPPhb59+4bq6ur+\
  8TSN12P8PYvXmrlNAJMkSTdOmJ1+WT82c+bM0KNHDzMLAACATJk9e3Zj9Wq3WGvPy9q1ZvEWgM/m\
  F//bb7+94h8AAIBM2nTTTcN2221XONwni9ea1QbAv08++1kzCgAAgMzae++9C4cyuZN9FhsAezXI\
  micAAAAAkGGN1K1WAKylPRt0A/bay2wCAABAA2ADZWoTwCRJNomHBhslpBsqdO/e3YwCAAAgk+bO\
  ndtY3bpprLfnZOk6s7YCoMHH/dtuu63iHwAAgEzr1q1b2GGHHQqH987adWa6AbDnnnuaSQAAAGRe\
  I7ev99YAWIcGgPv/AQAAKAW9e69R7++hAfDR9tYAAAAAoNTsscca9X7mlrRnrQFwSatWra484IAD\
  QpcuXRp7liIAAABkTiO3sO+SJEnbLF1jpp4CsMqOMV4zfQAAACgl6WaA8+Y1eLDdXrHmfikr19cq\
  gznbw7QBAACg1GT9NoAsNgB6mzYAAACUmkZuA8jUB9xZbAB8yrQBAACg1KQrADbZZJPw6U9/elY8\
  /WuM4Vm6vizuAfBKjF6mDgAAAKVkxYoVoaqqKv1ycoxdsnZ9WWsAtI+xMMZGpg4AAAAlqiZGpxhL\
  snRRWbsFoJfiHwAAgBKX1rWZWwGQiQZAkiRHxLjw9NNP/87YsWPD0qVLTRcAAABK2e4aAI07MsYv\
  /v73v5+Z7pr4m9/8xlQBAACglGVug/usNAAaLI3YZZddTBUAAABK2a4aABoAAAAAlL/MPd2u6A2A\
  JEm6xMNW+WO77rqrqQIAAEBJSve1e+mll3asqqoaEGve81fVvUVX9McAxkTsEw/P1Z9vueWW4e23\
  3zZjAAAAKEmf/OQnw5tvvpk/tF+svZ8p9nVl4RYAy/8BAAAoGzvttFPh0I5ZuK4sNAB21QAAAACg\
  XPTqtcbt/5nYDyALDYAGifjUpz5ltgAAAFCydt5558KhnTQAVtqhwckOO5gtAAAAlKxGbgHIxFL3\
  LDQAGtwL0UinBAAAAEpGI3XtjklU7Osq6lMA4vefPv5vev1569atQ3V1daiqqjJjAAAAKEkrVqwI\
  HTp0CMuXL88f3ibW328V87qKvQKgwXr/bbfdVvEPAABASUvr2kZuAyj6PgDFbgA0SEAjCQIAAICS\
  s/322xcOFX3Du0ytAGgkQQAAAFBytttuuzWGNADy2AAQAACActDIE+6K3gAo9g33j8RYvPfeex/z\
  3nvvddlxxx3NEgAAAEpeIyvci77kvahPAcgzLcY2pggAAADlYPz48WGPPfbIH5oT6+9NK70B0CbG\
  4lD82xEAAACgSXzwwQehU6dOhcMbxxp8QbGuKQtF9ycV/wAAAJSTjh07hs0337xwuKj7AFRlIC9b\
  mxoAAACUm3QfgFatWoUlS5ZMnDdv3oQ4tKKY15OFWwD+M8YNpgYAAADlpKamJmy00Ubpl9+McWOx\
  rycLS+97mhYAAACUm1XFf2bqXg0AAAAAqIC6t2gNgCRJjo9x9BNPPLHbnDlzTAcAAADKVSYee1+0\
  PQBi8T8jHraoPx87dmzhMxIBAACgHEyM8eliX0RRVgDE4r91PDR4HsKWW25pSgAAAFCOKvoWgE/k\
  /9vt2rULm266qSkBAABAOeoSY+NKbQBs3eBk661NBwAAAMrSwoULw89+9rMDkyQ5JMYninUdVUX6\
  dxus999qq63MCAAAAMpOv379woMPPph++c9VQyfEGFyMaynWCoCeGgAAAACUu27duhUOFW0DvGI1\
  ALbSAAAAAKDcbbHFFmsMVVoDwC0AAAAAlL1GnnhXtD0AitUA2FwDAAAAgHLXyAqAirsFoEEGevTo\
  YVYAAABQdj7xiU98ZD1cCQ2AHh+TEAAAACh5FX0LQJIk6aMHN23QDbACAAAAgDLUyC0A3WNd3LYi\
  GgCriv+k/qRNmzaNPRYBAAAASl7nzp1D+/bt12gCVEoDoMH6h80339yMAAAAoGw1suq9KIVwsVYA\
  aAAAAABQqQ2ATSulAZDuATCpTZs285MkCZtttpnZAAAAQNnq3n2NFf9F2QivqqX/wVwu90A8pHFa\
  bW3tNYsXLzYbAAAAKFuNfPBdlAZAqyLmYItWrVqFjh07mg0AAACUrUZuAaiYTQDrbWoaAAAAUO42\
  3XSN8rco98IXswHQ1TQAAACg3BXcArAsRptiXEeSy+WKlYP7Y/QzFQAAAChn77//fliwYEHo3Lnz\
  g127di1aHVxVxBx0Mw0AAAAod5tsskldhCKvhHcLAAAAALSMon4Q3uINgCRJusVIghUAAAAAVJbK\
  WQEQ6/70loM5MWo32WSTHtttt1344IMPTAEAAAAqQVE/CG/RTQDTT/9XNQDqtGrVKtTU1JgCAAAA\
  VIrOMRYV4x9u6VsANs4/6dKli5ceAACASlK0QrhVMb9RDQAAAAAqzMYV2QDYeOONvfQAAACUvVNO\
  OSXsscceoV27dsOSJJkb48CWvoYqDQAAAABoXm+88UYYP358+uUWq4Y6t/Q12AMAAAAAmlkj9W+L\
  F8T2AAAAAIBm1rFjx8KhThXVAHALAAAAAJWgU6dOFdcAaNvgpG1bswAAAIBKbAB0LPcGQIcGJx06\
  mAUAAACUvUq8BaDBLoedO3c2CwAAACh7jdwC3+IFcUs/BrBd/olbAABoCh988EF4/fXXw6JFi8Lb\
  b79dN/bWW2+FqVOnrvH/+8gjj4RXX3212a/prLPOanR8r732Wr0Jbq9evcInPvGJsNlmm3kRzVvz\
  FqDMNXILQNk3ANrnn7gFACCEJEk2+O/I5XIVlbOZM2eGMWPGhJEjR9bF8OHDM3eNV1555Vr//+68\
  887hhBNOCLvvvnvo169fY0sEM2HcuHF1x8mTJ68eW7BgQd1r8WEmTZrUZK/PhxWnqe233z707Nmz\
  7uutttqq7k3WHnvsYd6atwCZsdFGGxUOtfSK/BZvADR4CoBbAABYFw8//HC4995716lIKQXpJ7sD\
  Bw5cfX7eeeeF4447rqgFbFqsPv7442H8+PGZKVbX53UfMGBAOOSQQ8LBBx9c1yQwb8t73gJkWf1K\
  qjwt/li8pCU/NUqS5JF4OKT+fPDgwaF///5mAlDRrAD4eE8//XRdcZHFT0ybU/o9/+xnP2uxT1bT\
  JekPPPBAuPPOO8Ntt91Wdvns27dvOP3001vsvYd5a0UAQL5BgwaFk046KX/ogfge7shybgD0iofO\
  sfD/e9euXfdOl4xtvvnmZgKgAaAB8KEFaVpIlNsnp+siXWb94IMPNvun10OGDAm/+tWvWuQ+8yw0\
  Ai699NJm+6TavG25eQtQSu6///5w1FFH5Q89Fd/DHVC2DYA8T8b4gikAoAHwUUXUaaedVpafRGep\
  mKrkYrU5ViKat5oAAB8m/Z2Y7pmS55n4Hm6/lryGVkX63tt5+QFQRK2d9FP5I444ou6+/ObIc6V+\
  Un388cfX3Z9v3pbWvAUoVY3sgdeppa+hWA0Az/8D4EP9/e9/V0Q1UkxdcMEFitUmdvjhhzf62D3z\
  NpvzFqCUVVWtsQd/65a+hmLdApA+R2h3UwDALQBr/Adi3LjQu3dvE+NDPPTQQ+Gwww7b4L/nf//3\
  f+saAKx8vOAVV1xh3pbAvAUoZXPnzg233357qKmpefPMM888Ow5Vx/dw92sAAGgAVGwD4Pvf/35F\
  b572cdIN7IYNG6ZYbWJjx47doE0Bzdvmn7cAZWR8jKI8M7VYtwB08JoDUChdiq2I+mjpI+U2dMn6\
  1VdfLZEFHn30UfM24/MWoIwUbU+8YjUAWnvNASg0evRoSVgLI0aMWO//bbohm2J1Temjmczb7M5b\
  gDJTtD3xWrQBkCTJDTEGn3DCCZt+73vfq7sHAgDq3XnnnZKwFgYNGrTe/9vHH39cAhuRfkK9vrvV\
  m7fNP28BaKKavCXvG43F//vxsHH9+bRp08I222zjVQAq+xexPQCaNBcfJX0u+aGHHrr6PH0+ec+e\
  PZvk716wYEEYM2bM6vPm/pR90aJFoWPHjuv8v3Ov+odb330AzNvmn7cAZebNGJ/UAADQAKjYBkBz\
  bEyX7u5+4IEHhj59+tQVTS0tfdReWlQ+++yzdY+ISx+JVm7F6oABA8IhhxwSdt1119Cp078fZ7zD\
  Djs0S6GX3keeFpHvvPNOmDhxYpPmdfDgwaF///7mbQbnLYAGQNOoauF/byOvNQCNmTx5cpP9Xekn\
  pkOGDCl6oZEWwPvtt19dpLe+pcXUf/3XfzVZvtb1+0uL1aYs/M8999wWz3F9QZz+u+lj5dK8XnTR\
  RWHgwIHmbZnOW4Ay1KVY/3BLbwLYqcF33aWLlx6AOulS5KYqoh588MHMFRlpUfWTn/wknHfeeUW7\
  hvRT86Yq/q+99tpM5DjN689+9rO6x8wVYw6atwCsrSVLloSf//zn4eyzz26fJMkf0ij3BkADzX3P\
  HAClI/8+5A1x2WWXFWXZ9NpKi9WmMH78+KIVq3/5y18ydR93ei0//elPizIHzdvmn7cA5WLp0qV1\
  q9YuueSS9CkAP1sVldMAAICmlH6Kmi4Lz7K0WG2KT1PX50k6TdEASD/932yzzTKX17322su8LdN5\
  C4AGAACs4YQTTiiJ69x9992L8u82xafVxx57bCZzmsWmhHkLQNZUSQEAWdAUjx8rlQKlV69eJfs6\
  ZXn/nnSH+Q2R/xQD87a85i0AGgAAlJlSKVDWp9DMii222CKz11aqu8ubtwC0lJa+BWBZ/kl1dbVX\
  AICK0xSbvTXFJ89g3gJoADSnxfkny5cv9woA0GQ+8YlPSILiy7wFIJPSxwAWDpV7AwAAmk0pbwRX\
  Kkp1mb15C0CxpY8BLBxq6WuwBwAA0KLGjRsXJk+enLnrSjc4zPrj+ABAAwAAKBlp8X/88cdn7rrO\
  OussDQAAyppbAAAAAKACtPQKgBtitD/ppJP6d+vWrWuWnyUMAAAATWXhwoWFQ4vKugGQy+V+tOrL\
  z8foagoAAABQCWprawuHalr6GtwCAAAAABVAAwAAAACa2fz58wuHFlZKA2Cplx8AAIBKsemmm4b+\
  /fuHQw455P14+miMp1v6Gor1GMAlXn4AAAAqxS677BIGDx6cfjkhxqHFuIZirQCo9vID0NSmTp3q\
  OjFvzVuArCtaPVysBsByrzkATW3RokUVc519+/YtyrWPGzfORDNvS27eAmRM0erhFm0AJEmyZ4xD\
  rrjiii5DhgwJr7/+upcegDo777yzQmod7LrrriX7Wqf3P+ZyuSYP89a8BSgRRfvF39IrAH4R45Ef\
  /OAHBxx//PHh0Ucf9dIDUOfQQzf8Vri33367JL7XYl3nWWedtcF/x+TJk01W8xaADVNTKQ2ABpv/\
  VVfbCgCApjN+/PiSuM7HH3+8ZHP81ltvmWjmLQAbZkGlNAAaLHVYvHixlx6AJjNw4MDwwQcfZPoa\
  Z86cGa688soN/nu23377olz//fffX7bzZ6+99jJvy3TeAmRM0Qrhlm4ANPiv28KFC730ADSpBx54\
  INPXd++99zbJ39OzZ891/t9069Ztg//d4cOHh4cffjhzeX366Q1/lHKXLl3M2wzOW4ByMWXKlLr/\
  Xt11110bJ0myfYyu5d4AaLDmv1Q2vQGg+R144IFN8vf86le/yuzjytL/6J922mlF+/d33333Jvl7\
  fvjDH2buE+tBgwaZt2U6bwHKRbqSav/99w/HHnvst+JpuiP+j8q9AdCg4rcHAABN7dVXXw1HHHFE\
  5h5XlxZRp556apP9fb169Vrn/01TfcKd5njvvffOTI7//Oc/N8nydPM2m/MWoFw0sgK+xZfEVxWz\
  AWAPAICm8f3vfz+T13X00UeHww47rMULg7SY6t27d92u9+k1pPd2b7bZZi3+/afFXLpr/p133hlu\
  u+22Jv27d9hhh3X+32yxxRZNnuMBAwakn2SEPn36tOj93ek96WPGjAl//OMf625LaArr0yAxb5t/\
  3gKUcQOgxTcDTFryublJkpwUDzfXnx955JHhvvvuMxOAihZ/N5bt9zZ48OC6Z76vbUG3+eabN+v1\
  pMVq9+7d675Oi9Wmuh853cV97ty5q8+b+9Povn37hmHDhmVyvqXXtiHPek+L3vpCPH3iQOGy+Dlz\
  5jR5UVpv7NixYY899ljnRoR52/zzFqAc9OvXLzz44IP5Q9+I9fitLXkNLb0CoEHLwyaAANRLP+lM\
  C4Sm+jS3Mc1VOLa0tIG+vs4777y6XeebS/r6Nedr2JzW59Np87Zl5i1AOcjCCoCW3gNgfoOT+fPN\
  AgAUCOvoc5/73Hr/b9PNh1hTWsR37NjRvM3ovAUoB43Uv5XVAHj//ffNAgAUCOtg5513Dvvtt996\
  /+/TPRnSv4OmK+LN2+aftwDlIAubAFoBAEBmpAVC+kksH+6///u/M/F3lJtDDjnEvM34vAXQACi9\
  BkCDj/ytAACg0E9/+lNJ+BDpp6jpBkIbKv07FKz/lm6yt66b/5m3LT9vAUpdxd8C8CFJAKCCpUvU\
  04KMNV122WXrfZ96vvTvuPTSSyV0lXPPPde8LYF5C1DK0k//V6xYUTg8r6wbALlcrjYULHOwCgCA\
  QhdeeKH71Atce+21dUVmU0k/8X7ooYfkNeZ1Qz/9N29bbt4ClKr0MbaFPYFYH68o6wZAfc0fY9FW\
  W21V07t377B8+XKzAYAG0medp58astIll1wSvv3tbzf535sWZmkToFKL1vQT+6bMq3nbMvMWoBTN\
  nTu3cGh2Ma6jGA2A7XK5XOfp06c//9JLL4Udd9zRbABAcfohBg8eHH7yk580a56ffPLJcNZZZ1Vc\
  cZp+Om3elua8BSg18+atsdp/XjGuo8UbALH4r1n15VzTAADFaePS7/n1118P/fv3b/Z/a7PNNgtX\
  XHFFGDt2bDjvvPPKOq/p5ocjR46sK06b675087Zl5i2ABkAJNADyzDENAFCcrllApQVq+j2nS8pb\
  Unov/AUXXBDee++9uk9w03yXyyfZ6feS5nXYsGEt8jx68xaALDYAklwuV6wc/CXGD00FoNIlSVK2\
  31taRDb1J4EzZ84MY8aMqSs20hg+fHhJ5yj9RPrII48Mu+22W9hrr73qCsesmTp1ali0aFGYPHly\
  3fn48ePXuJfxkUceCa+++mrRrjFtVBx66KGrz9NcdunSJfTq1avJNvkzb0tr3gJkydVXXx3OOeec\
  sGDB6if/XRNr8e9WUgMgbYdfYCoAsCE++OCDuiXH77zzzur/qL711lt1RWtjrrzyyhYrQvOln4r2\
  7Nmz7uutttoqdOrUKROFKeateQvQcqqrqwd27NjxqvhlTazFZ1VSA+C0GNeYAgAAAFSI78S4tlj/\
  eDH3AJjttQcAAKCCFLUOLmYDYMb8+fPrlrsBAABABXinmP94VUv/g0mS7BYPD8XokZ5vu+224Y03\
  3jANAAAAKHdFXQHQ4nsAJEmyVTxMrz9v165dWLx4sWkAAABAuesYo7pY/3gxbgGYGWN112HJkiWN\
  PRMRAAAAysmiYhb/RWkA5HK55aFg2UP6bFwAAAAoNzU1NWHZsmXpl+8W+1qKtQlgg2/87bffNisA\
  AAAoO08//XRo27Zt2HjjjbdJkmRCjOsqrQHwXv6JFQAAAACUo3feWbnx/4IFC9rEQ7op/jaV1gBo\
  8OiDd99916wAAACg7DSy4n1GpTUAGqwAqO+IAAAAgAZAeTUA3tEAAAAAoNw1Uu8WrQAuVgOgQQtk\
  +vTpZgUAAABlZ8aMNT7wL1oBrAEAAAAAzVX8ugWgYQPgrbfeMisAAAAoO42sAKi4WwDSDOTqT5Ys\
  WRJmz55tZgAAAFA25s2bFz744IP8oVzFNQByudzSeJiZP/bmm2+aHQAAAJSNRurcd1fVw5XTAKjP\
  Rf6JfQAAAAAo8wbAtGJeTzEbAKtv/E+SxC0AAAAAlJVp09ao94u69L2qiP/2H2Jc8dhjj33tc5/7\
  3A/btm1rdgAAAKABUG4NgFwu9/yqL7cxLQAAACg3bgFoJCemBQAAABXQAChq/ZuFBoDd/wAAACg7\
  jdwCUPErANIE1JoaAAAAlJPXXnstTJgwobZt27Zfjac/iPF6Ma8nyeVyWchLugyip+kBAABAmUmf\
  gJeJve9aZSQhU80JAAAAylBm6t1MNQCWL19uagAAAKABUG4NgCRJjogxZMsttzx0k002Ceecc46p\
  AQAAQDl5PSsXUlXkfz+97/+4d955p+5kypQppgYAAADl5I2sXEixbwGYnH+iAQAAAECZyUyhW9Sn\
  ACRJslU8TK8/b926daiurg5VVVWmCAAAAOWga4z3s3AhRV0BkMvl3o6HD+rP000A//Wvf5keAAAA\
  lKy0ru3evXvYd999a5MkuTxGJja8y8JTABpsiPDqq6+aLQAAAJSsV155JcydOzeMGjUqrbn/I8Yp\
  GgCrav78k6lTp5otAAAAlKzJkyev0RPQAFjptY9JFAAAAJRyAyATS90ztwLALQAAAACUWQPACoDG\
  EjFp0iSzBQAAgHJqAGRiqXtRHwNYdwFJsnEoeCTC/PnzQ5cuXcwaAAAASsrChQsbq2c3ibX3/GJf\
  W9FXAKxKwoz8MasAAAAAKEWNfPr/ThaK/0w0AFZ5WQMAAACAUtdIPZuZne6z0gCYpAEAAABAqZs4\
  cWLh0MsaAB/RAHj55ZfNGgAAAErOhAkT1hjSAGioQYtk6tSpZg0AAAAlZ/z48ZltABT9KQB1F5Ek\
  3eLhzPbt208eN27crdttt91GkZkDAABAyUifALDxxhuHgjq7ezyfqwHQuFdi9DJ1AAAAKCXPPPNM\
  2G+//fKHZsSae6usXF+rDOZsvGkDAABAqWlkP7uJWbq+LDYA7AAIAABAyWnkCQCZ+oA7iw2AcaYN\
  AAAApebiiy+ue6z9t7/97T/H0wtjPJil68viHgA7xnjN1AEAAKBE7RRjStYuKosNgCTG/BidzRkA\
  AABKzMIYG8fIXLGdxVsA0iSNW7FiRaiurjZ1AAAAKCXjslj8Z64BkCTJoTGu3nbbbXfo3Llz+POf\
  /2zqAAAAUEpeyuqFVWXsenrH+M60adPqTsaMGWPqAAAAUEoyu7F91m4BaFDxv/jii6YOAAAApSSz\
  hWymNgFMkqRrPMzNH5s9e3bo3r27KQQAAEAm1dbW1kVVVdXysHJD+6VZvM5MrQDI5XLz4mFq/phV\
  AAAAAGTZY489FtJ97Pbaa68VSZJcGqO/BsDaaXAbwOjRo80mAAAAMiutW5csWRJeeuml9vH0jBgn\
  agCsZe7yT6wAAAAAIMtGjRpVOPS8BoAGAAAAAGWmkZXrL2TxOjO1CWDdBSXJpvEwK++8biPAbt26\
  mVUAAABkyqxZs8Jmm22WP5QW2d1X7XGXKZlbARCTNDvkbQSYNiiee+45swoAAIDMaeTT/ylZLP4z\
  2QBYpcENFI3cTwEAAADFL17XrFdfyOq1ZrUB8Gz+iRUAAAAAZNELL6xR7z+f1WvNagPgeQ0AAAAA\
  su7ZZ59doyegAbBu0q3/l9WfzJ07N0yZMsXMAgAAIDPSOjXdBDDPilDwZDsNgI+Ry+WWxMPY/LFG\
  uioAAABQNI3UqWNjPVutAbDuVu+kkD4CcOHChWYXAAAAWW4APJXl663K8LXdEuPFv/3tb1Xf/e53\
  /54kidkFAABAZowcOXKNnkCWrzfJ5XJZz+nGMeaGbK9WAAAAoIIsWrQobLLJJqGmpiZ/eLtYY/8r\
  q9dcCkX1/BiTTC8AAACyYtSoUYXF/7tZLv5LpQGQetr0AgAAICtmzpxZt19dKdWtpdIAeMr0AgAA\
  ICsGDBgQZs+eHW655Zbz4+n3YlyT9WsuhT0AUtvFmGqKAQAAkDHbx3ijFC60VBoAqekxtjK3AAAA\
  yIi3Y2xdKhdbMjvrL1u27Mnnn38+jBkzxhQDAAAgC54spYutyvoFJklyUDycG2O/9Py4444LQ4YM\
  Mc0AAADQACinBkBYuUrhy/Unjz32WEhvW0iSxFQDAABAA2AdiuuseybG0vqTdJfFCRMmmGYAAAAU\
  05wYJVWcZr4BkMvlFsfDc/lj6SoAAAAAaGkvvfRSeO6559J96p5IS1YNgKb3hAYAAAAAxfa73/0u\
  fO5znwvt2rU7NEmS+2N8sVSuvSQeAxgT2jcehtWfb7rppmHmzJn2AQAAAKDF1NbWhs0337zu1vQ8\
  X4519SOlcP2lsgLAPgAAAAAU1fjx4wuL/7ROfapUrr8kGgD2AQAAAKDYhg0bVjj07Kp6VQOgiY3I\
  P3nkkUfMPgAAAFrM8OHD1+gJlNL1l8QeAHUXmiQHhLzNALt06VK39KJ169ZmIQAAAM1qxYoVoVu3\
  bmHhwoX5w1+INfXIUvkeSmkFwLMxVmd6wYIFYdSoUWYhAAAAzS6tPwuK/0XpcCl9DyXTAMjlcsvj\
  4fH8MbcBAAAA0BJGjBhROPTkqjpVA6CZNKj4H330UbMQAACAZvfQQw8VDpVcQVoyewDUXWySfCoe\
  JtafV1VVhTlz5tTtBwAAAADNYf78+aFHjx5h+fIGH/jvEevp8aX0fZTUCoCY3Jfj4e3683QTBo8D\
  BAAAoDmlu/8XFP8zSq34L7kGwCrpMosVO+2001sXXHBB+PSnP202AgAA0GyGDh1aOPRwKX4fJXUL\
  QN0FJ8nW8bAwXvdu8TjSVAQAAKA5bbfdduFf//pX/tCAWJP+nwZAy6mKMTvGxqYjAAAAzWHy5Mlh\
  l112yR+qibFZrKXnltr30qqEX4cVMUaYjgAAADSXRpb/v1CKxX+pNwBS95uOAAAANJdDDz00pPvP\
  bbXVVlPDyk//Hy7V76WUbwFIbRljevp9mJYAAAA0k7Rw3jpJkiXx2CrW0bNL8Zso9RUAM2KMMRcB\
  AABoRmndmT76b26pFv/l0ABI3WcuAgAAoO78aFWlfPFJkmz65S9/ubZr165h0qRJYezYsaYlAAAA\
  Te2f5fBNlOweALH47xxWPgawTf3YSy+9FHr37m1qAgAA0FTSW8+3Div3AShpJXsLQC6XWxgPz+SP\
  3XPPPaYmAAAATemBcij+S7oBsMq9GgAAAAA0tZqamvovy+bx86XeAGhwH8bo0aPDjBkzzFQAAADW\
  27vvvhs233zz8I1vfCNsu+223ZMk2VgDoMhyudyUeHgl79wqAAAAADZIWlfOmTMn3HrrrWHatGnX\
  xqGRGgDZcHf+yb333mu2AgAAsN7uuuuuwqGyuA2gZJ8CsPobSJL9Ql43pm3btmH27NmhU6dOZi0A\
  AADrZP78+XXL/5cuXZo//PlYOz9b6t9bOawASF+EmfUn6Yv04IMPmrUAAACss7SeLCj+34kxqhy+\
  t5JvAORyudp4aHDj/x133GHWAgAAsM4aWf5/z6q6s+SV/C0Add9EkhweD6s/9u/cuXOYOXNmaNeu\
  ndkLAADAWkk/+e/Ro0dYuHBh/vDhsW5+uBy+v1Zl8joNj/F+/Un6Yg0dOtTsBQAAYK0NGzassPif\
  H2NEuXx/ZdEAyOVyy+Khwfb/d955p9kLAADAWrv99tsLhx5YVW+WhbK4BaDuG0mSr8XD6ps1unbt\
  Gt57773QunVrsxgAAICPtHz58rrd/+fNm5c/fFysmctmk7lWZfR6pfdkLKo/SV+0ESNGmMUAAAB8\
  rEcffbSw+E/ry7J6xFzZNAByudzi+henVatW4YADDvDpPwAAAGtlyJAhhUP3xzqzupy+x7K5BaDu\
  m0mSg+Nht0GDBk058cQTHzSFAQAA+DiVsPw/VVVO30x8cdI1//Xr/l+LsZOpDAAAwEd55JFHyn75\
  f6pVGb+Gd5jGAAAAfJxGdv8vu+X/5d4AGGQaAwAA8HF69+4ddtttt/yhIeX4fZbVHgCNGBdjd9MZ\
  AACAjzEpSZL+8XhCjD9YAVB6rAIAAABgbdwWi/6JMc4vx+I/Ve4rAD4Z4430+zSXAQAA+Ai9Yrxa\
  zt9gWa8ASJJkzhVXXDHlyCOPDIMHDzadAQAAaMzoci/+62rkcl0BEIv/0+LhLzE6pOdHHHFEeOCB\
  B0xrAAAACp0d4xINgNJtAHwxHh6vP6+qqgpvv/122GyzzUxtAAAA6tXG6BljRrl/o+V8C8CTMabV\
  n6xYsSLceuutpjYAAAD5hlVC8V/WDYDcyqUNN+eP3XTTTaY2AAAAoV+/fuF73/teuOiii55Pokr4\
  nsv6KQDxNdwlHiblj7388sth1113NdsBAAAq1CuvvFJYF/4rRp9YH88t5++7rJ8CEF+8V+Lhhfyx\
  W265xWwHAACoYP/4xz8Kh+aWe/Ff9g2AVRqs+7/55ptDbW2tGQ8AAFCB0nqwkdvDb6iE770SGgC3\
  xVhRfzJt2rTw2GOPmfUAAAAVaNiwYWH69On5Q8tW1Y0aAKUul8vNjIeH88euv/56sx4AAKAC3Xjj\
  jYVDD8a6cVYlfO9lvQng6m8ySY6Nhzvqz9u3bx/eeeedsPHGG5v9AAAAFWLBggVhiy22CNXV1fnD\
  x8S6+O5K+P5bVcjrfF+M1R2dxYsXh9tuu83sBwAAqCBDhgwpLP5nx3igUr7/imgA5HK59J6OQflj\
  N9xwg9kPAABQQa677rrCoVtX1YsVoSJuAaj7RpNk93gYlz82YcKEsNtuu/kpAAAAKHMvv/xyY/Vf\
  n1gTv1gpOaiUWwDSVQDj42F0/phVAAAAAJXh2muvLRx6sZKK/4pqAKxSt/1/kiS1/fr1CwcffLCf\
  AgAAgDK3bNmycNNNN63RE6i0PFTMLQCrCv+u8fD9GDfG73toPPbyowAAAFDe/u///i8MGDAgfyjd\
  CXDLWBfOr6Q8VNQKgPjizosxMMab8fQGPwYAAADlr5Hl/0MqrfhPVdQKgAI9YkyP0caPAwAAQHl6\
  4403wo477hhqa2vzhw+ItfBTlZaLVhU8D2bFuMOPAwAAQPnq1KlTOP/888MWW2xRs2pocoyRlZiL\
  Sl4BkPpijMf9SAAAAJS3RYsW9e3cuXOn+OVGsQ6+WwOgMk2IsZsfBwAAgLI1McanKz0JrcyD8Lf0\
  /0ybNk0mAAAAyrju0wCoYEmStNlll11WHHzwwWG77bYLkydPNiMAAADKy6IYN0uDFQAPx6L/r489\
  9lj6iMBw5ZVXmhEAAADl5bYY70tDhe8BkCTJmfGwuurfZJNNwltvvVW3SyQAAABl4TMxRkuDFQD/\
  iLGg/uT9998PN99sZQgAAEApe+2118LSpUvTL0cp/jUA6uRyufRekBvzx6644gqzAgAAoIT1798/\
  bL311uHggw+elSTJJ2VkpYp/DGCcDLuGlY+ESOrHRowYEQ466CCzAwAAoMSke7ylG73nWRFju1j7\
  Tq/03FT8YwDjJJgUD4/mj1111VV+agAAAErQ//zP/xQOPan4X6niVwDUJSFJvhoPd9efV1VVhddf\
  fz1ss802ZggAAECJePPNN8P2228fampq8oe/Fuvef8qOFQD17kvnSv3JihUrwuWXXy4rAAAAJSSt\
  4wqK/ykx7pWZlawAqE9EkpwdDxfXn3skIAAAQOlYtGhR6NmzZ93T3fKcE2veP8nOSlYA/Nu16Zyp\
  P0knzfXXXy8rAAAAJeDWW28tLP4Xrarz0ABoKJfLpTOlQcV/2WWXhdraWskBAADIdj3X2OZ/N66q\
  89AAaNRlMVZX/FOmTAn33ut2EQAAgCx74IEHwssvv9ygJxDD4900AD5cLpdbY4OIRrpIAAAAZMif\
  /rTGbf5DY333ssxoAHycBhX/nDlzwsKFC2UFAAAgg8aMGRMee+yxwuFLZEYD4GPlcrl05rwY4+Eb\
  brjhwbFjx4bOnTtLDAAAQAZdfPHFa/QEYl33iMysyWMAG0tKknSMefkgftkrRrpsRKMEAAAgY958\
  882www47hBUrVuQP/2es5/4hO2tS2DZiVfGfmhwK9gQAAAAgGy6//PLC4n9GjNtkRgNgff1BCgAA\
  ALLn3XffTVdw5w9dmsvllslM49wCsHaejPEFaQAAAMiW559//oV99tlnfPzyKzF2jDXu+7KiAbAh\
  vhrjbmkAAADInK/F+GeSJO1jfbtYOjQANlR6q0TaUfrUkiVLQrt27WQEAACg+CbF+HSMWqlYu8KW\
  j1d71VVX3XbccceF3r17F24yAQAAQHFcpPhfe1YAfFyCkqQqHh6IcWj92C233BK+8Y1vSA4AAEDx\
  TIuxYwyf0K4lKwA+Ri6XSyfT/Pyx3//+90HjBAAAoKguVvxrADSHC9NeQP3JhAkTwt132xMQAACg\
  JS1atKj+y/diXCcjGgBNLpfLvRRW3gaw2u9+9zuJAQAAaEGHHHJIXVx++eXpJ7J2/F9H9gBY20Ql\
  yX7xMDJ/7KGHHgqHHXaY5AAAADSzhx9+OBx++OH5Q0/HOCLWtAtkZ+1YAbCW4qRKJ9fw/LELL7xQ\
  YgAAAFrAwIEDC4eWK/41AJpTg3X/Tz75ZHj88cdlBQAAoBkNGzYsjBw5co2egMysG7cArGvCkuSZ\
  ePhc/flBBx0URowYITEAAADN5Itf/GLdB7B5RsZa9gsys26sAFh3DbpMjz32WF0AAADQ9NJV1wXF\
  f+oCmVl3VgCsT9KS5Ll42Kf+PO1GuRUAAACg6fXt27dw1fVzsY79nMysOysA1s9v8k+eeOIJtwEA\
  AAA0sQ+ptdz7v56sAFjfxBXsBWAVAAAAQNM68MAD65oAeV6INexnZWb9WAGw/n6bf5JOyuHDh8sK\
  AABAExg6dGhh8b9GHYYGQIvI5XIPxcOz9efbbrttWLJkicQAAAA0gfPPP79w6NlYh90nM+vPLQAb\
  krwkOSweru3SpcvFs2bN+k2bNm26ygoAAMCGuffee8NXvvKVwuFDY/36qOxoABSzCdAu5jD96P/n\
  MX4vIwAAAOsvrVH79OkTxowZkz/8eBw/SHY2jFsANnxy1q/7vyzGOzICAACw/u68887C4j91nsxo\
  AGRJdfA4CgAAgA2y0UYbhW222SZ/6OFcLvekzGw4twA0rdYxJsXYQSoAAADWz+LFi9/o1KnT5bW1\
  tefG06Ni3TpKVjQAsujkGP+QBgAAgPV2SoybkiRpG2vWpdLRNNwC0PRuiTHhrbfeCsOGDZMNAACA\
  dTNhVV0VFP9Nq0oKmlaSJJvss88+U8aPH//pTp06hSlTpoQuXbpIDAAAwNr5ZYxaaWh6VgA0bfHf\
  IR5eHjVq1NcWL14cZs2aFf74xz9KDAAAwNp5JsY90tBMNas9AJq8CXBpPPyo/rxDhw7htddeC1tu\
  uaXkAAAAfLSDYjwuDc3DCoCm998x5tefVFdXh1//+teyAgAA0Ijbb789zJ49O/3yIcW/BkBJyeVy\
  c+LhD/lj119/fXj55ZclBwAAIM+kSZPCiSeeGHbaaafwmc985o0kSdrLSvNxC0BzJHXlpJ0co2f9\
  2NFHHx3uucetLAAAAPl10n333Zc/9GysUT8vM83DCoBmECfs4nhosO7/3nvvDU888YTkAAAAROlj\
  0wuK/9Q1MtN8rABorsQmyUbxMCbG7vVjffr0CaNGjQqtWum7AAAAlau2tjZd8h/GjBmTPzwuxt6x\
  Rq2RoeahEm0mqybtz/LHRo8eHf7xj39IDgAAUNHSuqig+E+drfhvXlYANHeCk+ThePhy/Xn6OMDJ\
  kyeHTp06SQ4AAFBx0iel9erVK0yfPj1/+KFYmx4hO83LCoDm918xVtSfzJgxI1x88cWyAgAAVKQ/\
  /elPhcV/+qn/2TLT/KwAaIkkJ8lf4+H0+vMOHTqEV155JfTs2VNyAACAivH222+HnXfeuW4VQJ6r\
  Y136PdlpflYAtIzzY8yvP0kn+y9+8QtZAQAAKspPf/rTwuJ/QSh4ghoaACUtl8vNiof/zh97+OGH\
  w7x58yQHAACoCCNHjgy33npr4fDvY730ruxoAJSby2O8HmPZV7/61RdeffXV0LVrV1kBAADKXvrY\
  vx/96Eeh4Bb012JcKjstxx4ALZnsJNk/Ht6LOZ8Zj5NjfEJWAACAcnfttdeG73znO4XDX4210T2y\
  owFQCb6d/hxIAwAAUM4WLFhQt/Hfe++9lz88NNaih8lOy3ILQPFcH+MFaQAAAMrZ008/Hd5///38\
  ofQx6f9PZjQAKkltjB/EsAQDAAAoW4cffniYMGFCTadOnR5dNXRVLpd7RWZanlsAii9dCfBNaQAA\
  AMrYX2OcmSTJ4fH4bKxD35cSDYBK1CPGq7Nnz97kjTfeCJ/97GdlBAAAKCdzYvRadaSI3AJQZEmS\
  zPn2t789tFevXuGYY44JCxculBQAAKCcnKf4z0j9aQVAUYv/NvHwZIx96sd+/OMfhz//+c+SAwAA\
  lIOXYnwmRo1UFJ8VAEWUy+WWxcOL+WOXX355GDt2rOQAAAAlX/KElRufK/41AFjl3Bgz609WrFgR\
  zjjjjGBlBgAAUHIVf6xjbrvttrq6JrouxlOyogHAv39A0t0vz84fe+aZZ8K1114rOQAAQEm57rrr\
  woknnhj22WefcNBBB90hI9liD4AsvAhRPIyIcWD9WPfu3cMrr7wSNt10UwkCAAAyb9asWWGXXXYJ\
  c+fOrR+qjXF2rDkvlZ1ssAIgA3IruzBnxlhePzZnzpxwzjnnSA4AAFASfvKTn+QX/6n03v9HZEYD\
  gDWbAC/HwyX5YzfeeGMYMWKE5AAAAJk2bNiwcPPNNxcO/ynWORNkJzvcApClFyNJOsRD2gj4ZP3Y\
  jjvuGMaPHx/atWsnQQAAQOYsWbIk7L777mHKlCn5w6/H2D3Wm4tlKDusAMiQ+MNRHQ9n5I+lP0QX\
  XHCB5AAAAJl04YUXFhb/qbMU/9ljBUAWX5QkuSUevlF/3rp16/DCCy+EPfbYQ3IAAIDMmDhxYth7\
  773DsmXL8ocHxTrzJNnRAGDtGgCbxcOkGN3qx/bdd98wcuTIsNFGG0kQAABQdDU1NeELX/hCePbZ\
  Z/OH58XYNdaZ78lQ9rgFIIPiD8vMePiv/LHnnnsuXHXVVZIDAABkwmWXXVZY/Kd+pvjXAGDd3Rhj\
  eP1Jp06dQtu2bWUFAAAouqlTp4bzzjuvcDh9hNm1spNdbgHI8ouTJDvGw7gYT4wbN65m99137ycr\
  AABAMaU15KGHHlr36L886Ybme8Q/e12GsqtKCjL9gzUlSZLPxuPEeLp5WPmIwG4yAwAAFMt1111X\
  WPynzlf8Z58VAKXlP2LcJA0AAEAxzJgxI3z6058O8+bNyx8eFWO/WFvWyFC22QOgtNwc415pAAAA\
  iiF9KtmBBx6YP5Q+/+80xX9psAKg9GwZY0KMrlIBAAAUwfuf+tSnfjlp0qTfxq+vjDXlb6REA4Dm\
  860Y/5t+UVtbG1q1spADAABoMael9UiSJJulzYBYUy6TktKgcixN11dXVw8999xzw3HHHScbAABA\
  Sxka47r0i1j4z1T8lxZPAShBSZL0iYet6s/TXTi/9a1vSQwAANCcFsb4Tlr7S0WJ1pJuASi54j+J\
  h9Ex9qof69KlSxg3blz45Cc/KUEAAEBz+W6Ma6ShdLkFoMTkVnZsTg0rd9uss2DBgnDqqacGzRwA\
  AKAJa49QXV1df3p/jGtlRQOAlv9BHBsPA/PHRowYES677DLJAQAAmsSVV14Z9thjj7TWmBNWbvzn\
  E8cS5xaAUn3hkmSjeBgZY9/6sfbt24cxY8aEXr16SRAAALDeJk2aFPr06RMWL16cntbGuDjGr2P9\
  uFR2SpcVACUq/uDVxMMpMRbXj6U/nKecckpYsWKFBAEAAOtl2bJl4eSTT64v/uvrxh/G2E52NAAo\
  XhPg1Xj4ef7YqFGjwsCBAyUHAABYLxdccEEYPXp04fDPY/3xiuyUNrcAlPoLuPKpAI/G6Fs/VlVV\
  FZ566qmw7777ShAAALDWnn766fDFL34x1NTU5A8PjXF4TvGoAUAmmgA94yHdGLBr/diOO+5Ytx9A\
  p06dJAgAAPhYCxcuDHvuuWeYOnVq/vDcGHvEuvFtGSp9bgEoA/GH8a14OCN/bMqUKeH//b//JzkA\
  AMBa+fGPf1xY/KdOV/yXDysAyunFTJJ/xMPJ+WODBw8O/fv3lxwAAOBDDRkyJBx//PGFwzfFevEU\
  2dEAIJsNgC7xMCbG9vVjXbt2DWPHjg09e/aUIAAAYA1vvvlm3dL/efPm5Q9Pi7FnrBffl6Hy4RaA\
  MhJ/OBfEw3/EqMlvAMydO1dyAACANaSb/aWPEi8o/tN64mTFvwYA2W8CPBMPF6Zft27d+rYXX3zx\
  vd69e0sMAACwht/97nfh8ccfLxz+71hXPCk75cctAOX4oiZJVTwcEV/be+PxyBjpMZEZAACgXvro\
  8IMOOqjwkX9p4X9wrCVqZEgDgNL0PzE8EgAAAKiTLvlP7/tP7//PH46xV6wRp8lQeXILQGX4aYwX\
  pQEAAEi1b98+fOUrX0lXD+cPf0/xX96sAKgcO8V4IUYXqQAAAKKFX/rSl34xfPjw38av74i14Xel\
  RAOA8nFijEHpa/7uu++GLbbYQkYAAKBynZTWB0mSbBOPs2OdUC0l5c0tAJXl1mnTpl1/9NFHhy98\
  4Qth/vz5MgIAAJXpf9PiP/0iFv5vKv4rgxUAlfRiJ8kB8XBLjJ7p+THHHBPuuOOOwvt+AACA8jYh\
  xr4xFP0VxgqAyvK9+uI/ddddd4VLL71UVgAAoHIsiHGc4r8yWQFQSS92knSKh+dj7FI/1qZNmzBi\
  xIiw3377SRAAAJShl156KXTr1i1ss016q384PsYQWalMVgBUkFwutyge+oe8bt+yZcvCgAEDwuzZ\
  syUIAADKTPo+/6tf/WrYe++9wxVXXHGX4r+yWQFQiS96kvxnPNyQP3b44YeH+++/P7RqpScEAADl\
  oKamJvTr1y8MHTq0fqg2xoUxfhPrwFoZqjyqvQoUf9hvDCt3/VztoYceCgMHDpQcAAAoE7/97W/z\
  i//6+m8bxX/lsgKgUl/4JOkQD0/H6L36t0GrVuG+++4LRxxxhAQBAEAJS1f3po//Lqj3Xoqxv0f+\
  aQBQmU2AneNhVIyN68e6du0ann/++bDDDjtIEAAAlKA33ngj9OnTJ8ybNy9/eH6MPrH+e12GKpdb\
  ACpY/OF/NR6+lX5ZP5b+kvj6178eqqs1BQEAoNQsXry47v18QfGfvt8/WfGPBoAmwJ3x8Pv8sbFj\
  x4bvfve7kgMAACXmjDPOCGPGjCkc/kN833+v7KABQOr8GA12B7nlllvCX/7yF5kBAIASkb5/v/HG\
  GwuHH41xnuyQsgcAKydCknSPhxdibFs/1rNnzzB58uTQvn17CQIAgAwbMWJE+PKXvxxWrFiRP/xW\
  WHnf/ywZImUFAHXiL4U58fD1GIvT85122mn2M888o/gHAICMmzZtWjjhhBMKi/90U69jFP9oAPBh\
  TYAX4+HMGNcdc8wx22+11VZPygoAAGRXunl3fO8eZs1ao84/I76/Hy1D5HMLAB+lR4y0KbC1VAAA\
  QPacdNJJYdCgQYXD/xPrvB/LDoWsAOCjpG3Er4aVy4cAAIAMWbp0aZg/f37h8IgYZ8sOGgCsj3QF\
  wLfDymeHAgAAGdG2bdtwzz335Pbff/+7Vr1fnxbjhFwuVyM7NMYtAKyt38f4efrF3LlzQ7du3WQE\
  AACK73cxfpkkyYB4nBzruzFSggYAG2qj6urqO88666yvPPXUU+Hpp58OPXr0kBUAACiee2IcG8Mn\
  /qyVKilgbSRJ0j0eutefp48Zeeihh0KbNm0kBwAAWt74GCcp/lkX9gBgbV0QY//6kxEjRoTTTz9d\
  VgAAoOW9E6NfjEVSgQYAzeEnMRo8R/T6668Pf/jDH2QGAACa2V133RXmzZuXfpk+oeuYGNNlBQ0A\
  mkUul0t/0Xyt8BfNL3/5y3TnUQkCAIBmkr7f7t+/f9hvv/3SRsC5ceg5WWF92ASQdZswSfKZeHg8\
  Rof6sQ4dOoSRI0eGPffcU4IAAKAJPffcc6Fv376hurq6fmh2jGNjHfek7LCurABgncRfNC+ElZuN\
  rO4cpb+Mjj766DB9ulVIAADQVNL318cee2x+8Z9KN+beWnbQAKClmgB3x8MvCn859evXL8yfP1+C\
  AABgA6Xvq9P31zNmzCj8o1/E9+O3yhAaALRkEyDd/e+m/LHx48fXdSiXLVsmQQAAsJ5qamrq3len\
  768LXLPqfThoANDiTosxPH9g+PDh4Vvf+pbMAADAevrBD35Q9766QDrwfdlBA4CiyOVy6Uf9x8Zo\
  0Jq85ZZbPB4QAADWQ/o++q9//WvhcPp++9hV779hvXkKABs+iZIk3YTkmZC3GUkcCzfddFM46aST\
  JAgAANZC+kHaySefHApqtFkxPhvHpskQG8oKADZY/GWUbv//1Rirtyft2LFj6N69u+QAAMBauOee\
  e8Kpp55aWPyn76+PVvyjAUDWmgAvxsOJMWpivHvTTTfdcPjhh0sMAAB8jOeeey6ceOKJYfny5fnD\
  6fvqE+P77OdkiKbiFgCadkIlyTfi4elVXcr0KQHuAQAAgA8xZcqUsN9++4VZs2YV/tGZ8T31X2UI\
  DQBKRZsYD8boKxUAALCmvn37hhEjRhQOnx/rtIGygwYApWbjGE/G2F0qAACgoenTp4fDDjus9uWX\
  X66/PfuvsUY7U2ZoDvYAoLnNj9Ev/d0mFQAA0NDWW289/ZxzztkzfvlUjHti/EBWaC5WANBSdonx\
  xM0339zjoYceCjfeeGPYaKONZAUAgEqW3vj/xRivJEnSflV9Vi0tNJcqKaCFvLL//vvf+swzz/ww\
  bTqljwn829/+lm4aKDMAAFSi+pWyr6Qn8T3yYilBA4CyEAv9s+Phh/XnV199dejatWv4wx/+IDkA\
  AFSa9FP+Y2K8IBW0JHsA0FLuCyuXOK120UUXaQAAAFARnnzyybDq9uvlMU6MMUJW0ACgLMVfdunS\
  pnSJ0/z88V/84hd1twIAAEC5uuaaa8KBBx4YzjzzzLBkyZLTwsrN/qDF2QSQlp1wSXJwWLkaoEP9\
  WLoZ4M033xwGDBggQQAAlJVBgwaFU045JdTU1NQP3Rrjm7EOWyY7aABQCU2Ar8TD7TFa14+1adMm\
  3HHHHeGoo46SIAAAysLdd98djjvuuPziP5WeHBTrsKdkiJbmFgBaXPxlly55OjX9sn5s2bJloX//\
  /mHYsGESBABAyRs6dGg44YQTCov/2rDy03/FP0VhBQDFm3xJcmY8XJk/1qFDh7pflvvvv78EAQBQ\
  ktIN/w4//PBQXV2dP5wWXmfE+uvvMkSxWAFA0cRfflfFw8/zx9JfkkceeWQYNWqUBAEAUHKef/75\
  cPTRRxcW/6lzFP9oAFDpTYCL4mFg/tj8+fPDYYcdFsaMGSNBAACUjNGjR4dDDz207v1sgd/G972X\
  yBDF5hYAsjERk+TSePhR/liPHj3Ciy++GLbeemsJAgAg01566aXwpS99KcydO7fwjy6JNdfZMkQW\
  WAFAVvwkxl/zB0499VTFPwAAmTdu3Li6T/4bKf7TW17PkSGywgoAsjMZo3i4Nsa3qqqqfr98+fI9\
  49dHyAwAAFk1ceLEcPDBB4dZs2YV/lH6vva7OQUXGgDwoU2AjeLhqDgv/xmP7WKkjww8VGYAAMia\
  SZMmhYMOOijMnDmz8I+ui3Ga4p+scQsAmRJ/R9asKv5TS2J8LcZjMgMAQNa88sorYc6cOYXDNwaf\
  /KMBAOslfX7KkTGekgoAALLkmGOOCVdeeeWE+GXNqqFBMb6dfqglO2SRWwAoFR1j3B/jwKlTp4Yl\
  S5aET33qU7ICAEAxPRbjqCRJ+qX9gBgnK/7RAICm0WHixIn3HXnkkQdXV1eHoUOHhj333FNWAAAo\
  hkfCyttVq6UCDQBo6smaJNvEw+Mxtk3Pu3XrVtcE6NOnj+QAANCSHgorP/FfIhWUEnsAUEq+X1/8\
  p9LnrH7pS18KzzzzjMwAANBs0tWnedKnVH1V8Y8GADSvc2PckD8wf/78cNhhh4Unn3xSdgAAaHLp\
  +8wddtghDB8+PD29M0b/GMtkhlLkFgBKa8JG8fDXGN/LH+/QoUP45z//GQ455BBJAgCgSaS3m6Y7\
  /acrANq3b59r27Zt33nz5j0mM2gAQMs2Af4S4wf54+3atQuDBw8ORx99tCQBALBB0g+Xjj/++LBs\
  WYMP++fH6BNrqNdliFLkFgBKTm6lH8YvL84fTx8N+PWvfz0MGjRIkgAAWG/p+8njjjuusPhPXRtj\
  qgyhAQAt3wj4aTxcmD+2fPnycPLJJ4e//e1vEgQAwDq7+uqrwymnnBJWrFhR+Ee/je8/z85ZQo0G\
  ABStCfCrsHJzwNVqa2vDmWeeGS666CIJAgBgrV1yySXh9NNPDzU1NQ3ecsY4J77v/I0MUersAUB5\
  TOQkOT0ergwFTa2f//zn4fe//70EAQDwodKa6Je//GVj7xtrY5wV/9zyUjQAIGNNgJPi4foYrfPH\
  L7744nD22WdLEAAAa0g/7T/jjDPCNddcs8YfxfhmrJduliU0ACCbTYD0EQCDY7RLz3ffffcwbNiw\
  0KNHD8kBAKCBpUuXhpNOOinccccda/xRjBNjrXSXLFFO7AFAWYm/pO+NhyNjLIzx5sCBA0+Kxf8C\
  mQEAIN/ChQvDkUce2Vjxn76P7Kf4pxxZAUB5Tuwk2Sce5sb5PSUe+8R4MIZlAAAAhAULFoQvfelL\
  4YUXXij8o1kxjojvIUfLEuXICgDKUvylPWpV8Z9Kf4F/McZbMgMAQOfOnUOvXr0Kh6fFOEDxTzmz\
  AoBKslWMB2LsIRUAAJVt6dKl4zp27Di3pqbmoHj6cozDYm00XWYoZ1YAUEneDitXAgxPd3v9j//4\
  j/DII4/ICgBA5Rnetm3bL8b3hEfFr9NH/B2g+KcSWAFAJWpzzDHHTLz77rt3bN26dbj66qvDN7/5\
  TVkBAKgMg2KcGmOZVFBpqqSASpMkyU/iYcf06+XLl4dvfetbYdq0aeHXv/615AAAlLeLY/wshk9B\
  qUhuAaAStc//pZ+ugvnNb35T1whIGwIAAJS+FStWhPPPPz/MmpVu7B9qYvwwxk8V/1QytwBQmRM/\
  Sb4ZD3+P0SZ//LDDDgtDhgyp2xkWAIDSlD7mr3///mHo0KFh3333DbfffvvJW2+99c0ygwaABgCV\
  2wToGw+3x+iaP967d+9w3333hfgfCUkCACgxb775ZjjqqKPC+PHj84fviXFsrH1qZIhK5hYAKlb8\
  D8DwePhCjDfyx8eOHVvXKR492iNgAQBKSfr+LX0fV1D8p/aJsa0MoQEAld0ESJ/5+vkYo/LHZ8yY\
  EQ488MBw1113SRIAQAn45z//Wff+7d133y38o4np+734vu91WUIDADQB3ouHg2LcmT/+wQcfhOOO\
  Oy5cfPHFkgQAkGGXXnpp+PrXv173/q3AsBj7x/d7/5IlsAcA/PuHIUnShtgfY/xX4Z+ddtpp4aqr\
  rgqtW7eWKACAjFi2bFk466yzwrXXXtvYH/9vjDNiveMxT6ABAB/aCDgjHi6PsVH+eLoaIH1CAAAA\
  xTd79uy6T/2feOKJwj9KC5xfxjrn97IEGgCwNk2AI+JhcIxO6XmbNm3Co48+Gg444ADJAQAosokT\
  J4avfOUrYerUqYV/tCTGf8YaZ7AswZrsAQCNiP/ReDAe0mp/enreu3fvX8Tif7zMAAAU1/333x8+\
  //nPN1b8z4hxoOIfPpwVAPBRPyBJsnU8fC3+nFwRj51j3BLjaJkBAGh5t99+exgwYECoqakp/KPR\
  q96zTZcl0ACAppKumvltjF+mPz/SAQDQct5///3w2c9+NkyZMiV/ON2k6ZuxrqmWIfj4YgZYe7Ux\
  zotxfIxF9YNz586VGQCAZrbJJpss+vWvf31O/HJ+WLnZX/rBzAmK///P3p2AR1FlCxw/BQSEAGHR\
  yA6iQFhFElQWScBBVgcYh02WgSfyBkVFmFFRfI46CAp+Mg6bsowsA6IziiiLbAGjiBIUBQOC7HvC\
  EkIgCVl451YqTNvTnQSSQCf9/33f+W6nqrrRk3TXvadv3QIoAAAF6V8arTX2zpo1S0JCQmT9+vVk\
  BQAAoODs1WgzcODAydoO1OinA/+/XGZKM5BrXAIA5EHr1q27b9269VNzD9rixYvLX//6V3n22WfN\
  2gEkBwAAIP+sMQN+DaZdAhQAgBvw5rGsatp8p3Gr6/aePXvKvHnzpHz58iQJAADgKiUnJ4v5csWl\
  L/WmxrMa6WQHyBsuAQCuXZzGEveNS5culbCwMNmxYwcZAgAAuAr79+83Myylf//+5rbMSbppgMaf\
  GPwD+YMZAEBe30SWNUibdzRKu24PDAyUd999Vx5++GGSBAAAkIPly5fLoEGD5OzZs/bP9erVm7F7\
  9+7HyAxAAQDwtSLAXdp8pFHHfd8TTzwhb775pgQEBJAoAAAAN+np6fLyyy/baym5jU3MD9112wqy\
  BFAAAHytCFBZm/c1fuO+z0xl++CDD6R69eokCgAAwHHq1CkZMGCArF692tPutRoP63gljkwB+YM1\
  AIB8oien09p01nhdMivWV2zatElCQ0MlMjKSRAEAAKgtW7bY/SMPg3/Tj3rN9KsY/AMUAABfLgKk\
  azynD3+nEe+67+TJk/LAAw/IpEmThJk3AADAj/tL8tZbb0mbNm3k0KFD7rtN/6mHHvOC6VeRLSB/\
  cQkAUFBvLsuqp80HGs3d9w0ePNi+VSAAAIA/iY+Pl6FDh9p3TfJgm8bvdXyyl0wBBYMZAEAB0ZPX\
  Hm1aacxx39epUycSBAAA/Ep0dLS0aNHC2+DffDPShsE/ULCYAQBcjzeaZQ3RZppGmapVq3547Nix\
  hvq4CZkBAABFnRlvTJkyRZ599llJTU11331R40k9Zg6ZAigAAEWpCNBUm5c0Bun7ztL2bY1HyAwA\
  ACjKXnnlFXnppZc87dql0Vv7RTvIEkABAPAHfTXe0QgiFQAAoCg6fvy4NG/eXGJjY103/1PjjzoW\
  SSRDwPXDGgDAjbVE4y6NzVkb1q9fL+npLHoLAACKhqpVq25u0aLF/0rm7f3MlP9hOvAfyOAfoAAA\
  +KP9GvdpTFi7dm1Gx44dJSIiQg4ePEhmAABAYZahMdH0c1auXPmutqM17uF6f+DG4RIAwFfejJZ1\
  S/HixXekp6cHm5+DgoJk5syZ0q9fP5IDAAAKmxMagzTWkgrAdzADAPAdf88a/Bvnzp2T/v37y+DB\
  gyUhIYHsAAAAn3T+/Hl5/vnn5cKFC1mbVmo0Y/APUAAA4N1zGpvcNy5YsEDuuusu+frrr8kQAADw\
  KV999ZW9wN+ECRNk1KhRabppjEY3jTiyA1AAAODF5cuXD2gTLpm3Ckxz3bdv3z5p166dfQudtLQ0\
  kgUAAG6o1NRUGTdunISHh9v9FGP27NklLMsyaxtxjTHgo1gDAPDFN6ZltdZmgUZd931hYWEyb948\
  adSoEYkCAADX3c6dO2XQoEGydetWT7u/M92VywwyAJ/EDADAB+k501wKYG4PuNB9X3R0tF0EmDJl\
  imRkZJAsAABwvfonMnXqVAkNDfU2+Df9lg4M/gHfxQwAwNffpJbVX5tpGhXd93Xo0EHmzp0rtWvX\
  JlEAAKDAmNsTDxs2TNau9biu3xmNETqu+IBMAb6NGQCAj9OT6WJt7tRY475v/fr10qxZM/noo49I\
  FAAAKBBz5syx+xteBv+mf9KMwT9AAQBA/hUBDmvTSeMJjYuu+xITE6Vy5cokCQAA5KujR49Kly5d\
  7G/+PdySOFljlOmfaD/lKNkCKAAAyN8igDFVH7bQ+CZre5s2bdaFh4efJ0MAACC/zJ8/X5o2bSqr\
  Vq3ytDtaI1T7JX/jen+gcGENAKAwvnEtq4Q2YzX6arTU93Gwtu9I5iwBAACAa2buNjRkyBBPuy5p\
  vKLxuvY9uC8xQAEAwHUuBAToezg160eNoRpvalQgOwAA4FqkpKTYK/3/9NNPrpu3aQzRfscPZAgo\
  vLgEACjEXAb/9o8aczWaaHyatXH8+PFy4MABkgUAAHKlVKlSn4aEhPTSh+ka5pt+863/3Qz+gcKP\
  GQBA0TVg3rx5bw8ZMqRSYGCgvPrqq/Lkk09K8eLFyQwAAPDktMZTGv+0BwqWNVKbTTpe+I7UABQA\
  APjym9uybtbGzN0LztrWsmVLmT17tn0rHwAA4L/Mqv7ly5d33fRvjcc1TpIdoOjiEgCg6BrlOvg3\
  tmzZImFhYfLCCy9IcnIyGQIAwM8kJSXJuHHjpHbt2rJ3716zKVajt8bvGfwDRR8zAICi+ubOvFPA\
  GI2XNEq7769fv7688847EhERQbIAAPADa9eulccee0z27Nlj/9yiRYvYDRs2NCpXrtxpsgNQAABQ\
  NAoB9bWZpdHOwz4ZNGiQTJ48WW655RaSBQBAERQXFydjxoyRBQsWeNptVvafR5YA/8AlAEARpyf1\
  3dpEaDyqEe+2T+bPny8hISEya9YsycjIIGEAABSdPoDMnTtXGjZs6G3wf1YyV/kH4CeYAQD40xve\
  sqpoM0Wjr6f9rVu3lhkzZrBIIAAAhdz27dvl8ccfl6ioKG+HmJX+x+hYgOv+AT/CDADAj+hJ/oRG\
  P33YXWO/+/5NmzZJaGio/PnPf5aUlBQSBgBAIXP+/HkZPXq0ub7f2+DfrPzXSfsDAxn8AxQAAPhH\
  IWC5No01XtO45LovLS3N7jAEBASQKAAACpFFixZJgwYN5K233rLP525SNSZoNNV+wGqyBfgnLgEA\
  /P1DwLIaajNDI9zZlB4VFbWzbdu2TcgOAAC+LyYmRkaOHCmRkZHeDvlS4zHt928nWwAFALIAUASw\
  tBmkMVnjA/1cGKXtCI1XNCqQIQAAfJP5pv+OO+6QgwcPetodq/GMxvzLdPoBUAAA4FYIqKRNun4u\
  nHM2BWu8rvEHs5sMAQDge8zU/wEDBrhuSteYqTFOz+nxZAhAFtYAAHCFdhLOuAz+DfPNwVCN1hpb\
  zYYdO3bIfffdJ5s3byZhAADceN/16dPHnKezVvwzJ+iWej4fyeAfgDtmAADILVMwHBIRETFz48aN\
  AeaqgX79+snEiROlVq1aZAcAgOvLFOnHarynkaHn5ebahmr8Q/v3GaQHgLcOPQDkhulcnDGDf/OD\
  KR4uXrxYQkJCZNy4cZKYmEiGAADIZ0lJSTJhwgQ5ceJE1iZz9543NRpozDXnZ+e8vE1jDoN/ABQA\
  AOSXnp46JuPHj5f69evbBQFmFQEAkHcZGRkyd+5cuf322+X555+XF1980Wz+WMPcpedPGkzvB3DV\
  uAQAwNV9aFhWL23e0LjD0/6wsDCZNGmSREREkCwAAK7Bhg0bZPTo0fL999//qiagEWq+6SdDAK4V\
  MwAAXBXteJhvHxqLl28foqOjpX379tK9e3f7vsQAACB3zHmzW7du9nnUbfCf1W9/lSwBoAAA4HoX\
  AS5pmOsP62lM10hzP2b58uVy5513yrBhw+T48eMkDQAAL8x5csSIEfZ5c8WKFZ4OSXPOt8PJFoC8\
  4BIAAHn/ILGsO7WZpNHR0/6yZcvKqFGjZOzYsVKmTBkSBgCAio+Pl8mTJ8uUKVPkwoUL3g5bpTFG\
  ++xMqwNAAQCATxUCOmvzukYz931Vq1aVPXv2SGBgIIkCAPi1lJQUmTZtmr26/6lTp7wdZq71H6t9\
  9VVkDEB+4RIAAPnG6aTcpfGIxmHXfaNGjTrI4B8A4M/Myv7z5s2z75wzZswYb4P/4855NJTBP4D8\
  xgwAAAXz4WJZpbUZLZmLBcZqNNbPm67ajpfMWxgBAOBXzp07J3Xr1pUzZ8542m0W1jWX003R8+VF\
  sgWgIDADAECB0M5LkoYZ7JuFAvvrY7OA0TINs17AYI19rscfOHCApAEAirSgoCB5+umnE9w2J2vY\
  C+vqufI1Bv8AKAAAKMyFgFMa37lsMvcxXqDRUGOkxnHzjUhoaKi0bdtW1q5dS9IAAEWRmdo/8vDh\
  w7W0PeqcD9/TaKDnyT+Z8yUpAlDQuAQAwI0W2KtXr2VLly7tkLXBFAJefvll6dChA9kBABQqR48e\
  lerVq7tuMlP739B4W8Ne6t+yrC7aHGRlfwAUAAD414eQZVWWzMsByrvvi4iIsAsB7dq1I1EAAJ+2\
  ceNG+5y1bds22b9/v5nuf94Z9E92igAAcMNxCQCAG+1mjR897diwYYOEh4fL/fffb3esAADwNevW\
  rbPPVaZoHRkZKWfPnpURI0ZE6a7bNMYx+AfgS5gBAMA3Powsq6M2L2u08nbMfffdJ2PHjpUuXbqQ\
  MADADfX555/Lq6++Kl999ZWn3WbQf5v2sxn8A/ApzAAA4BO0k7RGo7U+NKP7bzwdExUVJV27dpWw\
  sDBZunSpUMAEAFznc5UsW7ZMWrVqJZ07d/Y2+Dd+0ahKxgD4GmYAAPDNDyfL6qbNXzTCvB3TtGlT\
  efHFF6V3794kDABQYNLS0mTJkiUyceJE2bFjR3aHbtZ4VfvXK8gaAF/EDAAAPkk7T8u1uVvDFAI2\
  eTpm+/bt9kwAAAAKQnJyskyfPl3q168vAwcOzG7wb85TnfXc1YrBPwBfxgwAAIXjw8qy2mvzokZ7\
  1zpBdHT0kdDQ0JpkCACQn95++2157bXX5OTJk9kd9oXGK9qfXkfGABQGzAAAUCho5ypSo4M+bKOx\
  0tm8XAf/t2s7VGMnWQIA5Jd9+/Z5G/xfds5D7fS8FM7gH0BhwgwAAIXzw8uyQrVJ0c+wrPmYpqDZ\
  XeNZjdZZx5k1Ah566CFp3rw5SQMA5Nru3bs3N2jQwKxDU8LZlK7xL42Jeu7ZRoYAUAAAAN9gCgB/\
  joyM/G2HDh3smU7ayqhRo6Rbt25SrBiTnwAAHmVoLNOYpLHJsqz52vbRMO0b2m/+hRQBoAAAAD6o\
  dOnSa5KTk3/jus0s5GQKAYMHD5bAwECSBAB+5uLFi7Jw4UJZvHixrFixwpwr7M0aCzTe0vj5SkfZ\
  smprk6r95WNkDgAFAADw1Q83y2qkjbk8wPK0v1KlSjJ8+HB57LHHpGZN1hAEgKLuyJEjMm3aNJk1\
  a5acPn3a3jZlypSTTz31lBn0z9I4Q5YAUAAAgMJZACipTV+NpzXu8nZc8eLFpUePHjJy5Ehp3749\
  iQOAIiYqKkr+/ve/y8cffyxpaWnuu2M0mlymQwyAAgAAFJligBnZj5LMRQK9LgDQpEkTuxBg7vXM\
  5QEAUHhduHBBFi1aJNOnT5dt27Jdr890hO/R/vAWsgaAAgAAFK1CQD1tntIYouF1hB8UFCRDhw6V\
  8ePHS5kyZUgcABQSO3fulJkzZ8q8efPk3Llz2dYIJPOa/79pX3gXmQNAAQAAim4hoKI2j2r8UeM2\
  T8eEhIRITEyMOZaEAYAPS01NlaVLl8qMGTNkw4YNkkPf9qDGdI3ZehzX/APwOyVIAQB/o52+s9q8\
  oYP7yZJ5WcBIDXO3gCuj/S5dunyt+5tJNjMFAAA3lrmm3xRs9+3bl9OhkRpTNT7Rc0A6mQPgr5gB\
  AABizwoIcQoBgzSKa9RwFoUyP4/QaOR6/NmzZ6Vs2bISEBBA8gDgBvrDH/4g8+fP97QrQWOhxnT9\
  OP+JTAFANothAYA/MdeAapgCQA2N3+rjeG3NBaTmG6PGGuEaizVSzPHPPPOM1KhRQ0aPHi07duwg\
  gQBw/ZnP48W33nrrk27bf5DMwm11/Sx/nME/APwHMwAA4OoEnzhx4vF69er9X2Ji4pWNrVq1kiFD\
  hkjfvn3tRQQBAHljFvFbsmSJlC9fXvr16+e66xeN2Rr/0Ii1O7SWZVbx/1ljhvZtvyJ7AEABAADy\
  54PTsoZpM8vTvtKlS0vv3r3tuwi0a9dOihVjohUA5FZGRoasX79e3nvvPfnoo48kKSlJGjZsaBZl\
  TdLdH2jM1YiSzNv3uX4uF9M+bQYZBAAKAACQ3wWAcdo8b8b72R1Xq1YtGTBggAwePNhepAoA4Nmu\
  XbtkwYIFsnDhQjl06NB/7W/YsOEDMTExa8gUAFAAAIAbUQQw8/wHaPyPRmhOx4eGhtqFADONNTg4\
  mAQC8HuxsbH2FP9FixbJ5s2bczrc3LbvUbIGABQAAOBGFwOaajNUY7BG5eyONXcNGD58uEydOpXE\
  AfA7Zu2Ujz/+2B70r1mzRtLTc7wjX5zGEo252mf9ngwCQN6UIAUAkDfaKd2uzWjLssZq+1unENDJ\
  jPfdj01NTWUGAAC/s3btWpkzZ4588skn9nX9OUjVWCGZi/yt0M/YVDIIAPmD1akAIP8KASkaH2o8\
  KJm3EzS3pop2Py40NNSsIWBWqWYKFgC/YAb+77//fk6Df/MN/2jz+amfoz01PmHwDwD5i0sAAKCg\
  P2gty6wAOEhjoMZx/dy919lVR6O/xsMaTbKOHzt2rJw6dUp+97vfyf333y8lS5YkiQAKtdWrV+/t\
  1KnT7R52HdRYZEI/G3eQKQCgAAAARaUQYGZdBevn7gkPu5uZYkBqamqfatWq1TUFAKNChQrSo0cP\
  uxjwwAMPyE033UQiAfiM5ORkWbdunX1tf9++fd1375PMW/ct1s8/c6nUAY1aGqed7Wbg/9VlOqMA\
  QAEAAPy0SNBRm9We9pUrV066detmFwM6d+5s/wwA11tCQoKsWLHCXsxv5cqVcv78efu2pwcOHDCf\
  YTv1kH9rfCSZU/pdP9/MjKfzGp8ztR8AKAAAAB/KlvWONsNzOq5UqVLSvn17e3bAgw8+KNWrVyd5\
  AArMyZMnZdmyZfagf/369ZKSkvJfxwwbNqzfrFmzlpAtAKAAAADIXQEgUJuuGg9pdNMom4vnmIUF\
  pWfPnnYxoFmzZiQSQJ6Y/uG2bdtk+fLl8umnn0p0dLRkZGTk9LRJ+rxnyB4AUAAAAFx9MaC0ZN5O\
  0BQDumtUyOk5d999t3zzzTckD8BVu3jxov3t/meffWYP/I8cOZLbp8ZL5m375mm/cjWZBADfVYIU\
  AIBv0o60uV/WUhOWZZlbAfxGo6fGbzVu9fSczp07J2vDSoEArlpERIRs2bIlt4ebxUw/kcxr/Tfo\
  59UlMggAvo8ZAABQ2D64M+8mcI9GD40HNRpl7QsICAi7dOmS2W8uHzCzBlqYp2TtN1N5P//8c+nY\
  saPd2Q8KCiKhAGzmFqQTJ07M7pA94hQlNTZrHzKDrAEABQAAwPUtCNRzigFtNXq53VKrmmSuKWCK\
  Ae0HDBhQftGiRfaOEiVK2JcMmGKAucWgeWy2ASg60tLS5Ntvv7Wn9rdt29Yu/LlJ0IjUWN61a9eE\
  lStXvu/6dI0vzD6Nz/SjZTcZBQAKAACAQuDDDz8sOXDgwNhLly55/NrfzAYIDw+XDh062IOEpk2b\
  SrFixUgcUIiYft327dvtAf+6detk48aN9m36jEceeURmz55tBvVmnr+5Vn+NxjfOQN8UEwO02aWx\
  yQz4NVbp650jqwBAAQAAUNg+8C2rpTbf5vb4ypUr24UAE+aWg40aNbLvOADAtwb8MTExsmHDBomK\
  ipLIyEiJjY31eGy5cuXOJCQk1NWHDOoBgAIAAKCIFwDMN/9mbYCOTlS/mucHBwfbawiYSwUA3Bjp\
  6enyww8/yBdffGF/u//ll1/KqVOnruYlGjCVHwAoAAAA/K8g0MgpBDyg0U6jbHbHm8sB4uLipFKl\
  SiQPuAHMN/zdu3eXhISEq31qosZGjfUaC7XvF0s2AYACAADAf4sB5jaDrTTaO2HuMlDK9ZhSpUrt\
  Sk5OfkMfRjgFgzrur9OrVy+pUaOG3HPPPfZMgfr165NcIJ+cOHFCqlatmptDzS35vnYG/Os0vtH+\
  XhoZBABQAAAAeCoIlHYKAhFOQcDM+39XzxlPuBxWUyNc4z6N1nFxcQ2Dg4OLu76OmS1w7733XikI\
  mLZixYokGFBmRs2WLVvsiI6OlsTERPsafjfpGjslc2G+qBIlSrySnp5+m9sxKZK5sJ9Zsd980/+l\
  vlcvkmEAAAUAAMC1FATKaBOo54w4b8dUrlz5oTNnzvwrp9eqW7euhIaG2tGiRQu75bICFHXx8fGy\
  detW+e67764M+g8cOPCrY8xlNvoeig0KCtosmQt2fu0M7M+7vBf/oU0fZ1/WgP9bfW8mkWUAAAUA\
  AMD1KhK8rs0z1/Lc2267TVq2bCnvv/8+dxpAoXfo0CHZtm3br2L//v25ffr92jdbn8377BZTT9Bj\
  Usk0AOBqlSAFAIB8Mk1ju2ReLnCvRnONgNw80QyOAgICchz8X7x4UcqUKUOm4ZMuXLggtWrVMt/i\
  5+VlzHvHawEgu1k4AABQAAAAXBc6MDmkzUInzGD+Jm1aSOaCgllRJ5uXMNOeNznPMcWDCq47jx49\
  ag+u6tSpIw0bNpRGjRrZbZMmTSQkJMTc45xfAgrE+fPnZdeuXXahqk+fPl6PCwwMtAtUV1kA2COZ\
  0/yjJXPa//dkHABQULgEAABw/U46llXZGeCHukTWgmbP6TnpdZfDb3MKASYaL1u27O4ePXrU9Pba\
  pjjQoEED+84D9erVs1sTtWvXlhIlqHcje+np6XL48GHZvXu3/Pzzz/aA34R5bIpPWc6ePSsVKlyp\
  TZkF+vZp/Kjxk8a2ihUrPhUfHx/u5Z854AzwtzqD/i36N3+W7AMAKAAAAPylKFDJKQTs1XPSvmyO\
  G63Nm1f7+iVLlrTXGGjevLm9xgD8l+nzmAH93r175ZdffrnS7tu3z/52/9KlSzm+xoIFC/45cODA\
  z50Bf4xGstvf6XjJXAtjlykIuMT3+u+f4bcAAKAAAABAzoWCqdo8fq3Pb9q0qfz4449e95vB30sv\
  vSQ1atSwZxPUrFnTfnzzzTeT/ELCTNXP7lKQ1NRUKV26tP1tfx4M177TrGz+Ts30gGQ9JpnfCACA\
  AgAAANdeBDCXEDTVCNFo5ERjjSo5PbdOnTrf7N+/39ym8A6XMJcUFDP7zbfBd9xxx389zwwYTUHA\
  FAOqVasmVapUsdvg4GCpXr263Zqfg4KC+AUVkHPnzsmJEyckLi5Ojh8/LseOHbOn65ttrq1ZRNIs\
  xJeNDP1dXtZji1/Df8YxyfxWf6b2nT7ktwIAoAAAAMCNKQxUdIoCIc7Avr5GPSeybhswSc957rcp\
  LKlRW6PG+PHjO48bN+6Za/1vMIUCs/jbTTfd5PWYzZs32/vNNeRZgf8w19q/8MIL9kD/5MmTdpjH\
  KSkpuX6N48eP/1ylShVzKckRJw5qHMp6rH8rZvp+hJenm2/tf9H42YldTvysfzsJ/IYAABQAAADw\
  3cKAua9gDacQcELPeTHZHDtUm7nX+m+VLVvWnoKenVtuuUVOnTr1q20VK1a0CwFmBoFpTSHBvFb5\
  8uXtx2ZlebPPPDYr0JvZBt7ExsZKYmLif2036yCY18mJKU6Yf8ebI0eO2Pe0T0pKsr9pN5dNmG/n\
  zZT6+Ph4e4q9yYF5nJCQYD82/z1Z2xo3bixRUVHZvr659CKP7tLf87Zsfs9vadPOGeib2Ofy+Nhl\
  OkYAgCKMZZEBAEWWM5g77EROdmpMcQoGJmpJ5qUFxXLzb128eNEMJO/XqGrG+hq3Os83j81ChxXP\
  nDnT1f15ZlV5E7kRFhaWbQHg6aeflkWLFl1zvp577jmZMGGC1/2rVq2SRx999Jpf37k9nrkA3zw4\
  67RXHpctW9ZUR17O46+9Sg5/E0/zzgAAUAAAAMC/iwWbtdnsus2yrABnQF/TCTO4rOa0VZx9pr05\
  IyPjpGTe5u2Ap9d37nZwOi//jefOnTNrGJil6s1/VwWnzVr1rtTp06fryn8uebhqSUlJ8c6A/Fe1\
  DQ17Dn5KSoqZRhByra8fExNjrqOv7m2/c0mEuQzD03QFk7tYDZNnM53/uMZRl8emNbM8LvHXDACA\
  Z1wCAABAXk+mlmXWEiib3W3e9Bgz8F3uDNxNXMuqgU303/gpm3/jM2265eF/ZaK+/thsXr+3Nh/k\
  4fUv6usH5pDLR5yCwylnYB9nQp+Xyl8aAAB5wwwAAADyyPnW+UwOx5hvq5u7DHQtpxBQ0aUoUF7D\
  XIRfzgnzuKyz3XyzfzKH/xTzDbm36wnM80tl81wz6A7I4fXNgDzKOdYseJDm/HtmWn+Cs90swx/v\
  /HzeiUSXbTnlcg5/UQAAFAxmAAAAAAAA4AeKkQIAAAAAACgAAAAAAAAACgAAAAAAAIACAAAAAAAA\
  oAAAAAAAAAAoAAAAAAAAAAoAAAAAAACAAgAAAAAAAKAAAAAAAAAABQAAAAAAAEABAAAAAAAAUAAA\
  AAAAAAAUAAAAAAAAAAUAAAAAAABAAQAAAAAAAFAAAAAAAAAAFAAAAAAAAKAAAAAAAAAAKAAAAAAA\
  AAAKAAAAAAAAgAIAAAAAAACgAAAAAAAAACgAAAAAAAAACgAAAAAAAIACAAAAAAAAFAAAAAAAAAAF\
  AAAAAAAAQAEAAAAAAABQAAAAAAAAABQAAAAAAADAdfH/AgwAWgZwppmLmFYAAAAASUVORK5CYII='
          "
          height="1024"
          transform="matrix(0.0361 0 0 0.0361 106.6105 167.0638)"
          width="1024"
        />
      </g>
    </g>
    <text
      fill="#010101"
      font-family="Texturina"
      font-size="7px"
      font-weight="700"
      id="text2_3"
      transform="matrix(1 0 0 1 8.5069 181.6035)"
    >
      {{ tagline1 }}
    </text>
    <text
      fill="#010101"
      font-family="Texturina"
      font-size="7px"
      font-weight="700"
      id="text3_3"
      transform="matrix(1 0 0 1 8.5068 193.5544)"
    >
      {{ tagline2 }}
    </text>
    <path d="M37.46,82.93h71.39" fill="none" id="text1_x5F_1_x5F_3" />
    <text id="text1_3">
      <textPath startOffset="50%" text-anchor="middle" xlink:href="#text1_x5F_1_x5F_3">
        <tspan fill="#FFFFFF" font-family="Texturina" font-size="11.6235px">{{ title }}</tspan>
      </textPath>
    </text>
  </svg>
</template>

<script>
import { BeerLabelBaseMixin } from '@/components/labels/utils'
import { createStyle } from '@/assets/styles/StyleGothic'

export default {
  mixins: [BeerLabelBaseMixin],

  computed: {
    style() {
      return createStyle()
    },
  },
}
</script>
